import fp from "lodash/fp";
import { isValidDate, isValidTimeString } from "./DateUtils";
import {
  isBlankString,
  isValidCoordinates,
  isValidObjectId,
  isValidObjectIdForNeighborhood,
} from "./ValidateUtils";
import { parseIntString, parseString } from "./SerializeUtils";
import { api } from "../api/shared/BaseApi";
import { IS_PRINTING } from "../components/orders-core/AdminPostForm";
import { formatDateTimeToUrl } from "./FormatUtils";
import { IS_PRINTING2 } from "../containers/admin/AdminCashCertificateContainer";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const EMAIL_REG =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = email => EMAIL_REG.test(email);

export const getObjectId = fp.flow(fp.get("id"), fp.toInteger, id =>
  id > 0 ? id : null,
);

export const getObjectIdIncludingUnknown = fp.flow(
  fp.get("id"),
  fp.toInteger,
  id => (id > -2 ? id : null),
);

export const getObjectCode = fp.flow(fp.get("id"), id =>
  !fp.isEmpty(id) ? id : null,
);

export const idsToObjectArray = fp.flow(
  parseIntString,
  fp.map(id => ({ id })),
);
export const codesToObjectArray = fp.flow(
  parseString,
  fp.map(id => ({ id })),
);

export const createNotEmptyValidator = fp.memoize(message => value =>
  fp.isEmpty(value) ? message : null,
);

export const createValidateStringValidator = fp.memoize(message => value =>
  isBlankString(value) ? message : null,
);

export const createPhoneEmptyValidator = fp.memoize(message => value =>
  value && value.length < 8 ? message : null,
);
export const createNotEmptyValidatorWithState = fp.memoize(
  (message, setState) => value => {
    if (fp.isEmpty(value)) {
      setState(false);
      return message;
    }
    setState(true);
    return null;
  },
);

export const createNotNilValidator = fp.memoize(message => value =>
  fp.isNil(value) ? message : null,
);

export const createNotNullValidator = fp.memoize(message => value =>
  value < 1 ? message : null,
);

export const createNotFalsyValidator = fp.memoize(message => value =>
  value ? null : message,
);

export const createFileValidator = fp.memoize(message => value =>
  value instanceof File ? null : message,
);
export const tariffCodeValidator = fp.memoize(message => value =>
  value ? null : message,
);

export const createFiniteValidator = fp.memoize(message => value =>
  fp.isFinite(value) ? null : message,
);

export const createOtpValidator = fp.memoize(message => value =>
  value && message ? message : null,
);

export const createUnlimitedValidator = fp.memoize(
  (unlimited, message) => value =>
    !unlimited && !value && message
      ? message
      : value && value <= 0
      ? message
      : null,
);
export const createDateValidator = fp.memoize(message => value =>
  isValidDate(value) ? null : message,
);

export const createObjectIdValidator = fp.memoize(message => value =>
  isValidObjectId(value) ? null : message,
);

export const createObjectIdValidatorForNeighborhood = fp.memoize(
  message => value => (isValidObjectIdForNeighborhood(value) ? null : message),
);

export const createDateTimeStringValidator = fp.memoize(message => value =>
  isValidTimeString(value) ? null : message,
);

export const createRegisteredBarcodeValidator = fp.memoize(
  (barcodeErrorMessage, message) => value =>
    value && barcodeErrorMessage
      ? barcodeErrorMessage
      : fp.isEmpty(value) && message
      ? message
      : null,
);

export function checkString(str) {
  // eslint-disable-next-line lodash/prefer-lodash-typecheck
  if (typeof str !== "string" || str.length < 2) {
    return false;
  }
  const letters = str.match(/\p{L}/gu);
  return letters && letters.length >= 2;
}

export const createOtherNameValidator = fp.memoize(
  (otherNameRequired, message) => value =>
    !checkString(value) && otherNameRequired ? message : null,
);

export const createINNValidator = fp.memoize(message => value =>
  fp.isEmpty(value) && message ? message : null,
);

export const createMXIKCodeValidator = fp.memoize(message => value =>
  fp.isEmpty(value) && message ? message : null,
);

export const createPaymentsValidator = fp.memoize(message => value =>
  fp.isEmpty(value) && message ? message : null,
);

export const createUnlimitedDateTimeStringValidator = fp.memoize(
  message => value => (isValidTimeString(value, true) ? null : message),
);

export const createCoordinatesValidator = fp.memoize(message => value =>
  isValidCoordinates(value) ? null : message,
);

export const createPercentValidator = fp.memoize(message => value =>
  fp.isFinite(value) && value >= 0 && value <= 100 ? null : message,
);

export const toFinite = v => fp.toFinite(v);

export function pdfDownload(dataurl, ids) {
  const a = document.createElement("a");
  a.href = dataurl;
  a.setAttribute("download", `${ids}.pdf`);
  a.click();
  return false;
}

export const downloadPDF = (url, id) => {
  api.get(url, { params: { ids: id } }).then(res =>
    fetch(res.data)
      .then(response => response.blob())
      .then(myBlob => pdfDownload(URL.createObjectURL(myBlob), id)),
  );
};
export const setInvalidate = (
  state,
  setState,
  loading,
  batchIds,
  showErrorMessage,
  showSuccessMessage,
  refresh,
) => {
  setState(prevState => ({ ...prevState, [loading]: true }));
  api
    .post(state.url, { body: { batch_ids: batchIds } })
    .catch(error => {
      setState(prevState => ({ ...prevState, [loading]: false }));
      showErrorMessage(error);
    })
    .then(res => {
      if (res.status === "success") {
        showSuccessMessage(res.status);
        setState(prevState => ({ ...prevState, [loading]: false }));
        refresh();
      } else {
        showErrorMessage(res);
        setState(prevState => ({ ...prevState, [loading]: false }));
      }
    });
};
export const getPDF = (state, setState, loading, id, showErrorMessage) => {
  setState(prevState => ({ ...prevState, [loading]: true }));
  api
    .get(state.url, { params: { ids: fp.isArray(id) ? id.join(",") : id } })
    .catch(error => {
      setState(prevState => ({ ...prevState, [loading]: false }));
      showErrorMessage(error);
    })
    .then(res =>
      fetch(res.data)
        .then(response => response.blob())
        .then(myBlob => {
          let objectURL = URL.createObjectURL(myBlob);
          if (myBlob && myBlob.size > 0) {
            if (loading === IS_PRINTING) {
              const iframe = document.createElement("iframe");
              iframe.style.display = "none";
              document.body.appendChild(iframe);
              iframe.src = objectURL;
              objectURL = URL.revokeObjectURL(myBlob);
              window.setTimeout(() => {
                iframe.contentWindow.print();
                setState(prevState => ({ ...prevState, [loading]: false }));
              }, 1000);
            } else {
              pdfDownload(objectURL, id);
              setState(prevState => ({ ...prevState, [loading]: false }));
            }
          } else {
            showErrorMessage(
              "Сервер не отвечает,пожалуйста повторите попытку позже",
            );
            setState(prevState => ({ ...prevState, [loading]: false }));
          }
        }),
    );
};

export const getForm55PDF = (
  state,
  setState,
  loading,
  id,
  paramsFilter,
  showErrorMessage,
) => {
  setState(prevState => ({ ...prevState, [loading]: true }));
  const params =
    id && id.length > 0
      ? { ids: fp.isArray(id) ? id.join(",") : id }
      : { ...paramsFilter };
  api
    .get(state.url, { params })
    .catch(error => {
      setState(prevState => ({ ...prevState, [loading]: false }));
      showErrorMessage(error);
    })
    .then(res =>
      fetch(res.data)
        .then(response => response.blob())
        .then(myBlob => {
          let objectURL = URL.createObjectURL(myBlob);
          if (myBlob && myBlob.size > 0) {
            if (loading === IS_PRINTING) {
              const iframe = document.createElement("iframe");
              iframe.style.display = "none";
              document.body.appendChild(iframe);
              iframe.src = objectURL;
              objectURL = URL.revokeObjectURL(myBlob);
              window.setTimeout(() => {
                iframe.contentWindow.print();
                setState(prevState => ({ ...prevState, [loading]: false }));
              }, 1000);
            } else {
              pdfDownload(objectURL, id);
              setState(prevState => ({ ...prevState, [loading]: false }));
            }
          } else {
            showErrorMessage(
              "Сервер не отвечает,пожалуйста повторите попытку позже",
            );
            setState(prevState => ({ ...prevState, [loading]: false }));
          }
        }),
    );
};

export const getMpPDF = (
  state,
  setState,
  loading,
  paramsFilter,
  showErrorMessage,
) => {
  setState(prevState => ({ ...prevState, [loading]: true }));
  const params = { ...paramsFilter };
  api
    .get(state.url, { params })
    .catch(error => {
      setState(prevState => ({ ...prevState, [loading]: false }));
      showErrorMessage(error);
    })
    .then(res =>
      fetch(res.data)
        .then(response => response.blob())
        .then(myBlob => {
          let objectURL = URL.createObjectURL(myBlob);
          if (myBlob && myBlob.size > 0) {
            if (loading === IS_PRINTING) {
              const iframe = document.createElement("iframe");
              iframe.style.display = "none";
              document.body.appendChild(iframe);
              iframe.src = objectURL;
              objectURL = URL.revokeObjectURL(myBlob);
              window.setTimeout(() => {
                iframe.contentWindow.print();
                setState(prevState => ({ ...prevState, [loading]: false }));
              }, 1000);
            } else {
              pdfDownload(objectURL);
              setState(prevState => ({ ...prevState, [loading]: false }));
            }
          } else {
            showErrorMessage(
              "Сервер не отвечает,пожалуйста повторите попытку позже",
            );
            setState(prevState => ({ ...prevState, [loading]: false }));
          }
        }),
    );
};

export const getIPSPDF = (state, setState, loading, id, showErrorMessage) => {
  setState(prevState => ({ ...prevState, [loading]: true }));
  api
    .get(`${state.url}/${id}`)
    .catch(error => {
      setState(prevState => ({ ...prevState, [loading]: false }));
      showErrorMessage(error);
    })
    .then(res => {
      // eslint-disable-next-line lodash/prefer-get
      if (res && res.data && res.data.value) {
        fetch(res.data.value)
          .then(response => response.blob())
          .then(myBlob => {
            let objectURL = URL.createObjectURL(myBlob);
            if (myBlob && myBlob.size > 0) {
              if (loading === IS_PRINTING) {
                const iframe = document.createElement("iframe");
                iframe.style.display = "none";
                document.body.appendChild(iframe);
                iframe.src = objectURL;
                objectURL = URL.revokeObjectURL(myBlob);
                window.setTimeout(() => {
                  iframe.contentWindow.print();
                  setState(prevState => ({ ...prevState, [loading]: false }));
                }, 1000);
              } else {
                pdfDownload(objectURL, id);
                setState(prevState => ({ ...prevState, [loading]: false }));
              }
            } else {
              showErrorMessage(
                "Сервер не отвечает,пожалуйста повторите попытку позже",
              );
              setState(prevState => ({ ...prevState, [loading]: false }));
            }
          });
      } else {
        showErrorMessage("Pdf not found");

        setState(prevState => ({ ...prevState, [loading]: false }));
      }
    });
};

export const getShiftPDF = (state, setState, loading, id, showErrorMessage) => {
  setState(prevState => ({ ...prevState, [loading]: true }));
  api
    .get(`${state.url}/${id}`)
    .catch(error => {
      setState(prevState => ({ ...prevState, [loading]: false }));
      showErrorMessage(error);
    })
    .then(res => {
      // eslint-disable-next-line lodash/prefer-get
      if (res && res.data) {
        fetch(res.data)
          .then(response => response.blob())
          .then(myBlob => {
            let objectURL = URL.createObjectURL(myBlob);
            if (myBlob && myBlob.size > 0) {
              if (loading === IS_PRINTING) {
                const iframe = document.createElement("iframe");
                iframe.style.display = "none";
                document.body.appendChild(iframe);
                iframe.src = objectURL;
                objectURL = URL.revokeObjectURL(myBlob);
                window.setTimeout(() => {
                  iframe.contentWindow.print();
                  setState(prevState => ({ ...prevState, [loading]: false }));
                }, 1000);
              } else {
                pdfDownload(objectURL, id);
                setState(prevState => ({ ...prevState, [loading]: false }));
              }
            } else {
              showErrorMessage(
                "Сервер не отвечает,пожалуйста повторите попытку позже",
              );
              setState(prevState => ({ ...prevState, [loading]: false }));
            }
          });
      } else {
        showErrorMessage("Pdf not found");

        setState(prevState => ({ ...prevState, [loading]: false }));
      }
    });
};

export const getPDF2 = (
  state,
  setState,
  loading,
  id,
  showErrorMessage,
  consolidated,
) => {
  setState(prevState => ({ ...prevState, [loading]: true }));
  api
    .get(state.url, {
      params: { ids: fp.isArray(id) ? id.join(",") : id, consolidated },
    })
    .catch(error => {
      setState(prevState => ({ ...prevState, [loading]: false }));
      showErrorMessage(error);
    })
    .then(res =>
      fetch(res.data)
        .then(response => response.blob())
        .then(myBlob => {
          let objectURL = URL.createObjectURL(myBlob);
          if (myBlob && myBlob.size > 0) {
            if (loading === IS_PRINTING) {
              const iframe = document.createElement("iframe");
              iframe.style.display = "none";
              document.body.appendChild(iframe);
              iframe.src = objectURL;
              objectURL = URL.revokeObjectURL(myBlob);
              window.setTimeout(() => {
                iframe.contentWindow.print();
                setState(prevState => ({ ...prevState, [loading]: false }));
              }, 1000);
            } else {
              pdfDownload(objectURL, id);
              setState(prevState => ({ ...prevState, [loading]: false }));
            }
          } else {
            showErrorMessage(
              "Сервер не отвечает,пожалуйста повторите попытку позже",
            );
            setState(prevState => ({ ...prevState, [loading]: false }));
          }
        }),
    );
};
export const getPDFHybrid = (
  state,
  setState,
  loading,
  id,
  showErrorMessage,
  hybrid,
) => {
  setState(prevState => ({ ...prevState, [loading]: true }));
  api
    .get(state.url, {
      params: { ids: fp.isArray(id) ? id.join(",") : id, hybrid },
    })
    .catch(error => {
      setState(prevState => ({ ...prevState, [loading]: false }));
      showErrorMessage(error);
    })
    .then(res =>
      fetch(res.data)
        .then(response => response.blob())
        .then(myBlob => {
          let objectURL = URL.createObjectURL(myBlob);
          if (myBlob && myBlob.size > 0) {
            if (loading === IS_PRINTING) {
              const iframe = document.createElement("iframe");
              iframe.style.display = "none";
              document.body.appendChild(iframe);
              iframe.src = objectURL;
              objectURL = URL.revokeObjectURL(myBlob);
              window.setTimeout(() => {
                iframe.contentWindow.print();
                setState(prevState => ({ ...prevState, [loading]: false }));
              }, 1000);
            } else {
              pdfDownload(objectURL, id);
              setState(prevState => ({ ...prevState, [loading]: false }));
            }
          } else {
            showErrorMessage(
              "Сервер не отвечает,пожалуйста повторите попытку позже",
            );
            setState(prevState => ({ ...prevState, [loading]: false }));
          }
        }),
    );
};

export const getCCPDF = (
  state,
  setState,
  loading,
  showErrorMessage,
  fromDate,
  toDate,
  direction,
) => {
  const parsedFromDate = formatDateTimeToUrl(fromDate.setHours(0, 0, 0, 0));
  const parsedToDate = formatDateTimeToUrl(toDate.setHours(23, 59, 0, 0));
  setState(prevState => ({ ...prevState, [loading]: true }));
  api
    .get(state.url, {
      params: {
        from_date_time: parsedFromDate,
        to_date_time: parsedToDate,
        direction,
      },
    })
    .catch(error => {
      setState(prevState => ({ ...prevState, [loading]: false }));
      showErrorMessage(error);
    })
    .then(res =>
      fetch(res.data)
        .then(response => response.blob())
        .then(myBlob => {
          let objectURL = URL.createObjectURL(myBlob);
          if (myBlob && myBlob.size > 0) {
            if (loading === IS_PRINTING || loading === IS_PRINTING2) {
              const iframe = document.createElement("iframe");
              iframe.style.display = "none";
              document.body.appendChild(iframe);
              iframe.src = objectURL;
              objectURL = URL.revokeObjectURL(myBlob);
              window.setTimeout(() => {
                iframe.contentWindow.print();
                setState(prevState => ({ ...prevState, [loading]: false }));
              }, 1000);
            } else {
              pdfDownload(objectURL, "document");
              setState(prevState => ({ ...prevState, [loading]: false }));
            }
          } else {
            showErrorMessage(
              "Сервер не отвечает,пожалуйста повторите попытку позже",
            );
            setState(prevState => ({ ...prevState, [loading]: false }));
          }
        }),
    );
};

const parseReceiptHumo = input => {
  const lines = input
    .trim()
    .split("\n")
    .map(line => line.trim());

  return input.includes("Введен") && lines.length === 23
    ? {
        title: lines[0],
        organization: lines[1],
        address: `${lines[2]} ${lines[3]}`,
        terminal_id: lines[4].match(/ID Терм\.:([^\s]+)/)[1],
        shift_number: parseInt(lines[4].match(/Номер смены (\d+)/)[1], 10),
        organization_id: lines[5].match(/ID Орг\.:([^\s]+)/)[1],
        check_number: parseInt(lines[5].match(/Чек (\d+)/)[1], 10),
        transaction: {
          type: lines[6],
          status: lines[7],
          amount: lines[8].match(/СУММА:\s+(.+)/)[1].trim(),
          aid: lines[9].match(/AID:([^\s]+)/)[1],
          method: lines[9].match(/HUMO/)[0],
          tvr: lines[10].split(":")[1].trim(),
          card_type: lines[11].split(":")[1].trim(),
          card_number: lines[12].split(":")[0].trim(),
          card_holder: lines[13].split(":")[0].trim(),
          pin_entered: lines[14],
          auth_code: lines[15].match(/Код авториз\.:([^\s]+)/)[1],
          response_code: lines[15].match(/Код ответа:([^\s]+)/)[1],
          rrn: lines[16].split(":")[1].trim(),
        },
        date: lines[17].match(/Дата:([^\s]+)/)[1],
        time: lines[17].match(/([0-9]{2}:[0-9]{2}:[0-9]{2})/)[0],
        pos_version: lines[18],
        footer: lines[19],
        note: lines[20],
      }
    : input.includes("Введен")
    ? {
        title: lines[0],
        organization: lines[1],
        address: `${lines[2]} ${lines[3]}`,
        terminal_id: lines[4].match(/ID Терм\.:([^\s]+)/)[1],
        shift_number: parseInt(lines[4].match(/Номер смены (\d+)/)[1], 10),
        organization_id: lines[5].match(/ID Орг\.:([^\s]+)/)[1],
        check_number: parseInt(lines[5].match(/Чек (\d+)/)[1], 10),
        transaction: {
          type: lines[6],
          status: lines[7],
          amount: lines[8].match(/СУММА:\s+(.+)/)[1].trim(),
          aid: lines[9].match(/AID:([^\s]+)/)[1],
          method: lines[9].match(/HUMO/)[0],
          tvr: lines[10].split(":")[1].trim(),
          card_type: lines[11].split(":")[1].trim(),
          card_number: lines[12].split(":")[0].trim(),
          pin_entered: lines[13],
          auth_code: lines[14].match(/Код авториз\.:([^\s]+)/)[1],
          response_code: lines[14].match(/Код ответа:([^\s]+)/)[1],
          rrn: lines[15].split(":")[1].trim(),
        },
        date: lines[16].match(/Дата:([^\s]+)/)[1],
        time: lines[16].match(/([0-9]{2}:[0-9]{2}:[0-9]{2})/)[0],
        pos_version: lines[17],
        footer: lines[18],
        note: lines[19],
      }
    : {
        title: lines[0],
        organization: lines[1],
        address: `${lines[2]} ${lines[3]}`,
        terminal_id: lines[4].match(/ID Терм\.:([^\s]+)/)[1],
        shift_number: parseInt(lines[4].match(/Номер смены (\d+)/)[1], 10),
        organization_id: lines[5].match(/ID Орг\.:([^\s]+)/)[1],
        check_number: parseInt(lines[5].match(/Чек (\d+)/)[1], 10),
        transaction: {
          type: lines[6],
          status: lines[7],
          amount: lines[8].match(/СУММА:\s+(.+)/)[1].trim(),
          aid: lines[9].match(/AID:([^\s]+)/)[1],
          method: lines[9].match(/HUMO/)[0],
          tvr: lines[10].split(":")[1].trim(),
          card_type: lines[11].split(":")[1].trim(),
          card_number: lines[12].split(":")[0].trim(),
          auth_code: lines[13].match(/Код авториз\.:([^\s]+)/)[1],
          response_code: lines[13].match(/Код ответа:([^\s]+)/)[1],
          rrn: lines[14].split(":")[1].trim(),
        },
        date: lines[15].match(/Дата:([^\s]+)/)[1],
        time: lines[15].match(/([0-9]{2}:[0-9]{2}:[0-9]{2})/)[0],
        pos_version: lines[16],
        footer: lines[17],
        note: lines[18],
      };
};

const parseReceiptHumoError = input => {
  const lines = input
    .trim()
    .split("\n")
    .map(line => line.trim());

  return input.includes("Введен") && lines.length === 24
    ? {
        title: lines[0],
        organization: lines[1],
        address: `${lines[2]} ${lines[3]}`,
        terminal_id: lines[4].match(/ID Терм\.:([^\s]+)/)[1],
        shift_number: parseInt(lines[4].match(/Номер смены (\d+)/)[1], 10),
        organization_id: lines[5].match(/ID Орг\.:([^\s]+)/)[1],
        check_number: parseInt(lines[5].match(/Чек (\d+)/)[1], 10),
        transaction: {
          type: lines[6],
          status: lines[7],
          error: lines[8],
          amount: lines[9].match(/СУММА:\s+(.+)/)[1].trim(),
          aid: lines[10].match(/AID:([^\s]+)/)[1],
          method: lines[10].match(/HUMO/)[0],
          tvr: lines[11].split(":")[1].trim(),
          card_type: lines[12].split(":")[1].trim(),
          card_number: lines[13].split(":")[0].trim(),
          card_holder: lines[14].split(":")[0].trim(),
          pin_entered: lines[15],
          auth_code: lines[16].match(/Код авториз\.:([^\s]+)/)[1],
          response_code: lines[16].match(/Код ответа:([^\s]+)/)[1],
          rrn: lines[17].split(":")[1].trim(),
        },
        date: lines[18].match(/Дата:([^\s]+)/)[1],
        time: lines[18].match(/([0-9]{2}:[0-9]{2}:[0-9]{2})/)[0],
        pos_version: lines[19],
        footer: lines[20],
        note: lines[21],
      }
    : input.includes("Введен") && lines.length === 23
    ? {
        title: lines[0],
        organization: lines[1],
        address: `${lines[2]} ${lines[3]}`,
        terminal_id: lines[4].match(/ID Терм\.:([^\s]+)/)[1],
        shift_number: parseInt(lines[4].match(/Номер смены (\d+)/)[1], 10),
        organization_id: lines[5].match(/ID Орг\.:([^\s]+)/)[1],
        check_number: parseInt(lines[5].match(/Чек (\d+)/)[1], 10),
        transaction: {
          type: lines[6],
          status: lines[7],
          error: lines[8],
          amount: lines[9].match(/СУММА:\s+(.+)/)[1].trim(),
          aid: lines[10].match(/AID:([^\s]+)/)[1],
          method: lines[10].match(/HUMO/)[0],
          tvr: lines[11].split(":")[1].trim(),
          card_type: lines[12].split(":")[1].trim(),
          card_number: lines[13].split(":")[0].trim(),
          pin_entered: lines[14],
          auth_code: lines[15].match(/Код авториз\.:([^\s]+)/)[1],
          response_code: lines[15].match(/Код ответа:([^\s]+)/)[1],
          rrn: lines[16].split(":")[1].trim(),
        },
        date: lines[17].match(/Дата:([^\s]+)/)[1],
        time: lines[17].match(/([0-9]{2}:[0-9]{2}:[0-9]{2})/)[0],
        pos_version: lines[18],
        footer: lines[19],
        note: lines[20],
      }
    : {
        title: lines[0],
        organization: lines[1],
        address: `${lines[2]} ${lines[3]}`,
        terminal_id: lines[4].match(/ID Терм\.:([^\s]+)/)[1],
        shift_number: parseInt(lines[4].match(/Номер смены (\d+)/)[1], 10),
        organization_id: lines[5].match(/ID Орг\.:([^\s]+)/)[1],
        check_number: parseInt(lines[5].match(/Чек (\d+)/)[1], 10),
        transaction: {
          type: lines[6],
          status: lines[7],
          error: lines[8],
          amount: lines[9].match(/СУММА:\s+(.+)/)[1].trim(),
          aid: lines[10].match(/AID:([^\s]+)/)[1],
          method: lines[10].match(/HUMO/)[0],
          tvr: lines[11].split(":")[1].trim(),
          card_type: lines[12].split(":")[1].trim(),
          card_number: lines[13].split(":")[0].trim(),
          auth_code: lines[14].match(/Код авториз\.:([^\s]+)/)[1],
          response_code: lines[14].match(/Код ответа:([^\s]+)/)[1],
          rrn: lines[15].split(":")[1].trim(),
        },
        date: lines[16].match(/Дата:([^\s]+)/)[1],
        time: lines[16].match(/([0-9]{2}:[0-9]{2}:[0-9]{2})/)[0],
        pos_version: lines[17],
        footer: lines[18],
        note: lines[19],
      };
};

const parseReceiptVisa = input => {
  const lines = input
    .trim()
    .split("\n")
    .map(line => line.trim());

  return lines.length === 25
    ? {
        title: lines[0],
        organization: lines[1],
        address: `${lines[2]} ${lines[3]}`,
        terminal_id: lines[4].match(/ID Терм\.:([^\s]+)/)[1],
        shift_number: parseInt(lines[4].match(/Номер смены (\d+)/)[1], 10),
        organization_id: lines[5].match(/ID Орг\.:([^\s]+)/)[1],
        check_number: parseInt(lines[5].match(/Чек (\d+)/)[1], 10),
        transaction: {
          type: lines[6],
          status: lines[7],
          amount: lines[8].match(/СУММА:\s+(.+)/)[1].trim(),
          aid: lines[9].match(/AID:([^\s]+)/)[1],
          method: lines[10],
          tvr: lines[12].split(":")[1].trim(),
          card_type: lines[13].split(":")[1].trim(),
          card_number: lines[14].split(":")[0].trim(),
          card_holder: lines[15].split(":")[0].trim(),
          pin_entered: lines[16],
          auth_code: lines[17].match(/Код авториз\.:([^\s]+)/)[1],
          response_code: lines[17].match(/Код ответа:([^\s]+)/)[1],
          rrn: lines[18].split(":")[1].trim(),
        },
        date: lines[19].match(/Дата:([^\s]+)/)[1],
        time: lines[19].match(/([0-9]{2}:[0-9]{2}:[0-9]{2})/)[0],
        pos_version: lines[20],
        footer: lines[21],
        note: lines[22],
      }
    : {
        title: lines[0],
        organization: lines[1],
        address: `${lines[2]} ${lines[3]}`,
        terminal_id: lines[4].match(/ID Терм\.:([^\s]+)/)[1],
        shift_number: parseInt(lines[4].match(/Номер смены (\d+)/)[1], 10),
        organization_id: lines[5].match(/ID Орг\.:([^\s]+)/)[1],
        check_number: parseInt(lines[5].match(/Чек (\d+)/)[1], 10),
        transaction: {
          type: lines[6],
          status: lines[7],
          amount: lines[8].match(/СУММА:\s+(.+)/)[1].trim(),
          aid: lines[9].match(/AID:([^\s]+)/)[1],
          method: lines[10],
          tvr: lines[12].split(":")[1].trim(),
          card_type: lines[13].split(":")[1].trim(),
          card_number: lines[14].split(":")[0].trim(),
          pin_entered: lines[15],
          auth_code: lines[16].match(/Код авториз\.:([^\s]+)/)[1],
          response_code: lines[16].match(/Код ответа:([^\s]+)/)[1],
          rrn: lines[17].split(":")[1].trim(),
        },
        date: lines[18].match(/Дата:([^\s]+)/)[1],
        time: lines[18].match(/([0-9]{2}:[0-9]{2}:[0-9]{2})/)[0],
        pos_version: lines[19],
        footer: lines[20],
        note: lines[21],
      };
};

const parseReceiptVisaError = input => {
  const lines = input
    .trim()
    .split("\n")
    .map(line => line.trim());

  return lines.length === 26
    ? {
        title: lines[0],
        organization: lines[1],
        address: `${lines[2]} ${lines[3]}`,
        terminal_id: lines[4].match(/ID Терм\.:([^\s]+)/)[1],
        shift_number: parseInt(lines[4].match(/Номер смены (\d+)/)[1], 10),
        organization_id: lines[5].match(/ID Орг\.:([^\s]+)/)[1],
        check_number: parseInt(lines[5].match(/Чек (\d+)/)[1], 10),
        transaction: {
          type: lines[6],
          status: lines[7],
          error: lines[8],
          amount: lines[9].match(/СУММА:\s+(.+)/)[1].trim(),
          aid: lines[10].match(/AID:([^\s]+)/)[1],
          method: lines[11],
          tvr: lines[13].split(":")[1].trim(),
          card_type: lines[14].split(":")[1].trim(),
          card_number: lines[15].split(":")[0].trim(),
          card_holder: lines[16],
          pin_entered: lines[17],
          auth_code: lines[18].match(/Код авториз\.:([^\s]+)/)[1],
          response_code: lines[18].match(/Код ответа:([^\s]+)/)[1],
          rrn: lines[19].split(":")[1].trim(),
        },
        date: lines[20].match(/Дата:([^\s]+)/)[1],
        time: lines[20].match(/([0-9]{2}:[0-9]{2}:[0-9]{2})/)[0],
        pos_version: lines[21],
        footer: lines[22],
        note: lines[23],
      }
    : {
        title: lines[0],
        organization: lines[1],
        address: `${lines[2]} ${lines[3]}`,
        terminal_id: lines[4].match(/ID Терм\.:([^\s]+)/)[1],
        shift_number: parseInt(lines[4].match(/Номер смены (\d+)/)[1], 10),
        organization_id: lines[5].match(/ID Орг\.:([^\s]+)/)[1],
        check_number: parseInt(lines[5].match(/Чек (\d+)/)[1], 10),
        transaction: {
          type: lines[6],
          status: lines[7],
          error: lines[8],
          amount: lines[9].match(/СУММА:\s+(.+)/)[1].trim(),
          aid: lines[10].match(/AID:([^\s]+)/)[1],
          method: lines[11],
          tvr: lines[13].split(":")[1].trim(),
          card_type: lines[14].split(":")[1].trim(),
          card_number: lines[15].split(":")[0].trim(),
          auth_code: lines[17].match(/Код авториз\.:([^\s]+)/)[1],
          response_code: lines[17].match(/Код ответа:([^\s]+)/)[1],
          rrn: lines[18].split(":")[1].trim(),
        },
        date: lines[19].match(/Дата:([^\s]+)/)[1],
        time: lines[19].match(/([0-9]{2}:[0-9]{2}:[0-9]{2})/)[0],
        pos_version: lines[20],
        footer: lines[21],
        note: lines[22],
      };
};

const parseReceiptUzcardDuo = str => {
  const lines = str
    .split("\n")
    .map(line => line.trim())
    .filter(line => line); // Remove empty lines and trim spaces

  return str.includes("Введен") && lines.length === 25
    ? {
        title: lines[0],
        company: lines[1],
        address: lines.slice(2, 4).join(", "),
        terminalId: lines[4].match(/ID Терм\.:([\d]+)/)?.[1]?.trim(),
        shiftNumber: lines[4].match(/Номер смены ([\d]+)/)?.[1]?.trim(),
        orgId: lines[5].match(/ID Орг\.:([\d]+)/)?.[1]?.trim(),
        receiptNumber: lines[5].match(/Чек ([\d]+)/)?.[1]?.trim(),
        paymentType: lines[6].trim(),
        status: lines[7].trim(),
        // eslint-disable-next-line no-useless-escape
        amount: lines[8].match(/СУММА:\s+([\d\.]+ UZS)/)?.[1]?.trim(),
        aid: lines[9].match(/AID:([A-Z0-9]+)/)?.[1]?.trim(),
        aidDetails: lines[10]
          .split(" ")
          .slice(1)
          .join(" ")
          .trim(),
        tvr: lines[12].match(/TVR:\s+([\dA-Z:]+)/)?.[1]?.trim(),
        cardType: lines[13].match(/Карта:([\w\s]+)/)?.[1]?.trim(),
        cardNumber: lines[14].split(":")[0].trim(),
        cardholder: lines[15].trim(),
        pinEntered: lines[16],
        authCode: lines[17].match(/Код авториз\.:([\d]+)/)?.[1]?.trim(),
        responseCode: lines[17].match(/Код ответа:([\d]+)/)?.[1]?.trim(),
        rrn: lines[18].match(/RRN\(ссылка\)\s*:\s*([\d]+)/)?.[1]?.trim(),
        date: lines[19].match(/Дата:([\d/]+)/)?.[1]?.trim(),
        time: lines[19].match(/[\d/]+\s+([\d:]+)/)?.[1]?.trim(),
        posDetails: lines[20].trim(),
        botInfo: lines[22],
      }
    : str.includes("Введен")
    ? {
        title: lines[0],
        company: lines[1],
        address: lines.slice(2, 4).join(", "),
        terminalId: lines[4].match(/ID Терм\.:([\d]+)/)?.[1]?.trim(),
        shiftNumber: lines[4].match(/Номер смены ([\d]+)/)?.[1]?.trim(),
        orgId: lines[5].match(/ID Орг\.:([\d]+)/)?.[1]?.trim(),
        receiptNumber: lines[5].match(/Чек ([\d]+)/)?.[1]?.trim(),
        paymentType: lines[6].split(":")[0].trim(),
        status: lines[7].trim(),
        // eslint-disable-next-line no-useless-escape
        amount: lines[8].match(/СУММА:\s+([\d\.]+ UZS)/)?.[1]?.trim(),
        aid: lines[9].match(/AID:([A-Z0-9]+)/)?.[1]?.trim(),
        aidDetails: lines[10]
          .split(" ")
          .slice(1)
          .join(" ")
          .trim(),
        tvr: lines[12].match(/TVR:\s+([\dA-Z:]+)/)?.[1]?.trim(),
        cardType: lines[13].match(/Карта:([\w\s]+)/)?.[1]?.trim(),
        cardNumber: lines[14].trim(),
        pinEntered: lines[15],
        authCode: lines[16].match(/Код авториз\.:([\d]+)/)?.[1]?.trim(),
        responseCode: lines[16].match(/Код ответа:([\d]+)/)?.[1]?.trim(),
        rrn: lines[17].match(/RRN\(ссылка\)\s*:\s*([\d]+)/)?.[1]?.trim(),
        date: lines[18].match(/Дата:([\d/]+)/)?.[1]?.trim(),
        time: lines[18].match(/[\d/]+\s+([\d:]+)/)?.[1]?.trim(),
        posDetails: lines[19].trim(),
        botInfo: lines[21],
      }
    : {
        title: lines[0],
        company: lines[1],
        address: lines.slice(2, 4).join(", "),
        terminalId: lines[4].match(/ID Терм\.:([\d]+)/)?.[1]?.trim(),
        shiftNumber: lines[4].match(/Номер смены ([\d]+)/)?.[1]?.trim(),
        orgId: lines[5].match(/ID Орг\.:([\d]+)/)?.[1]?.trim(),
        receiptNumber: lines[5].match(/Чек ([\d]+)/)?.[1]?.trim(),
        paymentType: lines[6].trim(),
        status: lines[7].trim(),
        // eslint-disable-next-line no-useless-escape
        amount: lines[8].match(/СУММА:\s+([\d\.]+ UZS)/)?.[1]?.trim(),
        aid: lines[9].match(/AID:([A-Z0-9]+)/)?.[1]?.trim(),
        aidDetails: lines[10]
          .split(" ")
          .slice(1)
          .join(" ")
          .trim(),
        tvr: lines[12].match(/TVR:\s+([\dA-Z:]+)/)?.[1]?.trim(),
        cardType: lines[13].match(/Карта:([\w\s]+)/)?.[1]?.trim(),
        cardNumber: lines[14].split(":")[0].trim(),
        pinEntered: false,
        authCode: lines[15].match(/Код авториз\.:([\d]+)/)?.[1]?.trim(),
        responseCode: lines[15].match(/Код ответа:([\d]+)/)?.[1]?.trim(),
        rrn: lines[16].match(/RRN\(ссылка\)\s*:\s*([\d]+)/)?.[1]?.trim(),
        date: lines[17].match(/Дата:([\d/]+)/)?.[1]?.trim(),
        time: lines[17].match(/[\d/]+\s+([\d:]+)/)?.[1]?.trim(),
        posDetails: lines[18].trim(),
        botInfo: lines[20],
      };
};

const parseReceiptUzcard = str => {
  const lines = str
    .split("\n")
    .map(line => line.trim())
    .filter(line => line); // Remove empty lines and trim spaces

  return {
    title: lines[0],
    company: lines[1],
    address: lines.slice(2, 4).join(", "),
    terminalId: lines[4].match(/ID Терм\.:([\d]+)/)?.[1]?.trim(),
    shiftNumber: lines[4].match(/Номер смены ([\d]+)/)?.[1]?.trim(),
    orgId: lines[5].match(/ID Орг\.:([\d]+)/)?.[1]?.trim(),
    receiptNumber: lines[5].match(/Чек ([\d]+)/)?.[1]?.trim(),
    paymentType: lines[6].trim(),
    status: lines[7].trim(),
    // eslint-disable-next-line no-useless-escape
    amount: lines[8].match(/СУММА:\s+([\d\.]+ UZS)/)?.[1]?.trim(),
    aid: lines[9].match(/AID:([A-Z0-9]+)/)?.[1]?.trim(),
    aidDetails: lines[9],
    tvr: lines[10].match(/TVR:\s+([\dA-Z:]+)/)?.[1]?.trim(),
    cardType: lines[11].match(/Карта:([\w\s]+)/)?.[1]?.trim(),
    cardNumber: lines[12].split(":")[0].trim(),
    cardholder: lines[13].trim(),
    pinEntered: lines[14].includes("Введен"),
    authCode: lines[15].match(/Код авториз\.:([\d]+)/)?.[1]?.trim(),
    responseCode: lines[15].match(/Код ответа:([\d]+)/)?.[1]?.trim(),
    rrn: lines[16].match(/RRN\(ссылка\)\s*:\s*([\d]+)/)?.[1]?.trim(),
    date: lines[17].match(/Дата:([\d/]+)/)?.[1]?.trim(),
    time: lines[17].match(/[\d/]+\s+([\d:]+)/)?.[1]?.trim(),
    posDetails: lines[18].trim(),
    botInfo: lines[20],
  };
};

const parseReceiptUnionPay = str => {
  const lines = str
    .split("\n")
    .map(line => line.trim())
    .filter(line => line); // Remove empty lines and trim spaces

  return {
    title: lines[0],
    company: lines[1],
    address: lines.slice(2, 4).join(", "),
    terminalId: lines[4].match(/ID Терм\.:([\d]+)/)?.[1]?.trim(),
    shiftNumber: lines[4].match(/Номер смены ([\d]+)/)?.[1]?.trim(),
    orgId: lines[5].match(/ID Орг\.:([\d]+)/)?.[1]?.trim(),
    receiptNumber: lines[5].match(/Чек ([\d]+)/)?.[1]?.trim(),
    paymentType: lines[6].trim(),
    status: lines[7].trim(),
    // eslint-disable-next-line no-useless-escape
    amount: lines[8].match(/СУММА:\s+([\d\.]+ UZS)/)?.[1]?.trim(),
    aid: lines[9].match(/AID:([A-Z0-9]+)/)?.[1]?.trim(),
    aidDetails: lines[10],
    tvr: lines[12].match(/TVR:\s+([\dA-Z:]+)/)?.[1]?.trim(),
    cardType: lines[13].match(/Карта:([\w\s]+)/)?.[1]?.trim(),
    cardNumber: lines[14].split(":")[0].trim(),
    pinEntered: lines[15].includes("Введен"),
    authCode: lines[16].match(/Код авториз\.:([\d]+)/)?.[1]?.trim(),
    responseCode: lines[16].match(/Код ответа:([\d]+)/)?.[1]?.trim(),
    rrn: lines[17].match(/RRN\(ссылка\)\s*:\s*([\d]+)/)?.[1]?.trim(),
    date: lines[18].match(/Дата:([\d/]+)/)?.[1]?.trim(),
    time: lines[18].match(/[\d/]+\s+([\d:]+)/)?.[1]?.trim(),
    posDetails: lines[19].trim(),
    botInfo: lines[21],
  };
};

const parseReceiptUzcardDuoError = str => {
  const lines = str
    .split("\n")
    .map(line => line.trim())
    .filter(line => line); // Remove empty lines and trim spaces

  return str.includes("Введен") && lines.length === 26
    ? {
        title: lines[0],
        company: lines[1],
        address: lines.slice(2, 4).join(", "),
        terminalId: lines[4].match(/ID Терм\.:([\d]+)/)?.[1]?.trim(),
        shiftNumber: lines[4].match(/Номер смены ([\d]+)/)?.[1]?.trim(),
        orgId: lines[5].match(/ID Орг\.:([\d]+)/)?.[1]?.trim(),
        receiptNumber: lines[5].match(/Чек ([\d]+)/)?.[1]?.trim(),
        paymentType: lines[6].trim(),
        status: lines[7].trim(),
        errorMessage: lines[8].trim(),
        // eslint-disable-next-line no-useless-escape
        amount: lines[9].match(/СУММА:\s+([\d\.]+ UZS)/)?.[1]?.trim(),
        aid: lines[10].match(/AID:([A-Z0-9]+)/)?.[1]?.trim(),
        aidDetails: lines[11],
        tvr: lines[13].match(/TVR:\s+([\dA-Z:]+)/)?.[1]?.trim(),
        cardType: lines[14].match(/Карта:([\w\s]+)/)?.[1]?.trim(),
        cardNumber: lines[15].split(":")[0].trim(),
        cardholder: lines[16].trim(),
        pinEntered: lines[17],
        responseCode: lines[18].match(/Код ответа:([\d]+)/)?.[1]?.trim(),
        rrn: lines[19].match(/RRN\(ссылка\)\s*:\s*([\d]+)/)?.[1]?.trim(),
        date: lines[20].match(/Дата:([\d/]+)/)?.[1]?.trim(),
        time: lines[20].match(/[\d/]+\s+([\d:]+)/)?.[1]?.trim(),
        posDetails: lines[21].trim(),
        botInfo: lines[23],
      }
    : str.includes("Введен")
    ? {
        title: lines[0],
        company: lines[1],
        address: lines.slice(2, 4).join(", "),
        terminalId: lines[4].match(/ID Терм\.:([\d]+)/)?.[1]?.trim(),
        shiftNumber: lines[4].match(/Номер смены ([\d]+)/)?.[1]?.trim(),
        orgId: lines[5].match(/ID Орг\.:([\d]+)/)?.[1]?.trim(),
        receiptNumber: lines[5].match(/Чек ([\d]+)/)?.[1]?.trim(),
        paymentType: lines[6].trim(),
        status: lines[7].trim(),
        errorMessage: lines[8].trim(),
        // eslint-disable-next-line no-useless-escape
        amount: lines[9].match(/СУММА:\s+([\d\.]+ UZS)/)?.[1]?.trim(),
        aid: lines[10].match(/AID:([A-Z0-9]+)/)?.[1]?.trim(),
        aidDetails: lines[11],
        tvr: lines[13].match(/TVR:\s+([\dA-Z:]+)/)?.[1]?.trim(),
        cardType: lines[14].match(/Карта:([\w\s]+)/)?.[1]?.trim(),
        cardNumber: lines[15].split(":")[0].trim(),
        pinEntered: lines[16],
        responseCode: lines[17].match(/Код ответа:([\d]+)/)?.[1]?.trim(),
        rrn: lines[18].match(/RRN\(ссылка\)\s*:\s*([\d]+)/)?.[1]?.trim(),
        date: lines[19].match(/Дата:([\d/]+)/)?.[1]?.trim(),
        time: lines[19].match(/[\d/]+\s+([\d:]+)/)?.[1]?.trim(),
        posDetails: lines[20].trim(),
        botInfo: lines[22],
      }
    : {
        title: lines[0],
        company: lines[1],
        address: lines.slice(2, 4).join(", "),
        terminalId: lines[4].match(/ID Терм\.:([\d]+)/)?.[1]?.trim(),
        shiftNumber: lines[4].match(/Номер смены ([\d]+)/)?.[1]?.trim(),
        orgId: lines[5].match(/ID Орг\.:([\d]+)/)?.[1]?.trim(),
        receiptNumber: lines[5].match(/Чек ([\d]+)/)?.[1]?.trim(),
        paymentType: lines[6].trim(),
        status: lines[7].trim(),
        errorMessage: lines[8].trim(),
        // eslint-disable-next-line no-useless-escape
        amount: lines[9].match(/СУММА:\s+([\d\.]+ UZS)/)?.[1]?.trim(),
        aid: lines[10].match(/AID:([A-Z0-9]+)/)?.[1]?.trim(),
        aidDetails: lines[11],
        tvr: lines[13].match(/TVR:\s+([\dA-Z:]+)/)?.[1]?.trim(),
        cardType: lines[14].match(/Карта:([\w\s]+)/)?.[1]?.trim(),
        cardNumber: lines[15].split(":")[0].trim(),
        pinEntered: false,
        responseCode: lines[16].match(/Код ответа:([\d]+)/)?.[1]?.trim(),
        rrn: lines[17].match(/RRN\(ссылка\)\s*:\s*([\d]+)/)?.[1]?.trim(),
        date: lines[18].match(/Дата:([\d/]+)/)?.[1]?.trim(),
        time: lines[18].match(/[\d/]+\s+([\d:]+)/)?.[1]?.trim(),
        posDetails: lines[19].trim(),
        botInfo: lines[21],
      };
};

const parseReceiptUzcardError = str => {
  const lines = str
    .split("\n")
    .map(line => line.trim())
    .filter(line => line); // Remove empty lines and trim spaces

  return {
    title: lines[0],
    company: lines[1],
    address: lines.slice(2, 4).join(", "),
    terminalId: lines[4].match(/ID Терм\.:([\d]+)/)?.[1]?.trim(),
    shiftNumber: lines[4].match(/Номер смены ([\d]+)/)?.[1]?.trim(),
    orgId: lines[5].match(/ID Орг\.:([\d]+)/)?.[1]?.trim(),
    receiptNumber: lines[5].match(/Чек ([\d]+)/)?.[1]?.trim(),
    paymentType: lines[6].trim(),
    status: lines[7].trim(),
    errorMessage: lines[8].trim(),
    // eslint-disable-next-line no-useless-escape
    amount: lines[9].match(/СУММА:\s+([\d\.]+ UZS)/)?.[1]?.trim(),
    aid: lines[10].match(/AID:([A-Z0-9]+)/)?.[1]?.trim(),
    aidDetails: lines[10]
      .split(" ")
      .slice(1)
      .join(" ")
      .trim(),
    tvr: lines[11].match(/TVR:\s+([\dA-Z:]+)/)?.[1]?.trim(),
    cardType: lines[12].match(/Карта:([\w\s]+)/)?.[1]?.trim(),
    cardNumber: lines[13].split(":")[0].trim(),
    cardholder: lines[14].trim(),
    pinEntered: lines[15],
    responseCode: lines[16].match(/Код ответа:([\d]+)/)?.[1]?.trim(),
    rrn: lines[17].match(/RRN\(ссылка\)\s*:\s*([\d]+)/)?.[1]?.trim(),
    date: lines[18].match(/Дата:([\d/]+)/)?.[1]?.trim(),
    time: lines[18].match(/[\d/]+\s+([\d:]+)/)?.[1]?.trim(),
    posDetails: lines[19].trim(),
    botInfo: lines[21],
  };
};

export const generatePDFHumo = stringSlip => {
  const receipt = stringSlip.includes("Visa")
    ? parseReceiptVisa(stringSlip)
    : parseReceiptHumo(stringSlip);

  const receiptHTML = `
              <div style="width: 300px; padding: 0px 10px 10px 10px; font-size: 12px; line-height: 1.5; color: #000; background: #fff; font-family: Arial, sans-serif;">
                <div style="text-align: center;">
                    <h2 style="font-size: 20px; margin: 5px 0; font-weight: bold; letter-spacing: 10px;">${
                      receipt.title
                    }</h2>
                    <div style="font-size: 16px;">${receipt.organization}</div>
                    <div style="font-size: 14px;">${receipt.address}</div>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <div>ID Терм.: <span >${receipt.terminal_id}</span></div>
                    <div>Номер смены: <span >${
                      receipt.shift_number
                    }</span></div>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <div>ID Орг.: <span >${receipt.organization_id}</span></div>
                    <div>Чек: <span >${receipt.check_number}</span></div>
                </div>
              
                <div style="display: flex; justify-content: space-between;">
                    <div>AID: <span >${receipt.transaction.aid}</span></div>
                  
                </div>
                <div style="display: flex; justify-content: space-between;">
                  <div >${receipt.transaction.method}</div> 
                  <div>TVR: <span >${receipt.transaction.tvr}</span></div>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <div>Карта: <span >${
                      receipt.transaction.card_type
                    }</span></div>
                    <div><span >${receipt.transaction.card_number}</span></div>
                  
                </div>
                
                <div>
                  <div><span >${receipt.transaction.card_holder ||
                    ""}</span></div>
                    <div><span >${receipt.transaction.pin_entered ||
                      ""}</span></div>
                    </div>
                <div style="display: flex; justify-content: space-between;">
                    <div>Код авториз.: <span >${
                      receipt.transaction.auth_code
                    }</span></div>
                    <div>Код ответа: <span >${
                      receipt.transaction.response_code
                    }</span></div>
                </div>
                <div>RRN(ссылка): <span >${receipt.transaction.rrn}</span></div>
                <div>Дата: <span >${receipt.date} ${receipt.time}</span></div>
                <div>${receipt.pos_version}${receipt.footer}</div>
                <div>${receipt.note}</div>
                <div style="text-align: center;">
                    <div style="font-size: 16px; font-weight: bold;">${
                      receipt.transaction.type
                    }</div>
                    <div style="font-weight: bold;">${
                      receipt.transaction.amount
                    }</div>
                    <div style="font-weight: bold;">${
                      receipt.transaction.status
                    }</div>
                </div>
                <div style="font-size: 12px; color: #000; text-align: center; margin-top: 10px;">
                    Операция подтверждена ПИН-кодом
                </div>
            </div>
        `;

  // Create a temporary container for rendering the HTML
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = receiptHTML;
  document.body.appendChild(tempDiv);

  // Convert HTML to canvas and generate PDF
  html2canvas(tempDiv, {
    scale: 6,
    width: 720,
    height: 600,
    useCORS: true,
  }).then(canvas => {
    const imgData = canvas.toDataURL("image/png");
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: [80, 210],
    });

    pdf.addImage(imgData, "PNG", 0, 0, 190, 190);
    const pdfBlob = pdf.output("blob");

    // Create an iframe to display the PDF
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);

    const blobURL = URL.createObjectURL(pdfBlob);
    iframe.src = blobURL;

    iframe.onload = () => {
      iframe.contentWindow.print();
      URL.revokeObjectURL(blobURL); // Cleanup the Blob URL
    };
    document.body.removeChild(tempDiv);
  });
};

export const generatePDFHumoError = stringSlip => {
  const receipt = stringSlip.includes("Visa")
    ? parseReceiptVisaError(stringSlip)
    : parseReceiptHumoError(stringSlip);

  const receiptHTML = `
            <div style="width: 300px; padding: 0px 10px 10px 10px; font-size: 12px; line-height: 1.5; color: #000; background: #fff; font-family: Arial, sans-serif;">
                <div style="text-align: center;">
                    <div>${receipt.transaction.error}</div>
                    <h2 style="font-size: 20px; font-weight: bold; letter-spacing: 10px;">${
                      receipt.title
                    }</h2>
                    <div style="font-size: 16px;">${receipt.organization}</div>
                    <div style="font-size: 14px;">${receipt.address}</div>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <div>ID Терм.: <span >${receipt.terminal_id}</span></div>
                    <div>Номер смены: <span >${
                      receipt.shift_number
                    }</span></div>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <div>ID Орг.: <span >${receipt.organization_id}</span></div>
                    <div>Чек: <span >${receipt.check_number}</span></div>
                </div>
              
                <div style="display: flex; justify-content: space-between;">
                    <div>AID: <span >${receipt.transaction.aid}</span></div>
                </div>
                <div style="display: flex; justify-content: space-between;">
                  <div >${receipt.transaction.method}</div> 
                  <div>TVR: <span >${receipt.transaction.tvr}</span></div>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <div>Карта: <span >${
                      receipt.transaction.card_type
                    }</span></div>
                    <div><span >${receipt.transaction.card_number}</span></div>
                </div>
                <div>
                   <div><span >${receipt.transaction.card_holder ||
                     ""}</span></div>
                </div>
                <div>${receipt.pinEntered ? "Введен PIN" : ""}</div>
                <div style="display: flex; justify-content: space-between;">
                    <div>Код авториз.: <span >${
                      receipt.transaction.auth_code
                    }</span></div>
                    <div>Код ответа: <span >${
                      receipt.transaction.response_code
                    }</span></div>
                </div>
                <div>RRN(ссылка): <span >${receipt.transaction.rrn}</span></div>
                <div>Дата: <span >${receipt.date} ${receipt.time}</span></div>
                <div>${receipt.pos_version}${receipt.footer}</div>
                <div>${receipt.note}</div>
                <div style="text-align: center;">
                    <div style="font-size: 16px; font-weight: bold;">${
                      receipt.transaction.type
                    }</div>
                    <div style="font-weight: bold;">${
                      receipt.transaction.amount
                    }</div>
                    <div style="font-weight: bold;">${
                      receipt.transaction.status
                    }</div>
                </div>
                <div style="font-size: 12px; color: #000; text-align: center; margin-top: 10px;">
                    Операция подтверждена ПИН-кодом
                </div>
            </div>
        `;

  // Create a temporary container for rendering the HTML
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = receiptHTML;
  document.body.appendChild(tempDiv);

  // Convert HTML to canvas and generate PDF
  html2canvas(tempDiv, {
    scale: 6,
    width: 720,
    height: 600,
    useCORS: true,
  }).then(canvas => {
    const imgData = canvas.toDataURL("image/png");
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: [80, 210],
    });

    pdf.addImage(imgData, "PNG", 0, 0, 190, 190);
    const pdfBlob = pdf.output("blob");

    // Create an iframe to display the PDF
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);

    const blobURL = URL.createObjectURL(pdfBlob);
    iframe.src = blobURL;

    iframe.onload = () => {
      iframe.contentWindow.print();
      URL.revokeObjectURL(blobURL); // Cleanup the Blob URL
    };
    document.body.removeChild(tempDiv);
  });
};

export const generatePDFCard = stringSlip => {
  const receipt = stringSlip.includes("UNIONPAY")
    ? parseReceiptUnionPay(stringSlip)
    : stringSlip.includes("DUO")
    ? parseReceiptUzcardDuo(stringSlip)
    : parseReceiptUzcard(stringSlip);

  const receiptHTML = `
            <div style=" width: 300px; padding: 0px 10px 10px 10px; font-size: 12px; line-height: 1.5; color: #000; background: #fff; font-family: Arial, sans-serif;">
                <div style="text-align: center;">
                     <div style="font-size: 20px;  font-weight: bold; letter-spacing: 10px;">${
                       receipt.title
                     }</div>
                    <div style="font-size: 16px;">${receipt.company}</div>
                    <div style="font-size: 14px;">${receipt.address}</div>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <div>ID Терм.: <span >${receipt.terminalId}</span></div>
                    <div>Номер смены: <span >${receipt.shiftNumber}</span></div>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <div>ID Орг.: <span >${receipt.orgId}</span></div>
                    <div>Чек: <span >${receipt.receiptNumber}</span></div>
                </div>
            
                <div style="display: flex; justify-content: space-between;">
                    <div>AID: <span >${receipt.aid}</span></div>
                </div>
                <div style="display: flex; justify-content: space-between;">
                  <div >${receipt.aidDetails}</div>
                  <div>TVR: <span >${receipt.tvr}</span></div>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <div>Карта: <span >${receipt.cardType}</span></div>
                    <div><span >${receipt.cardNumber}</span></div>
                </div>
                <div>${receipt.cardholder || ""}</div>
                <div style="display: flex; justify-content: space-between;">
                    <div>Код авториз.: <span >${receipt.authCode}</span></div>
                    <div>Код ответа: <span >${receipt.responseCode}</span></div>
                </div>
                <div>RRN(ссылка): <span >${receipt.rrn}</span></div>
                <div>Дата: <span >${receipt.date} ${receipt.time}</span></div>
                <div>${receipt.posDetails}</div>
                <div>${receipt.botInfo}</div>
                <div style="text-align: center; ">
                    <div style="font-size: 18px; font-weight: bold;">${
                      receipt.paymentType
                    }</div>
                    <div style="font-weight: bold;">${receipt.amount}</div>
                    <div style="font-weight: bold;">${receipt.status}</div>
                </div>
                <div style="font-size: 12px; color: #000; text-align: center; margin-top: 10px;">
                    Операция подтверждена ПИН-кодом
                </div>
            </div>
        `;

  // Create a temporary container for rendering the HTML
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = receiptHTML;
  document.body.appendChild(tempDiv);

  // Convert HTML to canvas and generate PDF
  html2canvas(tempDiv, {
    scale: 6,
    width: 720,
    height: 600,
    useCORS: true,
  }).then(canvas => {
    const imgData = canvas.toDataURL("image/png");
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: [80, 210],
    });

    pdf.addImage(imgData, "PNG", 0, 0, 190, 190);
    const pdfBlob = pdf.output("blob");

    // Create an iframe to display the PDF
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);

    const blobURL = URL.createObjectURL(pdfBlob);
    iframe.src = blobURL;

    iframe.onload = () => {
      iframe.contentWindow.print();
      URL.revokeObjectURL(blobURL); // Cleanup the Blob URL
    };
    document.body.removeChild(tempDiv);
  });
};

export const generatePDFCardError = stringSlip => {
  const receipt = stringSlip.includes("DUO")
    ? parseReceiptUzcardDuoError(stringSlip)
    : parseReceiptUzcardError(stringSlip);

  const receiptHTML = `
             <div style="width: 300px; padding: 0px 10px 10px 10px; font-size: 12px; line-height: 1.5; color: #000; background: #fff; font-family: Arial, sans-serif;">
                <div style="text-align: center; font-size: 12px">
                    <div>${receipt.errorMessage}</div>
                     <h2 style="font-size: 20px; font-weight: bold; letter-spacing: 10px;">${
                       receipt.title
                     }</h2>
                    <div style="font-size: 16px;">${receipt.company}</div>
                    <div style="font-size: 14px;">${receipt.address}</div>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <div>ID Терм.: <span >${receipt.terminalId}</span></div>
                    <div>Номер смены: <span >${receipt.shiftNumber}</span></div>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <div>ID Орг.: <span >${receipt.orgId}</span></div>
                    <div>Чек: <span >${receipt.receiptNumber}</span></div>
                </div>
               
                <div style="display: flex; justify-content: space-between;">
                    <div>AID: <span >${receipt.aid}</span></div>
                </div>
                <div style="display: flex; justify-content: space-between;">
                  <div >${receipt.aidDetails}</div>
                  <div>TVR: <span >${receipt.tvr}</span></div>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <div>Карта: <span>${receipt.cardType}</span></div>
                    <div><span >${receipt.cardNumber}</span></div>
                </div>
                <div>
                   <div><span >${receipt.cardholder || ""}</span></div>
                </div>
                 <div>${receipt.pinEntered ? "Введен PIN" : ""}</div>
                <div style="display: flex; justify-content: space-between;">
                    <div>Код ответа: <span >${receipt.responseCode}</span></div>
                </div>
                <div>RRN(ссылка): <span >${receipt.rrn}</span></div>
                <div>Дата: <span >${receipt.date} ${receipt.time}</span></div>
                <div>${receipt.posDetails}</div>
                <div>${receipt.botInfo}</div>
                <div style="text-align: center;">
                    <div style="font-size: 18px; font-weight: bold;">${
                      receipt.paymentType
                    }</div>
                    <div style="font-weight: bold;">${receipt.amount}</div>
                    <div style="font-weight: bold;">${receipt.status}</div>
                </div>
                <div style="font-size: 12px; color: #000; text-align: center; margin-top: 10px;">
                    Операция подтверждена ПИН-кодом
                </div>
            </div>
        `;

  // Create a temporary container for rendering the HTML
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = receiptHTML;
  document.body.appendChild(tempDiv);

  // Convert HTML to canvas and generate PDF
  html2canvas(tempDiv, {
    scale: 6,
    width: 720,
    height: 600,
    useCORS: true,
  }).then(canvas => {
    const imgData = canvas.toDataURL("image/png");
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: [80, 210],
    });

    pdf.addImage(imgData, "PNG", 0, 0, 190, 190);
    const pdfBlob = pdf.output("blob");

    // Create an iframe to display the PDF
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);

    const blobURL = URL.createObjectURL(pdfBlob);
    iframe.src = blobURL;

    iframe.onload = () => {
      iframe.contentWindow.print();
      URL.revokeObjectURL(blobURL); // Cleanup the Blob URL
    };
    document.body.removeChild(tempDiv);
  });
};
