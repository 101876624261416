import {
  API_ROOT_URL,
  API_V2_ROOT_URL,
} from "../../../shared/constants/ApiConstants";
import { api } from "../shared/BaseApi";

const CLICKHOUSE_WAREHOUSE_OUT_REPORT_URL = `${API_ROOT_URL}/clickhouse/warehouse_out`;
const CLICKHOUSE_WAREHOUSE_IN_REPORT_URL = `${API_ROOT_URL}/clickhouse/warehouse_in`;
export const CLICKHOUSE_WAREHOUSE_REPORT_URL = `${API_V2_ROOT_URL}/warehouse_report`;

export const getWarehouseOutReports = params =>
  api.get(CLICKHOUSE_WAREHOUSE_OUT_REPORT_URL, {
    params,
  });
export const getWarehouseInReports = params =>
  api.get(CLICKHOUSE_WAREHOUSE_IN_REPORT_URL, {
    params,
  });
