import React, { useState } from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import ConfirmDialog from "../deprecated/ConfirmDialog";
import ModalPaper from "../ui-core/ModalPaper";
import IpsBatchSyncPushByRangeForm from "./IpsBatchSyncPushByRangeForm";
import { ipsOrderSync } from "../../api/admin/AdminBatchHistoryUpdateApi";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { formatDateTimeToUrlWithTSeconds } from "../../helpers/FormatUtils";
import { endOfToday, startOfToday } from "date-fns";

const enhancer = compose(
  renderIf("open"),
  useSheet({
    paper: { maxWidth: "560px", minWidth: "560px", minHeight: "120px" },
  }),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
);

IpsBatchSyncPushByRangeDialog.propTypes = {
  classes: PropTypes.object,
  filter: PropTypes.object,

  onRequestClose: PropTypes.func.isRequired,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setIsOpenSuccessLoading: PropTypes.func,
  setIsOpenSuccess: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function IpsBatchSyncPushByRangeDialog(props) {
  const { classes, getLocalisationMessage } = props;
  const [confirm, setConfirm] = useState(false);
  const date = startOfToday();
  const date2 = endOfToday();
  const [dispatchValues, setDispatchValues] = useState({
    fromDate: date,
    toDate: date2,
  });

  return confirm ? (
    <ConfirmDialog
      confirmButtonLabel={getLocalisationMessage("yes", "Yes")}
      dismissButtonLabel={getLocalisationMessage("no", "No")}
      open={confirm}
      onConfirm={() => {
        props.setIsOpenSuccessLoading(true);
        props.setIsOpenSuccess(true);
        ipsOrderSync({
          ...toSnakeCase(dispatchValues),
          is_out_bound: false,
          size: 20,
          barcodes: [],
        })
          .then(() => {
            props.setIsOpenSuccessLoading(false);
            setConfirm(false);
          })
          .catch(error => {
            props.setIsOpenSuccessLoading(false);
            props.showErrorMessage(error);
            props.setIsOpenSuccess(false);
          });
      }}
      onRequestClose={() => {
        setConfirm(false);
      }}
    >
      {getLocalisationMessage(
        "are_you_sure_you_want_to_sync_filtered_orders_with_ips",
        "Are you sure you want to sync filtered orders with IPS",
      )}
    </ConfirmDialog>
  ) : (
    <ModalPaper
      open={true}
      title={getLocalisationMessage("sync_by_range", "Sync by range")}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
    >
      <IpsBatchSyncPushByRangeForm
        initialValues={dispatchValues}
        onClick={values => {
          setDispatchValues({
            fromDate: formatDateTimeToUrlWithTSeconds(values.fromDate),
            toDate: formatDateTimeToUrlWithTSeconds(values.toDate),
          });
          setConfirm(true);
        }}
        onDismiss={props.onRequestClose}
        onSubmitSuccess={() => {
          props.onRequestClose();
          props.showSuccessMessage(
            getLocalisationMessage("successfully_push", "Successfully Push"),
          );
        }}
        onSubmitFail={props.showErrorMessage}
        showSuccessMessage={props.showSuccessMessage}
        showErrorMessage={props.showErrorMessage}
      />
    </ModalPaper>
  );
}

export default enhancer(IpsBatchSyncPushByRangeDialog);
