import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";
import { api } from "../shared/BaseApi";
import sprintf from "sprintf";

const ORDER_FLOW_OPERATION_URL = `${API_ROOT_URL}/order_flow_operation`;
const ORDER_FLOW_OPERATION_ITEM_URL = `${API_ROOT_URL}/order_flow_operation/%s`;

export const getOrderFlowOperationList = params =>
  api.get(ORDER_FLOW_OPERATION_URL, { params });

export const createOrderFlowOperation = body =>
  api.post(ORDER_FLOW_OPERATION_URL, { body });

export const updateOrderFlowOperation = body =>
  api.put(ORDER_FLOW_OPERATION_URL, { body });

export const deleteOrderFlowOperation = id =>
  api.delete(sprintf(ORDER_FLOW_OPERATION_ITEM_URL, id));

export const getOrderFlowOperationItem = id =>
  api.get(sprintf(ORDER_FLOW_OPERATION_ITEM_URL, id));
