import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { DATE_TIME_SHORT_FORMAT } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import { endOfDay, startOfDay } from "date-fns";
import FormDateTimeField from "../form/FormDateTimeField";
import { Card, CardActions, CardContent } from "@material-ui/core";
import fp from "lodash/fp";

const getValues = getFormValues("IpsBatchSyncPushByRangeForm");

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "IpsBatchSyncPushByRangeForm",
    enableReinitialize: true,
  }),
  connect(
    fp.flow(getValues, fp.toPlainObject, values => ({
      values: {
        ...values,
      },
    })),
  ),
);

IpsBatchSyncPushByRangeForm.propTypes = {
  getLocalisationMessage: PropTypes.func,
  onClick: PropTypes.func,
  change: PropTypes.func,
  values: PropTypes.object,
};

function IpsBatchSyncPushByRangeForm(props) {
  const { getLocalisationMessage, change } = props;

  return (
    <FlexBox
      align="center"
      container={16}
      style={{ gap: 14 }}
      element={<form autoComplete="off" />}
    >
      <Card style={{ width: "100%" }}>
        <CardContent>
          <FlexBox style={{ gap: 14 }}>
            <FlexBox flex={true}>
              <FormDateTimeField
                fullWidth={true}
                ampmInClock={true}
                formatValue={DATE_TIME_SHORT_FORMAT}
                hintText={getLocalisationMessage("from_date")}
                name="fromDate"
                onChange={v => change("fromDate", startOfDay(v))}
              />
            </FlexBox>
            <FlexBox flex={true}>
              <FormDateTimeField
                fullWidth={true}
                formatValue={DATE_TIME_SHORT_FORMAT}
                hintText={getLocalisationMessage("to_date")}
                name="toDate"
                onChange={v => change("toDate", endOfDay(v))}
              />
            </FlexBox>
          </FlexBox>
        </CardContent>

        <CardActions>
          <FlexBox flex={true}>
            <CustomButton
              variant={CONTAINED}
              color={SECONDARY}
              style={{ backgroundColor: "#3F51B5" }}
              onClick={() => props.onClick(props.values)}
              size="large"
              fullWidth={true}
            >
              {getLocalisationMessage("submit", "Submit")}
            </CustomButton>
          </FlexBox>
        </CardActions>
      </Card>
    </FlexBox>
  );
}

export default enhancer(IpsBatchSyncPushByRangeForm);
