import React from "react";
import fp from "lodash/fp";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { safeParseDate } from "../../helpers/DateUtils";
import { DATE_TIME_SHORT_FORMAT, formatDate } from "../../helpers/FormatUtils";
import { DateTimePicker } from "@material-ui/pickers";
import {
  getCurrentLanguage,
  getMessage,
} from "../../reducers/LocalizationReducer";
import CustomButton, { SECONDARY } from "../ui-core/CustomButton";
import { compose } from "recompose";
import { connect } from "react-redux";

const enhancer = compose(
  connect(state => ({
    language: getCurrentLanguage(state),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

DateFieldTimeComponent.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
  language: PropTypes.string,
  inputRef: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  initialFocusedDate: PropTypes.instanceOf(Date),
  style: PropTypes.object,
};

export function DateFieldTimeComponent({
  initialFocusedDate,
  style,
  input,
  meta,
  language,
  getLocalisationMessage,
  ...custom
}) {
  const value = safeParseDate(input.value);

  return (
    <DateTimePicker
      {...input}
      {...custom}
      value={value}
      lang={language}
      ampm={false}
      error={Boolean(meta.touched && meta.error)}
      onChange={date => {
        input.onChange(date || new Date());
      }}
      initialFocusedDate={initialFocusedDate}
      format={custom.formatValue}
      onBlur={fp.noop}
      placeholder={custom.placeholder}
      helperText={meta.touched && meta.error}
      label={custom.label}
      inputVariant={custom.variant}
      size={custom.size}
      cancelLabel={
        <CustomButton color={SECONDARY}>
          {getLocalisationMessage("cancel")}
        </CustomButton>
      }
      okLabel={<CustomButton color={SECONDARY}>OK</CustomButton>}
    />
  );
}

FormDateTimeField.propTypes = {
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  formatDate: PropTypes.func,
  label: PropTypes.node,
  initialFocusedDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  placeholder: PropTypes.string,
  formatValue: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
  language: PropTypes.string,
  inputRef: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

FormDateTimeField.defaultProps = {
  formatDate: date => formatDate(date, DATE_TIME_SHORT_FORMAT),
  formatValue: DATE_TIME_SHORT_FORMAT,
  variant: "outlined",
  size: "small",
};

function FormDateTimeField(props) {
  return <Field {...props} component={DateFieldTimeComponent} />;
}

export default enhancer(FormDateTimeField);
