import React, { useEffect, useState } from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import AppTitle from "../../components/deprecated/AppTitle";
import { formatDateTimeToUrl, safeParseDate } from "../../helpers/FormatUtils";
import { endOfToday, startOfToday, subDays, format } from "date-fns";
import { getValue } from "../../helpers/DataUtils";
import { getUserWarehouseId } from "../../reducers/ProfileReducer";
import WarehouseDashboardFilterForm from "../../components/dashboard-core/WarehouseDashboardFilterForm";
import {
  ButtonGroup,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import {
  CLICKHOUSE_WAREHOUSE_REPORT_URL,
  getWarehouseInReports,
  getWarehouseOutReports,
} from "../../api/admin/AdminWarehouseReportsApi";
import {
  IS_DOWNLOADING,
  IS_PRINTING,
} from "../../components/orders-core/AdminPostForm";
import CustomButton, {
  OUTLINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import { getMpPDF } from "../../helpers/FormUtils";
import { GetApp, Print } from "@material-ui/icons";

const enhancer = compose(
  connect(
    state => ({
      warehouseId: getUserWarehouseId(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  getContext({
    setLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
  }),
);

AdminDriverDashboardContainer.propTypes = {
  getLocalisationMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  warehouseId: PropTypes.string,
};

function AdminDriverDashboardContainer(props) {
  const { getLocalisationMessage, warehouseId } = props;

  const date = startOfToday();
  const date2 = endOfToday();

  const [outData, setOutData] = useState([]);

  const [inData, setInData] = useState([]);

  const [filter, setFilter] = useState({
    from_date_time: formatDateTimeToUrl(subDays(date, 1)),
    to_date_time: formatDateTimeToUrl(subDays(date2, 1)),
    warehouse: {
      id: warehouseId,
    },
  });

  useEffect(() => {
    getWarehouseOutReports({
      from_date_time: filter.from_date_time,
      to_date_time: filter.to_date_time,
      warehouse_id: getValue(filter, "warehouse.id", null),
    })
      .then(r => {
        setOutData(getValue(r, "data.countries", []));
      })
      .catch(() => setOutData([]));
  }, [filter]);

  useEffect(() => {
    getWarehouseInReports({
      from_date_time: filter.from_date_time,
      to_date_time: filter.to_date_time,
      warehouse_id: getValue(filter, "warehouse.id", null),
    })
      .then(r => {
        setInData(getValue(r, "data.countries"), []);
      })
      .catch(() => setInData([]));
  }, [filter]);

  const headersOut = [
    getLocalisationMessage("region", "Region"),
    ...getValue(outData[0], "result", []).map(item => item.label),
    getLocalisationMessage("total"),
  ];
  const headersIn = [
    getLocalisationMessage("region", "Region"),
    ...getValue(inData[0], "result", []).map(item => item.label),
    getLocalisationMessage("total"),
  ];

  const [pdf, setPdf] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: CLICKHOUSE_WAREHOUSE_REPORT_URL,
  });

  return (
    <AdminAppLayout
      slug="warehouse_report"
      title={getLocalisationMessage("warehouse_report", "Warehouse Report")}
    >
      <FlexBox style={{ marginTop: 20, gap: 13, width: "100%" }}>
        <FlexBox style={{ width: "100%" }}>
          <AppTitle
            title={getLocalisationMessage(
              "warehouse_report",
              "Warehouse Report",
            )}
          >
            <WarehouseDashboardFilterForm
              initialValues={{
                fromDateTime: safeParseDate(filter.from_date_time),
                toDateTime: safeParseDate(filter.to_date_time),
                warehouse: filter.warehouse,
              }}
              onSubmit={v =>
                setFilter({
                  from_date_time: formatDateTimeToUrl(v.fromDateTime),
                  to_date_time: formatDateTimeToUrl(v.toDateTime),
                  warehouse: getValue(v, "warehouse"),
                  driver: getValue(v, "driver"),
                })
              }
            />
          </AppTitle>
        </FlexBox>
      </FlexBox>
      <FlexBox style={{ gap: 13, width: "100%" }} flex={true}>
        <Card style={{ width: "100%", height: "99%", marginTop: 20 }}>
          <CardContent>
            <FlexBox
              flex="none"
              direction="column"
              style={{
                padding: "2px 0 2px 20px",
                gap: 16,
              }}
            >
              <FlexBox style={{ gap: 20 }} direction="column">
                <FlexBox style={{ gap: 13 }}>
                  <Typography variant="h6">
                    {`${getLocalisationMessage(
                      "from_date",
                      "From date",
                    )} ${format(
                      safeParseDate(
                        getValue(filter, "from_date_time", new Date()),
                      ),
                      "dd/MM/yy",
                    )}`}
                    .
                  </Typography>
                  <Typography variant="h6">
                    {`${getLocalisationMessage("to_date", "To date")} ${format(
                      safeParseDate(
                        getValue(filter, "to_date_time", new Date()),
                      ),
                      "dd/MM/yy",
                    )}`}
                    .
                  </Typography>
                </FlexBox>

                <FlexBox direction="column" style={{ gap: 20 }}>
                  <FlexBox flex={true} direction="row" justify="space-between">
                    <FlexBox style={{ gap: 13 }}>
                      <Typography variant="h6">
                        {getLocalisationMessage(
                          "incoming_shipments",
                          "Incoming Shipments",
                        )}
                        .
                      </Typography>
                    </FlexBox>

                    <FlexBox>
                      <ButtonGroup variant={OUTLINED} color={SECONDARY}>
                        <CustomButton
                          onClick={() =>
                            getMpPDF(
                              pdf,
                              setPdf,
                              IS_PRINTING,
                              {
                                fromDateTime: filter.from_date_time,
                                toDateTime: filter.to_date_time,
                                warehouseId: getValue(
                                  filter,
                                  "warehouse.id",
                                  null,
                                ),
                              },
                              props.showErrorMessage,
                            )
                          }
                          endIcon={
                            pdf[IS_PRINTING] ? (
                              <CircularProgress size={20} color="secondary" />
                            ) : (
                              <Print />
                            )
                          }
                        >
                          {getLocalisationMessage("download", "Download")}
                        </CustomButton>
                        <CustomButton
                          onClick={() =>
                            getMpPDF(
                              pdf,
                              setPdf,
                              IS_DOWNLOADING,
                              {
                                fromDateTime: filter.from_date_time,
                                toDateTime: filter.to_date_time,
                                warehouseId: getValue(
                                  filter,
                                  "warehouse.id",
                                  null,
                                ),
                              },
                              props.showErrorMessage,
                            )
                          }
                        >
                          {pdf[IS_DOWNLOADING] ? (
                            <CircularProgress size={20} color="secondary" />
                          ) : (
                            <GetApp />
                          )}
                        </CustomButton>
                      </ButtonGroup>
                    </FlexBox>
                  </FlexBox>
                  <table
                    border="1"
                    style={{ borderCollapse: "collapse", width: "100%" }}
                  >
                    <thead>
                      <tr>
                        {headersIn.map((header, index) => (
                          <th
                            key={index}
                            style={{ padding: "8px", textAlign: "center" }}
                          >
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {inData.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              width: 300,
                            }}
                          >
                            {row.country}
                          </td>
                          {getValue(row, "result", []).map(
                            (item, itemIndex) => (
                              <td
                                key={itemIndex}
                                style={{
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {item.value}
                              </td>
                            ),
                          )}
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            {row.total}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </FlexBox>

                <FlexBox direction="column" style={{ gap: 20 }}>
                  <FlexBox style={{ gap: 13 }}>
                    <Typography variant="h6">
                      {getLocalisationMessage(
                        "outgoing_shipments",
                        "Outgoing shipments",
                      )}
                      .
                    </Typography>
                  </FlexBox>
                  <table
                    border="1"
                    style={{ borderCollapse: "collapse", width: "100%" }}
                  >
                    <thead>
                      <tr>
                        {headersOut.map((header, index) => (
                          <th
                            key={index}
                            style={{ padding: "8px", textAlign: "center" }}
                          >
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {outData.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              width: 300,
                            }}
                          >
                            {row.country}
                          </td>
                          {getValue(row, "result", []).map(
                            (item, itemIndex) => (
                              <td
                                key={itemIndex}
                                style={{
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {item.value}
                              </td>
                            ),
                          )}
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            {row.total}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </CardContent>
        </Card>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminDriverDashboardContainer);
