import {
  API_ROOT_URL,
  API_V2_ROOT_URL,
} from "../../../shared/constants/ApiConstants";
import { api } from "../shared/BaseApi";

const CLICKHOUSE_DRIVER_REPORT_URL = `${API_ROOT_URL}/clickhouse/driver/report`;
export const CLICKHOUSE_DRIVER_REPORT_PDF_URL = `${API_V2_ROOT_URL}/driver_report`;

export const getDriverReports = params =>
  api.get(CLICKHOUSE_DRIVER_REPORT_URL, {
    params,
  });
