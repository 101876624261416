import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";

const enhancer = compose(
  useSheet({
    container: {
      width: "100%",
      padding: "15px",
      // paddingBottom: "120px"
    },
    title: {
      fontSize: "16px",
    },
    mainContainer: {
      "& > div:nth-child(1)": {
        fontSize: "16px",
      },
      "& > div:nth-child(2)": {
        fontSize: "20px",
        color: "#E91E63",
        "& > span": {
          fontSize: "12px",
          lineHeight: "18px",
        },
      },
    },
    priceValue: {
      fontWeight: "bold",
      "& > span": {
        paddingRight: "5px",
      },
    },
  }),
);

StackedBarChart.propTypes = {
  data: PropTypes.object.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

StackedBarChart.defaultProps = {
  width: 100,
  height: 20,
  max: 100,
};

function StackedBarChart(props) {
  const options = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 15,
        right: 15,
        top: 15,
        bottom: 15,
      },
    },

    indexAxis: "y",
    scales: {
      x: {
        stacked: true,
        display: false,
      },
      y: {
        min: 0,
        stacked: true,
      },
    },
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      tooltip: {
        mode: "nearest",

        callbacks: {
          title() {
            return null;
          },
          label(tooltipItem) {
            return `${tooltipItem.label}: ${new Intl.NumberFormat(
              "ru-RU",
            ).format(tooltipItem.raw)}`;
          },
        },
        yPadding: 10,
        bodySpacing: 3,
        titleFontSize: 18,
        bodyFontSize: 18,
        displayColors: false,
      },
      legend: {
        display: false,
      },
    },
  };

  return (
    <div
      style={{
        maxHeight: "600px",
        margin: 10,
      }}
    >
      <Bar
        data={props.data}
        options={options}
        width={props.width}
        height={props.height}
      />
    </div>
  );
}

export default enhancer(StackedBarChart);
