import _ from "lodash";
import {
  differenceInMilliseconds as dateDifferenceInSeconds,
  format as dateFormat,
  formatDistance as dateDistanceInWords,
  formatDistanceToNow as dateformatDistance,
  isFuture as dateIsFuture,
} from "date-fns";
import fp from "lodash/fp";
import numberFormat from "number-format.js";
/* eslint-disable */
import enLocale from "date-fns/locale/en-US";
import ruLocale from "date-fns/locale/ru";
import uzLocale from "date-fns/locale/uz";
/* eslint-enable */
import {
  blue,
  deepOrange,
  deepPurple,
  green,
  red,
} from "@material-ui/core/colors";
import { safeParseDate } from "./DateUtils";
import { phoneCountyCodeList } from "../constants/PhoneCountryCodes";
import { CM_KG, INCHES_POUNDS } from "../constants/UnitsOfMeasureTypes";
import { KM, MILE } from "../constants/UnitOfDistanceConstants";
import Cookies from "js-cookie";

const localeMap = {
  en: enLocale,
  ru: ruLocale,
  uz: uzLocale,
};
const colors = [
  green[900],
  blue[900],
  deepPurple[900],
  deepOrange[900],
  red[900],
  green[800],
  blue[800],
  deepPurple[800],
  deepOrange[800],
  red[800],
  green[700],
  blue[700],
  deepPurple[700],
  deepOrange[700],
  red[700],
  green[600],
  blue[600],
  deepPurple[600],
  deepOrange[600],
  red[600],
  green[500],
  blue[500],
  deepPurple[500],
  deepOrange[500],
  red[500],
];

export { safeParseDate };

const DIGIT_REGEXP = /\d+/g;
const INTEGER_REGEXP = /-?\d+/g;
const POSITIVE_INTEGER_REGEXP = /\d+/g;
const NUMBER_REGEXP = /-?\d+(\.\d+)?/g;
const POSITIVE_NUMBER_REGEXP = /\d+(\.\d+)?/g;

export const DATE_TIME_SHORT_FORMAT = "dd/MM/yy HH:mm";
export const DATE_SHORT_FORMAT = "dd/MM/yy";
export const DATE_FORMAT = "do MMM yyyy";
export const MONTH_YEAR_FORMAT = "MMM dd, yyyy";
export const DATE_URL_FORMAT = "yyyy-MM-dd";
export const DATE_TIME_URL_FORMAT = "yyyy-MM-dd HH:mm";
export const DATE_TIME_URL_FORMAT_WITH_SECONDS = "yyyy-MM-dd HH:mm:ss";
export const DATE_TIME_URL_FORMAT_WITH_T_SECONDS = "yyyy-MM-dd'T'HH:mm:ss";
// export const FULL_DATE_URL_FORMAT = "ddd, MMM D, yyyy";
export const FULL_DATE_URL_FORMAT = "EEEE, MMMM d, yyyy";

export const TIME_FORMAT = "HH:mm";

export const PRICE_FORMAT = "#,##0.## AED";
export const NUMBER_FORMAT = "#,##0.####";
export const NUMBER_FORMAT_WITHOUT_DELIMETER = "##0.##";

export const extractDigitArray = fp.flow(
  fp.trim,
  v => v.match(DIGIT_REGEXP) || [],
);
export const extractIntNumberArray = fp.flow(
  fp.trim,
  v => v.match(INTEGER_REGEXP) || [],
);
export const extractPositiveIntNumberArray = fp.flow(
  fp.trim,
  v => v.match(POSITIVE_INTEGER_REGEXP) || [],
);
export const extractFloatNumberArray = fp.flow(
  fp.trim,
  v => v.match(NUMBER_REGEXP) || [],
);
export const extractPositiveFloatNumberArray = fp.flow(
  fp.trim,
  v => v.match(POSITIVE_NUMBER_REGEXP) || [],
);

export const extractIntNumber = fp.flow(
  extractIntNumberArray,
  fp.head,
  fp.trim,
);
export const extractPositiveIntNumber = fp.flow(
  extractPositiveIntNumberArray,
  fp.head,
  fp.trim,
);
export const extractFloatNumber = fp.flow(
  extractFloatNumberArray,
  fp.head,
  fp.trim,
);
export const extractPositiveFloatNumber = fp.flow(
  extractPositiveFloatNumberArray,
  fp.head,
  fp.trim,
);

export const parseFloat = fp.flow(extractFloatNumber, fp.toFinite);
export const parseOnlyPositiveFloat = fp.flow(
  extractPositiveFloatNumber,
  fp.toFinite,
);
export const parseInteger = fp.flow(extractIntNumber, fp.toFinite);
export const parseOnlyPositiveInteger = fp.flow(
  extractPositiveIntNumber,
  fp.toFinite,
);

export function formatDate(date, format = DATE_FORMAT, notSetValue = null) {
  const value = safeParseDate(date);
  const options = {};
  options.locale = localeMap[Cookies.get("language")];
  return value ? dateFormat(value, format, options) : notSetValue;
}

export function formatDateToUrl(date, notSetValue) {
  return formatDate(date, DATE_URL_FORMAT, notSetValue);
}

export function formatDateTimeToUrl(date, notSetValue) {
  return formatDate(date, DATE_TIME_URL_FORMAT, notSetValue);
}

export const formatDateTimeToUrlWithSeconds = (date, notSetValue) =>
  formatDate(date, DATE_TIME_URL_FORMAT_WITH_SECONDS, notSetValue);

export const formatDateTimeToUrlWithTSeconds = (date, notSetValue) =>
  formatDate(date, DATE_TIME_URL_FORMAT_WITH_T_SECONDS, notSetValue);

export function formatDateTimeFullUrl(date, notSetValue) {
  const value = safeParseDate(date);

  return value ? dateFormat(value) : notSetValue;
}

export function formatTime(date, notSetValue) {
  return formatDate(date, TIME_FORMAT, notSetValue);
}

export function formatDateDistanceToNow(
  date,
  notSetValue = null,
  getLocalisationMessage,
) {
  const value = safeParseDate(date);

  if (!value) {
    return notSetValue;
  }
  const options = {};

  if (getLocalisationMessage) {
    // const currentLanguage = getCurrentLanguage();

    // if (currentLanguage === RUSSIAN_LANGUAGE) {
    options.locale = localeMap[Cookies.get("language")];
    // }
  }
  const dateDistance = dateformatDistance(value, options);
  if (getLocalisationMessage) {
    return dateIsFuture(value)
      ? `${getLocalisationMessage("after") || "after"} ${dateDistance}`
      : `${dateDistance} ${getLocalisationMessage("ago") || "ago"}`;
  }

  return dateIsFuture(value) ? `after ${dateDistance}` : `${dateDistance} ago`;
}

export function formatDateDistanceToNowInSeconds(date, notSetValue = 0) {
  const value = safeParseDate(date);

  if (!value) {
    return notSetValue;
  }

  return dateDifferenceInSeconds(value, new Date()) / 1000;
}

export function formatDuration(seconds, notSetValue = null) {
  return fp.isFinite(seconds)
    ? dateDistanceInWords(new Date(0, 0, 0), new Date(0, 0, 0, 0, 0, seconds), {
        includeSeconds: true,
      })
    : notSetValue;
}

export function formatDistanceForKM(
  distance,
  unitOfDistance = KM,
  notSetValue = 0,
) {
  const returnDistance = distance > 0 ? distance : notSetValue;

  switch (unitOfDistance) {
    case MILE:
      return formatNumber(returnDistance / 1.60934, "#.00 M");
    case KM:
      return formatNumber(returnDistance, "#.00 km");
    default:
      return `${returnDistance} ${KM}`;
  }
}

export function formatDistance(distance, notSetValue = null) {
  return distance ? formatNumber(distance / 1000, "#.00 km") : notSetValue;
}

export function formatDistanceInUnity(
  distance,
  unitOfDistance = "m",
  notSetValue = null,
) {
  if (distance === null || distance < 0) {
    return notSetValue;
  }

  switch (unitOfDistance) {
    case MILE:
      return formatNumber(distance / 1609.34, "#.00 mile");
    case KM:
      return formatNumber(distance / 1000, "#.00 km");
    default:
      return distance;
  }
}

export function formatWeight(weight, notSetValue = null) {
  return weight ? formatNumber(weight, "#0.00 kg") : notSetValue;
}

export function formatPercentage(value) {
  return formatNumber(value, "0.00%");
}

export function formatNumber(
  value,
  format = NUMBER_FORMAT,
  notSetValue = null,
) {
  const number = fp.toNumber(value);

  return fp.isFinite(number)
    ? fp.toString(numberFormat(format, number))
    : notSetValue;
}

/**
 * @deprecated - Use `Price` component.
 */
export function formatPrice(price, notSetValue = null) {
  return formatNumber(price, PRICE_FORMAT, notSetValue);
}

export const parsePhone = fp.flow(
  extractDigitArray,
  fp.join(""),
  fp.truncate({ length: 15, omission: "" }),
);

export function formatPhone(code, phone, clean = true) {
  if (fp.isEmpty(code)) {
    return "";
  }

  const cleanCode = parsePhone(code);

  if (fp.isUndefined(phone)) {
    return `+${cleanCode}`;
  }

  let cleanValue = parsePhone(phone);

  if (fp.startsWith(cleanCode, cleanValue)) {
    cleanValue = cleanValue.slice(cleanCode.length);
  } else {
    // TODO: Added by Umid to clean the phone when code is changed
    cleanValue = clean ? "" : cleanValue;
  }

  return `(+${cleanCode}) ${cleanValue}`;
}

export function formatPhone2(phone) {
  if (!phone) {
    return phone;
  }
  const cleanPhone = phone.replace(/[^\d]/g, "");
  return `+${cleanPhone}`;
}

export const findPhoneCodeByCountryName = country => {
  const findedCountry = _.find(phoneCountyCodeList, { country });
  return findedCountry && findedCountry.code;
};
export const cuteCodeFromPhone = (phone = "", code = "") => {
  if (phone.length > 0) {
    const cleanPhone = parsePhone(phone);
    const cleanCode = parsePhone(code);
    return cleanPhone.startsWith(cleanCode)
      ? cleanPhone.slice(cleanCode.length)
      : cleanPhone;
  }
  return phone;
};

export function formatPhoneWithPlus(phone) {
  if (!phone) return "";
  const cleanValue = parsePhone(phone);
  return `+${cleanValue}`;
}

export const formatDateTime = (date, notSetValue) =>
  formatDate(date, DATE_TIME_SHORT_FORMAT, notSetValue);

export const formatText = fp.flow(
  fp.trim,
  fp.startCase,
  fp.split(" "),
  fp.map(fp.capitalize),
  fp.join(" "),
);

export const cleanupStringArray = fp.flow(
  fp.castArray,
  fp.map(fp.trim),
  fp.compact,
);
export const cleanupNumberArray = fp.flow(
  fp.castArray,
  fp.map(fp.toNumber),
  fp.filter(fp.isFinite),
);

export const takeFirstString = fp.flow(cleanupStringArray, fp.first);

export function wrapText(value, start = "(", end = ")") {
  const str = fp.trim(value);

  return str ? start + str + end : "";
}

export function numberToColor(number) {
  const index = fp.isFinite(number) ? fp.toLength(number) % colors.length : 0;

  return colors[index];
}

export function numberToCharacter(number) {
  const firstChar = "A".charCodeAt(0);
  const lastChar = "Z".charCodeAt(0);
  const length = lastChar - firstChar + 1;

  let out = "";
  let value = fp.isFinite(number) ? fp.toLength(number) : 0;

  while (value >= 0) {
    out = String.fromCharCode((value % length) + firstChar) + out;
    value = Math.floor(value / length) - 1;
  }

  return out;
}

export const formatErrorMessage = x =>
  fp.isString(x)
    ? x
    : !fp.isError(x) && !fp.isPlainObject(x)
    ? null
    : formatErrorMessage(x._error || x.reasonMessage || x.message);

export function formatNumberMinLength(number, length) {
  return `0${number}`.slice(-length);
}

export const leftMenuToggleFunc = (
  state,
  setState,
  initiallyOpen,
  groupName,
  groupNameIsEqual,
) => {
  if (state.loaded) setState(fp.set("loaded", false));
  if (state.loaded && initiallyOpen) {
    setState(
      fp.flow(fp.set("menuName", groupName), fp.set("isOpen", !state.isOpen)),
    );
  } else if (groupNameIsEqual) {
    setState(fp.set("isOpen", !state.isOpen));
  } else {
    setState(fp.flow(fp.set("menuName", groupName), fp.set("isOpen", true)));
  }
};
export const formatPriceRegex = length => {
  const formatRegex = "#,##0.";
  const decimalRegex = _.padEnd("", length, "0");

  return `${formatRegex}${decimalRegex}`;
};

export const formatUnitsOfMeasureTypes = (type, i18n) => {
  switch (type) {
    case CM_KG:
      return i18n.get("cm_kg", "SM / KG");
    case INCHES_POUNDS:
      return i18n.get("inches_pounds", "Inches/Pounds");
    default:
      return type;
  }
};

export const takeFirst20Chars = fp.flow(
  fp.trim,
  fp.slice(0, 15),
  fp.join(""),
  fp.toLower,
  fp.startCase,
);

export const wordsTo = fp.flow(
  fp.trim,
  fp.toLower,
  fp.split(" "),
  fp.map(fp.capitalize),
  fp.join("_"),
);
export const wordsTo2 = fp.flow(fp.toLower, fp.split(" "), fp.join("_"));

export function formatNoun(orderSize, messages, defaultMessage = "") {
  if (!orderSize) {
    return defaultMessage;
  }
  let messageTail = null;
  switch (orderSize) {
    case 1:
      messageTail = messages.get("orders_1");
      break;
    case 0:
    case 2:
    case 3:
    case 4:
    case 5:
      messageTail = messages.get("orders_2");
      break;
    default:
      messageTail = messages.get("orders_3");
  }
  return messageTail;
}

export const fade = (color, value) => `rgba(${color},${value})`;

export function formatDateDistanceToNowV2(date, notSetValue = {}) {
  const value = safeParseDate(date);

  if (!value) {
    return notSetValue;
  }
  const options = {};

  const dateDistance = dateformatDistance(value, options);

  return dateIsFuture(value)
    ? { dateDistance, addText: "remaining" }
    : { dateDistance, addText: "ago" };
}
