import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import ModalPaper from "../../components/ui-core/ModalPaper";
import { Card, CardContent, makeStyles } from "@material-ui/core";
import { renderIf } from "../../helpers/HOCUtils";
import PropTypes from "prop-types";
import { safeParseDate } from "../../helpers/DateUtils";
import IpsBatchSyncFilterForm from "../../components/dashboard-core/IpsBatchSyncFilterForm";
import { endOfToday, startOfToday } from "date-fns";

const useStyles = makeStyles({
  paper: {
    width: "600px",
    minWidth: "600px !important",
    maxWidth: "600px !important",
    minHeight: "auto !important",
  },
});

const enhancer = compose(
  renderIf("open"),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
);

IpsBatchSyncFilterDialog.propTypes = {
  isLoading: PropTypes.bool,

  filter: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

function IpsBatchSyncFilterDialog(props) {
  const { getLocalisationMessage, filter } = props;

  const classes = useStyles();

  const date = startOfToday();
  const date2 = endOfToday();

  return (
    <ModalPaper
      open={true}
      isLoading={props.isLoading}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
      title={`${getLocalisationMessage("filter", "Filter")}`}
    >
      <Card>
        <CardContent style={{ padding: 30 }}>
          <IpsBatchSyncFilterForm
            initialValues={{
              fromDate: filter.from_date
                ? safeParseDate(filter.from_date)
                : date,
              toDate: filter.to_date ? safeParseDate(filter.to_date) : date2,
            }}
            onSubmit={props.onSubmit}
            onDismiss={props.onDismiss}
          />
        </CardContent>
      </Card>
    </ModalPaper>
  );
}

export default enhancer(IpsBatchSyncFilterDialog);
