import * as d3 from "d3";
import { getValue } from "../../helpers/DataUtils";
import React from "react";

// eslint-disable-next-line react/prop-types
const MapMap = ({ width, height, data }) => {
  const projection = d3
    .geoMercator()
    .scale((width / 2) * 6) // Adjust scale to fit larger dimensions
    .center([63, 44]);

  const geoPathGenerator = d3.geoPath().projection(projection);
  const allSvgPaths = getValue(data, "features", []).map(shape => {
    const color = getValue(shape, "properties.color", 0) || "N/A";
    return (
      <path
        key={shape.id}
        d={geoPathGenerator(shape)}
        stroke="white"
        strokeWidth={2}
        fill={color}
        fillOpacity={0.9}
      />
    );
  });

  const allLabels = getValue(data, "features", []).map(shape => {
    const centroid = d3.geoCentroid(shape);
    const [x, y] = projection(centroid);
    const provinceName = getValue(shape, "properties.name", "");
    const value = getValue(shape, "properties.value", 0);
    const jurisdictionId = getValue(shape, "properties.jurisdiction_id", 0);

    return (
      <g key={`label-${shape.id}`}>
        {/* Province Name */}
        <text
          x={
            jurisdictionId === 270
              ? x - 12
              : jurisdictionId === 269
              ? x + 20
              : x
          }
          y={jurisdictionId === 270 ? y - 25 : y - 20} // Position above the circle
          textAnchor="middle"
          fill="black"
          fontSize="14px"
          fontWeight="bold"
        >
          {provinceName}
        </text>

        {/* Circle for Value */}
        <circle
          cx={
            jurisdictionId === 270
              ? x - 10
              : jurisdictionId === 269
              ? x + 20
              : x + 5
          }
          cy={y + 5}
          r={20} // Circle radius
          fill="white" // Circle color
          stroke="black" // Optional circle border
          strokeWidth={1} // Optional border width
        />

        {/* Value Inside Circle */}
        <text
          x={
            jurisdictionId === 270
              ? x - 10
              : jurisdictionId === 269
              ? x + 20
              : x + 5
          }
          y={y + 10} // Slightly below the vertical center of the circle
          textAnchor="middle"
          fill="black" // Text color
          fontSize="14px"
          fontWeight="bold"
        >
          {value}
        </text>
      </g>
    );
  });

  return (
    <div>
      <svg width={width} height={height}>
        {allSvgPaths}
        {allLabels}
      </svg>
    </div>
  );
};

export default MapMap;
