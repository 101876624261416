import { compose, mapPropsStream } from "recompose";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import useSheet from "react-jss";
import { renderIf } from "../../helpers/HOCUtils";
import fp from "lodash/fp";
import FlexBox from "../ui-core/FlexBox";
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { pipeStreams } from "../../helpers/StreamUtils";
import { isEqualData, toJS } from "../../helpers/DataUtils";
import { reduxForm } from "redux-form";
import ScannerTextField from "../deprecated/ScannerTextField";
import Text from "../ui-core/Text";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import ConfirmationButton from "../ui-core/ConfirmationButton";
import { ACTION_BY_INDEX, INDEX, RENDER } from "../orders-core/MUITable";
import _ from "lodash";
import { getCustomReasons } from "../../api/admin/AdminOrderApi";
import { formatText } from "../../helpers/FormatUtils";
import CustomsMuiTable from "../orders-core/CustomsMuiTable";

const enhancer = compose(
  renderIf("open"),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    { showErrorMessage },
  ),
  useSheet({
    modal: {
      maxWidth: "600px",
      minWidth: "600px",
      minHeight: "auto",
    },
    hintText: { fontSize: "14px", marginTop: "5px" },
    input: { "& input": { fontSize: "20px" } },
    scanner: { flexDirection: "column", width: "100%" },
    status: {
      marginBottom: 15,
      padding: 5,
      fontWeight: "bold",
      backgroundColor: "rgba(244,244,245,0.46)",
      borderBottom: "1px solid rgba(0,0,0,0.1)",
    },
    chips: {
      width: "100%",
      marginTop: 15,
      "& .MuiChip-root": {
        height: 52,
      },
    },
    chip: {
      margin: 10,
      flex: "1 auto",
      padding: 10,
      fontSize: "16px",
    },
    action: {
      borderTop: "1px solid rgba(0, 0, 0, 0.5)",
      paddingTop: 15,
      marginTop: 15,
    },

    table: {
      "& .MuiTableRow-root": {
        height: 60,
      },
    },
  }),
  mapPropsStream(
    pipeStreams(propStream => {
      const ordersStream = propStream
        .map(fp.get("list"))
        .filter(list => list && list.size > 0)
        .distinctUntilChanged(isEqualData)
        .map(list => toJS(list.map(item => item.get("barcode"))))
        .startWith({});

      return propStream
        .combineLatest(ordersStream, (props, orders) => ({
          ...props,
          orders,
        }))
        .distinctUntilChanged(isEqualData);
    }),
  ),
  reduxForm({
    form: "OrderCustomsCompleteForm",
    enableReinitialize: true,
  }),
);

OrderCustomsCompleteDialog.propTypes = {
  open: PropTypes.bool,

  classes: PropTypes.object,
  orders: PropTypes.array,

  onSubmit: PropTypes.func,
  showErrorMessage: PropTypes.func,
  focusInput: PropTypes.bool.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

function OrderCustomsCompleteDialog(props) {
  const { classes, getLocalisationMessage, orders } = props;
  const [uvOrders, setUvOrders] = React.useState([]);

  const [barcode, setBarcode] = useState(null);
  const [reason, setReason] = useState(null);
  const [error, setError] = useState(false);

  const [reasons, setReasons] = React.useState([]);

  useEffect(() => {
    getCustomReasons().then(res => {
      setReasons(res);
    });
  }, []);

  const ref = useRef(null);
  const stateRef = useRef(null);

  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      PaperProps={{
        style: {
          minWidth: 1300,
          maxWidth: 1300,
        },
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        {getLocalisationMessage("order_customs", "Customs clearance")}
      </DialogTitle>
      <DialogContent className={classes.paper}>
        <FlexBox flex={true} style={{ gap: 10 }}>
          <FlexBox direction="column" flex={true}>
            <div className={classes.status}>
              <Text
                element="h6"
                type="align-left"
                style={{ marginLeft: "10px", fontWeight: "bold" }}
              >
                {getLocalisationMessage(
                  "being_returned_from_customs",
                  "Being returned from customs",
                )}
                : {orders.length - uvOrders.length}
              </Text>
              <Text
                element="h6"
                type="align-left"
                style={{ marginLeft: "10px", fontWeight: "bold" }}
              >
                {getLocalisationMessage(
                  "being_held_on_customs",
                  "Being held on customs",
                )}
                : {uvOrders.length}
              </Text>
            </div>
            <ScannerTextField
              inputRef={ref}
              ref={stateRef}
              fullWidth={true}
              label={getLocalisationMessage("scan_barcode", "Scan Barcode")}
              focus={props.focusInput}
              className={classes.input}
              id="OrderCustomsFormScannerTextField"
              onChange={value => {
                if (orders.includes(value)) {
                  setBarcode(value);
                } else {
                  props.showErrorMessage(
                    props.getLocalisationMessage(
                      "barcode_does_not_exists_on_this_batch",
                      "Barcode does not exists on this Batch",
                    ),
                  );
                }
              }}
            />

            {barcode && (
              <div className={classes.chips}>
                <FlexBox style={{ gap: 13 }}>
                  <FlexBox
                    flex={true}
                    align="center"
                    justify="center"
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.3)",
                      borderRadius: 5,
                    }}
                  >
                    <Typography variant="h6" color="textSecondary">
                      {barcode}
                    </Typography>
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormControl fullWidth={true} error={error}>
                      <InputLabel
                        style={{ marginLeft: 16, fontSize: 18, top: "-5px" }}
                      >
                        {getLocalisationMessage("reason", "Reason")}
                      </InputLabel>
                      <Select
                        variant="outlined"
                        size="medium"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={event => {
                          setUvOrders(prev => {
                            setReason(event.target.value);
                            const prevBarcodes = prev.map(item => item.barcode);
                            return !prevBarcodes.includes(barcode)
                              ? [
                                  {
                                    barcode,
                                    reason: event.target.value,
                                  },
                                  ...prev,
                                ]
                              : prev;
                          });

                          if (
                            ref &&
                            ref.current &&
                            stateRef &&
                            stateRef.current
                          ) {
                            ref.current.value = "";
                            stateRef.current.state.stateValue.value = "";
                            setTimeout(() => ref.current.focus(), 30);
                          }

                          setBarcode(null);
                          setTimeout(() => {
                            setReason(null);
                            setError(false);
                          }, 30);
                        }}
                      >
                        {reasons.map((item, i) => (
                          <MenuItem key={i} value={item}>
                            {getLocalisationMessage(
                              fp.toLower(item),
                              formatText(item),
                            )}
                          </MenuItem>
                        ))}
                      </Select>
                      {error && (
                        <FormHelperText style={{ marginLeft: 16 }}>
                          {getLocalisationMessage(
                            "select_reason",
                            "Select Reason",
                          )}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </FlexBox>
                </FlexBox>
              </div>
            )}
          </FlexBox>

          <FlexBox flex={true} className={classes.table}>
            <CustomsMuiTable
              list={uvOrders}
              withoutPagination={true}
              columns={[
                {
                  type: INDEX,
                  label: getLocalisationMessage("#"),
                },
                {
                  type: RENDER,
                  name: "barcode",
                  render: row => _.get(row, `barcode`, ""),
                  label: getLocalisationMessage("orders"),
                },
                {
                  type: RENDER,
                  name: "reason",
                  label: getLocalisationMessage("reason"),
                  render: row =>
                    getLocalisationMessage(
                      fp.toLower(row.reason),
                      formatText(row.reason),
                    ),
                },
                {
                  type: ACTION_BY_INDEX,
                  name: "remove",
                  label: getLocalisationMessage("remove"),
                  dispatch: id => {
                    setUvOrders(prev => {
                      const idx = prev.findIndex(i => i.id === id);
                      return [...prev.slice(0, idx), ...prev.slice(idx + 1)];
                    });
                  },
                },
              ]}
            />
          </FlexBox>
        </FlexBox>
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button onClick={props.onRequestClose} primary={true}>
          {getLocalisationMessage("dismiss")}
        </Button>

        {barcode && !reason ? (
          <CustomButton
            variant={CONTAINED}
            color={SECONDARY}
            size="large"
            onClick={() => {
              setError(true);
            }}
          >
            {getLocalisationMessage("send_to_transit", "Send to Transit")}
          </CustomButton>
        ) : (
          <ConfirmationButton
            variant={CONTAINED}
            color={SECONDARY}
            buttonLabel={getLocalisationMessage(
              "send_to_transit",
              "Send to Transit",
            )}
            onConfirm={() => {
              props.onSubmit({
                uvOrders,
                orders,
              });
            }}
          >
            {getLocalisationMessage(
              "please_note_that_you_can_not_change_this_after_confirmation",
              "Please note that You can not change this after confirmation",
            )}
          </ConfirmationButton>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(OrderCustomsCompleteDialog);
