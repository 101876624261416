import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getMarketplaceDefaultBatchUpdateStatuses,
  getMarketplaceReceiveOrderFromWing,
  isCustomMarketplace,
} from "../../reducers/MarketplaceReducer";
import { Map } from "immutable";
import { renderIf } from "../../helpers/HOCUtils";
import { getSortingBatchUpdateItem } from "../../api/v2/admin/AdminOrderSortingApi";
import { getValue } from "../../helpers/DataUtils";
import OfflineOrderOutboundSortingVerifyOrdersDialog from "./OfflineOrderOutboundSortingVerifyOrdersDialog";
import fp from "lodash/fp";
import { toSnakeCase } from "../../helpers/CaseMapper";
import {
  batchAsyncUpdateOrder,
  updateRecipientPostcode,
} from "../../api/admin/AdminOrderApi";
import ResponseError from "../../helpers/ResponseError";
import { showSuccessMessage } from "../../reducers/NotificationsReducer";
import {
  getRegistryStatusByShipmentType,
  isRegistryBag,
} from "../../helpers/OrderOutboundSortingHelper";
import { getMessages } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  renderIf("open"),
  connect(
    state => ({
      i18n: getMessages(state),
      isMarketplaceCustom: isCustomMarketplace(state),
      isMarketplaceReceiveOrderFromWing: getMarketplaceReceiveOrderFromWing(
        state,
      ),
      defaultBatchUpdateStatuses: getMarketplaceDefaultBatchUpdateStatuses(
        state,
      ),
    }),
    { showSuccessMessage },
  ),
);

AdminOutboundOrderSortingBatchUpdateDialogWrapper.propTypes = {
  warehouse: PropTypes.number,

  selectedOrderBarcodes: PropTypes.array,
  bins: PropTypes.instanceOf(Map),
  orders: PropTypes.instanceOf(Map),
  i18n: PropTypes.instanceOf(Map),
  open: PropTypes.bool,

  onRequestClose: PropTypes.func,
  onConfirmUnknownOrder: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  getCachedSupplier: PropTypes.func,
  getSupplierPredictions: PropTypes.func,
  filter: PropTypes.object,
};

function AdminOutboundOrderSortingBatchUpdateDialogWrapper(props) {
  const { warehouse } = props;
  const [batchItem, setBatchItem] = useState({
    orderBarcodes: [],
  });
  const [barcodes, setBarcodes] = useState([]);
  const [weights, setWeights] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  useEffect(() => {
    if (
      props.filter.service_type_id ||
      props.filter.bean ||
      props.selectedOrderBarcodes.length
    ) {
      setIsLoading(true);
      getSortingBatchUpdateItem({
        ...props.filter,
        type: "ORDER",
        barcodes: props.selectedOrderBarcodes,
      })
        .then(res => {
          const orderBarcodes = [];
          const orderWeight = [];
          const courierServices = [];
          res.items.map(item => {
            orderBarcodes.push(item.barcode);
            orderWeight.push({
              number: item.barcode,
              weight: Number(
                parseFloat(getValue(item, "weight", 0)).toFixed(3),
              ),
            });
            courierServices.push(getValue(item, "service_type.code"));
            return item;
          });

          setBarcodes(orderBarcodes);
          const bag = isRegistryBag(courierServices);
          const orderStatus = getRegistryStatusByShipmentType(
            courierServices,
            getValue(props.filter, "bean.status"),
          );

          setWeights(orderWeight);

          setBatchItem({
            bag,
            category: res.category ? fp.toLower(res.category) : null,
            orderBarcodes: [],
            indexedOrders: [],
            orderStatus,
            innerShipmentType: res.inner_shipment_type,
            transportationType: res.transportation_type,
            weight: 0,
            to_warehouse:
              getValue(props.filter, "bean.to_warehouse") ||
              getValue(res, "to_warehouse"),
            warehouse:
              getValue(props.filter, "bean.next_warehouse") ||
              getValue(res, "next_warehouse"),
          });
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  }, [props.filter, props.selectedOrderBarcodes]);

  return (
    <OfflineOrderOutboundSortingVerifyOrdersDialog
      open={props.open}
      barcodes={barcodes}
      warehouseId={warehouse}
      weights={weights}
      setWeights={setWeights}
      isSubmitLoading={isSubmitLoading}
      filter={props.filter}
      isLoading={isLoading}
      initialValues={{ ...batchItem }}
      getCachedSupplier={props.getCachedSupplier}
      getSupplierPredictions={props.getSupplierPredictions}
      orders={props.orders}
      bins={props.bins}
      unverified={{}}
      onSubmit={fp.flow(toSnakeCase, values => {
        setIsSubmitLoading(true);

        if (values.indexed_orders && values.indexed_orders.length > 0) {
          Promise.all([
            updateRecipientPostcode({
              index:
                getValue(props.filter, "bean.next_postcode.name") ||
                getValue(values, "to_warehouse.postcode.name"),
              barcodes: values.indexed_orders,
            }),
          ])
            .catch(ResponseError.throw)
            .then(() => null);
        }

        const newValue = fp.omit(["indexed_orders"], values);

        return batchAsyncUpdateOrder(newValue).catch(ResponseError.throw);
      })}
      onSubmitSuccess={response => {
        setIsSubmitLoading(false);
        props.showSuccessMessage(
          props.i18n.get("successfully_updated", "Successfully Updated"),
        );
        props.onSubmitSuccess(response);
      }}
      onSubmitFail={() => setIsSubmitLoading(false)}
      onConfirmUnknownOrder={props.onConfirmUnknownOrder}
      onRequestClose={props.onRequestClose}
    />
  );
}

export default enhancer(AdminOutboundOrderSortingBatchUpdateDialogWrapper);
