import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, FormControl, MenuItem, Select } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import { getValue } from "../../helpers/DataUtils";
import {
  getCurrentLanguage,
  getMessage,
} from "../../reducers/LocalizationReducer";
import FlexBox from "../ui-core/FlexBox";
import FilledLineChart from "../charts/FilledLineChart";
import { getCustomerTotalCount } from "../../api/customer/CustomerDashboardApi";
import { getCustomerId } from "../../reducers/CustomerReducer";
import { formatText } from "../../helpers/FormatUtils";
import fp from "lodash/fp";

const years = [2021, 2022, 2023, 2024, 2025];
const dataTypes = ["MONTHLY", "DAILY"];
const months = [
  { label: "january", value: 1 },
  { label: "february", value: 2 },
  { label: "march", value: 3 },
  { label: "april", value: 4 },
  { label: "may", value: 5 },
  { label: "june", value: 6 },
  { label: "july", value: 7 },
  { label: "august", value: 8 },
  { label: "september", value: 9 },
  { label: "october", value: 10 },
  { label: "november", value: 11 },
  { label: "december", value: 12 },
];

const enhancer = compose(
  connect(state => ({
    customerId: getCustomerId(state),
    currentLanguage: getCurrentLanguage(state),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

CustomerOrderCountChartWrapper.propTypes = {
  getLocalisationMessage: PropTypes.func,
  customerId: PropTypes.string,
  currentLanguage: PropTypes.string,
};

function CustomerOrderCountChartWrapper(props) {
  const [chartData, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [note, setNote] = useState(null);
  const monthlyOrderRef = React.useRef(null);
  const [dataType, setDataType] = useState("MONTHLY");
  const [year, setYear] = useState(2025);
  const [month, setMonth] = useState(null);

  const [filter, setFilter] = useState({
    year: 2025,
    type: dataType,
    customer_id: props.customerId,
  });

  useEffect(() => {
    setIsLoading(true);
    const filterNew = filter.month
      ? { ...filter }
      : {
          year: filter.year,
          type: filter.type,
          customer_id: props.customerId,
        };
    getCustomerTotalCount(filterNew)
      .then(r => {
        if (r && r.data) {
          const barData = getValue(r, "data.result");
          setData({
            labels: barData.map(item =>
              item.label
                ? props.getLocalisationMessage(
                    fp.toLower(item.label),
                    formatText(item.label),
                  )
                : "",
            ),
            datasets: [
              {
                data: barData.map(item => item.value),
                borderColor: "#3F51B5",
                backgroundColor: "#3F51B5",
                type: "bar",
                minBarLength: 0,
              },
            ],
          });
        }
        setIsLoading(false);
      })
      .catch(() => {
        setNote(props.getLocalisationMessage("no_data", "No Data"));
        setIsLoading(false);
        setData({});
      });
  }, [filter, props.currentLanguage]);

  return (
    <Card
      style={{
        width: "100%",
        padding: "15px",
        boxShadow: "rgba(145, 158, 171, 0.12)",
        borderRadius: "16px",
      }}
      ref={monthlyOrderRef}
    >
      <FlexBox direction="row">
        <FlexBox
          flex={true}
          direction="column"
          style={{
            marginTop: 10,
            marginLeft: 15,
            marginRight: 15,
            fontSize: 14,
            width: "100%",
            gap: 13,
          }}
          justify="space-between"
        >
          <FlexBox style={{ fontWeight: 700, fontSize: 16 }}>
            {props.getLocalisationMessage(
              "order_creation_count",
              "Order Creation Count",
            )}
          </FlexBox>
          <FlexBox flex={true} style={{ gap: 13, width: "100%" }}>
            <FormControl size="small" fullWidth={true}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={year}
                fullWidth={true}
                variant="outlined"
                onChange={event => setYear(event.target.value)}
              >
                {years.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl size="small" fullWidth={true}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={dataType}
                variant="outlined"
                fullWidth={true}
                onChange={event => {
                  setDataType(event.target.value);
                  setMonth(0);
                }}
              >
                {dataTypes.map(item => (
                  <MenuItem key={item} value={item}>
                    {props.getLocalisationMessage(item)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {dataType === "DAILY" && (
              <FormControl size="small" fullWidth={true}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={month}
                  fullWidth={true}
                  variant="outlined"
                  onChange={event => setMonth(event.target.value)}
                >
                  {months.map(item => (
                    <MenuItem key={item} value={item.value}>
                      {props.getLocalisationMessage(item.label)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <CustomButton
              variant={CONTAINED}
              color={SECONDARY}
              fullWidth={true}
              disabled={dataType === "DAILY" && month === 0}
              style={{
                backgroundColor:
                  dataType === "DAILY" && month === 0 ? "lightgrey" : "#3F51B5",
              }}
              onClick={() =>
                setFilter(prev => ({
                  ...prev,
                  year,
                  type: dataType,
                  month,
                }))
              }
            >
              {props.getLocalisationMessage("apply", "Apply")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      {isLoading && monthlyOrderRef.current && (
        <FlexBox
          justify="center"
          align="flex-end"
          style={{ height: 400, position: "relative", gap: "10px" }}
        >
          {Array.from(
            { length: Math.ceil(monthlyOrderRef.current.offsetWidth / 30) },
            () => Math.floor(Math.random() * 400),
          ).map(item => (
            <Skeleton style={{ transform: "none" }} width={20} height={item} />
          ))}

          <FlexBox
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
            align="center"
            justify="center"
          >
            <h4>
              <strong>
                {note ||
                  `${props.getLocalisationMessage("loading", "Loading")}...`}
              </strong>
            </h4>
          </FlexBox>
        </FlexBox>
      )}
      {!isLoading && (
        <FilledLineChart
          data={chartData}
          options={{
            plugins: {
              tooltip: {
                callbacks: {
                  title: () => null,
                  label(tooltipItem) {
                    const datasetLabel = tooltipItem.label;
                    return `${datasetLabel}:  ${new Intl.NumberFormat(
                      "ru-RU",
                    ).format(tooltipItem.raw)}`;
                  },
                },
              },
              legend: {
                display: false,
              },
            },
            scales: {
              x: [
                {
                  grid: {
                    display: false,
                  },
                },
              ],
              y: [
                {
                  grid: {
                    drawBorder: false, // Remove the default border
                    borderDash: [5, 5], // Set a dashed pattern for the row lines
                  },
                },
              ],
            },
          }}
        />
      )}
    </Card>
  );
}

export default enhancer(CustomerOrderCountChartWrapper);
