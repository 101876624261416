import React from "react";
import { OrderedSet } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import FormSelectField from "../form/FormSelectField";
import FormCourierTypeChips from "../form/FormCourierTypeChips";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { parseString, stringifyArray } from "../../helpers/SerializeUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { priceTypesForFilter } from "../../constants/PackageRulesPriceType";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  SIMPLE,
  transportationTypeForPackageRule,
} from "../../constants/TransportationType";

export const TERRESTRIAL = "TERRESTRIAL";
export const AIR = "AIR";
export const COURIER_SERVICE = "COURIER_SERVICE";
export const HAND_TO_HAND = "HAND_TO_HAND";
export const CORPORATE_INTERNAL = "CORPORATE_INTERNAL";

const ACTIVE = "ACTIVE";
const INACTIVE = "INACTIVE";
const shopMenuOptions = OrderedSet.of(ACTIVE, INACTIVE);

export function formatTransportationTypesLocalised(
  code,
  getLocalisationMessage,
  notAvailableValue = "N/A",
) {
  switch (code) {
    case SIMPLE:
      return (
        (getLocalisationMessage &&
          getLocalisationMessage("simple_type", "Simple")) ||
        "Simple"
      );

    default:
      return (
        (getLocalisationMessage && getLocalisationMessage(code)) ||
        formatText(code) ||
        notAvailableValue
      );
  }
}

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  withContext(
    {
      getCachedServiceType: PropTypes.func,
      getServiceTypePredictions: PropTypes.func,
    },
    props => ({
      getCachedServiceType: props.getCachedServiceType,
      getServiceTypePredictions: props.getServiceTypePredictions,
    }),
  ),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props => ({
        serviceTypes: parseString(props.filter.getValue("service_types")),
        transportationType: fp.toLower(
          props.filter.getValue("transportation_type"),
        ),
        ruleStatus: props.filter.getValue("rule_status"),
        priceType: props.filter.getValue("price_type"),
      }))
      .distinctUntilChanged(isEqualData);

    const onSubmit = (values, dispatch, props) =>
      props.onFilterChange(
        props.filter.withMutations((filter: DataListFilter) => {
          filter.setValueMap({
            service_types: null,
            transportation_type: null,
            rule_status: null,
            price_type: null,
            page: 0,
          });

          if (!fp.isEmpty(values.ruleStatus)) {
            filter.setValue("rule_status", values.ruleStatus);
          }
          if (!fp.isEmpty(values.serviceTypes)) {
            filter.setValue(
              "service_types",
              stringifyArray(values.serviceTypes),
            );
          }
          if (!fp.isEmpty(values.transportationType)) {
            filter.setValue(
              "transportation_type",
              fp.toUpper(values.transportationType),
            );
          }
          if (!fp.isEmpty(values.priceType)) {
            filter.setValue("price_type", values.priceType);
          }
        }),
      );

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,

        onSubmit,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({
    form: "PackageRuleFilterForm",
    enableReinitialize: true,
  }),
);

PackageRuleFilterForm.propTypes = {
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  dirty: PropTypes.bool,
  onDismiss: PropTypes.func,
  reset: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function PackageRuleFilterForm(props) {
  return (
    <FlexBox element={<form onSubmit={props.handleSubmit} />} flex={true}>
      <FlexBox flex={true} direction="column" element={<Card />}>
        <FlexBox
          container={16}
          justify="space-between"
          element={<CardContent />}
        >
          <FlexBox
            style={{ paddingRight: ".5rem" }}
            gutter={16}
            flex={true}
            direction="column"
          >
            <FlexBox>
              <FormSelectField
                name="ruleStatus"
                autoWidth={true}
                fullWidth={true}
                label={props.getLocalisationMessage("status", "Status")}
                options={shopMenuOptions}
                formatOption={x =>
                  props.getLocalisationMessage(x, formatText(x))
                }
              />
            </FlexBox>
            <FlexBox>
              <FormSelectField
                name="priceType"
                autoWidth={true}
                fullWidth={true}
                options={priceTypesForFilter}
                formatOption={x =>
                  props.getLocalisationMessage(x.toLowerCase(), formatText(x))
                }
                label={props.getLocalisationMessage("price_type", "Price Type")}
              />
            </FlexBox>
          </FlexBox>
          <FlexBox
            style={{ paddingLeft: ".5rem" }}
            gutter={16}
            flex={true}
            direction="column"
          >
            <FlexBox>
              <FormCourierTypeChips
                name="serviceTypes"
                fullWidth={true}
                label={props.getLocalisationMessage(
                  "service_types",
                  "Service Types",
                )}
              />
            </FlexBox>
            <FlexBox>
              <FormSelectField
                name="transportationType"
                fullWidth={true}
                options={transportationTypeForPackageRule}
                formatOption={x =>
                  formatTransportationTypesLocalised(
                    x,
                    props.getLocalisationMessage,
                  )
                }
                label={props.getLocalisationMessage(
                  "transportation_type",
                  "Transportation Type",
                )}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <FlexBox element={<CardActions />} justify="flex-end">
          <Button
            onClick={() => {
              props.change("serviceTypes", null);
              props.change("transportationType", null);
              props.change("ruleStatus", null);
              props.change("priceType", null);
            }}
          >
            {props.getLocalisationMessage("clear", "Clear")}
          </Button>
          {props.dirty ? (
            <Button onClick={props.reset}>
              {props.getLocalisationMessage("reset", "Reset")}
            </Button>
          ) : (
            Boolean(props.onDismiss) && (
              <Button onClick={props.onDismiss}>
                {props.getLocalisationMessage("dismiss", "Dismiss")}
              </Button>
            )
          )}

          <Button type="submit">
            {props.getLocalisationMessage("submit", "Submit")}
          </Button>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(PackageRuleFilterForm);
