import React from "react";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { connect } from "react-redux";
import { Cancel } from "@material-ui/icons";
import { isValidDate } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { Card, CardActions, IconButton } from "@material-ui/core";
import FormChipAutoComplete from "../../components/form/FormChipAutoComplete";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import { formatText } from "../../helpers/FormatUtils";
import { OrderedSet } from "immutable";
import FormDateField from "../../components/form/FormDateField";
import FlexBox from "../../components/ui-core/FlexBox";
import CustomButton from "../../components/ui-core/CustomButton";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "BatchHistoryUpdateFilter",
    enableReinitialize: true,
  }),
  formValues({
    fromDateTime: "fromDateTime",
    toDateTime: "toDateTime",
  }),
);

BatchHistoryUpdateFilter.propTypes = {
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onRequestClose: PropTypes.func,
  fromDateTime: PropTypes.any,
  toDateTime: PropTypes.any,

  getLocalisationMessage: PropTypes.func,
};

function BatchHistoryUpdateFilter(props) {
  const { getLocalisationMessage, handleSubmit, onRequestClose } = props;

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Card>
          <FlexBox container={24} direction="column" style={{ gap: 13 }}>
            <FlexBox flex={true} gutter={8}>
              <FlexBox flex={true}>
                <FlexBox gutter={8} flex={true}>
                  <FlexBox flex={true} direction="column">
                    <FormDateField
                      fullWidth={true}
                      name="startDate"
                      hintText={getLocalisationMessage(
                        "from_date",
                        "From Date",
                      )}
                    />
                  </FlexBox>

                  {Boolean(isValidDate(props.fromDateTime)) && (
                    <FlexBox>
                      <IconButton
                        onClick={() => props.change("fromDateTime", null)}
                        style={{ padding: 4 }}
                      >
                        <Cancel />
                      </IconButton>
                    </FlexBox>
                  )}
                </FlexBox>
              </FlexBox>

              <FlexBox gutter={8} flex={true}>
                <FlexBox flex={true} direction="column">
                  <FormDateField
                    fullWidth={true}
                    name="endDate"
                    hintText={getLocalisationMessage("to_date", "To Date")}
                  />
                </FlexBox>

                {Boolean(isValidDate(props.toDateTime)) && (
                  <FlexBox>
                    <IconButton
                      style={{ padding: 4 }}
                      onClick={() => props.change("toDateTime", null)}
                    >
                      <Cancel />
                    </IconButton>
                  </FlexBox>
                )}
              </FlexBox>
            </FlexBox>
            <FlexBox flex={true} gutter={8}>
              <FlexBox flex={true}>
                <FlexBox gutter={8} flex={true}>
                  <FlexBox flex={true} direction="column">
                    <FormChipAutoComplete
                      name="statuses"
                      fullWidth={true}
                      options={OrderedSet.of(
                        "READY_FOR_DELIVERY",
                        "ISSUED_TO_RECIPIENT",
                      )}
                      formatOption={x =>
                        formatOrderStatusCodeForLocalisation(
                          x,
                          getLocalisationMessage,
                        ) || formatText(x)
                      }
                      hintText={getLocalisationMessage("type_here_to_search")}
                      label={getLocalisationMessage("statuses")}
                    />
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>

          <CardActions>
            <FlexBox flex={true} justify="flex-end">
              <CustomButton
                label={getLocalisationMessage("clear", "Clear")}
                onClick={() => {
                  fp.keys(props.initialValues).forEach(key => {
                    props.change(key, null);
                  });
                }}
              />
              <CustomButton
                label={getLocalisationMessage("close", "Close")}
                onClick={onRequestClose}
              />
              <CustomButton
                label={getLocalisationMessage("submit", "Submit")}
                type="submit"
              />
            </FlexBox>
          </CardActions>
        </Card>
      </form>
    </div>
  );
}

export default enhancer(BatchHistoryUpdateFilter);
