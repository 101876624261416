import React, { useEffect, useState } from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getCurrentLanguage,
  getMessage,
} from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import AppTitle from "../../components/deprecated/AppTitle";
import { formatDateTimeToUrl, safeParseDate } from "../../helpers/FormatUtils";
import { endOfToday, startOfToday, subDays, format } from "date-fns";
import { getValue } from "../../helpers/DataUtils";
import { getUserWarehouseId } from "../../reducers/ProfileReducer";
import DriverDashboardFilterForm from "../../components/dashboard-core/DriverDashboardFilterForm";
import {
  ButtonGroup,
  Card,
  CardContent,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CustomButton, {
  OUTLINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import {
  CLICKHOUSE_DRIVER_REPORT_PDF_URL,
  getDriverReports,
} from "../../api/admin/AdminDriverReportsApi";
import { RUSSIAN_LANGUAGE, UZBEK_LANGUAGE } from "../../constants/LocaleTypes";
import { getMpPDF } from "../../helpers/FormUtils";
import {
  IS_DOWNLOADING,
  IS_PRINTING,
} from "../../components/orders-core/AdminPostForm";
import { GetApp, Print } from "@material-ui/icons";
import PageLoading from "../../components/ui-core/PageLoading";

const useStyles = makeStyles({
  table: {
    width: "100%",
    borderCollapse: "collapse",
    "&>tr": {
      border: "2.5px solid #dddddd",
      textAlign: "center",
      padding: 16,
      fontSize: 20,
    },
    "&>tr>td": {
      border: "2.5px solid #dddddd",
      textAlign: "center",
      padding: 16,
      fontSize: 20,
    },
  },
});

const uzbekMonthsLatin = [
  "yanvar",
  "fevral",
  "mart",
  "aprel",
  "may",
  "iyun",
  "iyul",
  "avgust",
  "sentabr",
  "oktabr",
  "noyabr",
  "dekabr",
];
function formatUzbekLatin(date) {
  const day = date.getDate();
  const month = uzbekMonthsLatin[date.getMonth()];
  return `${day}-${month}`;
}

const localisationDateAndTitle = (currentLanguage, date) => {
  switch (currentLanguage) {
    case RUSSIAN_LANGUAGE:
      return `Осталось с ${new Intl.DateTimeFormat("ru-RU", {
        day: "numeric",
        month: "long",
      }).format(subDays(new Date(date), 1))}`;

    case UZBEK_LANGUAGE:
      return `${formatUzbekLatin(subDays(new Date(date), 1))}dan qoldi`;

    default:
      return `Pending from ${new Intl.DateTimeFormat("en-US", {
        day: "numeric",
        month: "short",
      }).format(subDays(new Date(date), 1))}`;
  }
};

const localisationDateAndTitleSecond = (currentLanguage, fromDate, toDate) => {
  switch (currentLanguage) {
    case RUSSIAN_LANGUAGE:
      return `Отчет с ${format(
        safeParseDate(fromDate),
        "dd/MM/yy HH:mm",
      )}  до ${format(safeParseDate(toDate), "dd/MM/yy HH:mm")}`;

    case UZBEK_LANGUAGE:
      return ` ${format(
        safeParseDate(fromDate),
        "dd/MM/yy HH:mm",
      )} dan ${format(safeParseDate(toDate), "dd/MM/yy HH:mm")} gacha hisobot`;

    default:
      return `Report from ${format(
        safeParseDate(fromDate),
        "dd/MM/yy HH:mm",
      )} to ${format(safeParseDate(toDate), "dd/MM/yy HH:mm")}`;
  }
};

const enhancer = compose(
  connect(
    state => ({
      warehouseId: getUserWarehouseId(state),
      currentLanguage: getCurrentLanguage(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  getContext({
    setLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
  }),
);

AdminDriverDashboardContainer.propTypes = {
  getLocalisationMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  warehouseId: PropTypes.string,
  currentLanguage: PropTypes.string,
};

function AdminDriverDashboardContainer(props) {
  const { getLocalisationMessage, warehouseId, currentLanguage } = props;

  const date = startOfToday();
  const date2 = endOfToday();

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  const [pdf, setPdf] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: CLICKHOUSE_DRIVER_REPORT_PDF_URL,
  });

  const [filter, setFilter] = useState({
    from_date_time: formatDateTimeToUrl(subDays(date, 1)),
    to_date_time: formatDateTimeToUrl(subDays(date2, 1)),
    warehouse: {
      id: warehouseId,
    },
  });

  useEffect(() => {
    setIsLoading(true);
    getDriverReports({
      from_date_time: filter.from_date_time,
      to_date_time: filter.to_date_time,
      driver_id: getValue(filter, "driver.id", null),
    })
      .then(r => {
        setData(r.data);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [filter]);

  return (
    <AdminAppLayout
      slug="driver_reports"
      title={getLocalisationMessage("drivers_report", "Driver's Report")}
    >
      <PageLoading isLoading={isLoading} />
      <FlexBox style={{ marginTop: 20, gap: 13, width: "100%" }}>
        <FlexBox style={{ width: "100%" }}>
          <AppTitle
            title={getLocalisationMessage("drivers_report", "Driver's Report")}
          >
            <DriverDashboardFilterForm
              initialValues={{
                fromDateTime: safeParseDate(filter.from_date_time),
                toDateTime: safeParseDate(filter.to_date_time),
                warehouse: filter.warehouse,
                driver: filter.driver,
              }}
              onSubmit={v =>
                setFilter({
                  from_date_time: formatDateTimeToUrl(v.fromDateTime),
                  to_date_time: formatDateTimeToUrl(v.toDateTime),
                  warehouse: getValue(v, "warehouse"),
                  driver: getValue(v, "driver"),
                })
              }
            />
          </AppTitle>
        </FlexBox>
      </FlexBox>
      <FlexBox style={{ gap: 13, width: "100%" }} flex={true}>
        <Card style={{ width: "100%", height: "99%", marginTop: 20 }}>
          <CardContent>
            <FlexBox
              flex="none"
              direction="column"
              style={{
                padding: "2px 0 2px 20px",
                gap: 16,
              }}
            >
              <FlexBox>
                <Typography
                  style={{
                    fontWeight: 800,
                    fontSize: "22px",
                  }}
                >
                  {localisationDateAndTitleSecond(
                    currentLanguage,
                    getValue(filter, "from_date_time", new Date()),
                    getValue(filter, "to_date_time", new Date()),
                  )}
                </Typography>
              </FlexBox>
              <FlexBox justify="space-between">
                <FlexBox
                  style={{ gap: 16, fontSize: "24px", fontWeight: "600" }}
                >
                  <FlexBox style={{ gap: 13 }}>
                    <Typography style={{ fontWeight: 500, fontSize: "18px" }}>
                      {getLocalisationMessage("driver", "Driver")}:
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: 400,
                        fontSize: "18px",
                      }}
                    >
                      {getValue(filter, "driver.name", "")}
                    </Typography>
                  </FlexBox>
                </FlexBox>

                <FlexBox style={{ gap: 16 }}>
                  <FlexBox>
                    <ButtonGroup variant={OUTLINED} color={SECONDARY}>
                      <CustomButton
                        disabled={!getValue(filter, "driver.id", null)}
                        onClick={() =>
                          getMpPDF(
                            pdf,
                            setPdf,
                            IS_PRINTING,
                            {
                              fromDateTime: filter.from_date_time,
                              toDateTime: filter.to_date_time,
                              driverId: getValue(filter, "driver.id", null),
                            },
                            props.showErrorMessage,
                          )
                        }
                        endIcon={
                          pdf[IS_PRINTING] ? (
                            <CircularProgress size={20} color="secondary" />
                          ) : (
                            <Print />
                          )
                        }
                      >
                        {getLocalisationMessage("download", "Download")}
                      </CustomButton>
                      <CustomButton
                        disabled={!getValue(filter, "driver.id", null)}
                        onClick={() =>
                          getMpPDF(
                            pdf,
                            setPdf,
                            IS_DOWNLOADING,
                            {
                              fromDateTime: filter.from_date_time,
                              toDateTime: filter.to_date_time,
                              driverId: getValue(filter, "driver.id", null),
                            },
                            props.showErrorMessage,
                          )
                        }
                      >
                        {pdf[IS_DOWNLOADING] ? (
                          <CircularProgress size={20} color="secondary" />
                        ) : (
                          <GetApp />
                        )}
                      </CustomButton>
                    </ButtonGroup>
                  </FlexBox>
                </FlexBox>
              </FlexBox>

              <FlexBox>
                <table className={classes.table}>
                  <tr>
                    <td
                      style={{
                        width: 350,
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {localisationDateAndTitle(
                        currentLanguage,
                        getValue(filter, "from_date_time", new Date()),
                      )}
                    </td>
                    <td style={{ width: 120 }}>
                      {getValue(data, "left_by_from_date", 0)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: 350,
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {getLocalisationMessage("dispatched_today", "Dispatched")}
                    </td>
                    <td style={{ width: 120 }}>
                      {getValue(data, "dispatched", 0)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: 350,
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {getLocalisationMessage("delivered", "Delivered")}
                    </td>
                    <td style={{ width: 120 }}>
                      {getValue(data, "delivered", 0)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: 350,
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {getLocalisationMessage("returned", "Returned")}
                    </td>
                    <td style={{ width: 120 }}>
                      {getValue(data, "returned_to_warehouse", 0)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: 350,
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {getLocalisationMessage("pending", "Pending")}
                    </td>
                    <td style={{ width: 120 }}>
                      {getValue(data, "left_by_end_date", 0)}
                    </td>
                  </tr>
                </table>
              </FlexBox>
            </FlexBox>
          </CardContent>
        </Card>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminDriverDashboardContainer);
