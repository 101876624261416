import React from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import FlexBox from "../../components/ui-core/FlexBox";
import CustomerAppLayout from "../../components/customer/CustomerAppLayout";
import CustomerOrderCountChartWrapper from "../../components/dashboard-core/CustomerOrderCountChartWrapper";
import CustomerOrderTotalCountByServiceTypeChartWrapper from "../../components/dashboard-core/CustomerOrderTotalCountByServiceTypeChartWrapper";
import CustomerOrderCountByJurisdictionWrapper from "../../components/dashboard-core/CustomerOrderCountByJurisdictionWrapper";
import {
  getCustomerTotalCountByFromJurisdiction,
  getCustomerTotalCountByToJurisdiction,
} from "../../api/customer/CustomerDashboardApi";
import CustomerOrderCountByToJurisdictionWrapper from "../../components/dashboard-core/CustomerOrderCountByToJurisdictionWrapper";

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  getContext({
    setLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
  }),
);

CustomerDashboardContainer.propTypes = {
  getLocalisationMessage: PropTypes.func,
};

function CustomerDashboardContainer(props) {
  const { getLocalisationMessage } = props;

  return (
    <CustomerAppLayout
      slug="dashboard"
      title={getLocalisationMessage("dashboard", "Dashboard")}
    >
      <FlexBox
        container={24}
        flex="none"
        direction="column"
        style={{ gap: 16 }}
      >
        <FlexBox justify="space-between" style={{ gap: 16 }}>
          <FlexBox flex={true} container={8}>
            <CustomerOrderCountChartWrapper />
          </FlexBox>

          <FlexBox flex={true} container={8}>
            <CustomerOrderTotalCountByServiceTypeChartWrapper />
          </FlexBox>
        </FlexBox>
        <FlexBox justify="space-between" style={{ gap: 16 }}>
          <CustomerOrderCountByJurisdictionWrapper
            apiUrl={getCustomerTotalCountByFromJurisdiction}
          />
        </FlexBox>
        <FlexBox justify="space-between" style={{ gap: 16 }}>
          <CustomerOrderCountByToJurisdictionWrapper
            apiUrl={getCustomerTotalCountByToJurisdiction}
          />
        </FlexBox>
      </FlexBox>
    </CustomerAppLayout>
  );
}

export default enhancer(CustomerDashboardContainer);
