import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { formValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { DATE_TIME_SHORT_FORMAT } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import { endOfDay, startOfDay } from "date-fns";
import FormDateTimeField from "../form/FormDateTimeField";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "IpsBatchSyncFilterForm",
    enableReinitialize: true,
  }),
  formValues({
    fromDate: "fromDate",
    toDate: "toDate",
  }),
);

IpsBatchSyncFilterForm.propTypes = {
  getLocalisationMessage: PropTypes.func,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  onDismiss: PropTypes.func,
};

function IpsBatchSyncFilterForm(props) {
  const { getLocalisationMessage, change } = props;

  return (
    <FlexBox
      flex={true}
      style={{ gap: 14 }}
      direction="column"
      element={<form autoComplete="off" onSubmit={props.handleSubmit} />}
    >
      <FlexBox flex={true} style={{ gap: 14 }}>
        <FlexBox flex={true}>
          <FormDateTimeField
            fullWidth={true}
            ampmInClock={true}
            formatValue={DATE_TIME_SHORT_FORMAT}
            placeholder={getLocalisationMessage("from_date")}
            name="fromDate"
            onChange={v => change("fromDate", startOfDay(v))}
          />
        </FlexBox>
        <FlexBox flex={true}>
          <FormDateTimeField
            fullWidth={true}
            formatValue={DATE_TIME_SHORT_FORMAT}
            placeholder={getLocalisationMessage("to_date")}
            name="toDate"
            onChange={v => change("toDate", endOfDay(v))}
          />
        </FlexBox>
      </FlexBox>
      <FlexBox flex={true} justify="flex-end" style={{ gap: 10 }}>
        <CustomButton
          variant={CONTAINED}
          color={SECONDARY}
          style={{ backgroundColor: "#3F51B5" }}
          onClick={props.onDismiss}
          size="large"
          fullWidth={true}
        >
          {getLocalisationMessage("clear", "clear")}
        </CustomButton>

        <CustomButton
          variant={CONTAINED}
          color={SECONDARY}
          style={{ backgroundColor: "#3F51B5" }}
          type="submit"
          size="large"
          fullWidth={true}
        >
          {getLocalisationMessage("apply", "Apply")}
        </CustomButton>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(IpsBatchSyncFilterForm);
