// eslint-disable-next-line no-unused-vars
import React from "react";
import { List } from "immutable";
import { DATE, DIALOG, RENDER } from "../../components/orders-core/MUITable";
import MultiLineCell from "../../components/data-list/MultiLineCell";
import _ from "lodash";
import OrderListServiceType from "../../components/orders-core/OrderListServiceType";
import AvatarWithName2 from "../../components/avatars/AvatarWithName2";
import PaymentTypeAvatarAndInfo from "../../components/orders-core/PaymentTypeAvatarAndInfo";
import PriceWrapper from "../../components/ui-core/PriceWrapper";
import { BOLD } from "../../components/ui-core/Text";
import { formatLocalisedPaymentType } from "../../helpers/OrderHelper";
import FlexBox from "../../components/ui-core/FlexBox";

export const ID = {
  ind: 1,
  slug: "id",
  label: "id",
  keyName: "id",
  name: "Id",
  type: DIALOG,
};
export const BARCODE = {
  ind: 1,
  slug: "BARCODE",
  label: "barcode",
  keyName: "barcode",
  name: "Barcode",
  type: DIALOG,
  align: "center",
  isCustomer: true,
};

export const PICKUP_ADDRESS = {
  ind: 4,
  slug: "PICKUP_ADDRESS",
  label: "sender_address",
  keyName: "locations.0.address",
  name: "Pickup Address",
  align: "center",
  longString: true,
};
export const PICKUP_JURISDICTION = {
  ind: 4,
  slug: "PICKUP_JURISDICTION",
  label: "sender_jurisdiction",
  keyName: "locations.0.jurisdiction",
  name: "Pickup jurisdiction",
  align: "center",
  longString: true,
};

export const DROPOFF_ADDRESS = {
  ind: 4,
  slug: "DROPOFF_ADDRESS",
  label: "recipient_address",
  keyName: "locations.1.address",
  name: "DropOff Address",
  align: "center",
  longString: true,
};
export const DROPOFF_JURISDICTION = {
  ind: 4,
  slug: "DROPOFF_JURISDICTION",
  label: "recipient_jurisdiction",
  keyName: "locations.1.jurisdiction",
  name: "DropOff jurisdiction",
  align: "center",
  longString: true,
};
export const SERVICE = {
  ind: 5,
  slug: "ORDER_TYPE",
  label: "shipment_type",
  keyName: "package.delivery_label",
  name: "Service",
  type: RENDER,
  render: (row, getLocalisationMessage) =>
    _.get(row, "notification.enabled") ? (
      <OrderListServiceType order={row} />
    ) : _.get(row, "head_order_id") ? (
      <AvatarWithName2
        name={getLocalisationMessage("notification")}
        isNotification={true}
      >
        <MultiLineCell
          firstLine={
            <strong style={{ textTransform: "capitalize" }}>
              {getLocalisationMessage("notification")}
            </strong>
          }
        />
      </AvatarWithName2>
    ) : (
      <AvatarWithName2
        name={_.get(row, "package.delivery_label", "-")}
        imagePath={_.get(row, "package.courier_type_icon")}
      >
        <MultiLineCell
          firstLine={
            <strong>{_.get(row, "package.delivery_label", "-")}</strong>
          }
          secondLine={_.get(row, "package.menu.name")}
        />
      </AvatarWithName2>
    ),
};
export const CREATED_DATE = {
  ind: 9,
  slug: "CREATED_DATE",
  label: "created_date",
  keyName: "created_date",
  name: "Created Time",
  type: DATE,
  isSortable: false,
  withFilter: false,
  align: "center",
};
export const STATUS = {
  ind: 17,
  slug: "STATUS",
  label: "status",
  keyName: "status",
  name: "Status",
  withTranslation: true,
  align: "center",
  withFilter: false,
  withColor: true,
};
export const COMPLETED_DATE = {
  ind: 26,
  slug: "DELIVERED_DATE",
  label: "completed_date",
  keyName: "completed",
  name: "Completed Date",
  type: DATE,
  align: "center",
};
export const PAYMENT_TYPE = {
  ind: 20,
  align: "center",
  slug: "PAYMENT_TYPE",
  label: "payment_type",
  keyName: "amount",
  name: "Payment Type",
  withRender: true,
  type: RENDER,
  render: (row, getLocalisationMessage) => (
    <FlexBox justify="center" align="center">
      <PaymentTypeAvatarAndInfo paymentType={_.get(row, "payment_type")}>
        {formatLocalisedPaymentType(
          _.get(row, "payment_type"),
          getLocalisationMessage,
        )}
      </PaymentTypeAvatarAndInfo>
    </FlexBox>
  ),
};
export const TOTAL = {
  ind: 20,
  align: "center",
  slug: "TOTAL",
  label: "total",
  keyName: "amount",
  name: "Total",
  withRender: true,
  render: row => (
    <MultiLineCell
      style={{ paddingRight: "10px" }}
      firstLine={<PriceWrapper price={_.get(row, "amount")} type={[BOLD]} />}
    />
  ),
};

export default List([
  BARCODE,
  SERVICE,
  PICKUP_JURISDICTION,
  PICKUP_ADDRESS,
  DROPOFF_JURISDICTION,
  DROPOFF_ADDRESS,
  CREATED_DATE,
  COMPLETED_DATE,
  STATUS,
  PAYMENT_TYPE,
  TOTAL,
]);
