import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { Card, LinearProgress } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import FlexBox from "../ui-core/FlexBox";
import {
  formatNumber,
  NUMBER_FORMAT,
  formatPercentage,
} from "../../helpers/FormatUtils";

const enhancer = compose(
  useSheet({
    card: {
      width: "100%",
      paddingLeft: "20px",
      paddingRight: "10px",
      paddingTop: "10px",
      paddingBottom: "5px",
    },
    title: {
      fontSize: "18px",
      textTransform: "uppercase",
      lineHeight: "24px",
      color: "#fff",
    },
    percentageValue: {
      fontSize: "22px",
      lineHeight: "28px",
      color: "#fff",
      "& > span": {
        fontSize: "10px",
        // color: fade("#fff", 0.75),
      },
    },
    stats: {
      fontSize: "14px",
      lineHeight: "16px",
      textTransform: "uppercase",
    },
    flexPadding: {
      padding: "8px",
    },
    progressPadding: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
    actions: {
      display: "flex",
      alignItems: "center",
      alignSelf: "center",
      marginRight: "12px",
    },
  }),
);

SpikeChart.propTypes = {
  classes: PropTypes.object,
  change: PropTypes.func,
  color: PropTypes.string,
  title: PropTypes.string,
  minHeight: PropTypes.number,
  backgroundColor: PropTypes.string,
  data: PropTypes.object.isRequired,
  tooltipFormat: PropTypes.object,
  actionIcons: PropTypes.node,
};

SpikeChart.defaultProps = {
  backgroundColor: "#7CB342",
  color: "#000",
  minHeight: 50,
  tooltipFormat: {
    displayColors: false,
  },
};

function SpikeChart(props) {
  const { classes } = props;

  const options = {
    maintainAspectRatio: false,
    tooltips: {
      mode: "index",
      intersect: false,
      ...props.tooltipFormat,
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 15,
        bottom: 15,
      },
      height: "100px",
    },
    legend: {
      display: false,
    },
    elements: {
      line: {
        label: false,
        backgroundColor: "transparent",
        // borderColor: fade(props.color, 0.75),
      },
      point: {
        radius: 1,
        // backgroundColor: fade(props.color, 0.75),
        // borderColor: fade(props.color, 0.75),
      },
      rectangle: {
        backgroundColor: "transparent",
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
            drawBorder: 0,
            drawTicks: 0,
          },
          ticks: {
            display: 0,
            fontSize: 1,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
            drawBorder: 0,
            drawTicks: 0,
          },
          ticks: {
            display: 0,
            fontSize: 1,
          },
        },
      ],
    },
  };

  return (
    <Card
      className={classes.card}
      style={{
        backgroundColor: props.backgroundColor,
        // color: fade(props.color, 0.75),
      }}
    >
      <FlexBox justify="space-between">
        <FlexBox direction="column" flex={5} className={classes.flexPadding}>
          <h3 className={classes.title}>{props.title}: </h3>
          <h3 className={classes.percentageValue}>
            {formatPercentage(props.data.get("percentage"))}{" "}
          </h3>
        </FlexBox>
        <FlexBox flex={8} className={classes.flexPadding}>
          <Line
            data={props.data.get("chartData").toJS()}
            width={100}
            height={props.minHeight}
            options={options}
          />
        </FlexBox>
        <FlexBox
          flex={1}
          justify="center"
          align="center"
          className={classes.flexPadding}
        >
          {React.isValidElement(props.actionIcons)
            ? React.cloneElement(props.actionIcons, {
                className: cx(props.actionIcons.className, classes.actions),
              })
            : props.actionIcons}
        </FlexBox>
      </FlexBox>
      <FlexBox justify="space-between">
        <FlexBox flex={true} className={classes.progressPadding}>
          <LinearProgress
            variant="determinate"
            // style={{ backgroundColor: lighten(props.backgroundColor, 0.3) }}
            color={props.color}
            value={props.data.get("percentage")}
          />
        </FlexBox>
      </FlexBox>
      <FlexBox justify="space-between">
        {props.data.get("infoData").map((item, idx) => (
          <FlexBox
            key={idx}
            flex={true}
            justify={item.get("align")}
            className={classes.flexPadding}
          >
            <h4 className={classes.stats}>
              {item.get("label")}:{" "}
              <strong>
                {formatNumber(
                  item.get("value"),
                  NUMBER_FORMAT,
                  item.get("value"),
                )}
              </strong>
            </h4>
          </FlexBox>
        ))}
      </FlexBox>
    </Card>
  );
}

export default enhancer(SpikeChart);
