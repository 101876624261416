import { List } from "immutable";
import {
  ACT_MANAGEMENT_URL,
  ADDRESS_BOOK_LIST_NEW_URL,
  ADMIN_AREAS_URL,
  ARCHIVE_ORDER_LIST_URL,
  AREAS_URL,
  BAD_ADDRESS_LIST_URL,
  CASH_CERTIFICATE_URL,
  CUSTOM_BATCH_UPDATE_URL,
  CHARTS_URL,
  CUSTOMER_FEEDBACK_LIST_URL,
  CUSTOMER_LIST_ALL_URL,
  DEFAULT_PACKAGE_NEW_URL,
  DRIVER_LIABILITY_URL,
  DRIVER_LIST_URL,
  DRIVER_ORDER_RADAR_URL,
  DRIVER_RADAR_URL,
  DRIVERS_ATTENDANCE_URL,
  DRIVERS_DASHBOARD_URL,
  FINANCE_BANK_DEPOSIT_ORDERS_URL,
  FINANCE_BANK_DEPOSITS_URL,
  FINANCE_CASHIERS_URL,
  FINANCE_ORDER_EXCEPTIONS_URL,
  FINANCE_ORDER_LIST_URL,
  FINANCE_SETTLEMENT_URL,
  FINANCE_TRANSACTIONS_URL,
  FINANCE_TRANSFER_LIST_URL,
  FINANCE_WITHDRAW_LIST_URL,
  HYBRID_URL,
  INTERNATIONAL_LETTER_URL,
  INTERNATIONAL_OUT_TARIFF_URL,
  INTERNATIONAL_PARCEL_URL,
  INTERNATIONAL_SETTINGS_URL,
  JOBS_URL,
  JURISDICTION_MANAGEMENT_URL,
  LETTERS_URL,
  LOCKER_POST_LIST_URL,
  OPERATOR_BALANCE_URL,
  ORDER_ACTIVITY_HISTORY_URL,
  ORDER_BATCH_UPDATE_URL,
  ORDER_BIN_VALIDATION_SIMPLE_FIREBASE_VIEW_URL,
  ORDER_BIN_VALIDATION_SIMPLE_VIEW_URL,
  ORDER_BIN_VALIDATION_VIEW_URL,
  ORDER_CUSTOMS_LIST_VIEW_URL,
  ORDER_LIST_MAP_URL,
  ORDER_LIST_REQUIRED_ATTENTION_URL,
  ORDER_LIST_URL,
  ORDER_OFFLINE_OUTBOUND_SORTING_VIEW_URL,
  ORDER_OUTBOUND_SORTING_FIREBASE_VIEW_URL,
  ORDER_RETURN_VIEW_URL,
  ORDER_TAGS_LIST_URL,
  ORDER_UPLOAD_V2_URL,
  ORDERS_PER_POSTCODES_URL,
  PACKAGE_RULE_LIST_URL,
  PI1_URL,
  POSTCODES_COORDINATES_URL,
  POSTCODES_LIST_URL,
  PROMO_LIST_URL,
  REPORT_WAREHOUSE_URL,
  REPORTS_URL,
  ROUTE_TEMPLATES_URL,
  SAMPLE_ORDER_LIST_URL,
  SERVICE_TYPES_LIST_URL,
  SETTINGS_DASHBOARD_URL,
  SUPPLIER_LIST_URL,
  SUPPLIER_USER_LIST_URL,
  TIME_SLOT_AVAILABILITY_LIST_URL,
  TIME_SLOT_LIST_URL,
  USERS_LIST_URL,
  USERS_PERMISSION_LIST_PATH,
  VEHICLE_LIST_ALL_URL,
  WAREHOUSE_LIST_URL,
  INTERNATIONAL_CHARTS_URL,
  INTERNATIONAL_EMPTY_BAGS_URL,
  ORDER_CUSTOMS_FEE_MANAGEMENT_LIST_VIEW_URL,
  DRIVER_REPORTS_URL,
  IPS_BATCH_SYNC_URL,
  WAREHOUSE_REPORTS_URL,
  NEW_CUSTOM_BATCH_UPDATE_URL,
  ORDER_FLOW_URL,
} from "./AdminPathConstants";

export const DASHBOARD = {
  permission: "ADMIN_DASHBOARD",
  ind: 1,
  group: "dashboard",
  slug: "dashboard",
  icon: "actionDashboard",
  url: CHARTS_URL,
};
export const INTERNATIONAL_DASHBOARD = {
  permission: "ADMIN_DASHBOARD",
  ind: 1,
  group: "dashboard",
  slug: "international_dashboard",
  icon: "actionDashboard",
  url: INTERNATIONAL_CHARTS_URL,
};
export const DRIVER_REPORTS = {
  permission: "ADMIN_DASHBOARD",
  ind: 1,
  group: "dashboard",
  slug: "drivers_report",
  icon: "actionDashboard",
  url: DRIVER_REPORTS_URL,
};
export const WAREHOUSE_REPORTS = {
  permission: "ADMIN_DASHBOARD",
  ind: 1,
  group: "dashboard",
  slug: "warehouse_report",
  icon: "actionDashboard",
  url: WAREHOUSE_REPORTS_URL,
};
export const MAP_VIEW_ORDERS = {
  permission: "ADMIN_MAP_VIEW_ORDERS_MENU",
  ind: 2,
  group: "orders",
  slug: "map_view_orders",
  icon: "mapsMap",
  url: DRIVER_ORDER_RADAR_URL,
  // params: { is_uae: true },
};
export const DOMESTIC_ORDERS = {
  permission: "ADMIN_DOMESTIC_ORDERS_MENU",
  ind: 2,
  group: "orders",
  slug: "domestic_orders",
  icon: "truckDelivery",
  url: ORDER_LIST_URL,
};

export const ARCHIVE_ORDERS = {
  permission: "ADMIN_ORDERS_ARCHIVE_MENU",
  ind: 2,
  group: "orders",
  slug: "archive_orders",
  icon: "truckDelivery",
  url: ARCHIVE_ORDER_LIST_URL,
};

export const SIMPLE_ORDERS = {
  permission: "CAN_CREATE_SIMPLE_ORDER",
  ind: 2,
  group: "orders",
  slug: "simple_orders",
  icon: "truckDelivery",
  url: SAMPLE_ORDER_LIST_URL,
};
export const UPLOAD_ORDERS_V2 = {
  permission: "ADMIN_UPLOAD_ORDERS_V2_MENU",
  ind: 2,
  group: "orders",
  slug: "new_upload_orders",
  icon: "fileFileUpload",
  url: ORDER_UPLOAD_V2_URL,
};

export const INTERNATIONAL_ORDERS = {
  permission: "ADMIN_INTERNATIONAL_ORDERS_MENU",
  ind: 2,
  group: "orders",
  slug: "international_orders",
  icon: "actionFlightTakeoff",
  url: ORDER_LIST_URL,
};
export const JOBS = {
  permission: "ADMIN_JOBS_MENU",
  ind: 2,
  group: "orders",
  slug: "jobs",
  icon: "fileFolderOpen",
  url: JOBS_URL,
};
export const REQUIRES_ATTENTION = {
  permission: "ADMIN_REQUIRES_ATTENTION_MENU",
  ind: 2,
  group: "orders",
  slug: "requires_attention",
  icon: "alertErrorOutline",
  url: ORDER_LIST_REQUIRED_ATTENTION_URL,
};
export const ORDER_RETURN = {
  permission: "ADMIN_ORDER_RETURN_MENU",
  ind: 2,
  group: "orders",
  slug: "order_return",
  icon: "hardwareDeviceHub",
  url: ORDER_RETURN_VIEW_URL,
};
export const ORDERS_ROUTING = {
  permission: "ADMIN_ORDERS_ROUTING_MENU",
  ind: 2,
  group: "orders",
  slug: "orders_routing",
  icon: "actionTimeline",
  url: ORDER_LIST_MAP_URL,
};

export const ORDERS_PER_POSTCODES = {
  permission: "ADMIN_ORDERS_PER_POSTCODES_MENU",
  ind: 2,
  group: "orders",
  slug: "orders_in_postcodes",
  icon: "imageBlurCircular",
  url: ORDERS_PER_POSTCODES_URL,
};
export const POSTCODES_COORDINATES = {
  permission: "ADMIN_POSTCODES_COORDINATES_MENU",
  ind: 2,
  group: "orders",
  slug: "coordinates_of_postcodes",
  icon: "communicationLocationOn",
  url: POSTCODES_COORDINATES_URL,
};
export const AREAS = {
  permission: "ADMIN_AREAS_MENU",
  ind: 2,
  group: "orders",
  slug: "areas",
  icon: "mapsMap",
  url: AREAS_URL,
};
export const ADMIN_AREAS = {
  permission: "ADMIN_ADMIN_AREAS_MENU",
  ind: 2,
  group: "orders",
  slug: "admin_areas",
  icon: "mapsMap",
  url: ADMIN_AREAS_URL,
};
export const BAD_ADDRESSES = {
  permission: "ADMIN_BAD_ADDRESSES_MENU",
  ind: 2,
  group: "orders",
  slug: "bad_addresses",
  icon: "alertWarning",
  url: BAD_ADDRESS_LIST_URL,
};
export const ACTIVITY_HISTORY = {
  permission: "ADMIN_ACTIVITY_HISTORY_MENU",
  ind: 2,
  group: "orders",
  slug: "activity_history",
  icon: "actionActivityHistory",
  url: ORDER_ACTIVITY_HISTORY_URL,
};
export const ORDER_INBOUND_SORTING_FIREBASE = {
  permission: "ADMIN_ORDER_INBOUND_SORTING_FIREBASE_MENU",
  ind: 2,
  group: "logistics",
  slug: "inbound_sorting_firebase",
  icon: "archive",
  url: ORDER_BIN_VALIDATION_SIMPLE_FIREBASE_VIEW_URL,
};
export const ORDER_SORTING_FIREBASE = {
  permission: "ADMIN_ORDER_SORTING_FIREBASE_MENU",
  ind: 2,
  group: "logistics",
  slug: "order_sorting_firebase",
  icon: "unArchive",
  url: ORDER_OUTBOUND_SORTING_FIREBASE_VIEW_URL,
};
export const ORDER_CUSTOMS = {
  permission: "ADMIN_ORDER_CUSTOMS_MENU",
  ind: 2,
  group: "logistics",
  slug: "order_customs",
  icon: "unArchive",
  url: ORDER_CUSTOMS_LIST_VIEW_URL,
};
export const CUSTOMS_FEE_MANAGEMENT = {
  permission: "ADMIN_CUSTOMS_ORDERS_MENU",
  ind: 2,
  group: "logistics",
  slug: "order_customs_fee",
  icon: "unArchive",
  url: ORDER_CUSTOMS_FEE_MANAGEMENT_LIST_VIEW_URL,
};
export const ORDER_INBOUND_SORTING = {
  permission: "ADMIN_ORDER_INBOUND_SORTING_MENU",
  ind: 2,
  group: "finance",
  slug: "inbound_sorting",
  icon: "archive",
  url: ORDER_BIN_VALIDATION_SIMPLE_VIEW_URL,
};

export const ORDER_INBOUND_SORTING_V2 = {
  permission: "ADMIN_ORDER_INBOUND_SORTING_MENU",
  ind: 2,
  group: "logistics",
  slug: "inbound_sorting",
  icon: "archive",
  url: ORDER_BIN_VALIDATION_SIMPLE_VIEW_URL,
};

export const BATCH_UPDATE = {
  permission: "ADMIN_BATCH_UPDATE_MENU",
  ind: 2,
  group: "logistics",
  slug: "order_batch_update",
  icon: "swapHorizontalCircle",
  url: ORDER_BATCH_UPDATE_URL,
};
export const ORDER_EXCEPTIONS = {
  permission: "ADMIN_ORDER_EXCEPTIONS_MENU",
  ind: 2,
  group: "logistics",
  slug: "order_exceptions",
  icon: "contentReport",
  url: FINANCE_ORDER_EXCEPTIONS_URL,
};

export const ACT_MANAGEMENT = {
  permission: "ADMIN_ACT_MANAGEMENT_MENU",
  ind: 2,
  group: "logistics",
  slug: "act_management",
  icon: "contentReport",
  url: ACT_MANAGEMENT_URL,
};
export const ROUTE_TEMPLATES = {
  permission: "ADMIN_ROUTE_TEMPLATES_MENU",
  ind: 2,
  group: "logistics",
  slug: "route_templates",
  icon: "route_templates",
  url: ROUTE_TEMPLATES_URL,
};
export const REPORT_WAREHOUSE = {
  permission: "ADMIN_REPORT_WAREHOUSE_MENU",
  ind: 2,
  group: "logistics",
  slug: "report_warehouse",
  icon: "report_warehouse",
  url: REPORT_WAREHOUSE_URL,
};
export const HYBRID = {
  permission: "ADMIN_HYBRID_MENU",
  ind: 2,
  group: "logistics",
  slug: "hybrid",
  icon: "hybrid",
  url: HYBRID_URL,
};

export const CUSTOM_BATCH_UPDATE = {
  permission: "ADMIN_ORDER_HISTORY_UPDATE",
  ind: 2,
  group: "logistics",
  slug: "custom_batch_update",
  icon: "custom_batch_update",
  url: CUSTOM_BATCH_UPDATE_URL,
};

export const CUSTOM_NEW_BATCH_UPDATE = {
  permission: "ADMIN_ORDER_HISTORY_UPDATE",
  ind: 2,
  group: "logistics",
  slug: "custom_new_batch_update",
  icon: "custom_new_batch_update",
  url: NEW_CUSTOM_BATCH_UPDATE_URL,
};

export const IPS_ORDER_SYNC = {
  permission: "IPS_ORDER_SYNC",
  ind: 2,
  group: "logistics",
  slug: "ips_batch_sync",
  icon: "custom_batch_update",
  url: IPS_BATCH_SYNC_URL,
};

export const ORDER_FLOW = {
  permission: "ORDER_FLOW_OPERATION",
  ind: 2,
  group: "logistics",
  slug: "order_flow",
  icon: "unArchive",
  url: ORDER_FLOW_URL,
};

export const LETTERS = {
  permission: "ADMIN_IPS_LETTERS",
  ind: 3,
  group: "ips",
  slug: "letters",
  icon: "archive",
  url: LETTERS_URL,
};
export const PI1 = {
  permission: "ADMIN_IPS_Pi1",
  ind: 3,
  group: "ips",
  slug: "pi1",
  icon: "unArchive",
  url: PI1_URL,
};
export const REPORTS = {
  permission: "ADMIN_IPS_REPORTS",
  ind: 3,
  group: "ips",
  slug: "reports",
  icon: "unArchive",
  url: REPORTS_URL,
};

export const INTERNATIONAL_LETTER = {
  permission: "INTERNATIONAL_LETTER",
  ind: 3,
  group: "international_reports",
  slug: "international_letter",
  icon: "archive",
  url: INTERNATIONAL_LETTER_URL,
};
export const INTERNATIONAL_PARCEL = {
  permission: "INTERNATIONAL_PARCEL",
  ind: 3,
  group: "international_reports",
  slug: "international_parcel",
  icon: "unArchive",
  url: INTERNATIONAL_PARCEL_URL,
};
export const INTERNATIONAL_EMPTY_BAGS = {
  permission: "INTERNATIONAL_PARCEL",
  ind: 3,
  group: "international_reports",
  slug: "empty_bags",
  icon: "unArchive",
  url: INTERNATIONAL_EMPTY_BAGS_URL,
};
// export const INTERNATIONAL_IN_TARIFF = {
//   permission: "INTERNATIONAL_IN_TARIFF",
//   ind: 3,
//   group: "international_reports",
//   slug: "in_tariffs",
//   icon: "unArchive",
//   url: INTERNATIONAL_IN_TARIFF_URL,
// };

export const INTERNATIONAL_OUT_TARIFF = {
  permission: "INTERNATIONAL_TARIFF",
  ind: 3,
  group: "international_reports",
  slug: "tariffs",
  icon: "unArchive",
  url: INTERNATIONAL_OUT_TARIFF_URL,
};

export const INTERNATIONAL_SETTINGS = {
  permission: "INTERNATIONAL_SETTINGS",
  ind: 3,
  group: "international_reports",
  slug: "settings",
  icon: "unArchive",
  url: INTERNATIONAL_SETTINGS_URL,
};

export const ORDER_BIN_VALIDATION = {
  permission: "ADMIN_ORDER_BIN_VALIDATION_MENU",
  ind: 4,
  group: "logistics",
  slug: "order_validation",
  icon: "hardwareDeviceHub",
  url: ORDER_BIN_VALIDATION_VIEW_URL,
};
export const ORDER_SORTING = {
  permission: "ADMIN_ORDER_SORTING_MENU",
  ind: 4,
  group: "finance",
  slug: "order_sorting",
  icon: "unArchive",
  url: ORDER_OFFLINE_OUTBOUND_SORTING_VIEW_URL,
};
export const ORDER_SORTING_V2 = {
  permission: "ADMIN_ORDER_SORTING_MENU",
  ind: 2,
  group: "logistics",
  slug: "order_sorting",
  icon: "unArchive",
  url: ORDER_OFFLINE_OUTBOUND_SORTING_VIEW_URL,
};

export const NEW_PACKAGES = {
  permission: "ADMIN_NEW_PACKAGES_MENU",
  ind: 3,
  group: "packages",
  slug: "marketplace_package",
  icon: "packageVariantClosed",
  url: DEFAULT_PACKAGE_NEW_URL,
};
export const SERVICE_TYPES = {
  permission: "ADMIN_SERVICE_TYPES_MENU",
  ind: 4,
  group: "packages",
  slug: "service_types",
  icon: "packageVariantClosed",
  url: SERVICE_TYPES_LIST_URL,
};
export const CUSTOM_PACKAGES = {
  permission: "ADMIN_CUSTOM_PACKAGES_MENU",
  ind: 4,
  group: "packages",
  slug: "package_rule",
  icon: "packageVariantClosed",
  url: PACKAGE_RULE_LIST_URL,
};
export const CASH_CERTIFICATE = {
  permission: "ADMIN_CASH_CERTIFICATE_MENU",
  ind: 4,
  group: "finance",
  slug: "cash_certificate",
  icon: "actionAssignmentReturned",
  url: CASH_CERTIFICATE_URL,
};
export const FINANCE_ORDERS = {
  permission: "ADMIN_FINANCE_ORDERS_MENU",
  ind: 4,
  group: "finance",
  slug: "finance_orders",
  icon: "actionAccountBalance",
  url: FINANCE_ORDER_LIST_URL,
};
export const FINANCE_SETTLEMENT = {
  permission: "ADMIN_FINANCE_SETTLEMENT_MENU",
  ind: 4,
  group: "finance",
  slug: "finance_settlement",
  icon: "imageStyle",
  url: FINANCE_SETTLEMENT_URL,
};
export const SUPPLIER_COD_TRANSFERS = {
  permission: "ADMIN_SUPPLIER_COD_TRANSFERS_MENU",
  ind: 4,
  group: "finance",
  slug: "supplier_cod_transfers",
  icon: "actionAssignmentReturned",
  url: FINANCE_TRANSFER_LIST_URL,
};
export const CUSTOMER_COD_TRANSFERS = {
  permission: "ADMIN_CUSTOMER_COD_TRANSFERS_MENU",
  ind: 4,
  group: "finance",
  slug: "customer_cod_transfers",
  icon: "actionAssignmentReturned",
  url: FINANCE_WITHDRAW_LIST_URL,
};
export const CUSTOMER_COD_BALANCE = {
  permission: "ADMIN_CUSTOMER_COD_BALANCE_MENU",
  ind: 4,
  group: "finance",
  slug: "customer_cod_balance",
  icon: "actionSwapHoriz",
  url: FINANCE_TRANSACTIONS_URL,
};
export const DRIVER_LIABILITY = {
  permission: "ADMIN_DRIVER_LIABILITY_MENU",
  ind: 4,
  group: "finance",
  slug: "driver_liability",
  icon: "notificationDriveEta",
  url: DRIVER_LIABILITY_URL,
};
export const OPERATOR_BALANCE = {
  permission: "ADMIN_OPERATOR_BALANCE_MENU",
  ind: 4,
  group: "finance",
  slug: "operator_balance",
  icon: "editorMonetizationOn",
  url: OPERATOR_BALANCE_URL,
};
export const BANK_DEPOSIT_ORDERS = {
  permission: "ADMIN_BANK_DEPOSIT_ORDERS_MENU",
  ind: 4,
  group: "finance",
  slug: "bank_deposit_order",
  icon: "editorMonetizationOn",
  url: FINANCE_BANK_DEPOSIT_ORDERS_URL,
};
export const BANK_DEPOSITS = {
  permission: "ADMIN_BANK_DEPOSITS_MENU",
  ind: 4,
  group: "finance",
  slug: "bank_deposits",
  icon: "editorMonetizationOn",
  url: FINANCE_BANK_DEPOSITS_URL,
};
export const CASHIER = {
  permission: "ADMIN_CASHIER_MENU",
  ind: 4,
  group: "finance",
  slug: "cashiers",
  icon: "actionAssignmentInd",
  url: FINANCE_CASHIERS_URL,
};
export const COURIERS = {
  permission: "ADMIN_COURIERS_MENU",
  ind: 5,
  group: "couriers",
  slug: "couriers",
  icon: "placesBusinessCenter",
  url: SUPPLIER_LIST_URL,
};
export const COURIER_USERS = {
  permission: "ADMIN_COURIER_USERS_MENU",
  ind: 5,
  group: "couriers",
  slug: "courier_users",
  icon: "socialPeopleOutline",
  url: SUPPLIER_USER_LIST_URL,
};
export const DRIVERS = {
  permission: "ADMIN_DRIVERS_MENU",
  ind: 5,
  group: "couriers",
  slug: "drivers",
  icon: "accountCardDetails",
  url: DRIVER_LIST_URL,
};
export const DRIVERS_ATTENDANCE = {
  permission: "ADMIN_DRIVERS_ATTENDANCE_MENU",
  ind: 5,
  group: "couriers",
  slug: "drivers_attendance",
  icon: "notificationEventAvailable",
  url: DRIVERS_ATTENDANCE_URL,
};
export const DRIVERS_DASHBOARD = {
  permission: "ADMIN_DRIVERS_DASHBOARD_MENU",
  ind: 5,
  group: "couriers",
  slug: "drivers_dashboard",
  icon: "accountCardDetails",
  url: DRIVERS_DASHBOARD_URL,
};
export const DRIVER_RADAR = {
  permission: "ADMIN_DRIVER_RADAR_MENU",
  ind: 5,
  group: "couriers",
  slug: "radar",
  icon: "radarIcon",
  url: DRIVER_RADAR_URL,
};
export const VEHICLES = {
  permission: "ADMIN_VEHICLES_MENU",
  ind: 5,
  group: "couriers",
  slug: "vehicles",
  icon: "vehicleIcon",
  url: VEHICLE_LIST_ALL_URL,
};
export const WAREHOUSE = {
  permission: "ADMIN_WAREHOUSE_MENU",
  ind: 5,
  group: "couriers",
  slug: "warehouse",
  icon: "warehouseIcon",
  url: WAREHOUSE_LIST_URL,
};
export const LOCKER_POST = {
  permission: "ADMIN_LOCKER_POST_MENU",
  ind: 5,
  group: "couriers",
  slug: "locker_post",
  icon: "warehouseIcon",
  url: LOCKER_POST_LIST_URL,
};
export const TIME_SLOTS = {
  permission: "ADMIN_TIME_SLOTS_MENU",
  ind: 5,
  group: "couriers",
  slug: "timeslots",
  icon: "timeslotIcon",
  url: TIME_SLOT_LIST_URL,
};
export const TIME_SLOT_AVAILABILITY = {
  permission: "ADMIN_TIME_SLOT_AVAILABILITY_MENU",
  ind: 5,
  group: "couriers",
  slug: "timeslot_availability",
  icon: "timeslotAvailabilityIcon",
  url: TIME_SLOT_AVAILABILITY_LIST_URL,
};
export const POSTCODES = {
  permission: "ADMIN_POSTCODES_MENU",
  ind: 5,
  group: "couriers",
  slug: "postcodes",
  icon: "postcodesIcon",
  url: POSTCODES_LIST_URL,
};
export const CUSTOMERS = {
  permission: "ADMIN_CUSTOMERS_MENU",
  ind: 6,
  group: "customers",
  slug: "customers",
  icon: "socialPeople",
  url: CUSTOMER_LIST_ALL_URL,
};

export const ADDRESS_BOOK_NEW = {
  permission: "ADMIN_ADDRESS_BOOK_NEW_MENU",
  ind: 6,
  group: "customers",
  slug: "address_book",
  icon: "addressIcon",
  url: ADDRESS_BOOK_LIST_NEW_URL,
};
export const PROMOS = {
  permission: "ADMIN_PROMOS_MENU",
  ind: 6,
  group: "customers",
  slug: "promos",
  icon: "actionCardGiftcard",
  url: PROMO_LIST_URL,
};
export const CUSTOMER_FEEDBACK = {
  permission: "ADMIN_CUSTOMER_FEEDBACK_MENU",
  ind: 6,
  group: "customers",
  slug: "customer_feedback",
  icon: "communicationMessage",
  url: CUSTOMER_FEEDBACK_LIST_URL,
};

export const JURISDICTION_MANAGEMENT = {
  permission: "ADMIN_JURISDICTION_MANAGEMENT_MENU",
  ind: 8,
  group: "settings",
  slug: "jurisdiction_management",
  icon: "mapsMap",
  url: JURISDICTION_MANAGEMENT_URL,
};
export const TAGS = {
  permission: "ADMIN_TAGS_MENU",
  ind: 8,
  group: "settings",
  slug: "tags",
  icon: "actionBookmark",
  url: ORDER_TAGS_LIST_URL,
};
export const SETTINGS = {
  permission: "ADMIN_SETTINGS_MENU",
  ind: 8,
  group: "settings",
  slug: "settings_dashboard",
  icon: "actionSettings",
  url: SETTINGS_DASHBOARD_URL,
};
export const USERS = {
  permission: "ADMIN_USERS_MENU",
  ind: 9,
  group: "users",
  slug: "users",
  icon: "socialPeopleOutline",
  url: USERS_LIST_URL,
};

export const PERMISSIONS = {
  permission: "ADMIN_PERMISSIONS_MENU",
  ind: 9,
  group: "users",
  slug: "permissions",
  icon: "socialPeopleOutline",
  url: USERS_PERMISSION_LIST_PATH,
};

export default List([
  DASHBOARD,
  INTERNATIONAL_DASHBOARD,
  DRIVER_REPORTS,
  WAREHOUSE_REPORTS,
  DOMESTIC_ORDERS,
  SIMPLE_ORDERS,
  ARCHIVE_ORDERS,
  ORDER_INBOUND_SORTING_FIREBASE,
  ORDER_SORTING_FIREBASE,
  ORDER_CUSTOMS,
  CUSTOMS_FEE_MANAGEMENT,
  UPLOAD_ORDERS_V2,
  LETTERS,
  PI1,
  REPORTS,
  INTERNATIONAL_LETTER,
  INTERNATIONAL_PARCEL,
  INTERNATIONAL_EMPTY_BAGS,
  INTERNATIONAL_OUT_TARIFF,
  INTERNATIONAL_SETTINGS,
  BATCH_UPDATE,
  ORDER_EXCEPTIONS,
  ACT_MANAGEMENT,
  ROUTE_TEMPLATES,
  REPORT_WAREHOUSE,
  HYBRID,
  CUSTOM_BATCH_UPDATE,
  CUSTOM_NEW_BATCH_UPDATE,
  IPS_ORDER_SYNC,
  ORDER_FLOW,
  JURISDICTION_MANAGEMENT,
  ACTIVITY_HISTORY,
  SERVICE_TYPES,
  CUSTOM_PACKAGES,
  CASH_CERTIFICATE,
  OPERATOR_BALANCE,
  CASHIER,
  ORDER_INBOUND_SORTING,
  ORDER_SORTING,
  COURIERS,
  DRIVERS,
  VEHICLES,
  WAREHOUSE,
  LOCKER_POST,
  POSTCODES,
  CUSTOMERS,
  PROMOS,
  CUSTOMER_FEEDBACK,
  TAGS,
  SETTINGS,
  USERS,
  PERMISSIONS,
]);

export const adminMarketplaceMenuConstantsWithoutFirebase = List([
  DASHBOARD,
  INTERNATIONAL_DASHBOARD,
  DRIVER_REPORTS,
  WAREHOUSE_REPORTS,
  DOMESTIC_ORDERS,
  SIMPLE_ORDERS,
  ARCHIVE_ORDERS,
  ORDER_INBOUND_SORTING_V2,
  ORDER_SORTING_V2,
  ORDER_CUSTOMS,
  CUSTOMS_FEE_MANAGEMENT,
  UPLOAD_ORDERS_V2,
  LETTERS,
  PI1,
  REPORTS,
  INTERNATIONAL_LETTER,
  INTERNATIONAL_PARCEL,
  INTERNATIONAL_EMPTY_BAGS,
  INTERNATIONAL_OUT_TARIFF,
  INTERNATIONAL_SETTINGS,
  BATCH_UPDATE,
  ORDER_EXCEPTIONS,
  ACT_MANAGEMENT,
  ROUTE_TEMPLATES,
  REPORT_WAREHOUSE,
  HYBRID,
  CUSTOM_BATCH_UPDATE,
  CUSTOM_NEW_BATCH_UPDATE,
  IPS_ORDER_SYNC,
  ORDER_FLOW,
  JURISDICTION_MANAGEMENT,
  ACTIVITY_HISTORY,
  SERVICE_TYPES,
  CUSTOM_PACKAGES,
  CASH_CERTIFICATE,
  OPERATOR_BALANCE,
  COURIERS,
  DRIVERS,
  VEHICLES,
  WAREHOUSE,
  LOCKER_POST,
  POSTCODES,
  CUSTOMERS,
  PROMOS,
  CUSTOMER_FEEDBACK,
  TAGS,
  SETTINGS,
  USERS,
  PERMISSIONS,
]);
