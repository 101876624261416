import React, { useState } from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import {
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getValue } from "../../helpers/DataUtils";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import { getPDF2, getShiftPDF } from "../../helpers/FormUtils";
import { IS_DOWNLOADING, IS_PRINTING } from "../orders-core/AdminPostForm";
import { GetApp, Print } from "@material-ui/icons";
import { MANIFEST_URL } from "../../api/admin/AdminBatchApi";
import _ from "lodash";
import { ACCEPT_PDF_URL } from "../../api/v2/admin/AdminOrderSortingApi";

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  withTheme,
  useSheet({
    rootStyle: {
      "& h3": {
        border: "0",
        backgroundColor: props => props.theme.palette.primary1Color,
      },
    },
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
  }),
);

AcceptBatchItemDialog.propTypes = {
  classes: PropTypes.object,

  onRequestClose: PropTypes.func.isRequired,

  getLocalisationMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  theme: PropTypes.object,
  open: PropTypes.bool,
  batchObject: PropTypes.object,
};

function AcceptBatchItemDialog(props) {
  const { classes, getLocalisationMessage, batchObject } = props;

  const [acceptPDF, setAcceptPDF] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: ACCEPT_PDF_URL,
  });
  const [stateManifestNew, setStateManifestNew] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: MANIFEST_URL,
  });

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={props.open}
      modal={true}
      autoScrollBodyContent={true}
      onClose={props.onRequestClose}
      bodyClassName={classes.body}
      contentClassName={classes.contentStyle}
      className={classes.rootStyle}
    >
      <DialogTitle
        style={{ color: props.theme.palette.appBarTextColor }}
        className={classes.dialogTitle}
      >
        <FlexBox align="center" direction="row">
          <FlexBox flex={true} gutter={8}>
            {getLocalisationMessage("batch")}
          </FlexBox>
        </FlexBox>
      </DialogTitle>

      <DialogContent>
        <FlexBox style={{ gap: 20 }} direction="column">
          <FlexBox
            flex={true}
            direction="column"
            style={{ gap: 16, marginTop: 16 }}
          >
            <FlexBox
              flex={true}
              direction="row"
              justify="center"
              style={{ gap: 16 }}
            >
              <FlexBox>
                <ButtonGroup variant={CONTAINED} color={SECONDARY}>
                  <CustomButton
                    style={{ width: "220px" }}
                    onClick={() =>
                      getShiftPDF(
                        acceptPDF,
                        setAcceptPDF,
                        IS_PRINTING,
                        _.get(batchObject, "acceptBatchId"),
                        props.showErrorMessage,
                      )
                    }
                    endIcon={
                      setAcceptPDF[IS_PRINTING] ? (
                        <CircularProgress size={20} color="white" />
                      ) : (
                        <Print />
                      )
                    }
                  >
                    {getLocalisationMessage("accept", "Accept")}
                  </CustomButton>
                  <CustomButton
                    onClick={() =>
                      getShiftPDF(
                        acceptPDF,
                        setAcceptPDF,
                        IS_DOWNLOADING,
                        _.get(batchObject, "acceptBatchId"),
                        props.showErrorMessage,
                      )
                    }
                  >
                    {setAcceptPDF[IS_DOWNLOADING] ? (
                      <CircularProgress size={20} />
                    ) : (
                      <GetApp />
                    )}
                  </CustomButton>
                </ButtonGroup>
              </FlexBox>
              <FlexBox>
                <ButtonGroup variant={CONTAINED} color={SECONDARY}>
                  <CustomButton
                    style={{ width: "220px" }}
                    onClick={() =>
                      getPDF2(
                        stateManifestNew,
                        setStateManifestNew,
                        IS_PRINTING,
                        getValue(batchObject, "sendToCustomsBatchId"),
                        props.showErrorMessage,
                      )
                    }
                    endIcon={
                      stateManifestNew[IS_PRINTING] ? (
                        <CircularProgress size={20} color="white" />
                      ) : (
                        <Print />
                      )
                    }
                  >
                    {getLocalisationMessage(
                      "sent_to_customs",
                      "Sent to customs",
                    )}
                  </CustomButton>
                  <CustomButton
                    onClick={() =>
                      getPDF2(
                        stateManifestNew,
                        setStateManifestNew,
                        IS_DOWNLOADING,
                        getValue(batchObject, "sendToCustomsBatchId"),
                        props.showErrorMessage,
                        true,
                      )
                    }
                  >
                    {stateManifestNew[IS_DOWNLOADING] ? (
                      <CircularProgress size={20} />
                    ) : (
                      <GetApp />
                    )}
                  </CustomButton>
                </ButtonGroup>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </DialogContent>
      <DialogActions>
        <FlexBox style={{ padding: 16 }}>
          <CustomButton
            variant={OUTLINED}
            color={SECONDARY}
            onClick={() => {
              props.onRequestClose();
            }}
          >
            {getLocalisationMessage("close", "Close")}
          </CustomButton>
        </FlexBox>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(AcceptBatchItemDialog);
