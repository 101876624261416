import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import { connect } from "react-redux";
import FlexBox, { ALIGN_CENTER } from "../../../components/ui-core/FlexBox";
import OfflineOrderSortingTabs from "../../../components/order-outbound-sorting/OfflineOrderSortingTabs";
import OfflineOrderSortingChips from "../../../components/order-outbound-sorting/OfflineOrderSortingChips";
import { CardContent, makeStyles, MenuItem } from "@material-ui/core";
import ChipTextField from "../../../components/deprecated/ChipTextField";
import { DIALOG, RENDER } from "../../../components/orders-core/MUITable";
import _ from "lodash";
import {
  getUser,
  getUserPermissions,
  getUserWarehouseId,
} from "../../../reducers/ProfileReducer";
import { hasRole } from "../../../helpers/RoleUtils";
import { ROLE_OPERATOR } from "../../../constants/AdminRoleTypes";
import { SIMPLE } from "../../../constants/OrderType";
import { getValue } from "../../../helpers/DataUtils";
import MultiLineCell from "../../../components/data-list/MultiLineCell";
import { formatOrderStatusCodeForLocalisation } from "../../../helpers/OrderHelper";
import DateTimeCell from "../../../components/data-list/DateTimeCell";
import {
  batchAsyncUpdateOrder,
  cancelRegistryStatusSorting,
} from "../../../api/admin/AdminOrderApi";
import ResponseError from "../../../helpers/ResponseError";
import CustomButton, {
  OUTLINED,
  SECONDARY,
} from "../../../components/ui-core/CustomButton";
import AdminBatchUpdatesItemDialogWrapper from "../../../wrappers/admin/AdminBatchUpdatesItemDialogWrapper";
import { toSnakeCase } from "../../../helpers/CaseMapper";
import {
  isOtpSettingsServiceType,
  isOtpSettingsTransportationType,
} from "../../../reducers/MarketplaceReducer";
import OfflineOrderRegistrySortingTable, {
  getMatchedBin,
} from "../../../components/order-outbound-sorting/OfflineOrderRegistrySortingTable";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../../api/admin/AdminSupplierApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../../api/admin/AdminWarehouseApi";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../../api/admin/AdminDriverApi";
import OfflineOrderRegistrySortingCreateBagsDialog from "../../../components/order-outbound-sorting/OfflineOrderRegistrySortingCreateBagsDialog";
import {
  clearShipment,
  getSortingBatchUpdateItem,
} from "../../../api/v2/admin/AdminOrderSortingApi";
import { IN_TRANSIT } from "../../../constants/OrderStatusCodes";
import { PUBLIC } from "../../../constants/NotePrivacyTypes";
import MenuButtonMore from "../../../components/ui-core/MenuButtonMore";
import { DATE } from "../../../components/orders-core/MUITableSimple";

const useStyles = makeStyles({
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  search: {
    marginRight: "1rem",
    flex: "1 1 auto",
    position: "relative",
    borderRadius: 4,
  },
  contentRoot: {
    overflow: "hidden",
    "& .MuiTab-root": {
      minWidth: "25%",
    },
  },
  btn: {
    margin: "0 .5rem",
  },
  input: { "& input": { fontSize: "20px" } },
});

function AdminOrderOfflineOutboundRegistrySortingTableContainer(props) {
  const {
    sortingServiceTypes,
    sortingBeans,
    total,
    onServiceTypeFilter,
    onBeanFilter,
    filter,
    isLoading,
    getLocalisationMessage,
    sortingItems,
    handleChangeRowsPerPage,
    handleChangePage,
    selectedItems,
    setSelectedItems,
    onBeanUnFilter,
    setFilter,
    tabCount,
    setRefreshTab,
    excludeSelectedItems,
    setExcludeSelectedItems,
    selectBin,
    refreshOrderList,
    setSelectBin,
    selectFilterBin,
    setSelectFilterBin,
  } = props;
  const classes = useStyles();
  const [isShipmentLoading, setIsShipmentLoading] = useState(false);

  const [showCreateBag, setShowCreateBag] = useState(false);
  const [batchId, setBatchId] = useState(null);
  const [batchItems, setBatchItems] = useState([]);

  const [divHeight, setDivHeight] = useState(0);
  const ref = useRef(false);
  const refHeight = _.get(ref, "current.clientHeight", false);

  useEffect(() => {
    if (refHeight && refHeight !== divHeight) setDivHeight(refHeight);
  }, [refHeight]);

  const [batchItem, setBatchItem] = useState({});

  useEffect(() => {
    if (
      showCreateBag &&
      (filter.service_type_id ||
        filter.beans ||
        selectFilterBin ||
        selectedItems.length)
    ) {
      setIsShipmentLoading(true);
      getSortingBatchUpdateItem({
        type: "SIMPLE",
        beans:
          filter.beans && filter.beans.length > 0
            ? filter.beans
            : selectFilterBin && selectFilterBin.length > 0
            ? selectFilterBin
            : [],
        barcodes:
          selectedItems && selectedItems.length
            ? selectedItems.map(item => item.barcode)
            : [],
        service_type_id: filter.service_type_id,
      })
        .then(res => {
          const barcodes = res.items.map(item => item.barcode);

          setBatchItems(res.items);
          setBatchItem({
            category: res.category,
            barcodes,
            orderStatus: res.order_status,
            innerShipmentType: res.inner_shipment_type,
            transportationType: res.transportation_type,
            weight: res.weight,
            to_warehouse: getValue(res, "to_warehouse"),
            warehouse: getValue(res, "next_warehouse"),
          });
          setIsShipmentLoading(false);
        })
        .catch(() => setIsShipmentLoading(false));
    }
  }, [filter, selectedItems, showCreateBag]);

  useEffect(() => {
    if (filter.beans && filter.beans.length > 0) {
      const prevExcludeBarcodes = excludeSelectedItems.map(
        item => item.barcode,
      );

      setSelectedItems(prev => {
        const prevBarcodes = prev.map(item => item.barcode);
        return [
          ...prev,
          ...sortingItems.filter(
            item =>
              !prevExcludeBarcodes.includes(item.barcode) &&
              !prevBarcodes.includes(item.barcode),
          ),
        ];
      });

      setSelectBin(prev => [
        ...prev,
        ...sortingItems
          .filter(item => !prevExcludeBarcodes.includes(item.barcode))
          .filter(item => !prev.includes(item.bean.code))
          .map(i => getMatchedBin(i.bean.code, sortingBeans)),
      ]);
    }
  }, [filter.beans, sortingItems]);

  return (
    <FlexBox direction="column" style={{ overflow: "hidden", height: "100%" }}>
      <OfflineOrderSortingTabs
        onTabChange={props.onTabChange}
        tabValue={SIMPLE}
        tabCount={tabCount}
      />

      <AdminBatchUpdatesItemDialogWrapper
        batchId={batchId}
        onRequestClose={() => {
          setBatchId(false);
          refreshOrderList();
        }}
      />

      <FlexBox
        style={{
          height: "100%",
          overflowY: "auto",
          background: "white",
          borderRadius: 4,
          boxShadow:
            "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
        }}
        flex={true}
        direction="column"
      >
        <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          {showCreateBag && (
            <OfflineOrderRegistrySortingCreateBagsDialog
              open={true}
              isLoading={isShipmentLoading}
              onSubmit={values =>
                batchAsyncUpdateOrder(toSnakeCase(values)).catch(
                  ResponseError.throw,
                )
              }
              onSubmitSuccess={res => {
                if (res && res.status === "success") {
                  setBatchId(getValue(res, "data.id"));
                  setShowCreateBag(false);
                  setFilter({
                    init: true,
                    page: 0,
                    size: 20,
                    service_type_id: null,
                    beans: [],
                    barcodes: [],
                  });
                  setSelectedItems([]);
                  setExcludeSelectedItems([]);
                  setBatchItems([]);
                  setBatchItem({});
                  setRefreshTab(prev => !prev);
                  setSelectBin([]);
                  setSelectFilterBin([]);
                }
              }}
              initialValues={{
                ...batchItem,
                status: IN_TRANSIT,
                privacy: PUBLIC,
              }}
              filter={filter}
              setBatchItems={setBatchItems}
              batchItems={batchItems}
              getCachedSupplier={getCachedSupplier}
              getSupplierPredictions={getSupplierPredictions}
              getCachedWarehouse={getCachedWarehouse}
              getWarehousePredictions={getWarehousePredictions}
              getCachedDriver={getCachedDriver}
              getDriverPredictions={getDriverPredictions}
              onRequestClose={() => {
                setShowCreateBag(false);
                setBatchItems([]);
                setBatchItem({});
              }}
            />
          )}
          <OfflineOrderSortingChips
            total={total}
            selectBin={selectBin}
            sortingServiceTypes={sortingServiceTypes}
            sortingBeans={sortingBeans}
            onServiceTypeFilter={onServiceTypeFilter}
            onBeanFilter={onBeanFilter}
            onBeanUnFilter={onBeanUnFilter}
            filter={filter}
            simple={true}
            selectFilterBin={selectFilterBin}
          />

          <OfflineOrderRegistrySortingTable
            setSelectBin={setSelectBin}
            setSelectFilterBin={setSelectFilterBin}
            filter={filter}
            setFilter={setFilter}
            isLoading={isLoading}
            isCheckboxEnabled={true}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            sortingBeans={sortingBeans}
            excludeSelectedItems={excludeSelectedItems}
            setExcludeSelectedItems={setExcludeSelectedItems}
            size="medium"
            selectFilterBin={selectFilterBin}
            list={sortingItems}
            setBatchId={setBatchId}
            total={total}
            page={filter.page}
            rowsPerPage={filter.size}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            columns={[
              {
                label: getLocalisationMessage("batch_number", "Batch Number"),
                keyName: "barcode",
                name: "Barcode",
                type: DIALOG,
                align: "center",
              },
              {
                type: DATE,
                name: "created_date",
                keyName: "created_date",
                label: getLocalisationMessage("created_date"),
              },
              {
                type: RENDER,
                name: "type",
                label: getLocalisationMessage("type"),
                render: row => getLocalisationMessage(row.type.toLowerCase()),
              },
              {
                type: RENDER,
                name: "to_warehouse.name",
                label: getLocalisationMessage("destination_warehouse"),
                render: row => (
                  <MultiLineCell
                    firstLine={
                      <span style={{ padding: 10 }}>
                        {getValue(row, "to_warehouse.name", "N/A")}
                      </span>
                    }
                  />
                ),
              },
              {
                type: RENDER,
                name: "from_jurisdiction.name",
                label: getLocalisationMessage("from_city", "From City"),
                render: row => (
                  <MultiLineCell
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      gap: 3,
                    }}
                    firstLine={
                      <span style={{ padding: 10 }}>
                        {getValue(row, "from_jurisdiction.name", "N/A")}
                      </span>
                    }
                    secondLine={
                      <span style={{ padding: 10 }}>
                        {getValue(row, "from_postcode.name", "N/A")}
                      </span>
                    }
                  />
                ),
              },
              {
                type: RENDER,
                name: "to_jurisdiction.name",
                label: getLocalisationMessage("to_city", "To City"),
                render: row => (
                  <MultiLineCell
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      gap: 3,
                    }}
                    firstLine={
                      <span style={{ padding: 10 }}>
                        {getValue(row, "to_jurisdiction.name", "N/A")}
                      </span>
                    }
                    secondLine={
                      <span style={{ padding: 10 }}>
                        {getValue(row, "to_postcode.name", "N/A")}
                      </span>
                    }
                  />
                ),
              },
              {
                type: RENDER,
                name: "weight",
                label: getLocalisationMessage("weight", "weight"),
                render: row => (
                  <span style={{ padding: 10 }}>
                    {`${getValue(row, "weight", 0)} kg`}
                  </span>
                ),
              },
              {
                type: RENDER,
                name: "flow",
                label: getLocalisationMessage("flow", "flow"),
                render: row => (
                  <span style={{ padding: 10 }}>
                    {getLocalisationMessage(
                      getValue(row, "flow", "N/A").toLowerCase(),
                    )}
                  </span>
                ),
              },
              {
                type: RENDER,
                name: "flow",
                label: getLocalisationMessage("status", "Status"),
                render: row => (
                  <MultiLineCell
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      gap: 3,
                    }}
                    firstLine={
                      <span
                        style={{
                          color: "#FF9800",
                        }}
                      >
                        {formatOrderStatusCodeForLocalisation(
                          row.status,
                          getLocalisationMessage,
                        )}
                      </span>
                    }
                    secondLine={
                      <DateTimeCell
                        date={getValue(row, "last_status_date", "N/A")}
                      />
                    }
                  />
                ),
              },
              {
                name: getLocalisationMessage("cancel_registry"),
                label: getLocalisationMessage("action", "Action"),
                dispatch: id => {
                  cancelRegistryStatusSorting({
                    batch_id: id,
                  })
                    .then(() => {
                      props.showSuccessMessage(
                        getLocalisationMessage("successfully"),
                      );
                      setTimeout(() => {
                        setRefreshTab(prev => !prev);
                      }, 500);
                      refreshOrderList();
                      setSelectedItems([]);
                      setExcludeSelectedItems([]);
                      setSelectBin([]);
                      setSelectFilterBin([]);
                    })
                    .catch(error => props.showErrorMessage(error));
                },
              },
            ]}
            headerActions={
              <FlexBox
                align={ALIGN_CENTER}
                style={{
                  padding: ".25rem 1rem",
                  width: "100%",
                  borderBottom: "1px solid #e0e0e0",
                }}
              >
                {selectedItems.length ? (
                  <React.Fragment>
                    <FlexBox flex={true}>
                      {selectedItems.length}{" "}
                      {getLocalisationMessage(
                        "items_selected",
                        "item(s) selected",
                      )}
                      .
                    </FlexBox>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className={classes.search} ref={ref}>
                      <ChipTextField
                        style={{
                          maxHeight: divHeight > 86 && "5.8rem",
                          overflow: divHeight > 86 && "auto",
                        }}
                        fullWidth={true}
                        addOnBlur={false}
                        clearOnBlur={false}
                        value={filter.barcodes}
                        disableUnderline={true}
                        placeholder={getLocalisationMessage("search")}
                        hintText={getLocalisationMessage("search")}
                        onChange={v =>
                          setFilter({
                            ...filter,
                            init: false,
                            barcodes: v,
                          })
                        }
                      />
                    </div>
                  </React.Fragment>
                )}

                {(Boolean(filter.bean) || Boolean(selectedItems.length)) && (
                  <FlexBox align="center" direction="row">
                    <CustomButton
                      style={{
                        margin: "0 .5rem",
                      }}
                      variant={OUTLINED}
                      color={SECONDARY}
                      onClick={() => setShowCreateBag(true)}
                    >
                      {getLocalisationMessage("create_bag", "Create Bag")}
                    </CustomButton>
                  </FlexBox>
                )}

                {props.permissions.includes(
                  "ORDER_SORTING_CLEAR_OUTBOUND_ITEMS",
                ) && (
                  <MenuButtonMore desktop={false}>
                    <MenuItem
                      onClick={() => {
                        clearShipment({
                          type: "SIMPLE",
                          beans: filter.beans,
                          barcodes:
                            selectedItems && selectedItems.length
                              ? selectedItems.map(item => item.barcode)
                              : [],
                          service_type_id: filter.service_type_id,
                        })
                          .then(r => {
                            if (r && r.status === "success") {
                              setFilter({
                                init: true,
                                page: 0,
                                size: 20,
                                service_type_id: null,
                                beans: [],
                              });

                              setRefreshTab(prev => !prev);
                              setSelectedItems([]);
                              setExcludeSelectedItems([]);
                              setSelectBin([]);
                              setSelectFilterBin([]);

                              props.showSuccessMessage(
                                getLocalisationMessage("successfully_deleted"),
                              );
                            }
                          })
                          .catch(error => {
                            props.showErrorMessage(error);
                          });
                      }}
                    >
                      {getLocalisationMessage("delete", "Delete")}
                    </MenuItem>
                  </MenuButtonMore>
                )}
              </FlexBox>
            }
          />
        </CardContent>
      </FlexBox>
    </FlexBox>
  );
}

AdminOrderOfflineOutboundRegistrySortingTableContainer.propTypes = {
  onTabChange: PropTypes.func,
  setRefreshTab: PropTypes.func,
  tabCount: PropTypes.object,
  setFilter: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  sortingItems: PropTypes.array,
  sortingServiceTypes: PropTypes.array,
  sortingBeans: PropTypes.array,
  total: PropTypes.number,
  handleChangeRowsPerPage: PropTypes.func,
  handleChangePage: PropTypes.func,
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func,
  onBeanFilter: PropTypes.func,
  onBeanUnFilter: PropTypes.func,
  onServiceTypeFilter: PropTypes.func,
  filter: PropTypes.object,
  isLoading: PropTypes.bool,
  selectBin: PropTypes.array,
  setSelectBin: PropTypes.func,
  excludeSelectedItems: PropTypes.array,
  setExcludeSelectedItems: PropTypes.func,
  selectFilterBin: PropTypes.array,
  setSelectFilterBin: PropTypes.func,
  refreshOrderList: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  permissions: PropTypes.array,
};

const mapStateToProps = state => {
  const userRoles = getUser(state).get("roles") || [];
  return {
    isOtpSettingsServiceType: isOtpSettingsServiceType(state),
    isOtpSettingsTransportationType: isOtpSettingsTransportationType(state),
    userWarehouseId: getUserWarehouseId(state),
    permissions: getUserPermissions(state),
    isOperator: hasRole(userRoles, ROLE_OPERATOR),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  };
};

const mapDispatchToProps = { showErrorMessage, showSuccessMessage };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminOrderOfflineOutboundRegistrySortingTableContainer);
