import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";
import { api } from "../shared/BaseApi";

const CLICK_HOUSE_CUSTOMER_TOTAL_COUNT_URL = `${API_ROOT_URL}/clickhouse/customer/total`;
const CLICK_HOUSE_CUSTOMER_TOTAL_COUNT_BY_SERVICE_TYPE_URL = `${API_ROOT_URL}/clickhouse/customer/service_type`;
const CLICK_HOUSE_CUSTOMER_TOTAL_COUNT_BY_FROM_JURISDICTION_URL = `${API_ROOT_URL}/clickhouse/customer/from_jurisdiction`;
const CLICK_HOUSE_CUSTOMER_TOTAL_COUNT_BY_TO_JURISDICTION_URL = `${API_ROOT_URL}/clickhouse/customer/to_jurisdiction`;

export const getCustomerTotalCount = params =>
  api.get(CLICK_HOUSE_CUSTOMER_TOTAL_COUNT_URL, {
    params,
  });

export const getCustomerTotalCountByServiceType = params =>
  api.get(CLICK_HOUSE_CUSTOMER_TOTAL_COUNT_BY_SERVICE_TYPE_URL, {
    params,
  });

export const getCustomerTotalCountByFromJurisdiction = params =>
  api.get(CLICK_HOUSE_CUSTOMER_TOTAL_COUNT_BY_FROM_JURISDICTION_URL, {
    params,
  });

export const getCustomerTotalCountByToJurisdiction = params =>
  api.get(CLICK_HOUSE_CUSTOMER_TOTAL_COUNT_BY_TO_JURISDICTION_URL, {
    params,
  });
