import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";

// eslint-disable-next-line import/no-internal-modules
import Chart from "chart.js/auto";

import { CategoryScale } from "chart.js";

Chart.register(CategoryScale, annotationPlugin);

const enhancer = compose(
  useSheet({
    container: {
      width: "100%",
      padding: "15px",
    },
    title: {
      fontSize: "16px",
    },
  }),
);

FilledLineChart.propTypes = {
  data: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  id: PropTypes.string,
  withoutLegend: PropTypes.bool,
  options: PropTypes.object,
  tooltipFormat: PropTypes.object,
};

FilledLineChart.defaultProps = {
  width: 100,
  height: 600,
  options: {},
};

function FilledLineChart(props) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    datasetStrokeWidth: 3,
    pointDotStrokeWidth: 4,
    plugins: {
      tooltip: props.tooltipFormat,
      legend: {
        position: "bottom",
        display: !props.withoutLegend,
        labels: {
          boxWidth: 19,
          fontSize: 18,
        },
      },
      ...props.options.plugins,
    },
    scales: {
      x: {
        beginAtZero: true,
        stacked: true,
      },
    },
    ...props.options,
  };

  return (
    <div
      style={{
        maxHeight: "600px",
      }}
    >
      <Line
        data={props.data}
        options={options}
        width={props.width}
        id={props.id}
        height={props.height}
      />
    </div>
  );
}

export default enhancer(FilledLineChart);
