import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getMessages } from "../../reducers/LocalizationReducer";
import PropTypes from "prop-types";
import { Map } from "immutable";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import MUITableInternationalReports, {
  RENDER,
} from "../../components/orders-core/MUITableInternationalReports";
import {
  Edit,
  FilterList,
  Print,
  Refresh,
  DeleteForever,
} from "@material-ui/icons";
import {
  createInternationalParcel,
  deleteInternationalParcel,
  getInternationalParcelItem,
  getInternationalParcelList,
  updateInternationalParcel,
} from "../../api/admin/AdminInternationalReportsApi";
import { getValue } from "../../helpers/DataUtils";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_CENTER,
  JUSTIFY_END,
} from "../../components/ui-core/FlexBox";
import {
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ConfirmDialog from "../../components/deprecated/ConfirmDialog";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import _ from "lodash";
import {
  formatDate,
  formatDateTimeToUrl,
  formatText,
  safeParseDate,
} from "../../helpers/FormatUtils";
import InternationalParcelForm from "../../components/order-create-wizard-new/international-reports/InternationalParcelForm";
import InternationalParcelFilter from "../../components/order-create-wizard-new/international-reports/InternationalParcelFilter";
import CustomButton, { SECONDARY } from "../../components/ui-core/CustomButton";
import { getForm55PDF, getMpPDF } from "../../helpers/FormUtils";
import {
  IS_DOWNLOADING,
  IS_PRINTING,
} from "../../components/orders-core/AdminPostForm";
import { API_V2_ROOT_URL } from "../../../shared/constants/ApiConstants";
import InternationalParcelMPPDFFilter from "../../components/order-create-wizard-new/international-reports/InternationalParcelMPPDFFilter";

const CN_94_PDF = `${API_V2_ROOT_URL}/cp94`;
const MP_PDF = `${API_V2_ROOT_URL}/international_report`;

export const localisationAction = (option, i18n) => {
  switch (option) {
    case "IN":
      return i18n.get("inbound", "Inbound");

    case "OUT":
      return i18n.get("outbound", "Outbound");

    default:
      return i18n.get(option, formatText(option));
  }
};

export const localisationCategory = (option, i18n) => {
  switch (option) {
    case "SAL":
      return i18n.get("sal", "S.A.L");

    case "AIR":
      return i18n.get("air", "Air");

    case "GROUND":
      return i18n.get("surface", "Surface");

    default:
      return i18n.get(option, formatText(option));
  }
};

const useStyles = makeStyles({
  tabs: {
    margin: "0 -1rem",
  },
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #eee",
  },
  title: {
    fontSize: "24px",
    textTransform: "capitalize",
    fontWeight: 500,
  },
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  search: {
    flex: "1 1 auto",
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
});

const enhancer = compose(
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    {
      showSuccessMessage,
      showErrorMessage,
    },
  ),
);

AdminInternationalParcelContainer.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
};

function AdminInternationalParcelContainer(props) {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({
    page: 0,
    size: 20,
    refresh: true,
  });

  const [id, setId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const [data, setData] = useState({});

  const classes = useStyles();

  const refreshList = () => setFilter({ ...filter, refresh: !filter.refresh });
  const [success, setSuccess] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [openMPPDFForm, setMPPDFForm] = useState(false);

  const [cn94PDF, setCn94PDF] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: CN_94_PDF,
  });

  const [mpPDF, setMPDF] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: MP_PDF,
  });

  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getInternationalParcelList(filter)
      .then(res => {
        if (res && res.data) {
          setList(getValue(res, "data.list", []));
          setTotal(getValue(res, "data.total", 0));
          setIsLoading(false);
        }
      })
      .catch(() => {
        setList([]);
        setIsLoading(false);
      });
  }, [filter]);

  useEffect(() => {
    if (id) {
      getInternationalParcelItem(id).then(res => {
        if (res && res.data) {
          setData(res.data);
        }
      });
    }
  }, [id]);

  const handleChangePage = (event, page) => setFilter({ ...filter, page });
  const handleChangeRowsPerPage = event =>
    setFilter({ ...filter, size: parseInt(event.target.value, 10), page: 0 });

  const onDelete = removeId => {
    deleteInternationalParcel(removeId)
      .then(() => {
        setDeleteId(0);
        props.showSuccessMessage(
          props.i18n.get("successfully_removed", "Successfully Removed"),
        );
        refreshList();
      })
      .catch(error => {
        props.showErrorMessage(error);
        setDeleteId(0);
      });
  };

  return (
    <AdminAppLayout
      title={props.i18n.get("international_parcel", "International Parcel")}
    >
      <ConfirmDialog
        open={deleteId > 0}
        onRequestClose={() => {
          setDeleteId(0);
        }}
        onConfirm={() => {
          onDelete(deleteId);
          setDeleteId(0);
        }}
      >
        {props.i18n.get(
          "are_you_sure_you_want_delete",
          "Are you sure you want delete?",
        )}
      </ConfirmDialog>

      <Card>
        <CardContent>
          <FlexBox direction="column" style={{ gap: 10 }}>
            <FlexBox
              style={{
                height: "35px",
                padding: "2px 0 2px 20px",
              }}
              justify="space-between"
              align="center"
            >
              <Typography variant="h5" className={classes.title}>
                {props.i18n.get("international_parcel", "International Parcel")}
              </Typography>

              <FlexBox justify={JUSTIFY_END} align={ALIGN_CENTER}>
                <CustomButton
                  color={SECONDARY}
                  className={classes.pLR1rem}
                  onClick={() => setMPPDFForm(true)}
                >
                  {props.i18n.get("mp", "MP")}
                </CustomButton>

                <CustomButton
                  color={SECONDARY}
                  className={classes.pLR1rem}
                  onClick={() =>
                    getForm55PDF(
                      cn94PDF,
                      setCn94PDF,
                      IS_PRINTING,
                      selectedItems,
                      filter,
                      props.showErrorMessage,
                    )
                  }
                  endIcon={
                    cn94PDF[IS_PRINTING] ? (
                      <CircularProgress size={20} color="white" />
                    ) : (
                      <Print />
                    )
                  }
                >
                  {props.i18n.get("cp_94", "CP 94")}
                </CustomButton>
                <IconButton
                  className={isLoading && classes.refresh}
                  tooltip={props.i18n.get("refresh", "Refresh")}
                  onClick={() => refreshList()}
                >
                  <Refresh />
                </IconButton>

                <IconButton
                  tooltip={props.i18n.get("filter", "Filter")}
                  onClick={() => setOpenFilter(true)}
                >
                  <FilterList />
                </IconButton>
              </FlexBox>
            </FlexBox>

            <InternationalParcelFilter
              open={openFilter}
              onRequestClose={() => setOpenFilter(false)}
              filter={filter}
              setFilter={setFilter}
            />

            <InternationalParcelForm
              onClose={() => {
                setId(null);
                setData({});
              }}
              success={success}
              setSuccess={setSuccess}
              update={id > 0}
              onSubmit={values => {
                if (id) {
                  updateInternationalParcel(id, {
                    ...toSnakeCase(values),
                    date: formatDateTimeToUrl(values.date),
                    formation_date: formatDateTimeToUrl(values.formationDate),
                  })
                    .then(res => {
                      if (res && res.status === "success") {
                        props.showSuccessMessage(
                          props.i18n.get("successfully_updated"),
                        );

                        refreshList();
                        setData({});
                        setId(null);
                      }
                    })
                    .catch(error => props.showErrorMessage(error));
                } else {
                  createInternationalParcel({
                    ...toSnakeCase(values),
                    date: formatDateTimeToUrl(values.date),
                    formation_date: formatDateTimeToUrl(values.formationDate),
                  })
                    .then(res => {
                      if (res && res.status === "success") {
                        setSuccess(true);

                        props.showSuccessMessage(
                          props.i18n.get("successfully_created"),
                        );

                        refreshList();
                        setData({});
                      }
                    })
                    .catch(error => props.showErrorMessage(error));
                }
              }}
              initialValues={{
                ...toCamelCase(data),
                date: safeParseDate(data.date),
                formationDate: safeParseDate(data.formation_date),
              }}
            />

            <InternationalParcelMPPDFFilter
              onRequestClose={() => {
                setMPPDFForm(false);
              }}
              open={openMPPDFForm}
              onSubmit={values => {
                getMpPDF(
                  mpPDF,
                  setMPDF,
                  IS_PRINTING,
                  { ...toSnakeCase(values) },
                  props.showErrorMessage,
                );
              }}
              loading={mpPDF[IS_PRINTING]}
              initialValues={{
                year: 2025,
                dateType: "MONTH",
              }}
            />

            <Divider style={{ height: 1, weight: "100%" }} />

            <MUITableInternationalReports
              isLoading={isLoading}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              size="small"
              list={list}
              total={total}
              activeId={id}
              orderBy={filter.order_by}
              orderByDirection={filter.order_by_direction}
              setOrderBy={v =>
                setFilter({
                  ...filter,
                  order_by: v,
                  order_by_direction: filter.order_by_direction
                    ? filter.order_by_direction === "desc"
                      ? "asc"
                      : "desc"
                    : "asc",
                })
              }
              page={filter.page}
              rowsPerPage={filter.size}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              withCheckbox={true}
              columns={[
                {
                  type: RENDER,
                  name: "date",
                  style: { width: 120 },
                  align: "center",
                  label: props.i18n.get("date", "Date"),
                  render: row => formatDate(_.get(row, `date`), "dd-MM-yyyy"),
                  isSortable: false,
                },
                {
                  type: RENDER,
                  isSortable: false,
                  name: "formation_date",
                  label: props.i18n.get("formation_date", "Formation Date"),
                  render: row =>
                    formatDate(_.get(row, `formation_date`), "dd-MM-yyyy"),
                },
                {
                  type: RENDER,
                  name: "action",
                  isSortable: false,
                  label: props.i18n.get("action", "Action"),
                  render: row =>
                    localisationAction(
                      _.get(row, `action`, props.i18n.get("na", "N/A")),
                      props.i18n,
                    ),
                },
                {
                  type: RENDER,
                  name: "category",
                  isSortable: false,
                  label: props.i18n.get("category", "Category"),
                  render: row =>
                    localisationCategory(
                      _.get(row, `category`, props.i18n.get("na", "N/A")),
                      props.i18n,
                    ),
                },
                {
                  type: RENDER,
                  isSortable: false,
                  name: "from",
                  label: props.i18n.get("from", "From"),
                  render: row =>
                    `${_.get(
                      row,
                      `from_country.name`,
                      props.i18n.get("na", "N/A"),
                    )} -> ${_.get(
                      row,
                      `from_city.name`,
                      props.i18n.get("na", "N/A"),
                    )} (${_.get(
                      row,
                      `from_city.code`,
                      props.i18n.get("na", "N/A"),
                    )})`,
                },
                {
                  type: RENDER,
                  isSortable: false,
                  name: "to",
                  label: props.i18n.get("to", "to"),
                  render: row =>
                    `${_.get(
                      row,
                      `to_country.name`,
                      props.i18n.get("na", "N/A"),
                    )} -> ${_.get(
                      row,
                      `to_city.name`,
                      props.i18n.get("na", "N/A"),
                    )} (${_.get(
                      row,
                      `to_city.code`,
                      props.i18n.get("na", "N/A"),
                    )})`,
                },
                {
                  type: RENDER,
                  isSortable: false,
                  name: "transit_region",
                  label: props.i18n.get("through", "Through"),
                  render: row =>
                    _.get(row, `transit_region`)
                      ? _.get(row, `transit_region.parent`)
                        ? `${_.get(
                            row,
                            `transit_region.parent.name`,
                            props.i18n.get("na", "N/A"),
                          )}  -> ${_.get(
                            row,
                            `transit_region.name`,
                            props.i18n.get("na", "N/A"),
                          )} (${_.get(
                            row,
                            `transit_region.code`,
                            props.i18n.get("na", "N/A"),
                          )})`
                        : _.get(
                            row,
                            `transit_region.code`,
                            props.i18n.get("na", "N/A"),
                          )
                        ? `${_.get(
                            row,
                            `transit_region.name`,
                            props.i18n.get("na", "N/A"),
                          )} (${_.get(
                            row,
                            `transit_region.code`,
                            props.i18n.get("na", "N/A"),
                          )})`
                        : _.get(
                            row,
                            `transit_region.name`,
                            props.i18n.get("na", "N/A"),
                          )
                      : props.i18n.get("na", "N?A"),
                },
                {
                  isSortable: false,
                  type: RENDER,
                  name: "spz_weight",
                  label: props.i18n.get("spz_weight", "SPZ Weight"),
                  render: row =>
                    _.get(
                      row,
                      `tariff.price_per_kg`,
                      props.i18n.get("na", "N/A"),
                    ),
                },
                {
                  isSortable: false,
                  type: RENDER,
                  name: "spz_item",
                  label: props.i18n.get("spz_item", "SPZ Item"),
                  render: row =>
                    _.get(
                      row,
                      `tariff.price_per_item`,
                      props.i18n.get("na", "N/A"),
                    ),
                },
                {
                  isSortable: true,
                  type: RENDER,
                  name: "dispatch_number",
                  label: props.i18n.get("dispatch_number", "Dispatch No."),
                  render: row =>
                    _.get(row, `dispatch_number`, props.i18n.get("na", "N/A")),
                },
                {
                  isSortable: false,
                  type: RENDER,
                  name: "subclass",
                  label: props.i18n.get("subclass", "Subclass"),
                  render: row =>
                    _.get(row, `subclass.name`, props.i18n.get("na", "N/A")),
                },
                {
                  isSortable: false,
                  type: RENDER,
                  name: "weight",
                  label: props.i18n.get("weight", "Weight"),
                  render: row =>
                    _.get(row, `weight`, props.i18n.get("na", "N/A")),
                },
                {
                  isSortable: false,
                  type: RENDER,
                  name: "items",
                  label: props.i18n.get("items", "Items"),
                  render: row =>
                    _.get(row, `items`, props.i18n.get("na", "N/A")),
                },
                {
                  isSortable: false,
                  type: RENDER,
                  name: "comments",
                  style: { width: 180 },
                  label: props.i18n.get("comment", "Comment"),
                  render: row =>
                    _.get(row, `comments`) && _.get(row, `comments`).length > 50
                      ? `${_.get(row, `comments`).slice(0, 50)}...`
                      : _.get(row, `comments`, props.i18n.get("na", "N/A")),
                },
                {
                  isSortable: false,
                  type: RENDER,
                  name: "return_weight",
                  label: props.i18n.get("return_weight", "Return Weight"),
                  render: row =>
                    _.get(row, `return_weight`, props.i18n.get("na", "N/A")),
                },
                {
                  isSortable: false,
                  type: RENDER,
                  name: "return_items",
                  label: props.i18n.get("return_item", "Return Item"),
                  render: row =>
                    _.get(row, `return_items`, props.i18n.get("na", "N/A")),
                },
                {
                  isSortable: false,
                  type: RENDER,
                  name: "transit_weight",
                  label: props.i18n.get("transit_weight", "Transit Weight"),
                  render: row =>
                    _.get(row, `transit_weight`, props.i18n.get("na", "N/A")),
                },
                {
                  isSortable: false,
                  type: RENDER,
                  name: "transit_items",
                  label: props.i18n.get("transit_item", "Transit Item"),
                  render: row =>
                    _.get(row, `transit_items`, props.i18n.get("na", "N/A")),
                },
                {
                  isSortable: false,
                  type: RENDER,
                  name: "action",
                  label: props.i18n.get("action", "Action"),
                  align: "center",
                  render: row => (
                    <FlexBox
                      flex={true}
                      align={ALIGN_CENTER}
                      justify={JUSTIFY_CENTER}
                      style={{ gap: 16 }}
                    >
                      <IconButton onClick={() => setDeleteId(_.get(row, "id"))}>
                        <DeleteForever />
                      </IconButton>

                      <IconButton onClick={() => setId(_.get(row, "id"))}>
                        <Edit />
                      </IconButton>
                    </FlexBox>
                  ),
                },
              ]}
            />
          </FlexBox>
        </CardContent>
      </Card>
    </AdminAppLayout>
  );
}

export default enhancer(AdminInternationalParcelContainer);
