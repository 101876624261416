import React, { useState } from "react";
import FlexBox from "./FlexBox";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import {
  Avatar,
  Card,
  ListItemAvatar,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import cx from "classnames";
import { PROFILE_URL } from "../../constants/DashboardPathConstants";
import { Map } from "immutable";
import { MERCHANTS } from "../../constants/MerchantTypes";
import AppLanguageSelector from "../app-sidebar/AppLanguageSelector";
import PageLoading from "./PageLoading";

const useStyles = makeStyles({
  root: {
    minHeight: "80px",
    padding: "2px 0 2px 20px",
  },
  title: {
    fontSize: "24px",
    textTransform: "capitalize",
    fontWeight: 700,
  },
  item: {
    "&.MuiListItemText-root": {
      "&>span": {
        color: "var(--black, #000)",
        textAlign: "right",
        fontSize: "16px",
        fontWeight: 500,
      },
      "&>p": {
        color: "var(--black, #000)",
        textAlign: "right",
        fontSize: "12px",
        fontWeight: 400,
      },
    },
  },
});

AppTitle.propTypes = {
  crumbs: PropTypes.node,
  children: PropTypes.node,
  setLocation: PropTypes.func,
  customer: PropTypes.instanceOf(Map),
};

function AppTitle(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Card
      style={{
        minHeight: "90px",
        paddingBottom: "8px",
        borderBottom: "1.5px solid var(--slate-200, #E2E8F0)",
      }}
    >
      <PageLoading isLoading={isLoading} />
      <FlexBox
        className={cx(classes.root, "app-title")}
        justify="space-between"
        align="center"
      >
        <FlexBox direction="column" align="center" style={{ gap: 5 }}>
          {props.crumbs && (
            <Typography className={classes.title}>{props.crumbs}</Typography>
          )}
        </FlexBox>

        <FlexBox style={{ width: "85%", gap: 13 }} justify="flex-end">
          {props.children}

          <FlexBox>
            <AppLanguageSelector setIsLoading={setIsLoading} />
          </FlexBox>

          <FlexBox align="center" justify="flex-end">
            <FlexBox
              align="center"
              justify="flex-end"
              style={{ gap: 8, cursor: "pointer" }}
              onClick={() => props.setLocation(PROFILE_URL)}
            >
              {props.customer.get("merchant_type") === MERCHANTS ? (
                <ListItemText
                  className={classes.item}
                  primary={props.customer.get("full_name")}
                  secondary={
                    <Typography>
                      {`${props.customer.getIn([
                        "company",
                        "name",
                      ])}/ ${props.customer.get("merchant_inn")}`}
                    </Typography>
                  }
                />
              ) : (
                <FlexBox justify="center" align="center">
                  <Typography
                    style={{
                      color: "var(--black, #000)",
                      textAlign: "right",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    {props.customer.get("full_name")}
                  </Typography>
                </FlexBox>
              )}

              <FlexBox>
                <ListItemAvatar>
                  <Avatar
                    alt={props.customer.get("full_name")}
                    src="/static/images/avatar/2.jpg"
                  />
                </ListItemAvatar>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Card>
  );
}

export default AppTitle;
