import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, FormControl, MenuItem, Select } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import { getOrderTotalCountByServiceType } from "../../api/admin/AdminDashboardNewApi";
import { getValue } from "../../helpers/DataUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import FlexBox from "../ui-core/FlexBox";
import { COLUMN, INDEX } from "../orders-core/MUITable";
import MuiStyledTableTotalCount from "../orders-core/MuiStyledTableTotalCount";

const years = [2021, 2022, 2023, 2024, 2025];

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

AdminOrderTotalCountByServiceTypeChartWrapper.propTypes = {
  getLocalisationMessage: PropTypes.func,
};

function AdminOrderTotalCountByServiceTypeChartWrapper(props) {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [note, setNote] = useState(null);
  const monthlyOrderRef = React.useRef(null);
  const [year, setYear] = useState(2025);
  const [year2, setYear2] = useState(2024);

  const [filter, setFilter] = useState({
    year: 2025,
    year2: 2024,
  });

  useEffect(() => {
    setIsLoading(true);
    getOrderTotalCountByServiceType(filter)
      .then(r => {
        if (r && r.data) {
          const barData = getValue(r, "data.result");
          const barData2 = getValue(r, "data.result2");

          setData(barData);
          setData2(barData2);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setNote(props.getLocalisationMessage("no_data", "No Data"));
        setIsLoading(false);
        setData([]);
        setData2([]);
      });
  }, [filter]);

  return (
    <Card
      style={{
        width: "100%",
        padding: "15px",
        boxShadow: "rgba(145, 158, 171, 0.12)",
        borderRadius: "16px",
      }}
      ref={monthlyOrderRef}
    >
      <FlexBox direction="row">
        <FlexBox
          flex={true}
          align="center"
          style={{
            marginTop: 10,
            marginLeft: 15,
            marginRight: 15,
            fontSize: 14,
          }}
          justify="space-between"
        >
          <FlexBox flex={true} style={{ fontWeight: 700, fontSize: 16 }}>
            {props.getLocalisationMessage(
              "order_creation_by_shipment_type",
              "Order Creation by Shipment Type",
            )}
          </FlexBox>
          <FlexBox flex={true} style={{ gap: 13 }}>
            <FormControl size="small" fullWidth={true}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={year}
                variant="outlined"
                onChange={event => setYear(event.target.value)}
              >
                {years.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl size="small" fullWidth={true}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={year2}
                variant="outlined"
                onChange={event => setYear2(event.target.value)}
              >
                {years.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <CustomButton
              variant={CONTAINED}
              color={SECONDARY}
              fullWidth={true}
              style={{ backgroundColor: "#3F51B5" }}
              onClick={() =>
                setFilter({
                  year,
                  year2,
                })
              }
            >
              {props.getLocalisationMessage("apply", "apply")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      {isLoading && monthlyOrderRef.current && (
        <FlexBox
          justify="center"
          align="flex-end"
          style={{ height: 400, position: "relative", gap: "10px" }}
        >
          {Array.from(
            { length: Math.ceil(monthlyOrderRef.current.offsetWidth / 30) },
            () => Math.floor(Math.random() * 400),
          ).map(item => (
            <Skeleton style={{ transform: "none" }} width={20} height={item} />
          ))}

          <FlexBox
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
            align="center"
            justify="center"
          >
            <h4>
              <strong>
                {note ||
                  `${props.getLocalisationMessage("loading", "Loading")}...`}
              </strong>
            </h4>
          </FlexBox>
        </FlexBox>
      )}
      {!isLoading && (
        <FlexBox
          style={{
            marginTop: 10,
            borderTop: "2px solid rgba(0, 0, 0, 0.08)",
            height: "90%",
          }}
        >
          <FlexBox flex={true} direction="column">
            <FlexBox align="center" justify="center" style={{ height: 50 }}>
              <h6
                style={{
                  fontSize: 24,
                }}
              >
                {filter.year}{" "}
              </h6>
            </FlexBox>
            <MuiStyledTableTotalCount
              isLoading={isLoading}
              withoutHeader={true}
              style={{ borderRight: "2px solid rgba(0, 0, 0, 0.08)" }}
              list={data}
              columns={[
                {
                  type: INDEX,
                  name: "name",
                },
                {
                  type: COLUMN,
                  name: "value",
                },
              ]}
            />
          </FlexBox>
          <FlexBox flex={true} direction="column">
            <FlexBox align="center" justify="center" style={{ height: 50 }}>
              <h6
                style={{
                  fontSize: 24,
                }}
              >
                {filter.year2}{" "}
              </h6>
            </FlexBox>

            <MuiStyledTableTotalCount
              isLoading={isLoading}
              withoutHeader={true}
              list={data2}
              columns={[
                {
                  type: INDEX,
                  name: "name",
                },
                {
                  type: COLUMN,
                  name: "value",
                },
              ]}
            />
          </FlexBox>
        </FlexBox>
      )}
    </Card>
  );
}

export default enhancer(AdminOrderTotalCountByServiceTypeChartWrapper);
