import React, { useEffect, useState } from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  mapPropsStream,
  withState,
} from "recompose";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
import ScannerTextField from "../deprecated/ScannerTextField";
import { getMessage } from "../../reducers/LocalizationReducer";
import { danger1 } from "../../../shared/theme/main-theme";
import { withTheme } from "@material-ui/core/styles";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import FlexBox from "../ui-core/FlexBox";
import { mergeSideEffectStreams } from "../../helpers/StreamUtils";
import { formValues, reduxForm } from "redux-form";
import { Close, Done } from "@material-ui/icons";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import FormUserAutoComplete from "../form/FormUserAutoComplete";
import ConfirmDialog from "../deprecated/ConfirmDialog";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import { RENDER } from "../orders-core/MUITable";
import _ from "lodash";
import { green, grey, yellow } from "@material-ui/core/colors";
import { formatText } from "../../helpers/FormatUtils";
import ShiftingTable from "../orders-core/ShiftingTable";
import PageLoading from "../ui-core/PageLoading";
import { checkVerifyOrder } from "../../api/v2/admin/AdminOrderSortingApi";
import { SENT_TO_CUSTOMS } from "../../constants/OrderStatusCodes";
import { getValue } from "../../helpers/DataUtils";

const UNVERIFIED = "Unverified";
const VERIFIED = "Verified";

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
    },
  ),
  useSheet({
    chip: { margin: "4px" },
    chipDanger: { backgroundColor: danger1 },
    modal: { width: "800px", minWidth: 800 },
    scannerField: { marginBottom: "10px" },
  }),
  withState(
    "state",
    "setState",
    Map({
      inputRef: null,
    }),
  ),
  mapPropsStream(propsStream => {
    const { handler: refInput, stream: refStream } = createEventHandler();
    const { handler: refInput2, stream: refStream2 } = createEventHandler();
    const {
      handler: onSetFocus,
      stream: onSetFocusStream,
    } = createEventHandler();

    const sideEffectsStream = mergeSideEffectStreams(
      onSetFocusStream
        .withLatestFrom(refStream)
        .delay(1000)
        .do(([, input]) => {
          input.focus();
        }),
      onSetFocusStream
        .withLatestFrom(refStream2)
        .delay(1000)
        .do(([, input]) => {
          input.focus();
        }),
    ).startWith(null);

    return propsStream.combineLatest(sideEffectsStream, props => ({
      ...props,

      onSetFocus,
      refInput,
      refInput2,
    }));
  }),
  withTheme,
  reduxForm({
    form: "ShiftAcceptDialog",
    enableReinitialize: true,
    validate: (values, props) => ({
      supervisor:
        !isValidObjectId(values.supervisor) &&
        props.getLocalisationMessage("select_user", "Select User"),
      fromOperator:
        !isValidObjectId(values.fromOperator) &&
        props.getLocalisationMessage("select_user", "Select User"),
      toOperator:
        !isValidObjectId(values.toOperator) &&
        props.getLocalisationMessage("select_user", "Select User"),
    }),
  }),
  formValues({
    verifiedOrderBarcodes: "verifiedOrderBarcodes",
  }),
);

ShiftAcceptDialog.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.object,

  barcodes: PropTypes.array,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  getLocalisationMessage: PropTypes.func,

  refInput: PropTypes.func,
  refInput2: PropTypes.func,
  handleSubmit: PropTypes.func,
  scanBatch: PropTypes.string,
  setScanBatch: PropTypes.func,
  change: PropTypes.func,
  verifiedOrderBarcodes: PropTypes.array,
  sendToCustoms: PropTypes.array,
  setSendToCustoms: PropTypes.func,
  isLoading: PropTypes.bool,
  isLoadingSubmit: PropTypes.bool,
};

function ShiftAcceptDialog(props) {
  const {
    classes,
    getLocalisationMessage,
    barcodes,
    setScanBatch,
    scanBatch,
    isLoadingSubmit,
    sendToCustoms,
  } = props;

  const [allOrders, setAllOrders] = useState([]);
  const [isClose, setIsClose] = useState(false);
  const [verifiedOrders, setVerifiedOrders] = useState([]);
  const [openCustomsDialog, setOpenCustomsDialog] = useState(false);

  useEffect(() => {
    if (barcodes && barcodes.length > 0) {
      setAllOrders(barcodes.map(number => ({ number, status: UNVERIFIED })));
    }
  }, [barcodes]);

  if (isLoadingSubmit) {
    return <PageLoading isLoading={isLoadingSubmit} />;
  }

  return (
    <Dialog
      open={props.open}
      maxWidth="xl"
      PaperProps={{
        style: {
          width: "1440px",
        },
      }}
    >
      <DialogTitle
        style={{
          color: props.theme.palette.appBarTextColor,
          backgroundColor: props.theme.palette.primary.main,
        }}
      >
        <FlexBox flex={true}>
          {getLocalisationMessage("accept_shift", "Accept Shift")}
        </FlexBox>
      </DialogTitle>

      <ConfirmDialog
        confirmButtonLabel={getLocalisationMessage("yes", "Yes")}
        dismissButtonLabel={getLocalisationMessage("no", "No")}
        open={isClose}
        onConfirm={props.onRequestClose}
        onRequestClose={() => setIsClose(false)}
      >
        {getLocalisationMessage(
          "are_you_sure_you_want_to_exit",
          "Are you sure you want to exit?",
        )}
      </ConfirmDialog>

      <ConfirmDialog
        confirmButtonLabel={getLocalisationMessage("yes", "Yes")}
        dismissButtonLabel={getLocalisationMessage("no", "No")}
        open={openCustomsDialog}
        onConfirm={() => {
          const customs = verifiedOrders
            .filter(item => item.is_order)
            .map(item => ({
              ...item,
              status: SENT_TO_CUSTOMS,
            }));
          props.setSendToCustoms([...customs]);
          setOpenCustomsDialog(false);
        }}
        onRequestClose={() => setOpenCustomsDialog(false)}
      >
        {getLocalisationMessage(
          "are_you_sure_you_want_to_send_these_orders_to_customs",
          "Are you sure you want to send these orders to customs?",
        )}
      </ConfirmDialog>

      <DialogContent>
        <form className={classes.form}>
          <FlexBox direction="column">
            <FlexBox flex={true}>
              {barcodes &&
              barcodes.length > 0 &&
              !barcodes.includes(scanBatch) ? (
                <ScannerTextField
                  className={classes.scannerField}
                  focus={true}
                  autoFocus={true}
                  fullWidth={true}
                  hintText={getLocalisationMessage(
                    "verify_orders",
                    "Verify Orders",
                  )}
                  onChange={v => {
                    checkVerifyOrder(v).then(res => {
                      if (res && getValue(res, "data.is_valid", false)) {
                        setVerifiedOrders(prev => {
                          const prevObj = prev.map(i => i.number);
                          return !prevObj.includes(v)
                            ? [
                                ...prev,
                                {
                                  number: v,
                                  status: VERIFIED,
                                  is_order: getValue(
                                    res,
                                    "data.is_order",
                                    false,
                                  ),
                                },
                              ]
                            : [...prev];
                        });
                        if (!props.verifiedOrderBarcodes.includes(v)) {
                          props.change("verifiedOrderBarcodes", [
                            ...props.verifiedOrderBarcodes,
                            v,
                          ]);
                        }
                        setAllOrders(prev => prev.filter(i => i.number !== v));
                      }
                    });
                  }}
                  inputRef={props.refInput2}
                />
              ) : (
                <ScannerTextField
                  className={classes.scannerField}
                  focus={true}
                  autoFocus={true}
                  fullWidth={true}
                  hintText={getLocalisationMessage("scan_batch", "Scan Batch")}
                  onChange={v => setScanBatch(v)}
                  inputRef={props.refInput}
                />
              )}
            </FlexBox>

            <FlexBox flex={true} style={{ width: "100%", gap: 10 }}>
              <Card style={{ width: "100%" }}>
                <CardHeader
                  title={
                    <FlexBox style={{ padding: 3 }}>
                      {`${getLocalisationMessage("all", "All")} (${
                        allOrders.length
                      })`}
                    </FlexBox>
                  }
                />

                <FlexBox flex={true} style={{ width: "100%" }}>
                  <ShiftingTable
                    list={allOrders}
                    isLoading={props.isLoading}
                    columns={[
                      {
                        type: RENDER,
                        name: "barcode",
                        render: row => (
                          <div
                            style={{
                              color: grey[500],
                            }}
                          >
                            <strong>{_.get(row, "number")}</strong>
                          </div>
                        ),
                        label: getLocalisationMessage("barcode"),
                      },
                      {
                        type: RENDER,
                        name: "status",
                        render: row => (
                          <div
                            style={{
                              color: grey[500],
                            }}
                          >
                            {getLocalisationMessage(
                              _.get(row, "status"),
                              formatText(_.get(row, "status")),
                            )}
                          </div>
                        ),
                        label: getLocalisationMessage("status"),
                      },
                    ]}
                  />
                </FlexBox>
              </Card>

              <Card style={{ width: "100%" }}>
                <CardHeader
                  title={
                    <FlexBox justify="space-between">
                      <FlexBox>
                        {`${getLocalisationMessage("verified", "Verified")} (${
                          verifiedOrders.length
                        })`}
                      </FlexBox>
                      <FlexBox>
                        <CustomButton
                          variant={CONTAINED}
                          color={SECONDARY}
                          disabled={props.verifiedOrderBarcodes.length === 0}
                          onClick={() => setOpenCustomsDialog(true)}
                        >
                          {getLocalisationMessage("send_to_customs")}
                        </CustomButton>
                      </FlexBox>
                    </FlexBox>
                  }
                />

                <FlexBox flex={true} style={{ width: "100%" }}>
                  <ShiftingTable
                    list={verifiedOrders}
                    isLoading={false}
                    columns={[
                      {
                        type: RENDER,
                        name: "barcode",
                        render: row => (
                          <div
                            style={{
                              color: green[500],
                            }}
                          >
                            <strong>{_.get(row, "number")}</strong>
                          </div>
                        ),
                        label: getLocalisationMessage("barcode"),
                      },
                      {
                        type: RENDER,
                        name: "status",
                        render: row => (
                          <div
                            style={{
                              color: green[500],
                            }}
                          >
                            {getLocalisationMessage(
                              _.get(row, "status"),
                              formatText(_.get(row, "status")),
                            )}
                          </div>
                        ),
                        label: getLocalisationMessage("status"),
                      },
                      {
                        type: RENDER,
                        name: "action",
                        render: (row, index) => (
                          <IconButton
                            onClick={() => {
                              setVerifiedOrders(prev => {
                                const idx = prev.findIndex(
                                  (i, iindex) => iindex === index,
                                );
                                return [
                                  ...prev.slice(0, idx),
                                  ...prev.slice(idx + 1),
                                ];
                              });

                              const verifiedArray = props.verifiedOrderBarcodes.filter(
                                (i, iindex) => iindex !== index,
                              );

                              props.change(
                                "verifiedOrderBarcodes",
                                verifiedArray,
                              );

                              if (barcodes.includes(_.get(row, "number"))) {
                                setAllOrders(prev => [
                                  ...prev,
                                  {
                                    ...row,
                                    status: UNVERIFIED,
                                  },
                                ]);
                              }
                            }}
                          >
                            <Close color="error" />
                          </IconButton>
                        ),
                        label: getLocalisationMessage("action"),
                      },
                    ]}
                  />
                </FlexBox>
              </Card>

              {sendToCustoms && sendToCustoms.length > 0 && (
                <Card style={{ width: "100%" }}>
                  <CardHeader
                    title={
                      <FlexBox style={{ padding: 3 }}>
                        {`${getLocalisationMessage(
                          "sent_to_customs",
                          "Sent to customs",
                        )} (${sendToCustoms.length})`}
                      </FlexBox>
                    }
                  />

                  <FlexBox
                    flex={true}
                    style={{ width: "100%", borderLeft: "1px solid #e0e0e0" }}
                  >
                    <ShiftingTable
                      list={sendToCustoms}
                      isLoading={false}
                      columns={[
                        {
                          type: RENDER,
                          name: "barcode",
                          render: row => (
                            <div
                              style={{
                                color: yellow[900],
                              }}
                            >
                              <strong>{_.get(row, "number")}</strong>
                            </div>
                          ),
                          label: getLocalisationMessage("barcode"),
                        },
                        {
                          type: RENDER,
                          name: "status",
                          render: row => (
                            <div
                              style={{
                                color: yellow[900],
                              }}
                            >
                              {getLocalisationMessage(
                                _.get(row, "status"),
                                formatText(_.get(row, "status")),
                              )}
                            </div>
                          ),
                          label: getLocalisationMessage("status"),
                        },
                        {
                          type: RENDER,
                          name: "action",
                          label: getLocalisationMessage("action"),
                          render: (row, index) => (
                            <IconButton
                              onClick={() => {
                                props.setSendToCustoms(prev => {
                                  const idx = prev.findIndex(
                                    (i, iindex) => iindex === index,
                                  );
                                  return [
                                    ...prev.slice(0, idx),
                                    ...prev.slice(idx + 1),
                                  ];
                                });
                              }}
                            >
                              <Close color="error" />
                            </IconButton>
                          ),
                        },
                      ]}
                    />
                  </FlexBox>
                </Card>
              )}
            </FlexBox>

            <FlexBox
              flex={true}
              direction="column"
              style={{ marginTop: 13, gap: 13 }}
            >
              <FlexBox flex={true} style={{ gap: 13 }}>
                <FormUserAutoComplete
                  name="fromOperator"
                  fullWidth={true}
                  label={`${getLocalisationMessage("from")} *`}
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type to search ...",
                  )}
                  disabled={true}
                />
                <FormUserAutoComplete
                  name="toOperator"
                  fullWidth={true}
                  label={`${getLocalisationMessage("to")} *`}
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type to search ...",
                  )}
                />

                <FormUserAutoComplete
                  name="supervisor"
                  fullWidth={true}
                  label={`${getLocalisationMessage("supervisor")} *`}
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type to search ...",
                  )}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </form>
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={() => setIsClose(true)}>
            {getLocalisationMessage("cancel", "Cancel")}
          </Button>
          <CustomButton
            variant={CONTAINED}
            color={SECONDARY}
            disabled={!(barcodes.length > 0)}
            onClick={() => props.handleSubmit()}
            endIcon={
              isLoadingSubmit ? (
                <CircularProgress color="secondary" size={20} />
              ) : (
                <Done size={20} />
              )
            }
          >
            {isLoadingSubmit
              ? getLocalisationMessage("loading")
              : getLocalisationMessage("save", "save")}
          </CustomButton>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(ShiftAcceptDialog);
