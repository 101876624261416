import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import {
  API_ROOT_URL,
  API_V2_ROOT_URL,
} from "../../../shared/constants/ApiConstants";

const ORDER_HISTORY_LIST_URL = `${API_ROOT_URL}/order_history_update/all`;
const ORDER_HISTORY_UPDATE_URL = `${API_V2_ROOT_URL}/admin/order_history_update`;
const ORDER_HISTORY_UPDATE_ITEM_URL = `${API_ROOT_URL}/order_history_update/%s`;

export const getList = params => api.get(ORDER_HISTORY_LIST_URL, { params });

export const updateBatch = body => api.post(ORDER_HISTORY_UPDATE_URL, { body });

export const getBatchItem = id =>
  api.get(sprintf(ORDER_HISTORY_UPDATE_ITEM_URL, id));

const IPS_BATCH_SYNC_URL = `${API_ROOT_URL}/admin/order/update_ips_non_synced_orders`;
const GET_IPS_BATCH_SYNC_BY_RANGE_URL = `${API_ROOT_URL}/admin/order/get_ips_non_synced_orders`;

export const ipsOrderSync = body => api.post(IPS_BATCH_SYNC_URL, { body });

export const getIpsBatchSyncList = body =>
  api.post(GET_IPS_BATCH_SYNC_BY_RANGE_URL, { body });
