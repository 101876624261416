import { Observable } from "rxjs";
import React from "react";
import { fromJS, Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import { Timelapse } from "@material-ui/icons";
import { withTheme } from "@material-ui/core/styles";
import TextWithLink from "../../router/TextWithLink";
import UserAvatar from "../../avatars/UserAvatar";
// import Text, { ALIGN_RIGHT } from "../../ui-core/Text";
import FlexBox from "../../ui-core/FlexBox";
// import TimeSlotAvatar from "../../time-slots-core/TimeSlotAvatar";
import { isEqualData } from "../../../helpers/DataUtils";
import { formatOrderStatusCodeLocalization } from "../../../helpers/OrderHelper";
import { getUser } from "../../../reducers/ProfileReducer";
import { isReverseLogisticEnabled } from "../../../reducers/MarketplaceReducer";
import { getIsRTL, getMessage } from "../../../reducers/LocalizationReducer";
import { getTimeSlotAvailability } from "../../../api/admin/AdminTimeSlotAvailabilityApi";
import { getStatusLocalisation } from "../../../reducers/localisation/OrderStatusLocalisationReducer";
import { ROLE_ADMIN_VIEWER } from "../../../../shared/constants/Authorities";
import { hasRole } from "../../../helpers/RoleUtils";
import DateTimeCell from "../../data-list/DateTimeCell";
import { DatePicker } from "@material-ui/pickers";
import { startOfToday } from "date-fns";
import { safeParseDate } from "../../../helpers/DateUtils";
import { DATE_URL_FORMAT } from "../../../helpers/FormatUtils";
import { OUTLINED } from "../../form/FormTextField";
import CustomButton, { SECONDARY } from "../../ui-core/CustomButton";

const mapResponseDataMap = fp.flow(
  fp.update("pending", Boolean),
  fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
  fromJS,
);

const enhancer = compose(
  connect(state => {
    const userRoles = getUser(state).get("roles") || [];
    return {
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      statusMessages: getStatusLocalisation(state),
      isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
      isRTL: getIsRTL(state),
      reverseLogisticEnabled: isReverseLogisticEnabled(state),
    };
  }),
  withTheme,
  useSheet({
    root: {
      flex: "1 1 0%",
      height: "150px",
      padding: "12px",
      display: "flex",
      flexDirection: "column",

      color: props => props.theme.palette.alternateTextColor,

      "& a": {
        color: props => props.theme.palette.alternateTextColor,
      },
    },

    panel: {
      display: "flex",
      flexDirection: "row",
      "& > div": {
        flex: "1 1 0%",
        fontFamily: "Blogger Sans",
      },
    },
    rootIdLine: {
      marginRight: 30,
    },

    idLine: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      lineHeight: "auto",
      overflow: "hidden",
      position: "relative",
      maxWidth: "225px",
      "&:hover": {
        overflow: "visible",
        "& span": {
          backgroundColor: props => props.theme.palette.primary1Color,
          boxShadow: props =>
            `0 0 0px 2px ${props.theme.palette.primary1Color}`,
          padding: "5px 0",
        },
      },
    },
    idLineWhite: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      position: "relative",
      maxWidth: "225px",
      color: "black",
      fontSize: 12,
      "&:hover": {
        overflow: "visible",
        "& span": {
          backgroundColor: props => props.theme.palette.primary1Color,
          boxShadow: props =>
            `0 0 0px 2px ${props.theme.palette.primary1Color}`,
        },
      },
    },

    createdDate: {
      // width: "32px",
      // height: "32px",
      fontSize: 15,
      margin: "4px",
      color: props => props.theme.palette.alternateTextColor,
    },

    informerTextBlock: {
      marginTop: 5,
      lineHeight: 1.2,
      marginLeft: props => (!props.isRTL ? "2px" : "0"),
      marginRight: props => (props.isRTL ? "2px" : "0"),
      flexDirection: "column",
      justifyContent: "center",
      textTransform: "uppercase",
    },

    informerSecondaryText: {
      fontSize: "12px",
      color: "black",
    },

    shipment: { textAlign: "right" },
    customer: { display: "flex", marginTop: "20px", flexDirection: "row" },
    customerTextBlock: {
      marginLeft: props => (!props.isRTL ? "12px" : "0"),
      marginRight: props => (props.isRTL ? "12px" : "0"),
      lineHeight: "15px",
      paddingTop: "4px",
      textTransform: "uppercase",
    },
    customerTitle: {
      color: "black",
      fontSize: "10px",
    },
    valueText: {
      color: "black",
      fontSize: "15px",
    },
    courier: {
      display: "flex",
      marginTop: "20px",
      textAlign: props => (props.isRTL ? "left" : "right"),
      flexDirection: "row-reverse",
      color: "black",
    },

    courierTextBlock: {
      paddingTop: "4px",
      lineHeight: "15px",
      marginLeft: props => (!props.isRTL ? "0" : "12px"),
      marginRight: props => (props.isRTL ? "0" : "12px"),
      textTransform: "uppercase",
    },
    courierTitle: {
      // color: props => props.theme.palette.alternateTextColor,
      color: "black",
      fontSize: "10px",
    },
    courierLastSync: {
      fontSize: "12px",
      color: props => props.theme.palette.alternateTextColor,
    },
    toCourier: { textAlign: "center", marginTop: "20px", lineHeight: "18px" },
    toCourier2: { textAlign: "center", lineHeight: "18px" },
    toCourierTime: { fontSize: "12px" },
    statusWrapper: {
      "&>div": {
        display: "flex",
        "&>div": {
          margin: "0 .5rem",
        },
      },
    },
  }),
  mapPropsStream(propsStream => {
    const pickupTimeSlotAvailabilityResponseStream = propsStream
      .distinctUntilKeyChanged("order", isEqualData)
      .filter(
        props =>
          props.order && props.order.get("pickup_timeslot_availability_id"),
      )
      .switchMap(props =>
        getTimeSlotAvailability(
          props.order.get("pickup_timeslot_availability_id"),
        ).catch(error => Observable.of({ error })),
      )
      .startWith({})
      .map(mapResponseDataMap)
      .distinctUntilChanged(isEqualData);

    const dropoffTimeSlotAvailabilityResponseStream = propsStream
      .distinctUntilKeyChanged("order", isEqualData)
      .filter(
        props =>
          props.order && props.order.get("drop_off_timeslot_availability_id"),
      )
      .switchMap(props =>
        getTimeSlotAvailability(
          props.order.get("drop_off_timeslot_availability_id"),
        ).catch(error => Observable.of({ error })),
      )
      .startWith({})
      .map(mapResponseDataMap)
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        pickupTimeSlotAvailabilityResponseStream,
        dropoffTimeSlotAvailabilityResponseStream,
        (
          props,
          pickupTimeSlotAvailabilityResponse,
          dropoffTimeSlotAvailabilityResponse,
        ) => ({
          ...props,
          pickupTimeSlotLoading: pickupTimeSlotAvailabilityResponse.get(
            "pending",
          ),
          dropoffTimeSlotLoading: dropoffTimeSlotAvailabilityResponse.get(
            "pending",
          ),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

OrderDetailsDialogHeader.propTypes = {
  classes: PropTypes.object,
  getDriverUrl: PropTypes.func,
  getSupplierUrl: PropTypes.func,
  getCustomerUrl: PropTypes.func,
  order: PropTypes.instanceOf(Map).isRequired,
  getLocalisationMessage: PropTypes.func,
  isAdminViewer: PropTypes.bool,
  isDevelopers: PropTypes.bool,
  statusMessages: PropTypes.instanceOf(Map),
  getReverseUrl: PropTypes.func,
  setDate: PropTypes.func,
  date: PropTypes.string,
};

OrderDetailsDialogHeader.defaultProps = {
  getDriverUrl: fp.noop,
  getSupplierUrl: fp.noop,
  getCustomerUrl: fp.noop,
};

function OrderDetailsDialogHeader(props) {
  const { classes, order, getLocalisationMessage, statusMessages } = props;
  const NA =
    (getLocalisationMessage && getLocalisationMessage("na", "N/A")) || "N/A";

  return (
    <div className={classes.root}>
      <div className={classes.panel}>
        <div className={classes.rootIdLine}>
          <div className={classes.informerTextBlock}>
            <div className={classes.idLine}>{order.get("barcode") || NA}</div>
          </div>
          {order.get("head_order_id") && (
            <div style={{ marginTop: ".5rem" }} className={classes.idLine}>
              <span>
                {getLocalisationMessage("order_shipment_reference")}:{" "}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <span
                  onClick={() =>
                    props.getReverseUrl(order.get("head_order_id"))
                  }
                  style={{ cursor: "pointer" }}
                >
                  {order.get("reverse_order_number")}
                </span>
              </span>
            </div>
          )}
          {order.get("back_order_id") && (
            <div style={{ marginTop: ".5rem" }} className={classes.idLine}>
              <span>
                {getLocalisationMessage("notification")}:{" "}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <span
                  onClick={() =>
                    props.getReverseUrl(order.get("back_order_id"))
                  }
                  style={{ cursor: "pointer" }}
                >
                  {order.get("reverse_order_number")}
                </span>
              </span>
            </div>
          )}
          {order.get("reference_id") && (
            <div className={classes.idLine}>
              <span>
                {getLocalisationMessage("ref", "Ref")}#:{" "}
                {order.get("reference_id") || NA}
              </span>
            </div>
          )}
        </div>
        <div style={{ color: "black" }}>
          <div className={classes.toCourier2}>
            <FlexBox className={classes.statusWrapper}>
              <span style={{ textTransform: "uppercase" }}>
                {getLocalisationMessage("created", "Created")}
              </span>
              <DateTimeCell date={order.get("created_date")} />
            </FlexBox>
          </div>
        </div>

        {2 === 3 && (
          <FlexBox
            style={{
              visibility: order.get("merchant_promise_date")
                ? "visible"
                : "hidden",
            }}
          >
            <Avatar
              size={40}
              style={{
                backgroundColor: "#fff",
              }}
            >
              <Timelapse className={classes.createdDate} />
            </Avatar>

            <div className={classes.informerTextBlock}>
              <div>
                {getLocalisationMessage("promise_date", "Promise Date")}:
              </div>
              <div className={classes.informerSecondaryText}>
                <DateTimeCell date={order.get("merchant_promise_date")} />
              </div>
            </div>
          </FlexBox>
        )}
        <FlexBox />
        {order.get("shipment_id") && (
          <FlexBox justify="flex-end">
            <div className={classes.informerTextBlock}>
              <div>{getLocalisationMessage("shipment_id", "SHIPMENT ID")}</div>
              <div className={classes.informerSecondaryText}>
                {order.get("shipment_id")}
              </div>
            </div>
          </FlexBox>
        )}
        {props.isDevelopers && (
          <FlexBox justify="flex-end">
            <div className={classes.informerTextBlock}>
              <DatePicker
                style={{ minWidth: 70 }}
                fullWidth={true}
                initialTime={startOfToday()}
                value={safeParseDate(props.date)}
                onChange={e => props.setDate(e)}
                format={DATE_URL_FORMAT}
                onBlur={fp.noop}
                inputVariant={OUTLINED}
                size="small"
                cancelLabel={
                  <CustomButton color={SECONDARY}>Cancel</CustomButton>
                }
                okLabel={<CustomButton color={SECONDARY}>OK</CustomButton>}
              />
            </div>
          </FlexBox>
        )}
      </div>

      <div className={classes.panel}>
        <div>
          <div className={classes.customer}>
            <UserAvatar size={40} name={order.getIn(["customer", "name"])} />

            <div className={classes.customerTextBlock}>
              <span className={classes.customerTitle}>
                {getLocalisationMessage("customer", "Customer")}
              </span>
              <br />
              {!props.isAdminViewer ? (
                <TextWithLink
                  target="_blank"
                  to={props.getCustomerUrl(
                    order.getIn(["customer", "id"]),
                    order.getIn(["customer", "merchant_type"]),
                  )}
                  className={classes.valueText}
                >
                  {order.getIn(["customer", "company", "name"]) ||
                    order.getIn(["customer", "name"])}
                </TextWithLink>
              ) : (
                order.getIn(["customer", "company", "name"]) ||
                order.getIn(["customer", "name"])
              )}
            </div>
          </div>
        </div>

        <div style={{ marginLeft: "130px", color: "black" }}>
          <div className={classes.toCourier}>
            <FlexBox className={classes.statusWrapper}>
              <span style={{ textTransform: "uppercase" }}>
                {formatOrderStatusCodeLocalization(
                  order.get("status"),
                  statusMessages,
                )}
              </span>
              {order.get("last_status_date") ? (
                <DateTimeCell date={order.get("last_status_date")} />
              ) : (
                NA
              )}
            </FlexBox>
          </div>
        </div>

        <div>
          {Boolean(order.get("driver") || order.get("supplier")) && (
            <div className={classes.courier}>
              <UserAvatar
                size={40}
                src={order.getIn(["driver", "description"])}
                name={
                  order.getIn(["driver", "name"]) ||
                  order.getIn(["supplier", "name"])
                }
              />
              <div className={classes.courierTextBlock}>
                <div className={classes.courierTitle}>
                  {getLocalisationMessage("courier_company")}
                </div>

                <div>
                  {Boolean(order.get("supplier")) && (
                    <span>
                      {!props.isAdminViewer ? (
                        <TextWithLink
                          target="_blank"
                          className={classes.valueText}
                          to={props.getSupplierUrl(
                            order.getIn(["supplier", "id"]),
                          )}
                        >
                          {order.getIn(["supplier", "name"])}
                        </TextWithLink>
                      ) : (
                        order.getIn(["supplier", "name"])
                      )}
                    </span>
                  )}

                  {Boolean(order.get("driver") && order.get("supplier")) &&
                    " / "}

                  {Boolean(order.get("driver")) && (
                    <span>
                      {!props.isAdminViewer ? (
                        <TextWithLink
                          target="_blank"
                          className={classes.valueText}
                          to={props.getDriverUrl(order.getIn(["driver", "id"]))}
                        >
                          {order.getIn(["driver", "name"])}
                        </TextWithLink>
                      ) : (
                        order.getIn(["driver", "name"])
                      )}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default enhancer(OrderDetailsDialogHeader);
