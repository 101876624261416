export const geoJson = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {
        adm1_code: "UZB-354",
        OBJECTID_1: 401,
        diss_me: 354,
        adm1_cod_1: "UZB-354",
        iso_3166_2: "UZ-",
        wikipedia: null,
        iso_a2: "UZ",
        adm0_sr: 1,
        name: "Bukhoro",
        name_alt: "Bukhara|Buxoro|Mawarranahr|Transoxania|Turan",
        name_local: null,
        type: "Wiloyat",
        type_en: "Region",
        code_local: null,
        code_hasc: "UZ.BU",
        note: null,
        hasc_maybe: null,
        region: null,
        region_cod: null,
        provnum_ne: 13,
        gadm_level: 1,
        check_me: 0,
        scalerank: 4,
        datarank: 6,
        abbrev: null,
        postal: "BU",
        area_sqkm: 0,
        sameascity: 7,
        labelrank: 7,
        featurecla: "Admin-1 scale rank",
        name_len: 7,
        mapcolor9: 5,
        mapcolor13: 4,
        fips: "UZ02",
        fips_alt: null,
        woe_id: 2347660,
        woe_label: "Bukhoro, UZ, Uzbekistan",
        woe_name: "Bukhoro",
        latitude: 39.945999999999998,
        longitude: 63.7729,
        sov_a3: "UZB",
        adm0_a3: "UZB",
        adm0_label: 2,
        admin: "Uzbekistan",
        geonunit: "Uzbekistan",
        gu_a3: "UZB",
        gn_id: 1114929,
        gn_name: "Bukhara Province",
        gns_id: -3431445,
        gns_name: "Buxoro Viloyati",
        gn_level: 1,
        gn_region: null,
        gn_a1_code: "UZ.02",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "vie",
        gns_adm1: "UZ02",
        gns_region: null,
        color: "#FF0000",
        jurisdiction_id: 259,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [63.735055779800348, 40.726248277535944],
            [63.854221632822089, 40.757951769158836],
            [63.925586786101292, 40.838644518030094],
            [63.985014682981273, 40.932798976691402],
            [64.002998080596967, 40.95246186002683],
            [64.022738478298152, 40.964528306735929],
            [64.042892287149357, 40.969799303395149],
            [64.061650832020291, 40.965975247559612],
            [64.08123620009053, 40.953521227222836],
            [64.101700067304307, 40.930473538522989],
            [64.106816034332553, 40.896418769410673],
            [64.108521355775906, 40.830608831832109],
            [64.122318963249597, 40.80900808395586],
            [64.164693637601317, 40.79748423870663],
            [64.435478143201692, 40.773015449403431],
            [64.458009067964156, 40.788880113526375],
            [64.488394809972533, 40.878228665320648],
            [64.507463413205926, 40.885928452935701],
            [64.541156447112371, 40.89564362215549],
            [64.673499789983282, 40.900139472458648],
            [64.717114698684384, 40.907012436874453],
            [64.749102411147362, 40.917502753349396],
            [64.781193474599206, 40.951970934511024],
            [64.793285759729997, 40.950756536784723],
            [64.802019077018826, 40.926933701728984],
            [64.823258090588411, 40.826345527324207],
            [64.832404819926523, 40.799809679573059],
            [64.84651248576256, 40.770405789495527],
            [64.881135694756551, 40.7485208198799],
            [64.915758904649692, 40.733482978056941],
            [65.014409213614272, 40.715086168391963],
            [65.317491490040197, 40.699118150582166],
            [65.365447219413625, 40.709660143001173],
            [65.341831089033519, 40.647596746892305],
            [65.337025181267052, 40.622068590393127],
            [65.334854770930974, 40.59579112416111],
            [65.335164829293433, 40.571012274696642],
            [65.337335239629567, 40.550910142688963],
            [65.341055942677656, 40.538998724711377],
            [65.340280796321736, 40.527500718783131],
            [65.326948276841506, 40.527862453989002],
            [65.274651726795753, 40.536389064803473],
            [65.264729852001096, 40.536802476852785],
            [65.255273065199788, 40.534735419304099],
            [65.247521599841946, 40.529516100387667],
            [65.236152785122897, 40.516622830479236],
            [65.234447462780167, 40.504142970821448],
            [65.207782423819822, 40.440658474109284],
            [65.18592329262583, 40.42115062130415],
            [65.167423130173347, 40.407921453712078],
            [65.144788852623435, 40.352989407135368],
            [65.134040154629474, 40.336142890182202],
            [65.016424595218894, 40.337073066169097],
            [64.993635288038149, 40.342809149922317],
            [64.972241244837619, 40.353764553491374],
            [64.952035760042236, 40.367252101703173],
            [64.932657098446384, 40.371437892744581],
            [64.910177849628099, 40.371282864012954],
            [64.875399611003218, 40.366761176187367],
            [64.858811477367738, 40.359759020562365],
            [64.84961307208556, 40.351129055161749],
            [64.846822544125132, 40.341723945203881],
            [64.845272251413235, 40.332422187134227],
            [64.844807163419887, 40.323637193001986],
            [64.850129836023086, 40.304258531406049],
            [64.875089552640702, 40.284983221698326],
            [64.858501418105902, 40.247207750437326],
            [64.84961307208556, 40.240696520328186],
            [64.844807163419887, 40.239456285079456],
            [64.844187045795479, 40.237130846011752],
            [64.84558230977575, 40.233151760545297],
            [64.856951125394119, 40.218966580343363],
            [64.886406691415687, 40.196048081953421],
            [64.901754591601104, 40.181139431339659],
            [64.905940382642541, 40.174654038752863],
            [64.904700148293159, 40.171191718123282],
            [64.874779494278243, 40.168401191062145],
            [64.867958204907211, 40.167109279869351],
            [64.861757033160529, 40.163750312027275],
            [64.854212274277018, 40.159357815410885],
            [64.845272251413235, 40.159099432992491],
            [64.830027704015379, 40.14959096934777],
            [64.782588738579364, 40.075331936219897],
            [64.768791131105843, 40.060500799971976],
            [64.751117791852607, 40.047452501331918],
            [64.733186070181034, 40.037685655268803],
            [64.672104526902388, 40.016188259280767],
            [64.6641980319136, 40.003114122218989],
            [64.656446568354397, 39.996835436106522],
            [64.652880894037992, 39.992546292277524],
            [64.620428093581609, 39.96110118307314],
            [64.604150018308701, 39.933376776916703],
            [64.590042351573231, 39.907796943574084],
            [64.605545282288915, 39.883173122841328],
            [64.67815066901818, 39.805787664968761],
            [64.705952590439665, 39.791059882407609],
            [64.752978142926963, 39.771061103187321],
            [64.754373406907234, 39.765273341691369],
            [64.75948937393548, 39.75617829009596],
            [64.763055047352509, 39.747703355225639],
            [64.77700768355777, 39.742329007577666],
            [64.796386346052998, 39.741450507534921],
            [64.83984622602253, 39.75682424614206],
            [64.854935743789497, 39.754421291809109],
            [64.863927442597401, 39.74310415303421],
            [64.983248325249974, 39.699876817061437],
            [65.001541782127561, 39.67620901073721],
            [65.009758334579487, 39.653936469292631],
            [65.010068393841323, 39.637632554698598],
            [65.013324008895836, 39.615850937870448],
            [65.02138553171693, 39.59505117387252],
            [65.040660842323973, 39.58647288711407],
            [65.064535353323947, 39.579754951429805],
            [65.079624871990234, 39.573243720421317],
            [65.097453240874358, 39.559368598582012],
            [65.113421257784807, 39.542367051997843],
            [65.117452020094674, 39.536708481711088],
            [65.123653191841356, 39.525649726253846],
            [65.133626742580077, 39.499036363237565],
            [65.135332065822126, 39.492111721079056],
            [65.13781253362157, 39.464258123713421],
            [65.090631952402646, 39.436662908766181],
            [64.990379672983465, 39.358993231852139],
            [64.977822299859213, 39.351448472968627],
            [64.965988397146759, 39.348089505126552],
            [64.956841668708023, 39.34863210748577],
            [64.944594353946229, 39.347521064345699],
            [64.937152947850279, 39.344782213228598],
            [64.931261835365433, 39.341190701389735],
            [64.8550907734205, 39.276750190269098],
            [64.845427281044238, 39.266595771477597],
            [64.839381138029069, 39.258120835707984],
            [64.838295933310633, 39.252643134373159],
            [64.838295933310633, 39.247475491400792],
            [64.839381138029069, 39.242669582735004],
            [64.840931430740909, 39.238044542121912],
            [64.845737339406696, 39.229440416042308],
            [64.852713656609978, 39.221508084430582],
            [64.8847013690729, 39.190993150313602],
            [64.888112013758416, 39.187014064847148],
            [64.890902540819582, 39.182027289028213],
            [64.891677687175559, 39.176239529330843],
            [64.889352248107741, 39.167867947247998],
            [64.8847013690729, 39.163785508994039],
            [64.87896528531968, 39.161175849086135],
            [64.712618850179751, 39.114253648487107],
            [64.658927036153841, 39.099112453876643],
            [64.521312696623625, 39.056065985956508],
            [64.474442172868009, 39.030951239707974],
            [64.383181594055316, 38.953126533162958],
            [64.367678664239008, 38.940465807251201],
            [64.367566302971341, 38.940374045912847],
            [64.337343791000137, 38.970955709000066],
            [64.313572632000103, 38.982479553000104],
            [64.28339359600011, 38.979689026000045],
            [64.205982300000073, 38.949458314000111],
            [64.180144084000062, 38.945117493000055],
            [64.15058516400012, 38.949458314000111],
            [64.120612834000099, 38.961679789000044],
            [63.959692424000139, 39.051364238000104],
            [63.820062703000133, 39.129137268000051],
            [63.709268432000101, 39.190890605000021],
            [63.648496948000115, 39.239518128000043],
            [63.541009969000072, 39.356100159000022],
            [63.477551310000138, 39.402919007000037],
            [63.33864506000009, 39.479451803000131],
            [63.008949423000104, 39.661094462000079],
            [62.846478719000061, 39.75059804300011],
            [62.710156291000033, 39.825735576000113],
            [62.593160848000139, 39.890227763000041],
            [62.53538659700007, 39.93497955300009],
            [62.524586222000039, 39.939733785000101],
            [62.452807658000097, 40.009238586000066],
            [62.441955607000068, 40.035955302000048],
            [62.424385620000066, 40.09212758400011],
            [62.421595092000132, 40.115071920000048],
            [62.425780883000073, 40.136672669000092],
            [62.434100790000116, 40.154811097000049],
            [62.439681844000063, 40.172897848000048],
            [62.435754435000092, 40.194188538000049],
            [62.405058635000046, 40.245244853000088],
            [62.400976196000045, 40.265605368000138],
            [62.400201050000135, 40.29211537700013],
            [62.396532023000134, 40.312010803000135],
            [62.388315471000112, 40.330149232000082],
            [62.362580608000087, 40.369681702],
            [62.356379435000122, 40.388181865000035],
            [62.353278850000095, 40.43040151000011],
            [62.343615356000043, 40.439393209000059],
            [62.260933065000074, 40.475721741000115],
            [62.213442424000107, 40.486573792000087],
            [62.191841675000092, 40.499027812000065],
            [62.187061256835932, 40.505515522211795],
            [62.187139926666248, 40.505589910745783],
            [62.395602654638822, 40.702709662420943],
            [62.447279087060224, 40.788311672745436],
            [62.435135125985255, 40.821772161755916],
            [62.402734003271689, 40.859935208443147],
            [62.239488152656065, 40.998970649475922],
            [62.465417515206184, 41.080955309539917],
            [62.464642367950887, 41.094675400849042],
            [62.180370314138713, 41.392305813462485],
            [62.534767286880765, 41.493281562394117],
            [62.6190515475908, 41.491782944727078],
            [62.650832553579448, 41.436799221307041],
            [62.675533888678046, 41.403829657812452],
            [62.843740675791537, 41.254613959566569],
            [62.978564488260474, 41.074599107262969],
            [63.002490676103776, 41.064212145374228],
            [63.090909051011863, 41.048941759554509],
            [63.222322218795341, 41.010313625773165],
            [63.376421340072795, 40.94468455714653],
            [63.677333205263267, 40.804305528077663],
            [63.699967481913745, 40.774048977278454],
            [63.736606073411508, 40.745781968762799],
            [63.735055779800348, 40.726248277535944],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "UZB-355",
        OBJECTID_1: 402,
        diss_me: 355,
        adm1_cod_1: "UZB-355",
        iso_3166_2: "UZ-",
        wikipedia: null,
        iso_a2: "UZ",
        adm0_sr: 1,
        name: "Khorezm",
        name_alt: "Khiva|Khwarazm|Khorazm|Xorazm",
        name_local: null,
        type: "Wiloyat",
        type_en: "Region",
        code_local: null,
        code_hasc: "UZ.KH",
        note: null,
        hasc_maybe: null,
        region: null,
        region_cod: null,
        provnum_ne: 8,
        gadm_level: 1,
        check_me: 0,
        scalerank: 4,
        datarank: 6,
        abbrev: null,
        postal: "KH",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 4,
        featurecla: "Admin-1 scale rank",
        name_len: 7,
        mapcolor9: 5,
        mapcolor13: 4,
        fips: "UZ05",
        fips_alt: null,
        woe_id: 2347665,
        woe_label: "Khorazm, UZ, Uzbekistan",
        woe_name: "Khorezm",
        latitude: 41.248899999999999,
        longitude: 61.248100000000001,
        sov_a3: "UZB",
        adm0_a3: "UZB",
        adm0_label: 2,
        admin: "Uzbekistan",
        geonunit: "Uzbekistan",
        gu_a3: "UZB",
        gn_id: 1484843,
        gn_name: "Xorazm Viloyati",
        gns_id: -3431448,
        gns_name: "Xorazm Viloyati",
        gn_level: 1,
        gn_region: null,
        gn_a1_code: "UZ.05",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "vie",
        gns_adm1: "UZ05",
        gns_region: null,
        color: "#800080",
        jurisdiction_id: 271,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [61.314221633221791, 41.2862399359243],
            [61.339646436933492, 41.288074449476227],
            [61.357164748354478, 41.298151353901886],
            [61.396903924376602, 41.355796414073268],
            [61.412871942186371, 41.370239976693597],
            [61.428219842371789, 41.379438381975746],
            [61.44640994736119, 41.38628550886915],
            [61.46609866731967, 41.389670315132861],
            [61.520255568439779, 41.389566962345469],
            [61.711303337778759, 41.354840400564001],
            [61.759672479201583, 41.339337469848317],
            [61.804837680614412, 41.318589381794538],
            [61.863800490400365, 41.284560452003234],
            [62.034022658219044, 41.142734483010031],
            [62.1534985696033, 40.998893134210761],
            [62.157581007857175, 40.995715034421295],
            [62.171688673693325, 40.989643052984547],
            [62.186726516415604, 40.985870673093046],
            [62.239488152656065, 40.998970649475922],
            [62.402734003271689, 40.859935208443147],
            [62.435135125985255, 40.821772161755916],
            [62.447279087060224, 40.788311672745436],
            [62.395602654638822, 40.702709662420943],
            [62.187139926666248, 40.505589910745783],
            [62.187061256835932, 40.505515522211795],
            [62.185330445000034, 40.507864482000059],
            [62.177268921000064, 40.530653789000041],
            [62.171377808000045, 40.539955547000091],
            [62.134170776000133, 40.577834372000069],
            [62.120683227000086, 40.598970032000096],
            [62.100064331000056, 40.660413310000109],
            [62.088695516000087, 40.674365947000098],
            [62.055725952000046, 40.701754456000032],
            [62.044770549000134, 40.714983622000034],
            [62.036812378000093, 40.736325989000136],
            [62.025340210000081, 40.781956279000042],
            [61.993869263000136, 40.865878804000033],
            [61.982707153000092, 40.885670878000013],
            [61.990251912000133, 40.893163961000141],
            [61.985394328000098, 40.907891745000143],
            [61.982707153000092, 40.978430075000119],
            [61.979296509000051, 40.998842265000064],
            [61.97082157400007, 41.015430399000081],
            [61.897441040000103, 41.108809713000142],
            [61.877907349000111, 41.124984436000034],
            [61.654355103000057, 41.249421285000039],
            [61.567331991000117, 41.277791647000043],
            [61.470076945000073, 41.297170309000109],
            [61.433179972000062, 41.300322571000066],
            [61.410752401000082, 41.292002666000116],
            [61.397213175000104, 41.270970358000085],
            [61.387498006000044, 41.235778707000037],
            [61.363003377000098, 41.225185039000053],
            [61.343986450000045, 41.193817444000047],
            [61.315874471000086, 41.170459697000098],
            [61.281354614000122, 41.157282207],
            [61.243217408000078, 41.156300354000052],
            [61.202599731000134, 41.168806051000047],
            [61.099660279000034, 41.224099833000054],
            [61.081883586000117, 41.224513245000082],
            [61.022765747000051, 41.216865133000084],
            [61.017184692000058, 41.225960185000076],
            [61.014084107000087, 41.234900208000084],
            [61.010260051000046, 41.237225647000031],
            [61.004318068162149, 41.229533187953223],
            [60.991139771000064, 41.212472636000115],
            [60.984628540000074, 41.206994935000125],
            [60.978013957000087, 41.202809144000071],
            [60.930264933000046, 41.240222880000061],
            [60.860295044000111, 41.252986959000111],
            [60.658860311000069, 41.24378855400002],
            [60.538144165000062, 41.21324778200011],
            [60.478096151000102, 41.212110902000092],
            [60.443266235000124, 41.219655660000058],
            [60.414534140000114, 41.235261943000097],
            [60.360273886000073, 41.27634470600006],
            [60.299915812000052, 41.309831035000059],
            [60.203694296000037, 41.347089742000094],
            [60.094243612000071, 41.389516093000111],
            [60.066131633000055, 41.410496725000115],
            [60.059206991000053, 41.434164531000135],
            [60.071712687000058, 41.514366354000032],
            [60.079567505000114, 41.531471252000074],
            [60.092589966000048, 41.545475566000079],
            [60.109643189000053, 41.55679270400006],
            [60.1509843340001, 41.572347311000073],
            [60.164730265000117, 41.58495636],
            [60.155635213000039, 41.603327332000106],
            [60.089075969000078, 41.674124044000052],
            [60.058276815000113, 41.718591614000019],
            [60.049181763000036, 41.753499044000108],
            [60.074916626000118, 41.781042583000101],
            [60.102408488000094, 41.795279440000058],
            [60.133724406000113, 41.797294821],
            [60.171344849000036, 41.788406474000084],
            [60.205864705000124, 41.772025045000134],
            [60.225398397000106, 41.765462138000146],
            [60.241108033000103, 41.767064107000095],
            [60.252683553000111, 41.780060730000116],
            [60.250823202000106, 41.794271750000092],
            [60.241108033000103, 41.808069357000136],
            [60.228912395000066, 41.81961903900006],
            [60.214856405000035, 41.828584900000095],
            [60.167520793000108, 41.849591370000013],
            [60.152534627000136, 41.861192729000066],
            [60.14114411615401, 41.874221483967233],
            [60.141166620043464, 41.874240221309122],
            [60.152897169968469, 41.884007066472947],
            [60.164834426367634, 41.906331284760967],
            [60.162819044763125, 41.917390042016763],
            [60.151191847625682, 41.93439158770164],
            [60.149176466920437, 41.938887438004798],
            [60.147626174208597, 41.943357448987058],
            [60.150571730001388, 41.947930812756795],
            [60.157393019372364, 41.951961575066576],
            [60.173050977920241, 41.954287014134366],
            [60.200232781717546, 41.955062161389662],
            [60.220851677662836, 41.96152171555471],
            [60.271907993359207, 41.988290107302646],
            [60.290976596592714, 42.000847480426813],
            [60.296867709976823, 42.003431301013734],
            [60.313094110205043, 42.005575872928176],
            [60.334436475662869, 41.993251043800683],
            [60.345133497712709, 41.988961899971684],
            [60.395311314265712, 41.979350084438863],
            [60.444765658608219, 41.975319322128968],
            [60.458976678131137, 41.966637682582956],
            [60.462438998760831, 41.945992947316682],
            [60.461818882035743, 41.900853583426141],
            [60.469880405756157, 41.885092271191411],
            [60.496907179922459, 41.854034736513853],
            [60.507914260334815, 41.828454902271972],
            [60.535922886432047, 41.790731106055659],
            [60.549978876324076, 41.777321072209702],
            [60.567342157214796, 41.768768622074248],
            [60.588271112421864, 41.76478953750717],
            [60.636226840896029, 41.763652655945293],
            [60.651988153130731, 41.756883043417673],
            [60.663305291905658, 41.740424099192808],
            [60.680306837590479, 41.701589259836425],
            [60.706093378306775, 41.667146917995893],
            [60.738494501020455, 41.639525865526224],
            [60.975379266240168, 41.478812161352181],
            [61.000804070851132, 41.474807237464006],
            [61.01330976803132, 41.470802314475293],
            [61.025040317956268, 41.465066229822696],
            [61.060955438142912, 41.434628810970878],
            [61.091806268144808, 41.413596502976191],
            [61.114388868851165, 41.406775214504506],
            [61.172266473019363, 41.398351956477626],
            [61.191128370677745, 41.390006211917125],
            [61.244200067079419, 41.345538642494347],
            [61.256912468935241, 41.331043403030606],
            [61.268746371647751, 41.301872056949833],
            [61.28399091994504, 41.296161810718957],
            [61.290450474110116, 41.293242092448594],
            [61.314221633221791, 41.2862399359243],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "UZB-356",
        OBJECTID_1: 403,
        diss_me: 356,
        adm1_cod_1: "UZB-356",
        iso_3166_2: "UZ-",
        wikipedia: null,
        iso_a2: "UZ",
        adm0_sr: 1,
        name: "Karakalpakstan",
        name_alt:
          "Qoraqalpog`iston autonomous republic|Kara-Kalpakia|Qoraqalpoghiston|Qoraqalpog`iston|Qoraqalpoghiston Respublikasi",
        name_local: null,
        type: "Repuplikasi",
        type_en: "Automonous Region",
        code_local: null,
        code_hasc: "UZ.QR",
        note: null,
        hasc_maybe: null,
        region: null,
        region_cod: null,
        provnum_ne: 9,
        gadm_level: 1,
        check_me: 0,
        scalerank: 4,
        datarank: 6,
        abbrev: null,
        postal: "QR",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 4,
        featurecla: "Admin-1 scale rank",
        name_len: 14,
        mapcolor9: 5,
        mapcolor13: 4,
        fips: "UZ09",
        fips_alt: null,
        woe_id: 2347663,
        woe_label: "Qoraqalpoghiston, UZ, Uzbekistan",
        woe_name: "Karakalpakstan",
        latitude: 43.852899999999998,
        longitude: 59.223300000000002,
        sov_a3: "UZB",
        adm0_a3: "UZB",
        adm0_label: 2,
        admin: "Uzbekistan",
        geonunit: "Uzbekistan",
        gu_a3: "UZB",
        gn_id: 453752,
        gn_name: "Karakalpakstan",
        gns_id: -3431454,
        gns_name: "Qoraqalpog`iston Respublikasi",
        gn_level: 1,
        gn_region: null,
        gn_a1_code: "UZ.09",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "vie",
        gns_adm1: "UZ09",
        gns_region: null,
        color: "#008000",
        jurisdiction_id: 265,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [59.055132166208296, 45.324023174864891],
            [59.120349569000041, 45.293050435000026],
            [59.292432088000112, 45.211246643000095],
            [59.479397420000112, 45.12246653200009],
            [59.67576786300009, 45.02913889600012],
            [59.876582479000035, 44.933795878000097],
            [60.07667362400008, 44.838659566000075],
            [60.270873657000095, 44.746468812000018],
            [60.45401493300011, 44.659445699000059],
            [60.621136516000035, 44.580070700000107],
            [60.766864055000042, 44.510824280000094],
            [60.886236613000051, 44.454135234000091],
            [60.97398319500013, 44.412432353000071],
            [61.036304972000039, 44.382821758],
            [61.062143189000039, 44.361996155000043],
            [61.080229940000095, 44.335486145000061],
            [61.086637818000099, 44.311198222000144],
            [61.0931490480001, 44.259418437000079],
            [61.102864217000047, 44.236060690000045],
            [61.161671997000099, 44.16810618100007],
            [61.276393677000101, 44.078860982000037],
            [61.331170695000139, 44.036176249000022],
            [61.38605106600005, 43.993439840000121],
            [61.456124308000085, 43.935174663000083],
            [61.513485148000086, 43.887244772000059],
            [61.571052694000116, 43.839289043000122],
            [61.628516886000057, 43.791359152000013],
            [61.685981079000044, 43.743429261000074],
            [61.742101684000033, 43.696532899000033],
            [61.798325643000112, 43.64958486000009],
            [61.854549602000077, 43.602688497000145],
            [61.910670207000123, 43.555817973000103],
            [61.985704386000123, 43.49334116600005],
            [62.00596154800013, 43.486984965],
            [62.026115357000094, 43.480628765000091],
            [62.028320515889732, 43.480922383130689],
            [62.135205112725714, 43.347742010785169],
            [62.185331252435333, 43.313945624091332],
            [62.455805698773986, 43.25927195903364],
            [62.470998570227778, 43.2527607289245],
            [62.470843539697569, 43.24276133886471],
            [62.465417515206184, 43.231211656093024],
            [62.193082716893912, 42.855058905843805],
            [61.936715936391295, 42.492186998231404],
            [61.881628859284376, 42.396895657108843],
            [61.859149611365467, 42.189311428279836],
            [61.859149611365467, 42.177012438473241],
            [61.862095168057579, 42.167426459563472],
            [61.97614505405096, 42.011182766371576],
            [61.995885450852882, 41.980021878007278],
            [61.991699659811445, 41.974647529459929],
            [61.979710728367422, 41.965139064915917],
            [61.764840122173894, 41.874731146824928],
            [61.743084343767464, 41.861011053717078],
            [61.743704462291078, 41.847781887024468],
            [61.751610956380489, 41.834190986025106],
            [62.180370314138713, 41.392305813462485],
            [62.464642367950887, 41.094675400849042],
            [62.465417515206184, 41.080955309539917],
            [62.239488152656065, 40.998970649475922],
            [62.186726516415604, 40.985870673093046],
            [62.171688673693325, 40.989643052984547],
            [62.157581007857175, 40.995715034421295],
            [62.1534985696033, 40.998893134210761],
            [62.034022658219044, 41.142734483010031],
            [61.863800490400365, 41.284560452003234],
            [61.804837680614412, 41.318589381794538],
            [61.759672479201583, 41.339337469848317],
            [61.711303337778759, 41.354840400564001],
            [61.520255568439779, 41.389566962345469],
            [61.46609866731967, 41.389670315132861],
            [61.44640994736119, 41.38628550886915],
            [61.428219842371789, 41.379438381975746],
            [61.412871942186371, 41.370239976693597],
            [61.396903924376602, 41.355796414073268],
            [61.357164748354478, 41.298151353901886],
            [61.339646436933492, 41.288074449476227],
            [61.314221633221791, 41.2862399359243],
            [61.290450474110116, 41.293242092448594],
            [61.28399091994504, 41.296161810718957],
            [61.268746371647751, 41.301872056949833],
            [61.256912468935241, 41.331043403030606],
            [61.244200067079419, 41.345538642494347],
            [61.191128370677745, 41.390006211917125],
            [61.172266473019363, 41.398351956477626],
            [61.114388868851165, 41.406775214504506],
            [61.091806268144808, 41.413596502976191],
            [61.060955438142912, 41.434628810970878],
            [61.025040317956268, 41.465066229822696],
            [61.01330976803132, 41.470802314475293],
            [61.000804070851132, 41.474807237464006],
            [60.975379266240168, 41.478812161352181],
            [60.738494501020455, 41.639525865526224],
            [60.706093378306775, 41.667146917995893],
            [60.680306837590479, 41.701589259836425],
            [60.663305291905658, 41.740424099192808],
            [60.651988153130731, 41.756883043417673],
            [60.636226840896029, 41.763652655945293],
            [60.588271112421864, 41.76478953750717],
            [60.567342157214796, 41.768768622074248],
            [60.549978876324076, 41.777321072209702],
            [60.535922886432047, 41.790731106055659],
            [60.507914260334815, 41.828454902271972],
            [60.496907179922459, 41.854034736513853],
            [60.469880405756157, 41.885092271191411],
            [60.461818882035743, 41.900853583426141],
            [60.462438998760831, 41.945992947316682],
            [60.458976678131137, 41.966637682582956],
            [60.444765658608219, 41.975319322128968],
            [60.395311314265712, 41.979350084438863],
            [60.345133497712709, 41.988961899971684],
            [60.334436475662869, 41.993251043800683],
            [60.313094110205043, 42.005575872928176],
            [60.296867709976823, 42.003431301013734],
            [60.290976596592714, 42.000847480426813],
            [60.271907993359207, 41.988290107302646],
            [60.220851677662836, 41.96152171555471],
            [60.200232781717546, 41.955062161389662],
            [60.173050977920241, 41.954287014134366],
            [60.157393019372364, 41.951961575066576],
            [60.150571730001388, 41.947930812756795],
            [60.147626174208597, 41.943357448987058],
            [60.149176466920437, 41.938887438004798],
            [60.151191847625682, 41.93439158770164],
            [60.162819044763125, 41.917390042016763],
            [60.164834426367634, 41.906331284760967],
            [60.152897169968469, 41.884007066472947],
            [60.141166620043464, 41.874240221309122],
            [60.14114411615401, 41.874221483967233],
            [60.118221477000077, 41.900440979000024],
            [60.088765910000063, 41.919354553000062],
            [59.983656046000135, 41.949042663000029],
            [59.931772908000141, 41.947647400000051],
            [59.917510213000071, 41.95242747100005],
            [59.90645145700006, 41.966612651000105],
            [59.90800174900005, 41.979815979000108],
            [59.919267212000136, 41.989660340000086],
            [59.936940551000134, 41.99348439500011],
            [59.937250611000138, 41.993406881000084],
            [59.937560669000106, 41.993406881000084],
            [59.937870727000075, 41.993406881000084],
            [59.997195272000113, 42.010615133000073],
            [59.994094686000096, 42.050095928000104],
            [59.974457642000118, 42.099240215000037],
            [59.983656046000135, 42.145593974000036],
            [60.000089152000101, 42.154508159000073],
            [60.020553020000136, 42.160786845000075],
            [60.036986124000123, 42.169701029000095],
            [60.04039676900004, 42.186470032000045],
            [60.028201131000117, 42.199518332000082],
            [60.005050090000054, 42.209776103000081],
            [59.960711711000101, 42.222721049000086],
            [59.925675090000084, 42.246285502],
            [59.897873169000036, 42.27853159600005],
            [59.866350545000046, 42.304214783000134],
            [59.820978638000042, 42.307987162000074],
            [59.776330200000075, 42.303956401000079],
            [59.689513794000106, 42.30979583800007],
            [59.478157186000089, 42.285637106000081],
            [59.426480754000124, 42.286773987000089],
            [59.401469360000135, 42.293905335000034],
            [59.361575154000093, 42.325789694],
            [59.338114055000119, 42.33180999800004],
            [59.287161093000066, 42.335944113000068],
            [59.258428996000077, 42.347545471000018],
            [59.245199829000114, 42.365348003000065],
            [59.233314250000035, 42.441725769000101],
            [59.223805786000071, 42.460871888000099],
            [59.209646444000043, 42.477718405000076],
            [59.146084432000066, 42.526759339000108],
            [59.12200321500012, 42.537998962000074],
            [59.096475057000077, 42.535983582000114],
            [59.067432902000064, 42.525570780000095],
            [59.038597453000136, 42.519731344000093],
            [59.009245239000052, 42.518465271000053],
            [58.96470015500006, 42.52438222300006],
            [58.894420207000053, 42.547843323000052],
            [58.827447550000102, 42.593757833000012],
            [58.612266887000033, 42.780852356000139],
            [58.587875611000072, 42.791187643000058],
            [58.569375447000141, 42.76850168900009],
            [58.555732870000043, 42.683597310000039],
            [58.534545532000095, 42.654503480000102],
            [58.493204386000059, 42.645460104000051],
            [58.441838013000108, 42.647837220000099],
            [58.390574992000097, 42.65667389],
            [58.282674602000043, 42.688919983000062],
            [58.258283325000036, 42.689514262000046],
            [58.239576457000055, 42.685147604000036],
            [58.186556437000036, 42.661195578000047],
            [58.148832642000116, 42.648999939000106],
            [58.130642537000142, 42.638845520000075],
            [58.121340780000139, 42.624246928000105],
            [58.127025187000072, 42.604997457000053],
            [58.145732056000043, 42.592569276000091],
            [58.270995728000116, 42.549031881000047],
            [58.306239054000059, 42.528671367000129],
            [58.337038208000138, 42.493712260000024],
            [58.363703247000103, 42.447074280000066],
            [58.437910604000137, 42.357699890000077],
            [58.478218221000077, 42.322327372000075],
            [58.489587036000103, 42.314524231000036],
            [58.494134562000113, 42.305713399000041],
            [58.490620565000143, 42.297574361000045],
            [58.478218221000077, 42.291993307000013],
            [58.413932739000131, 42.284758607000043],
            [58.397602987000084, 42.287859192000099],
            [58.385820760000115, 42.301320903000061],
            [58.386130818000083, 42.316849671000085],
            [58.391505168000037, 42.333980408000031],
            [58.394915813000068, 42.352248027000087],
            [58.384683879000079, 42.387827251000061],
            [58.358949016000111, 42.413407085000102],
            [58.325049276000072, 42.431855570000039],
            [58.225727173000109, 42.466582133000145],
            [58.203816366000126, 42.469501851000047],
            [58.185729614000138, 42.46725392700003],
            [58.149452759000042, 42.455678406000089],
            [58.133226360000037, 42.454567362000091],
            [58.117206665000083, 42.461517843000081],
            [58.094365682000102, 42.486710104000025],
            [58.07989628100006, 42.497303772000095],
            [58.060879354000122, 42.502264709000144],
            [58.038245077000056, 42.502781474000102],
            [58.016024210000126, 42.499706726000056],
            [57.997730753000042, 42.49342804000014],
            [57.987085408000041, 42.483428650000036],
            [57.978300415000092, 42.47177561500007],
            [57.952772258000039, 42.450071513000069],
            [57.925590454000144, 42.441105653000136],
            [57.908640584000125, 42.430434469000033],
            [57.914324992000104, 42.403950297000051],
            [57.935719035000091, 42.36981801400006],
            [57.937476033000053, 42.354237570000123],
            [57.928794393000032, 42.330931498000098],
            [57.888073364000036, 42.261581727000049],
            [57.873707316000036, 42.249644470000106],
            [57.835466757000063, 42.240446066000118],
            [57.82730188000005, 42.227371928000053],
            [57.834536581000123, 42.211636454000086],
            [57.847352336000085, 42.20091359500006],
            [57.850452922000045, 42.191430969000095],
            [57.828438762000133, 42.179545390000072],
            [57.809215129000108, 42.176393128000115],
            [57.740898885000036, 42.178744405000145],
            [57.721571899000082, 42.175333761000019],
            [57.666278117000104, 42.155102438000114],
            [57.619769328000132, 42.148177796000141],
            [57.478279256000121, 42.166522930000042],
            [57.382264445000033, 42.163629049000036],
            [57.288213338000048, 42.129419251000058],
            [57.207804810000084, 42.070559794000047],
            [57.152924439000117, 41.993742778000069],
            [57.152924439000117, 41.99348439500011],
            [57.115510702000051, 41.952091573000075],
            [57.074376261000054, 41.928785503000114],
            [56.978361450000079, 41.895118306000086],
            [56.950146118000077, 41.866050314000091],
            [56.94983605900012, 41.827499695000114],
            [56.971953573000121, 41.748538106000069],
            [56.969473104000144, 41.710891825000033],
            [56.961618286000032, 41.678723247000079],
            [56.96048140500011, 41.647200623000117],
            [56.978361450000079, 41.611466370000102],
            [57.000582316000134, 41.584026184000066],
            [57.012054484000032, 41.557826233000043],
            [57.015775187000031, 41.528990784000115],
            [57.015051717000119, 41.493644104000111],
            [57.019599243000073, 41.465945537000067],
            [57.033035115000132, 41.438246969000062],
            [57.052258748000042, 41.413597311000046],
            [57.074169555000083, 41.39499379500009],
            [57.088328898000043, 41.387294007],
            [57.103625122000096, 41.381609599000058],
            [57.153751261000139, 41.372359518000053],
            [57.161399374000098, 41.367967021000055],
            [57.159228963000032, 41.361145732000097],
            [57.147963501000049, 41.351068827000063],
            [57.126672811000049, 41.339596660000041],
            [57.08006066900009, 41.322026672000078],
            [57.060320272000126, 41.304921774000036],
            [57.029521118000048, 41.262598776000118],
            [57.010194132000038, 41.254123841000109],
            [56.887927694000098, 41.273347474000047],
            [56.793463176000046, 41.288333639000115],
            [56.570014282000102, 41.297480367000105],
            [56.533721316142987, 41.298968795186852],
            [56.326824992000127, 41.307453919000096],
            [56.140686483000081, 41.315153707],
            [55.978422486000056, 41.321716614000081],
            [55.978215779000038, 41.551263326000054],
            [55.978009074000056, 41.780680848000088],
            [55.977905721000127, 42.010150045000046],
            [55.977699015000098, 42.239619243000078],
            [55.977595663000045, 42.469088440000036],
            [55.977388957000073, 42.698531799000079],
            [55.977285604000087, 42.928000997000112],
            [55.977078898000116, 43.157470194000069],
            [55.976975545000073, 43.386913555000035],
            [55.976768839000044, 43.616382752000078],
            [55.976665487000105, 43.84585195000011],
            [55.976458781000133, 44.075295309000069],
            [55.976355428000033, 44.304842021000127],
            [55.976148723000051, 44.534285381000075],
            [55.976045370000122, 44.763780416000117],
            [55.975838663000104, 44.993223776000065],
            [55.975838663000104, 44.993275452000063],
            [55.975838663000104, 44.993378804000088],
            [55.975838663000104, 44.993430481000104],
            [55.975838663000104, 44.99348215700013],
            [55.975838663000104, 44.993533834000061],
            [55.975838663000104, 44.993585511000049],
            [55.975838663000104, 44.993688864000092],
            [55.975838663000104, 44.99374054],
            [55.975838663000104, 44.993843892000115],
            [55.975838663000104, 44.993895569000045],
            [55.975838663000104, 44.99399892200006],
            [55.975838663000104, 44.994050598000086],
            [55.975838663000104, 44.994153952000033],
            [55.975838663000104, 44.994205628000117],
            [55.975838663000104, 44.994257305000133],
            [55.975838663000104, 44.994308980000056],
            [55.975838663000104, 44.994360657000072],
            [55.975838663000104, 44.994412333000071],
            [55.975942017000079, 44.994464010000087],
            [55.976045370000122, 44.994515686000113],
            [55.976045370000122, 44.994567363000044],
            [55.976045370000122, 44.994619039000042],
            [55.976045370000122, 44.994670716000144],
            [55.976045370000122, 44.994774068000083],
            [55.976148723000051, 44.994774068000083],
            [55.976148723000051, 44.994825745000099],
            [55.976148723000051, 44.994929098000028],
            [55.976148723000051, 44.994980774000055],
            [55.976148723000051, 44.995032451000043],
            [55.976148723000051, 44.995084127000069],
            [55.976252075000104, 44.995135804000085],
            [55.976355428000033, 44.995187480000084],
            [55.976355428000033, 44.99523915600011],
            [55.976458781000133, 44.995290832000109],
            [55.976458781000133, 44.995342509000039],
            [55.976562134000062, 44.995394186000055],
            [55.976665487000105, 44.995445862000054],
            [55.976768839000044, 44.99549753900007],
            [55.976768839000044, 44.995549215000096],
            [55.976768839000044, 44.995600892000112],
            [55.976872192000144, 44.995600892000112],
            [55.976975545000073, 44.995652568000111],
            [55.976975545000073, 44.995704244000137],
            [55.977078898000116, 44.99575592000005],
            [55.977182251000045, 44.995807597000066],
            [55.977285604000087, 44.995859273000065],
            [55.977388957000073, 44.995859273000065],
            [55.977388957000073, 44.995910950000081],
            [55.977492310000116, 44.995910950000081],
            [55.977595663000045, 44.995910950000081],
            [55.977699015000098, 44.995962627000011],
            [55.977699015000098, 44.996014303000038],
            [55.977802368000084, 44.996065980000139],
            [55.977905721000127, 44.996065980000139],
            [55.978009074000056, 44.996065980000139],
            [55.978009074000056, 44.996117656000138],
            [55.978112427000099, 44.996169332000051],
            [55.978215779000038, 44.996169332000051],
            [55.978319132000138, 44.996221008000077],
            [55.978422486000056, 44.996221008000077],
            [56.07206018100004, 45.017563375000066],
            [56.165801229000067, 45.03880238900004],
            [56.25943892500004, 45.060093079000126],
            [56.353386679000039, 45.081435445000125],
            [56.447127726000133, 45.102726136000115],
            [56.540868774000103, 45.124016826000087],
            [56.634506470000133, 45.14530751500007],
            [56.707609709910173, 45.161910897984825],
            [56.728247518000103, 45.16659820600006],
            [56.822091919000115, 45.187888896000061],
            [56.915729614000043, 45.20912791000012],
            [57.009470663000116, 45.230418599000103],
            [57.103211710000096, 45.251760967000109],
            [57.197056112000098, 45.272999980000066],
            [57.290797160000068, 45.294342347000082],
            [57.384538208000038, 45.315633037000055],
            [57.478279256000121, 45.336923726000038],
            [57.603232870000056, 45.363898825000064],
            [57.728186483000115, 45.390925599000127],
            [57.853243449000104, 45.417900696000061],
            [57.978300415000092, 45.444927470000096],
            [58.036074666000047, 45.457071431000088],
            [58.093848918000049, 45.469112041000074],
            [58.151623169000061, 45.481204326000039],
            [58.209604126000102, 45.49334828800005],
            [58.269755493000048, 45.505543926000058],
            [58.33001021400014, 45.517842916000092],
            [58.390264934000072, 45.530141907000143],
            [58.450416301000132, 45.542389222000082],
            [58.531444947000068, 45.558718974000016],
            [58.554906046000042, 45.557168681000093],
            [58.582191203000036, 45.548693746000083],
            [58.65898238200009, 45.512261861000098],
            [58.680789836000031, 45.501823223000144],
            [58.743111613000053, 45.472264303000117],
            [58.840470011000036, 45.426013896000143],
            [58.967904094000062, 45.365449117000082],
            [59.055132166208296, 45.324023174864891],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "UZB-357",
        OBJECTID_1: 404,
        diss_me: 357,
        adm1_cod_1: "UZB-357",
        iso_3166_2: "UZ-",
        wikipedia: null,
        iso_a2: "UZ",
        adm0_sr: 1,
        name: "Navoi",
        name_alt: "Navoiy|Navoiy",
        name_local: null,
        type: "Wiloyat",
        type_en: "Region",
        code_local: null,
        code_hasc: "UZ.NW",
        note: null,
        hasc_maybe: null,
        region: null,
        region_cod: null,
        provnum_ne: 10,
        gadm_level: 1,
        check_me: 0,
        scalerank: 4,
        datarank: 6,
        abbrev: null,
        postal: "NW",
        area_sqkm: 0,
        sameascity: 6,
        labelrank: 6,
        featurecla: "Admin-1 scale rank",
        name_len: 5,
        mapcolor9: 5,
        mapcolor13: 4,
        fips: "UZ07",
        fips_alt: null,
        woe_id: 2347667,
        woe_label: "Nawoiy, UZ, Uzbekistan",
        woe_name: "Navoi",
        latitude: 42.172600000000003,
        longitude: 64.272199999999998,
        sov_a3: "UZB",
        adm0_a3: "UZB",
        adm0_label: 2,
        admin: "Uzbekistan",
        geonunit: "Uzbekistan",
        gu_a3: "UZB",
        gn_id: 1484841,
        gn_name: "Navoiy Province",
        gns_id: -3431452,
        gns_name: "Navoiy Viloyati",
        gn_level: 1,
        gn_region: null,
        gn_a1_code: "UZ.07",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "vie",
        gns_adm1: "UZ07",
        gns_region: null,
        color: "#0000FF",
        jurisdiction_id: 263,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [65.812602580000089, 42.875756124000134],
            [65.9569348550001, 42.933013611000092],
            [66.101267131000043, 42.990322775000067],
            [66.079718059000072, 42.741940003000082],
            [66.058168986000112, 42.493608907000095],
            [66.055688517000135, 42.46725392700003],
            [66.053208048000045, 42.44087310900008],
            [66.048143758000094, 42.432268983000114],
            [66.04318282100013, 42.423664856000102],
            [66.031400594000047, 42.42025421200006],
            [66.019618367000135, 42.416843567000058],
            [66.016724487000033, 42.409944763000055],
            [66.013727254000059, 42.403020121000083],
            [66.013159364206615, 42.372869945895701],
            [66.010058228000048, 42.208225810000073],
            [66.006492553000101, 42.013431499000035],
            [66.00752608200014, 42.008057149000081],
            [66.008662964000052, 42.002656962000088],
            [66.012900432000038, 42.000073141000087],
            [66.017241251000087, 41.997618510000052],
            [66.137647339000125, 41.99723093700004],
            [66.247356405000119, 41.99692087800014],
            [66.356600382000124, 41.996636658000057],
            [66.477368205000118, 41.996223247000046],
            [66.481192261000047, 41.996636658000057],
            [66.484809611000117, 41.997024231000069],
            [66.488633667000101, 41.99689504000014],
            [66.492406047000088, 41.996791687000012],
            [66.495765015000131, 41.996171571000048],
            [66.499175659000059, 41.995603130000092],
            [66.501656128000036, 41.994517924000093],
            [66.50413659700007, 41.99348439500011],
            [66.521448202000101, 41.867109680000084],
            [66.53886315900013, 41.740760804000047],
            [66.570695841000088, 41.617305807],
            [66.602631877000135, 41.493799134000142],
            [66.645523315000105, 41.346469625000111],
            [66.688208049000082, 41.199191793000097],
            [66.710738974000037, 41.178624573],
            [66.732959839000046, 41.158057353000117],
            [66.757890443844587, 41.153140136204144],
            [66.757506952084213, 41.149736640433616],
            [66.635188836795351, 41.112167873848279],
            [66.626817254712478, 41.096561591244466],
            [66.614259880688962, 41.084159246851755],
            [66.631313104116487, 40.94473623309068],
            [66.633018425559897, 40.939258530856478],
            [66.635498895157866, 40.934659329564397],
            [66.641079950179574, 40.926132716951372],
            [66.649503208206454, 40.91838125339217],
            [66.655549351221623, 40.915099799016559],
            [66.669346957795881, 40.909286200897469],
            [66.759780715207796, 40.890579331970571],
            [66.765258417441999, 40.887246202550216],
            [66.768824089959764, 40.88344798423708],
            [66.77083947066501, 40.878874620467371],
            [66.771304558658471, 40.871562405580534],
            [66.770994501195219, 40.861718045151633],
            [66.768514031597306, 40.843760484159048],
            [66.768514031597306, 40.82696564404921],
            [66.770219353940035, 40.822366440958461],
            [66.779366083278148, 40.804925645702014],
            [66.801225212673387, 40.775211697262051],
            [66.77316491063209, 40.721907456863619],
            [66.620150994972391, 40.49509959606948],
            [66.600617302846274, 40.50972402584307],
            [66.589248488127168, 40.521196194248972],
            [66.585837844341029, 40.525847073283757],
            [66.583667434004894, 40.530136217112755],
            [66.582582228387139, 40.533753567373225],
            [66.582737258018085, 40.538249416777205],
            [66.588628371402194, 40.554088243377691],
            [66.589455193702236, 40.56351919175728],
            [66.588008253777843, 40.56793752679539],
            [66.585217726716678, 40.572019965049321],
            [66.551111280760949, 40.602018134329398],
            [66.522999301876268, 40.620931707931987],
            [66.511682163101341, 40.625634263810184],
            [66.502845493025063, 40.626616115741143],
            [66.493388706223755, 40.623722235892416],
            [66.421196729745304, 40.586205146150604],
            [66.397787306738849, 40.578040270542033],
            [66.377943557149479, 40.575973212094055],
            [66.361665480077875, 40.577756048802627],
            [66.343940463981198, 40.5832079135144],
            [66.325802035835238, 40.590985216394586],
            [66.305131463046564, 40.602741603841963],
            [66.290248650854551, 40.60821930607608],
            [66.276140985018401, 40.60956289321291],
            [66.262963495169174, 40.606203925370835],
            [66.250664503563996, 40.599253444790691],
            [66.239037307325816, 40.589150701943311],
            [66.229890577987817, 40.577781887224347],
            [66.221674024636513, 40.565172838155945],
            [66.208806594049065, 40.537939358414661],
            [66.20586103735701, 40.522178046179931],
            [66.205550978095175, 40.505796617220113],
            [66.214387648171453, 40.488381660385301],
            [66.221363967173318, 40.479700019040621],
            [66.225859815677893, 40.47163849532032],
            [66.225239698952919, 40.463990382749955],
            [66.217333204863507, 40.45236318651186],
            [66.21004682839839, 40.447221381061922],
            [66.190616489959041, 40.440038357384282],
            [66.185655551662364, 40.434069728735011],
            [66.185345493299906, 40.426628323538353],
            [66.196197544081372, 40.402960517214041],
            [66.199814894341841, 40.367200425759023],
            [66.192786900295232, 40.345392971408529],
            [66.179299351184056, 40.322862047545442],
            [66.178059115935412, 40.31645416932443],
            [66.180539584634118, 40.309038600750853],
            [66.184105258950524, 40.304051825831095],
            [66.199504835979383, 40.294129951036439],
            [66.198264601630058, 40.284569811447653],
            [66.193303664232701, 40.277645169289144],
            [66.168292270771644, 40.250101630286053],
            [66.15961063032637, 40.237389228430146],
            [66.153409457680311, 40.224418443256582],
            [66.145657993221846, 40.201732489762605],
            [66.11971642377398, 40.155016994738531],
            [66.117701043967998, 40.147343045545114],
            [66.119561395042354, 40.141942856777405],
            [66.123902214815303, 40.140082505703049],
            [66.131188592179797, 40.139591580187243],
            [66.150153842625741, 40.142227078516868],
            [66.158990512702019, 40.142562975301047],
            [66.16844730040259, 40.141426092839879],
            [66.175733676867708, 40.138583888935301],
            [66.181624790251817, 40.13264110050639],
            [66.184260287682093, 40.126284898229528],
            [66.184105258950524, 40.118430080983472],
            [66.178989291922335, 40.110187690109825],
            [66.169997593114374, 40.10176443118354],
            [66.105453729206204, 40.055746568149601],
            [66.094343296006343, 40.051224881223305],
            [65.878594190669332, 40.015749009709026],
            [65.836322870004437, 40.022441107870861],
            [65.792914666878346, 40.043731798283858],
            [65.771985711671221, 40.038383287258853],
            [65.752452020444366, 40.046134751717403],
            [65.733486769099102, 40.057658596067284],
            [65.692920769877674, 40.06879486768895],
            [65.677262811329626, 40.081533107966578],
            [65.651579624300211, 40.109179998857854],
            [65.641502719874666, 40.110652778103173],
            [65.638195427976655, 40.111918849974955],
            [65.618351678387228, 40.114864407566415],
            [65.527091098675214, 40.146180325561517],
            [65.512518344845773, 40.146852118230669],
            [65.506007114736519, 40.144371650431339],
            [65.50011600135241, 40.139462388978046],
            [65.494069859236561, 40.130005602176766],
            [65.491279331276132, 40.123597723955754],
            [65.490504184920212, 40.118378404140032],
            [65.490659213651838, 40.10618276622165],
            [65.488798862577426, 40.085873927739527],
            [65.487403598597155, 40.080706284767246],
            [65.485543246623536, 40.076107083475165],
            [65.483682896448556, 40.072748114733685],
            [65.480427281393929, 40.068019721333059],
            [65.454795770308522, 40.038615831255612],
            [65.454020623952601, 40.032052924303059],
            [65.457431267738855, 40.028461412464196],
            [65.46425255710983, 40.02773794205234],
            [65.470970492793981, 40.026239326183969],
            [65.476551547815689, 40.023681342220129],
            [65.480737338857125, 40.019883123907022],
            [65.481977574105713, 40.015258084193221],
            [65.481202426850473, 40.009857896324888],
            [65.477481723802498, 40.001641343872933],
            [65.461617058780291, 39.975415554484243],
            [65.458826531719012, 39.969446925834887],
            [65.441773309190808, 39.917718818368826],
            [65.435262079081667, 39.905729885126021],
            [65.426580437737016, 39.901053168568893],
            [65.413506300675238, 39.897926743824115],
            [65.384980909741159, 39.897642523883377],
            [65.357075637330752, 39.900923977359611],
            [65.327258335204021, 39.911052557729306],
            [65.293565301297576, 39.925832018033162],
            [65.286278923933196, 39.926297105127247],
            [65.280077753085891, 39.924100857268741],
            [65.274806756426642, 39.917047023901034],
            [65.272326287728049, 39.911026720206962],
            [65.26860558467996, 39.893740952782679],
            [65.260234002597144, 39.887824001876112],
            [65.252637566870192, 39.883612372413069],
            [65.192434522735027, 39.8714684113381],
            [65.143083531180025, 39.866171576257244],
            [65.134040154629474, 39.864388740447964],
            [65.127735630095231, 39.861701565275013],
            [65.123188103847895, 39.858032538171102],
            [65.121482782404485, 39.853174954460542],
            [65.129079217232061, 39.840540066071213],
            [65.158896519358905, 39.814520982257477],
            [65.178740268948275, 39.800878404414789],
            [65.186026646312655, 39.794444687772142],
            [65.194759962702108, 39.785065416235994],
            [65.224990675978859, 39.700987861100856],
            [65.22623091032824, 39.693184718899658],
            [65.225300734341431, 39.678456936338506],
            [65.222975295273613, 39.669181016690573],
            [65.220339796944018, 39.66119700823532],
            [65.190884230023244, 39.60977895823234],
            [65.189954054935697, 39.603732815217199],
            [65.202201368798171, 39.574070542721358],
            [65.255583123562246, 39.491801662716512],
            [65.236617873116359, 39.482319037493511],
            [65.13781253362157, 39.464258123713421],
            [65.135332065822126, 39.492111721079056],
            [65.133626742580077, 39.499036363237565],
            [65.123653191841356, 39.525649726253846],
            [65.117452020094674, 39.536708481711088],
            [65.113421257784807, 39.542367051997843],
            [65.097453240874358, 39.559368598582012],
            [65.079624871990234, 39.573243720421317],
            [65.064535353323947, 39.579754951429805],
            [65.040660842323973, 39.58647288711407],
            [65.02138553171693, 39.59505117387252],
            [65.013324008895836, 39.615850937870448],
            [65.010068393841323, 39.637632554698598],
            [65.009758334579487, 39.653936469292631],
            [65.001541782127561, 39.67620901073721],
            [64.983248325249974, 39.699876817061437],
            [64.863927442597401, 39.74310415303421],
            [64.854935743789497, 39.754421291809109],
            [64.83984622602253, 39.75682424614206],
            [64.796386346052998, 39.741450507534921],
            [64.77700768355777, 39.742329007577666],
            [64.763055047352509, 39.747703355225639],
            [64.75948937393548, 39.75617829009596],
            [64.754373406907234, 39.765273341691369],
            [64.752978142926963, 39.771061103187321],
            [64.705952590439665, 39.791059882407609],
            [64.67815066901818, 39.805787664968761],
            [64.605545282288915, 39.883173122841328],
            [64.590042351573231, 39.907796943574084],
            [64.604150018308701, 39.933376776916703],
            [64.620428093581609, 39.96110118307314],
            [64.652880894037992, 39.992546292277524],
            [64.656446568354397, 39.996835436106522],
            [64.6641980319136, 40.003114122218989],
            [64.672104526902388, 40.016188259280767],
            [64.733186070181034, 40.037685655268803],
            [64.751117791852607, 40.047452501331918],
            [64.768791131105843, 40.060500799971976],
            [64.782588738579364, 40.075331936219897],
            [64.830027704015379, 40.14959096934777],
            [64.845272251413235, 40.159099432992491],
            [64.854212274277018, 40.159357815410885],
            [64.861757033160529, 40.163750312027275],
            [64.867958204907211, 40.167109279869351],
            [64.874779494278243, 40.168401191062145],
            [64.904700148293159, 40.171191718123282],
            [64.905940382642541, 40.174654038752863],
            [64.901754591601104, 40.181139431339659],
            [64.886406691415687, 40.196048081953421],
            [64.856951125394119, 40.218966580343363],
            [64.84558230977575, 40.233151760545297],
            [64.844187045795479, 40.237130846011752],
            [64.844807163419887, 40.239456285079456],
            [64.84961307208556, 40.240696520328186],
            [64.858501418105902, 40.247207750437326],
            [64.875089552640702, 40.284983221698326],
            [64.850129836023086, 40.304258531406049],
            [64.844807163419887, 40.323637193001986],
            [64.845272251413235, 40.332422187134227],
            [64.846822544125132, 40.341723945203881],
            [64.84961307208556, 40.351129055161749],
            [64.858811477367738, 40.359759020562365],
            [64.875399611003218, 40.366761176187367],
            [64.910177849628099, 40.371282864012954],
            [64.932657098446384, 40.371437892744581],
            [64.952035760042236, 40.367252101703173],
            [64.972241244837619, 40.353764553491374],
            [64.993635288038149, 40.342809149922317],
            [65.016424595218894, 40.337073066169097],
            [65.134040154629474, 40.336142890182202],
            [65.144788852623435, 40.352989407135368],
            [65.167423130173347, 40.407921453712078],
            [65.18592329262583, 40.42115062130415],
            [65.207782423819822, 40.440658474109284],
            [65.234447462780167, 40.504142970821448],
            [65.236152785122897, 40.516622830479236],
            [65.247521599841946, 40.529516100387667],
            [65.255273065199788, 40.534735419304099],
            [65.264729852001096, 40.536802476852785],
            [65.274651726795753, 40.536389064803473],
            [65.326948276841506, 40.527862453989002],
            [65.340280796321736, 40.527500718783131],
            [65.341055942677656, 40.538998724711377],
            [65.337335239629567, 40.550910142688963],
            [65.335164829293433, 40.571012274696642],
            [65.334854770930974, 40.59579112416111],
            [65.337025181267052, 40.622068590393127],
            [65.341831089033519, 40.647596746892305],
            [65.365447219413625, 40.709660143001173],
            [65.317491490040197, 40.699118150582166],
            [65.014409213614272, 40.715086168391963],
            [64.915758904649692, 40.733482978056941],
            [64.881135694756551, 40.7485208198799],
            [64.84651248576256, 40.770405789495527],
            [64.832404819926523, 40.799809679573059],
            [64.823258090588411, 40.826345527324207],
            [64.802019077018826, 40.926933701728984],
            [64.793285759729997, 40.950756536784723],
            [64.781193474599206, 40.951970934511024],
            [64.749102411147362, 40.917502753349396],
            [64.717114698684384, 40.907012436874453],
            [64.673499789983282, 40.900139472458648],
            [64.541156447112371, 40.89564362215549],
            [64.507463413205926, 40.885928452935701],
            [64.488394809972533, 40.878228665320648],
            [64.458009067964156, 40.788880113526375],
            [64.435478143201692, 40.773015449403431],
            [64.164693637601317, 40.79748423870663],
            [64.122318963249597, 40.80900808395586],
            [64.108521355775906, 40.830608831832109],
            [64.106816034332553, 40.896418769410673],
            [64.101700067304307, 40.930473538522989],
            [64.08123620009053, 40.953521227222836],
            [64.061650832020291, 40.965975247559612],
            [64.042892287149357, 40.969799303395149],
            [64.022738478298152, 40.964528306735929],
            [64.002998080596967, 40.95246186002683],
            [63.985014682981273, 40.932798976691402],
            [63.925586786101292, 40.838644518030094],
            [63.854221632822089, 40.757951769158836],
            [63.735055779800348, 40.726248277535944],
            [63.736606073411508, 40.745781968762799],
            [63.699967481913745, 40.774048977278454],
            [63.677333205263267, 40.804305528077663],
            [63.376421340072795, 40.94468455714653],
            [63.222322218795341, 41.010313625773165],
            [63.090909051011863, 41.048941759554509],
            [63.002490676103776, 41.064212145374228],
            [62.978564488260474, 41.074599107262969],
            [62.843740675791537, 41.254613959566569],
            [62.675533888678046, 41.403829657812452],
            [62.650832553579448, 41.436799221307041],
            [62.6190515475908, 41.491782944727078],
            [62.534767286880765, 41.493281562394117],
            [62.180370314138713, 41.392305813462485],
            [61.751610956380489, 41.834190986025106],
            [61.743704462291078, 41.847781887024468],
            [61.743084343767464, 41.861011053717078],
            [61.764840122173894, 41.874731146824928],
            [61.979710728367422, 41.965139064915917],
            [61.991699659811445, 41.974647529459929],
            [61.995885450852882, 41.980021878007278],
            [61.97614505405096, 42.011182766371576],
            [61.862095168057579, 42.167426459563472],
            [61.859149611365467, 42.177012438473241],
            [61.859149611365467, 42.189311428279836],
            [61.881628859284376, 42.396895657108843],
            [61.936715936391295, 42.492186998231404],
            [62.193082716893912, 42.855058905843805],
            [62.465417515206184, 43.231211656093024],
            [62.470843539697569, 43.24276133886471],
            [62.470998570227778, 43.2527607289245],
            [62.455805698773986, 43.25927195903364],
            [62.185331252435333, 43.313945624091332],
            [62.135205112725714, 43.347742010785169],
            [62.028320515889732, 43.480922383130689],
            [62.110916382000141, 43.491920065000045],
            [62.146056356000088, 43.496674297000141],
            [62.181403035000073, 43.501428529000123],
            [62.216749716000095, 43.506156922000116],
            [62.251889689000109, 43.510859477000082],
            [62.308423706000099, 43.518404236000038],
            [62.364854370000103, 43.526000671000105],
            [62.42128503400005, 43.533597107000077],
            [62.477715698000111, 43.541116029000108],
            [62.568976278000093, 43.551890564000132],
            [62.660236857000086, 43.562716776000087],
            [62.751497437000069, 43.573491313],
            [62.842861369000104, 43.584291688000036],
            [62.934121949000087, 43.59506622300006],
            [63.02538252800008, 43.60584076],
            [63.116643107000073, 43.616615296000134],
            [63.208007040000098, 43.627467347000078],
            [63.360349162000091, 43.617416281000061],
            [63.512691284000084, 43.607261861000097],
            [63.665136760000109, 43.597288310000067],
            [63.81737552900006, 43.58723724400005],
            [63.955920044000038, 43.578064678000061],
            [64.094671265000102, 43.568917949000081],
            [64.233164103000036, 43.559719544000075],
            [64.371656942000072, 43.550598654000083],
            [64.426744018000136, 43.547032979000022],
            [64.444210653000084, 43.550676168000109],
            [64.461573934000114, 43.554319357000111],
            [64.562136272000032, 43.594807842000108],
            [64.658357788000103, 43.633591003000049],
            [64.757628214000135, 43.673614400000105],
            [64.855038290000039, 43.712914328000096],
            [64.873073364000106, 43.717823588000044],
            [64.891005086000064, 43.722784525000066],
            [64.907024781000132, 43.720975850000087],
            [64.922941122000054, 43.719115499000083],
            [64.939580933000116, 43.708289287000127],
            [64.956530803000078, 43.697359721000055],
            [65.064224487000047, 43.595557150000104],
            [65.171918172000119, 43.493677063000121],
            [65.221734253000136, 43.455281474000088],
            [65.271446981000054, 43.417015076000098],
            [65.374489787000073, 43.369912008000085],
            [65.477377563000118, 43.322808940000101],
            [65.49716963700007, 43.310019023000024],
            [65.516910034000034, 43.297229106000145],
            [65.601866089000055, 43.169019877000011],
            [65.656126343000096, 43.08721608500008],
            [65.731057170000042, 42.974225566000086],
            [65.79523929800007, 42.877203065000117],
            [65.804024292000065, 42.876479594000045],
            [65.812602580000089, 42.875756124000134],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "UZB-358",
        OBJECTID_1: 405,
        diss_me: 358,
        adm1_cod_1: "UZB-358",
        iso_3166_2: "UZ-",
        wikipedia: null,
        iso_a2: "UZ",
        adm0_sr: 1,
        name: "Samarkand",
        name_alt: "Samarqand",
        name_local: null,
        type: "Wiloyat",
        type_en: "Region",
        code_local: null,
        code_hasc: "UZ.SA",
        note: null,
        hasc_maybe: null,
        region: null,
        region_cod: null,
        provnum_ne: 4,
        gadm_level: 1,
        check_me: 0,
        scalerank: 4,
        datarank: 6,
        abbrev: null,
        postal: "SA",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 4,
        featurecla: "Admin-1 scale rank",
        name_len: 9,
        mapcolor9: 5,
        mapcolor13: 4,
        fips: "UZ10",
        fips_alt: null,
        woe_id: 20070173,
        woe_label: "Samarqand, UZ, Uzbekistan",
        woe_name: "Samarkand",
        latitude: 39.992600000000003,
        longitude: 66.312600000000003,
        sov_a3: "UZB",
        adm0_a3: "UZB",
        adm0_label: 2,
        admin: "Uzbekistan",
        geonunit: "Uzbekistan",
        gu_a3: "UZB",
        gn_id: 1114927,
        gn_name: "Samarqand Viloyati",
        gns_id: -3431456,
        gns_name: "Samarqand Viloyati",
        gn_level: 1,
        gn_region: null,
        gn_a1_code: "UZ.10",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "vie",
        gns_adm1: "UZ10",
        gns_region: null,
        color: "#800080",
        jurisdiction_id: 266,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [66.620150994972391, 40.49509959606948],
            [66.62805748906186, 40.49065542350894],
            [66.754096307398697, 40.487063909871523],
            [66.795644158551113, 40.477839667067002],
            [66.811767205991714, 40.474429023280749],
            [66.82794192937655, 40.47256867130713],
            [66.850731235658031, 40.473395494506462],
            [66.877137893099246, 40.477736314279525],
            [66.883029005584092, 40.478149726328809],
            [66.888455030974853, 40.475204168737378],
            [66.893570998003099, 40.468822129837463],
            [66.901322463360941, 40.456187242347312],
            [66.905198195140542, 40.446627101859264],
            [66.907368604577414, 40.437118639113834],
            [66.906283399858978, 40.42337270848364],
            [66.900547316105758, 40.408799953754851],
            [66.89186567566037, 40.39355540635691],
            [66.889850294955124, 40.387612617028708],
            [66.888765090236689, 40.381308092494464],
            [66.888455030974853, 40.374564317489188],
            [66.89016035331764, 40.327538764102684],
            [66.89248579328472, 40.310769762414537],
            [66.906593459120813, 40.258912461940525],
            [66.908918898188631, 40.253770657389964],
            [66.912329542874147, 40.248654690361633],
            [66.920236036963502, 40.24436554653272],
            [66.940751581020606, 40.236769110805881],
            [66.94571251841802, 40.233255113332774],
            [66.949123163103536, 40.228914293559825],
            [66.94819298711667, 40.221886297714434],
            [66.945867547149589, 40.216046861173737],
            [66.945402460055504, 40.208760483809243],
            [66.948658075110075, 40.20075063693227],
            [66.975943230795508, 40.175041612380454],
            [66.97532311407042, 40.15189057089313],
            [66.970982294297471, 40.14284719524187],
            [66.96803673670604, 40.139617417709701],
            [66.962920769677794, 40.136413479498515],
            [66.94416222480686, 40.131633409254448],
            [66.938891229046988, 40.129230454921498],
            [66.936255730717448, 40.124657091151761],
            [66.935480585260848, 40.119308580126841],
            [66.939976433765423, 40.074350084288938],
            [66.94323204882005, 40.069259954783121],
            [66.947882927854835, 40.064893297487743],
            [66.959355096260651, 40.062722887151466],
            [66.967881707974414, 40.062309475102182],
            [66.975788202063882, 40.063291327033141],
            [66.981989373810563, 40.064841619744925],
            [67.001109653887397, 40.072283026740251],
            [67.006070591284811, 40.073264879570473],
            [67.012426791763005, 40.073445745824472],
            [67.020178257120847, 40.072515570737011],
            [67.027154575223449, 40.068665676479753],
            [67.032115512620862, 40.062464504733072],
            [67.032890658976783, 40.048821925991092],
            [67.029790073553045, 40.042259019937802],
            [67.030100131915503, 40.034068305008219],
            [67.031650424627344, 40.02877147082674],
            [67.066428664151488, 39.99910919923019],
            [67.079916213262663, 40.003036606953827],
            [67.084412061767239, 40.003605047734766],
            [67.089062940802023, 40.004948634871624],
            [67.100896844413796, 39.989549058742028],
            [67.179703403789006, 39.97285757141978],
            [67.191175572194936, 39.971694851436183],
            [67.207195265948769, 39.972469997792189],
            [67.214171584051314, 39.97399445208228],
            [67.220992873422347, 39.974330348866545],
            [67.237270948695311, 39.966242988523078],
            [67.24424726769729, 39.963607490193539],
            [67.253393996136026, 39.962573961419167],
            [67.263212518143177, 39.965416165323745],
            [67.286776970780579, 39.963116562879094],
            [67.459944696190746, 39.938182685582461],
            [67.476687860356435, 39.917460435950318],
            [67.503714633623474, 39.824236152376528],
            [67.499838901843873, 39.81638133423121],
            [67.484491000759078, 39.81315155669904],
            [67.476687860356435, 39.812686468705579],
            [67.449712762134311, 39.808009752148365],
            [67.428163690202098, 39.80191193318916],
            [67.397106153725986, 39.789251207277317],
            [67.381603224808941, 39.780414537201125],
            [67.373490025144577, 39.774213365454443],
            [67.371526320383396, 39.769975898468971],
            [67.36945926283471, 39.763025417888713],
            [67.368374058116274, 39.748375148794054],
            [67.368839146109735, 39.739512641195432],
            [67.37023440919063, 39.732613837458615],
            [67.371939732432679, 39.727575384796154],
            [67.374316848343824, 39.717265937273197],
            [67.374626905807077, 39.711633206307454],
            [67.373800082607772, 39.699721788329896],
            [67.371629673170844, 39.69359813094897],
            [67.367288853397895, 39.689050604701578],
            [67.358297153690671, 39.686208400797085],
            [67.349925571607855, 39.68527822481019],
            [67.319643182386926, 39.684554755297711],
            [67.306000603644975, 39.682177639386481],
            [67.300264519891641, 39.672720851685909],
            [67.297008904837128, 39.664866034439967],
            [67.294838495400199, 39.595619615552835],
            [67.305070427658052, 39.579031480118772],
            [67.319229771236905, 39.566164049531238],
            [67.360932652020324, 39.537586981753861],
            [67.436482787925229, 39.485704651174302],
            [67.427284383000085, 39.476609599000057],
            [67.414778686000034, 39.438110657000095],
            [67.408164103000104, 39.397906393000085],
            [67.407078899000112, 39.361009420000045],
            [67.394418173000076, 39.327368063000108],
            [67.386873413000046, 39.294191793000095],
            [67.380258830000116, 39.292331441000101],
            [67.371267130000092, 39.294501851000092],
            [67.360621785000092, 39.291763],
            [67.358697241325899, 39.29043062335947],
            [67.119655389284787, 39.337159939079953],
            [67.066428664151488, 39.339097805419414],
            [67.066118604889652, 39.334007676812803],
            [67.06766889760155, 39.324163316383931],
            [67.067823928131759, 39.31915070214319],
            [67.067048780876576, 39.314112250380077],
            [67.064878371439704, 39.30891876898599],
            [67.061157668391729, 39.304603786735385],
            [67.056351759725942, 39.30121898047156],
            [67.050925734335124, 39.299151922922874],
            [67.038833449204333, 39.297136542217629],
            [66.92834923662798, 39.293777574375525],
            [66.91403486521682, 39.296051337499193],
            [66.90674848785244, 39.298247586257162],
            [66.900237257743242, 39.301063950840671],
            [66.894656202721535, 39.304448757104467],
            [66.890780470941934, 39.310365708910325],
            [66.889385206961776, 39.318478909474123],
            [66.891090529304506, 39.333775132816129],
            [66.8974467297827, 39.361551214916631],
            [66.893726026734726, 39.375478014498725],
            [66.873727248413729, 39.405088609251948],
            [66.852281529269192, 39.407620754793982],
            [66.767273797247981, 39.420643215012348],
            [66.688570590660277, 39.430565089807061],
            [66.675393100810993, 39.430461737918876],
            [66.657409702295865, 39.426379298765653],
            [66.641855095636117, 39.419635525559045],
            [66.633638544083567, 39.414312852955817],
            [66.627282341806676, 39.409041856296597],
            [66.59488121909294, 39.370672104933675],
            [66.585372756347681, 39.363540758099447],
            [66.510596958382905, 39.340131334193728],
            [66.502535434662548, 39.33956289341279],
            [66.489512974444267, 39.34225006768645],
            [66.473493279791057, 39.34863210748577],
            [66.414375441273648, 39.385348212450026],
            [66.388795607031625, 39.397853908730866],
            [66.369882033429064, 39.396975408688121],
            [66.225549758214754, 39.371137192927108],
            [66.220588819918135, 39.370904648930434],
            [66.125142450064061, 39.334343574496359],
            [66.104213494856936, 39.323698229289846],
            [66.088555536308945, 39.319615790136623],
            [66.052847120797992, 39.319538275770867],
            [65.985977817822004, 39.333465074453585],
            [65.979466586813487, 39.337237454345086],
            [65.974919060566208, 39.343361110826606],
            [65.972645298341831, 39.354859116754881],
            [65.971818475142527, 39.362352199694243],
            [65.971715122355022, 39.369018460333649],
            [65.973058710391115, 39.384753933247396],
            [65.972748651129393, 39.400153510276255],
            [65.969648064806336, 39.406251329235431],
            [65.966702508114281, 39.410902208270301],
            [65.941432733134093, 39.425914212570859],
            [65.911770460638195, 39.441830553537216],
            [65.859473910592442, 39.481311347140917],
            [65.848621860710352, 39.492990221121801],
            [65.843195835319534, 39.500276598486209],
            [65.843195835319534, 39.5004833031619],
            [65.842730747326129, 39.501000067998689],
            [65.840405308258312, 39.506141873448627],
            [65.830793490926851, 39.538672187371475],
            [65.828313023127521, 39.542987168722817],
            [65.824902378442061, 39.546992091711616],
            [65.82040652903811, 39.550402737296423],
            [65.815290562009864, 39.552934881939279],
            [65.80877933100129, 39.554691881125422],
            [65.799270868255917, 39.555880439530625],
            [65.767799920629841, 39.556758937774731],
            [65.756947869848261, 39.55528616032808],
            [65.748576287765502, 39.550816148446444],
            [65.746509230216816, 39.545855211049115],
            [65.745579055129269, 39.54050670002411],
            [65.74640587832863, 39.525339666992039],
            [65.746095819066795, 39.520275376807149],
            [65.744648879142403, 39.515211087521692],
            [65.741238233557681, 39.510792752483496],
            [65.736742385052992, 39.506994534170389],
            [65.715813429845866, 39.495108953715174],
            [65.694212681070383, 39.486323961381686],
            [65.682585483932883, 39.483378403790255],
            [65.255583123562246, 39.491801662716512],
            [65.202201368798171, 39.574070542721358],
            [65.189954054935697, 39.603732815217199],
            [65.190884230023244, 39.60977895823234],
            [65.220339796944018, 39.66119700823532],
            [65.222975295273613, 39.669181016690573],
            [65.225300734341431, 39.678456936338506],
            [65.22623091032824, 39.693184718899658],
            [65.224990675978859, 39.700987861100856],
            [65.194759962702108, 39.785065416235994],
            [65.186026646312655, 39.794444687772142],
            [65.178740268948275, 39.800878404414789],
            [65.158896519358905, 39.814520982257477],
            [65.129079217232061, 39.840540066071213],
            [65.121482782404485, 39.853174954460542],
            [65.123188103847895, 39.858032538171102],
            [65.127735630095231, 39.861701565275013],
            [65.134040154629474, 39.864388740447964],
            [65.143083531180025, 39.866171576257244],
            [65.192434522735027, 39.8714684113381],
            [65.252637566870192, 39.883612372413069],
            [65.260234002597144, 39.887824001876112],
            [65.26860558467996, 39.893740952782679],
            [65.272326287728049, 39.911026720206962],
            [65.274806756426642, 39.917047023901034],
            [65.280077753085891, 39.924100857268741],
            [65.286278923933196, 39.926297105127247],
            [65.293565301297576, 39.925832018033162],
            [65.327258335204021, 39.911052557729306],
            [65.357075637330752, 39.900923977359611],
            [65.384980909741159, 39.897642523883377],
            [65.413506300675238, 39.897926743824115],
            [65.426580437737016, 39.901053168568893],
            [65.435262079081667, 39.905729885126021],
            [65.441773309190808, 39.917718818368826],
            [65.458826531719012, 39.969446925834887],
            [65.461617058780291, 39.975415554484243],
            [65.477481723802498, 40.001641343872933],
            [65.481202426850473, 40.009857896324888],
            [65.481977574105713, 40.015258084193221],
            [65.480737338857125, 40.019883123907022],
            [65.476551547815689, 40.023681342220129],
            [65.470970492793981, 40.026239326183969],
            [65.46425255710983, 40.02773794205234],
            [65.457431267738855, 40.028461412464196],
            [65.454020623952601, 40.032052924303059],
            [65.454795770308522, 40.038615831255612],
            [65.480427281393929, 40.068019721333059],
            [65.483682896448556, 40.072748114733685],
            [65.485543246623536, 40.076107083475165],
            [65.487403598597155, 40.080706284767246],
            [65.488798862577426, 40.085873927739527],
            [65.490659213651838, 40.10618276622165],
            [65.490504184920212, 40.118378404140032],
            [65.491279331276132, 40.123597723955754],
            [65.494069859236561, 40.130005602176766],
            [65.50011600135241, 40.139462388978046],
            [65.506007114736519, 40.144371650431339],
            [65.512518344845773, 40.146852118230669],
            [65.527091098675214, 40.146180325561517],
            [65.618351678387228, 40.114864407566415],
            [65.638195427976655, 40.111918849974955],
            [65.641502719874666, 40.110652778103173],
            [65.651579624300211, 40.109179998857854],
            [65.677262811329626, 40.081533107966578],
            [65.692920769877674, 40.06879486768895],
            [65.733486769099102, 40.057658596067284],
            [65.752452020444366, 40.046134751717403],
            [65.771985711671221, 40.038383287258853],
            [65.792914666878346, 40.043731798283858],
            [65.836322870004437, 40.022441107870861],
            [65.878594190669332, 40.015749009709026],
            [66.094343296006343, 40.051224881223305],
            [66.105453729206204, 40.055746568149601],
            [66.169997593114374, 40.10176443118354],
            [66.178989291922335, 40.110187690109825],
            [66.184105258950524, 40.118430080983472],
            [66.184260287682093, 40.126284898229528],
            [66.181624790251817, 40.13264110050639],
            [66.175733676867708, 40.138583888935301],
            [66.16844730040259, 40.141426092839879],
            [66.158990512702019, 40.142562975301047],
            [66.150153842625741, 40.142227078516868],
            [66.131188592179797, 40.139591580187243],
            [66.123902214815303, 40.140082505703049],
            [66.119561395042354, 40.141942856777405],
            [66.117701043967998, 40.147343045545114],
            [66.11971642377398, 40.155016994738531],
            [66.145657993221846, 40.201732489762605],
            [66.153409457680311, 40.224418443256582],
            [66.15961063032637, 40.237389228430146],
            [66.168292270771644, 40.250101630286053],
            [66.193303664232701, 40.277645169289144],
            [66.198264601630058, 40.284569811447653],
            [66.199504835979383, 40.294129951036439],
            [66.184105258950524, 40.304051825831095],
            [66.180539584634118, 40.309038600750853],
            [66.178059115935412, 40.31645416932443],
            [66.179299351184056, 40.322862047545442],
            [66.192786900295232, 40.345392971408529],
            [66.199814894341841, 40.367200425759023],
            [66.196197544081372, 40.402960517214041],
            [66.185345493299906, 40.426628323538353],
            [66.185655551662364, 40.434069728735011],
            [66.190616489959041, 40.440038357384282],
            [66.21004682839839, 40.447221381061922],
            [66.217333204863507, 40.45236318651186],
            [66.225239698952919, 40.463990382749955],
            [66.225859815677893, 40.47163849532032],
            [66.221363967173318, 40.479700019040621],
            [66.214387648171453, 40.488381660385301],
            [66.205550978095175, 40.505796617220113],
            [66.20586103735701, 40.522178046179931],
            [66.208806594049065, 40.537939358414661],
            [66.221674024636513, 40.565172838155945],
            [66.229890577987817, 40.577781887224347],
            [66.239037307325816, 40.589150701943311],
            [66.250664503563996, 40.599253444790691],
            [66.262963495169174, 40.606203925370835],
            [66.276140985018401, 40.60956289321291],
            [66.290248650854551, 40.60821930607608],
            [66.305131463046564, 40.602741603841963],
            [66.325802035835238, 40.590985216394586],
            [66.343940463981198, 40.5832079135144],
            [66.361665480077875, 40.577756048802627],
            [66.377943557149479, 40.575973212094055],
            [66.397787306738849, 40.578040270542033],
            [66.421196729745304, 40.586205146150604],
            [66.493388706223755, 40.623722235892416],
            [66.502845493025063, 40.626616115741143],
            [66.511682163101341, 40.625634263810184],
            [66.522999301876268, 40.620931707931987],
            [66.551111280760949, 40.602018134329398],
            [66.585217726716678, 40.572019965049321],
            [66.588008253777843, 40.56793752679539],
            [66.589455193702236, 40.56351919175728],
            [66.588628371402194, 40.554088243377691],
            [66.582737258018085, 40.538249416777205],
            [66.582582228387139, 40.533753567373225],
            [66.583667434004894, 40.530136217112755],
            [66.585837844341029, 40.525847073283757],
            [66.589248488127168, 40.521196194248972],
            [66.600617302846274, 40.50972402584307],
            [66.620150994972391, 40.49509959606948],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "UZB-361",
        OBJECTID_1: 406,
        diss_me: 361,
        adm1_cod_1: "UZB-361",
        iso_3166_2: "UZ-",
        wikipedia: null,
        iso_a2: "UZ",
        adm0_sr: 1,
        name: "Kashkadarya",
        name_alt: "Kashka-Dar'ya|Kashkadar|Kashkadar'ya|Qashqadaryo",
        name_local: null,
        type: "Wiloyat",
        type_en: "Region",
        code_local: null,
        code_hasc: "UZ.QA",
        note: null,
        hasc_maybe: null,
        region: null,
        region_cod: null,
        provnum_ne: 11,
        gadm_level: 1,
        check_me: 0,
        scalerank: 4,
        datarank: 6,
        abbrev: null,
        postal: "QA",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 4,
        featurecla: "Admin-1 scale rank",
        name_len: 11,
        mapcolor9: 5,
        mapcolor13: 4,
        fips: "UZ08",
        fips_alt: null,
        woe_id: 2347664,
        woe_label: "Qashqadaryo, UZ, Uzbekistan",
        woe_name: "Kashkadarya",
        latitude: 38.7774,
        longitude: 66.023099999999999,
        sov_a3: "UZB",
        adm0_a3: "UZB",
        adm0_label: 2,
        admin: "Uzbekistan",
        geonunit: "Uzbekistan",
        gu_a3: "UZB",
        gn_id: 1114928,
        gn_name: "Qashqadaryo Province",
        gns_id: -3431453,
        gns_name: "Qashqadaryo Viloyati",
        gn_level: 1,
        gn_region: null,
        gn_a1_code: "UZ.08",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "vie",
        gns_adm1: "UZ08",
        gns_region: null,
        color: "#FFA500",
        jurisdiction_id: 264,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [66.052847120797992, 39.319538275770867],
            [66.088555536308945, 39.319615790136623],
            [66.104213494856936, 39.323698229289846],
            [66.125142450064061, 39.334343574496359],
            [66.220588819918135, 39.370904648930434],
            [66.225549758214754, 39.371137192927108],
            [66.369882033429064, 39.396975408688121],
            [66.388795607031625, 39.397853908730866],
            [66.414375441273648, 39.385348212450026],
            [66.473493279791057, 39.34863210748577],
            [66.489512974444267, 39.34225006768645],
            [66.502535434662548, 39.33956289341279],
            [66.510596958382905, 39.340131334193728],
            [66.585372756347681, 39.363540758099447],
            [66.59488121909294, 39.370672104933675],
            [66.627282341806676, 39.409041856296597],
            [66.633638544083567, 39.414312852955817],
            [66.641855095636117, 39.419635525559045],
            [66.657409702295865, 39.426379298765653],
            [66.675393100810993, 39.430461737918876],
            [66.688570590660277, 39.430565089807061],
            [66.767273797247981, 39.420643215012348],
            [66.852281529269192, 39.407620754793982],
            [66.873727248413729, 39.405088609251948],
            [66.893726026734726, 39.375478014498725],
            [66.8974467297827, 39.361551214916631],
            [66.891090529304506, 39.333775132816129],
            [66.889385206961776, 39.318478909474123],
            [66.890780470941934, 39.310365708910325],
            [66.894656202721535, 39.304448757104467],
            [66.900237257743242, 39.301063950840671],
            [66.90674848785244, 39.298247586257162],
            [66.91403486521682, 39.296051337499193],
            [66.92834923662798, 39.293777574375525],
            [67.038833449204333, 39.297136542217629],
            [67.050925734335124, 39.299151922922874],
            [67.056351759725942, 39.30121898047156],
            [67.061157668391729, 39.304603786735385],
            [67.064878371439704, 39.30891876898599],
            [67.067048780876576, 39.314112250380077],
            [67.067823928131759, 39.31915070214319],
            [67.06766889760155, 39.324163316383931],
            [67.066118604889652, 39.334007676812803],
            [67.066428664151488, 39.339097805419414],
            [67.119655389284787, 39.337159939079953],
            [67.358697241325899, 39.29043062335947],
            [67.351216675000046, 39.285251770000059],
            [67.346462443000064, 39.280807597000063],
            [67.344395386000087, 39.273831279000063],
            [67.342690063000134, 39.259568583000089],
            [67.350037296000096, 39.220057946000082],
            [67.350079794000123, 39.219829407000077],
            [67.378191773000083, 39.199494731000073],
            [67.499011271000086, 39.165982564000075],
            [67.580349976000093, 39.163605449000016],
            [67.619830770000135, 39.151254781000063],
            [67.624171590000117, 39.147379049000108],
            [67.632129761000101, 39.136216939000064],
            [67.637710815000105, 39.131540223000087],
            [67.644532105000053, 39.130610047000062],
            [67.661171916000058, 39.133374736000093],
            [67.667579793000129, 39.131772767000058],
            [67.676581395000085, 39.119821488000099],
            [67.676726522000081, 39.119628805000076],
            [67.678586874000075, 39.105934550000143],
            [67.667218058000117, 39.028368226000083],
            [67.672185417000037, 39.003992322000073],
            [67.672230672000069, 39.003770244000094],
            [67.673254198948143, 39.002978021812226],
            [67.658433871625903, 38.994157620377848],
            [67.640398797166711, 38.988473212568664],
            [67.567793410437503, 38.98596690544835],
            [67.555081007682304, 38.983434759906203],
            [67.543402133701534, 38.979636542492472],
            [67.537511021216687, 38.97689769137537],
            [67.531464878201518, 38.972815253121411],
            [67.524178500837138, 38.966975816580714],
            [67.518287388352292, 38.959896144791287],
            [67.515651890022752, 38.952454738695252],
            [67.515806918754265, 38.944031479768967],
            [67.523248324850329, 38.920751247971737],
            [67.528984408603549, 38.885068670882475],
            [67.553375686238894, 38.850548813776697],
            [67.555391066044876, 38.846259670847161],
            [67.556786330025147, 38.84114370381883],
            [67.556476270763312, 38.83540762006561],
            [67.555391066044876, 38.829103095531451],
            [67.553375686238894, 38.823367010878854],
            [67.552135450990249, 38.817320867863714],
            [67.551670362996902, 38.812049872103955],
            [67.551670362996902, 38.806830553187496],
            [67.552290479721876, 38.801662909315752],
            [67.553685743702147, 38.796598619130947],
            [67.555701125306655, 38.791792711364536],
            [67.558801710730393, 38.781560777308073],
            [67.559421828354743, 38.776031399129806],
            [67.559421828354743, 38.770295315376472],
            [67.556631301293521, 38.762853909280466],
            [67.551515334265275, 38.754869899925922],
            [67.541128370577781, 38.742441718010838],
            [67.536425815598875, 38.734173488715498],
            [67.533945346900225, 38.726783759462961],
            [67.533790318168599, 38.709291287363015],
            [67.532084994926606, 38.70456289306307],
            [67.52851932240884, 38.701100572433518],
            [67.521543003406862, 38.701927394733474],
            [67.51627200674767, 38.704407864331444],
            [67.498133578601767, 38.719652410830122],
            [67.493947787560387, 38.722287909159633],
            [67.488625115856451, 38.72482005470178],
            [67.482113884847934, 38.727042140982007],
            [67.46738610228681, 38.730349432880047],
            [67.45157311410793, 38.731873888069487],
            [67.437155389009945, 38.73138296255371],
            [67.424546339941628, 38.728850816112299],
            [67.410128614843643, 38.723760688405036],
            [67.400671828042448, 38.71805044217416],
            [67.39431562666482, 38.713192857564309],
            [67.388889602173435, 38.708076891435354],
            [67.329151646031676, 38.616997178876659],
            [67.319023064762575, 38.60627431930439],
            [67.301194695878507, 38.592114977524176],
            [67.291737909077256, 38.586120510453185],
            [67.27654503762335, 38.579841824340747],
            [67.236960891232172, 38.572322902979579],
            [67.228744337880869, 38.557698473205988],
            [67.217892287099403, 38.543332424052153],
            [67.200012241371837, 38.527984523866735],
            [67.162650181260858, 38.503722439239141],
            [67.157069126239151, 38.497495429070852],
            [67.150557896129953, 38.484757187893877],
            [67.145080193895865, 38.467652289421579],
            [67.134228143114285, 38.416285916262012],
            [67.126476677756443, 38.392540595572029],
            [67.123686150695278, 38.38721792296883],
            [67.110973748839399, 38.376262519399802],
            [67.000179477900588, 38.306421821310011],
            [66.943077020088424, 38.281281235740494],
            [66.931449822950981, 38.278568223944376],
            [66.895121290714997, 38.273891506487786],
            [66.887059767893959, 38.269188951508966],
            [66.877913038555846, 38.260714015739268],
            [66.862513462426364, 38.240250149424782],
            [66.85181644127573, 38.220380561413776],
            [66.848095738227755, 38.20888255548553],
            [66.842669711937617, 38.19653188703694],
            [66.836158481828477, 38.184542954693597],
            [66.828717074833094, 38.173897610386405],
            [66.706760694750244, 38.083980617811278],
            [66.698492466354196, 38.075454006097516],
            [66.692446324238517, 38.058891709984351],
            [66.687330357210271, 38.019669297899753],
            [66.681904330920133, 38.006130072844542],
            [66.666246372372143, 37.981764635429556],
            [66.636532423932181, 37.966597602397485],
            [66.636523669965754, 37.966593132883474],
            [66.624335978000033, 37.993831889000091],
            [66.624232626000037, 37.993883566000079],
            [66.624025920000065, 37.994012757000121],
            [66.590384562000111, 38.012978007000072],
            [66.554159384000059, 38.026853129000045],
            [66.515918823000106, 38.035095520000084],
            [66.439437704000113, 38.035457255000097],
            [66.408328492000066, 38.042846985000097],
            [66.306939331000081, 38.080389914000108],
            [66.260430542000051, 38.126743673000078],
            [66.23107832900007, 38.148783672000093],
            [66.198418824000044, 38.160720927000028],
            [66.133564900000124, 38.175293681000085],
            [66.071604858000057, 38.214541932000145],
            [66.043492879000041, 38.226194967000112],
            [65.977450399000077, 38.234566549000078],
            [65.87668135600012, 38.272962138000111],
            [65.842884969000124, 38.275339254000073],
            [65.814359578000051, 38.266192526000083],
            [65.765990438000131, 38.235548401000045],
            [65.740203898000118, 38.224076234000051],
            [65.671629273000065, 38.219683736000036],
            [65.60413985200006, 38.237408753000068],
            [65.43960209100004, 38.324948629000062],
            [65.340176636000137, 38.377813620000012],
            [65.205301148000103, 38.449643860000037],
            [65.110836630000108, 38.517262472000084],
            [64.977718139000046, 38.612269592000104],
            [64.910538778000102, 38.645342509000102],
            [64.763364299000102, 38.686011861000054],
            [64.634896688000083, 38.74414784800004],
            [64.42431522600009, 38.882950745000073],
            [64.367566302971341, 38.940374045912847],
            [64.367678664239008, 38.940465807251201],
            [64.383181594055316, 38.953126533162958],
            [64.474442172868009, 39.030951239707974],
            [64.521312696623625, 39.056065985956508],
            [64.658927036153841, 39.099112453876643],
            [64.712618850179751, 39.114253648487107],
            [64.87896528531968, 39.161175849086135],
            [64.8847013690729, 39.163785508994039],
            [64.889352248107741, 39.167867947247998],
            [64.891677687175559, 39.176239529330843],
            [64.890902540819582, 39.182027289028213],
            [64.888112013758416, 39.187014064847148],
            [64.8847013690729, 39.190993150313602],
            [64.852713656609978, 39.221508084430582],
            [64.845737339406696, 39.229440416042308],
            [64.840931430740909, 39.238044542121912],
            [64.839381138029069, 39.242669582735004],
            [64.838295933310633, 39.247475491400792],
            [64.838295933310633, 39.252643134373159],
            [64.839381138029069, 39.258120835707984],
            [64.845427281044238, 39.266595771477597],
            [64.8550907734205, 39.276750190269098],
            [64.931261835365433, 39.341190701389735],
            [64.937152947850279, 39.344782213228598],
            [64.944594353946229, 39.347521064345699],
            [64.956841668708023, 39.34863210748577],
            [64.965988397146759, 39.348089505126552],
            [64.977822299859213, 39.351448472968627],
            [64.990379672983465, 39.358993231852139],
            [65.090631952402646, 39.436662908766181],
            [65.13781253362157, 39.464258123713421],
            [65.236617873116359, 39.482319037493511],
            [65.255583123562246, 39.491801662716512],
            [65.682585483932883, 39.483378403790255],
            [65.694212681070383, 39.486323961381686],
            [65.715813429845866, 39.495108953715174],
            [65.736742385052992, 39.506994534170389],
            [65.741238233557681, 39.510792752483496],
            [65.744648879142403, 39.515211087521692],
            [65.746095819066795, 39.520275376807149],
            [65.74640587832863, 39.525339666992039],
            [65.745579055129269, 39.54050670002411],
            [65.746509230216816, 39.545855211049115],
            [65.748576287765502, 39.550816148446444],
            [65.756947869848261, 39.55528616032808],
            [65.767799920629841, 39.556758937774731],
            [65.799270868255917, 39.555880439530625],
            [65.80877933100129, 39.554691881125422],
            [65.815290562009864, 39.552934881939279],
            [65.82040652903811, 39.550402737296423],
            [65.824902378442061, 39.546992091711616],
            [65.828313023127521, 39.542987168722817],
            [65.830793490926851, 39.538672187371475],
            [65.840405308258312, 39.506141873448627],
            [65.842730747326129, 39.501000067998689],
            [65.843195835319534, 39.5004833031619],
            [65.843195835319534, 39.500276598486209],
            [65.848621860710352, 39.492990221121801],
            [65.859473910592442, 39.481311347140917],
            [65.911770460638195, 39.441830553537216],
            [65.941432733134093, 39.425914212570859],
            [65.966702508114281, 39.410902208270301],
            [65.969648064806336, 39.406251329235431],
            [65.972748651129393, 39.400153510276255],
            [65.973058710391115, 39.384753933247396],
            [65.971715122355022, 39.369018460333649],
            [65.971818475142527, 39.362352199694243],
            [65.972645298341831, 39.354859116754881],
            [65.974919060566208, 39.343361110826606],
            [65.979466586813487, 39.337237454345086],
            [65.985977817822004, 39.333465074453585],
            [66.052847120797992, 39.319538275770867],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "UZB-362",
        OBJECTID_1: 407,
        diss_me: 362,
        adm1_cod_1: "UZB-362",
        iso_3166_2: "UZ-",
        wikipedia: null,
        iso_a2: "UZ",
        adm0_sr: 1,
        name: "Surkhandarya",
        name_alt: "Sukhan-Dar'ya|Surxondaryo|Surkhan-Dar'ya",
        name_local: null,
        type: "Wiloyat",
        type_en: "Region",
        code_local: null,
        code_hasc: "UZ.SU",
        note: null,
        hasc_maybe: null,
        region: null,
        region_cod: null,
        provnum_ne: 12,
        gadm_level: 1,
        check_me: 0,
        scalerank: 4,
        datarank: 6,
        abbrev: null,
        postal: "SU",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 4,
        featurecla: "Admin-1 scale rank",
        name_len: 12,
        mapcolor9: 5,
        mapcolor13: 4,
        fips: "UZ12",
        fips_alt: null,
        woe_id: 2347668,
        woe_label: "Surkhondaryo, UZ, Uzbekistan",
        woe_name: "Surkhandarya",
        latitude: 37.983499999999999,
        longitude: 67.426900000000003,
        sov_a3: "UZB",
        adm0_a3: "UZB",
        adm0_label: 2,
        admin: "Uzbekistan",
        geonunit: "Uzbekistan",
        gu_a3: "UZB",
        gn_id: 1114926,
        gn_name: "Surxondaryo Viloyati",
        gns_id: -3431458,
        gns_name: "Surxondaryo Viloyati",
        gn_level: 1,
        gn_region: null,
        gn_a1_code: "UZ.12",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "vie",
        gns_adm1: "UZ12",
        gns_region: null,
        color: "#008000",
        jurisdiction_id: 268,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [67.818371623000075, 38.973410340000044],
            [67.84524336700008, 38.973772075000028],
            [67.921156047000125, 38.993770854000076],
            [67.921156047000125, 38.993796692000075],
            [67.92125940000011, 38.993796692000075],
            [67.92125940000011, 38.993874207],
            [67.92151778200008, 38.993874207],
            [67.934850302000143, 38.9990676890001],
            [67.948906290000139, 39.002013245000114],
            [67.963272339000127, 39.001909892000072],
            [67.980118856000104, 38.99738820500005],
            [67.987973674000045, 38.993770854000076],
            [68.003373251000141, 38.982117819000109],
            [68.019806356000117, 38.979689026000045],
            [68.036446167000122, 38.984391582000114],
            [68.053085979000059, 38.993951721000116],
            [68.060940796000068, 38.995527853000056],
            [68.069105672000092, 38.996096293000107],
            [68.077270549000104, 38.995527853000056],
            [68.085073690000058, 38.993951721000116],
            [68.085280396000087, 38.993951721000116],
            [68.085435425000128, 38.993796692000075],
            [68.0856421310001, 38.993770854000076],
            [68.10093835400005, 38.979275615000034],
            [68.134683065000104, 38.920984599000093],
            [68.153386406878667, 38.899402540247422],
            [68.163725220000117, 38.887472433000084],
            [68.173698771000034, 38.86731862400012],
            [68.172561890000111, 38.845278626000038],
            [68.163673543000073, 38.828638814000101],
            [68.15127120000011, 38.815125428000044],
            [68.135974976000057, 38.804505921000043],
            [68.119025106000095, 38.796702780000118],
            [68.095615683000119, 38.792930400000103],
            [68.077890666000087, 38.793395488000044],
            [68.066728556000044, 38.787969462000063],
            [68.063317912000116, 38.766317038000068],
            [68.066315145000033, 38.75621429500012],
            [68.072206259000041, 38.74735178600011],
            [68.076650431000132, 38.738101705000105],
            [68.07592696200004, 38.726862081000064],
            [68.070759318000057, 38.720738424000075],
            [68.045489543000087, 38.706708273000061],
            [68.037634725000032, 38.691644592000074],
            [68.040321900000095, 38.673351135000104],
            [68.053706095000109, 38.635937398000038],
            [68.055876506000118, 38.615731914000065],
            [68.052517538000075, 38.55826772100005],
            [68.055153035000103, 38.536305237000079],
            [68.062594442000034, 38.523076070000087],
            [68.086882365000122, 38.494033915000074],
            [68.092566773000101, 38.478789368000037],
            [68.094633830000134, 38.446982524000106],
            [68.098044474000062, 38.432435608000048],
            [68.10755293800014, 38.418276266000106],
            [68.14672367300011, 38.388743185000067],
            [68.183723999000051, 38.335490621000105],
            [68.200828898000111, 38.321693014000061],
            [68.25266036000005, 38.298748678000038],
            [68.332035360000134, 38.242989807000072],
            [68.356219930000123, 38.212604065000022],
            [68.360664103000033, 38.174053447000063],
            [68.344334350000111, 38.115452372000021],
            [68.319529663000139, 38.060210266000098],
            [68.280565633000037, 38.013236390000145],
            [68.268576701000086, 37.994193624000076],
            [68.2684733480001, 37.99411611000005],
            [68.2684733480001, 37.993986918000104],
            [68.268369995000057, 37.993909404000107],
            [68.268266642000128, 37.993831889000091],
            [68.260773559000086, 37.976778666000143],
            [68.256226034000122, 37.961559957000105],
            [68.248732951000079, 37.948950907000096],
            [68.238024506812906, 37.942716056000677],
            [68.233023316000128, 37.939804179000106],
            [68.217727091000086, 37.936083476000078],
            [68.171114950000117, 37.931070863000031],
            [68.13995406100014, 37.922699280000046],
            [68.12222904500004, 37.908333232000047],
            [68.079647665000095, 37.806272279000012],
            [68.050192098000139, 37.760409444000061],
            [67.977328329000045, 37.679742534000042],
            [67.923378133000085, 37.638788961000017],
            [67.871856730000104, 37.586802470000094],
            [67.829223674000104, 37.527943014000101],
            [67.801215047000085, 37.46644806000009],
            [67.797339315000045, 37.424435120000084],
            [67.817183065000052, 37.345421855000097],
            [67.820645386000081, 37.303305563000066],
            [67.8154260660001, 37.26733876600008],
            [67.803953899000135, 37.23343902600007],
            [67.785092001000123, 37.203570048000074],
            [67.780544475000113, 37.188868103000061],
            [67.775996948000056, 37.207549134000146],
            [67.764266398000075, 37.220881653000077],
            [67.746593058000087, 37.229408265000075],
            [67.725870809000128, 37.233542379000113],
            [67.69016239400014, 37.232250468000046],
            [67.677501668000104, 37.233542379000113],
            [67.666442912000093, 37.238012391000012],
            [67.644635457000049, 37.251215719000101],
            [67.633163290000141, 37.25405792300009],
            [67.621794474000069, 37.252921041000064],
            [67.602725871000075, 37.248270162000011],
            [67.592287231000057, 37.247184957000115],
            [67.58603438300014, 37.242611593000078],
            [67.579936564000093, 37.232663880000075],
            [67.57218509900008, 37.223155416000083],
            [67.561384725000039, 37.219899801000082],
            [67.545003296000118, 37.231526998000049],
            [67.52614139800005, 37.272893982000085],
            [67.503145386000142, 37.281963196000049],
            [67.482371460000081, 37.277363993000108],
            [67.463974650000068, 37.266279398000108],
            [67.43110843900007, 37.239769389000088],
            [67.411264689000063, 37.227909648000065],
            [67.391162557000087, 37.219589742000011],
            [67.36951013200013, 37.214680481000073],
            [67.345222209000099, 37.21302683600004],
            [67.31959069800007, 37.208427633000071],
            [67.281453491000093, 37.188661398000107],
            [67.259129272000109, 37.185147400000062],
            [67.236753377000127, 37.192407939000134],
            [67.225281210000048, 37.207704163000074],
            [67.217994832000045, 37.226256003000117],
            [67.20796960500013, 37.243464254000088],
            [67.187815796000052, 37.258243714000116],
            [67.143477417000099, 37.271860453000016],
            [67.123323609000124, 37.281963196000049],
            [67.113918498000089, 37.297181905000087],
            [67.097382039000081, 37.3405901080001],
            [67.085083049000048, 37.349633484000037],
            [67.064102417000129, 37.354568583000088],
            [67.024104858000044, 37.377487081000112],
            [67.005914754000116, 37.384385885000086],
            [66.957648966000136, 37.385212708000012],
            [66.865768270000046, 37.367926941000121],
            [66.734975220000081, 37.363301900000067],
            [66.724433227000134, 37.360149638000109],
            [66.7047445070001, 37.346532899000096],
            [66.6940991620001, 37.343406474000062],
            [66.667020711000134, 37.344310811000099],
            [66.654670044000113, 37.346145325000094],
            [66.588575887000047, 37.36846954300006],
            [66.564598022000098, 37.370691631000057],
            [66.539018189000046, 37.369089661000118],
            [66.519587850000107, 37.364180400000095],
            [66.494834839000077, 37.379424948000036],
            [66.49700524900004, 37.397744242000016],
            [66.512094768000054, 37.414823303000048],
            [66.530801636000035, 37.430145366000033],
            [66.546614624000142, 37.450790100000034],
            [66.542687215000058, 37.466758118000058],
            [66.526822551000066, 37.480374858000062],
            [66.5071338300001, 37.49383656800012],
            [66.49349125100008, 37.522206929000049],
            [66.497211954000079, 37.557889506000038],
            [66.519122763000041, 37.625249736000029],
            [66.522946818000094, 37.665040589000057],
            [66.514988647000052, 37.743485413000059],
            [66.518812703000094, 37.782707825000017],
            [66.553642619000129, 37.832007142000094],
            [66.609039754000094, 37.889187114000038],
            [66.645781698000064, 37.945901998000068],
            [66.636523669965754, 37.966593132883474],
            [66.636532423932181, 37.966597602397485],
            [66.666246372372143, 37.981764635429556],
            [66.681904330920133, 38.006130072844542],
            [66.687330357210271, 38.019669297899753],
            [66.692446324238517, 38.058891709984351],
            [66.698492466354196, 38.075454006097516],
            [66.706760694750244, 38.083980617811278],
            [66.828717074833094, 38.173897610386405],
            [66.836158481828477, 38.184542954693597],
            [66.842669711937617, 38.19653188703694],
            [66.848095738227755, 38.20888255548553],
            [66.85181644127573, 38.220380561413776],
            [66.862513462426364, 38.240250149424782],
            [66.877913038555846, 38.260714015739268],
            [66.887059767893959, 38.269188951508966],
            [66.895121290714997, 38.273891506487786],
            [66.931449822950981, 38.278568223944376],
            [66.943077020088424, 38.281281235740494],
            [67.000179477900588, 38.306421821310011],
            [67.110973748839399, 38.376262519399802],
            [67.123686150695278, 38.38721792296883],
            [67.126476677756443, 38.392540595572029],
            [67.134228143114285, 38.416285916262012],
            [67.145080193895865, 38.467652289421579],
            [67.150557896129953, 38.484757187893877],
            [67.157069126239151, 38.497495429070852],
            [67.162650181260858, 38.503722439239141],
            [67.200012241371837, 38.527984523866735],
            [67.217892287099403, 38.543332424052153],
            [67.228744337880869, 38.557698473205988],
            [67.236960891232172, 38.572322902979579],
            [67.27654503762335, 38.579841824340747],
            [67.291737909077256, 38.586120510453185],
            [67.301194695878507, 38.592114977524176],
            [67.319023064762575, 38.60627431930439],
            [67.329151646031676, 38.616997178876659],
            [67.388889602173435, 38.708076891435354],
            [67.39431562666482, 38.713192857564309],
            [67.400671828042448, 38.71805044217416],
            [67.410128614843643, 38.723760688405036],
            [67.424546339941628, 38.728850816112299],
            [67.437155389009945, 38.73138296255371],
            [67.45157311410793, 38.731873888069487],
            [67.46738610228681, 38.730349432880047],
            [67.482113884847934, 38.727042140982007],
            [67.488625115856451, 38.72482005470178],
            [67.493947787560387, 38.722287909159633],
            [67.498133578601767, 38.719652410830122],
            [67.51627200674767, 38.704407864331444],
            [67.521543003406862, 38.701927394733474],
            [67.52851932240884, 38.701100572433518],
            [67.532084994926606, 38.70456289306307],
            [67.533790318168599, 38.709291287363015],
            [67.533945346900225, 38.726783759462961],
            [67.536425815598875, 38.734173488715498],
            [67.541128370577781, 38.742441718010838],
            [67.551515334265275, 38.754869899925922],
            [67.556631301293521, 38.762853909280466],
            [67.559421828354743, 38.770295315376472],
            [67.559421828354743, 38.776031399129806],
            [67.558801710730393, 38.781560777308073],
            [67.555701125306655, 38.791792711364536],
            [67.553685743702147, 38.796598619130947],
            [67.552290479721876, 38.801662909315752],
            [67.551670362996902, 38.806830553187496],
            [67.551670362996902, 38.812049872103955],
            [67.552135450990249, 38.817320867863714],
            [67.553375686238894, 38.823367010878854],
            [67.555391066044876, 38.829103095531451],
            [67.556476270763312, 38.83540762006561],
            [67.556786330025147, 38.84114370381883],
            [67.555391066044876, 38.846259670847161],
            [67.553375686238894, 38.850548813776697],
            [67.528984408603549, 38.885068670882475],
            [67.523248324850329, 38.920751247971737],
            [67.515806918754265, 38.944031479768967],
            [67.515651890022752, 38.952454738695252],
            [67.518287388352292, 38.959896144791287],
            [67.524178500837138, 38.966975816580714],
            [67.531464878201518, 38.972815253121411],
            [67.537511021216687, 38.97689769137537],
            [67.543402133701534, 38.979636542492472],
            [67.555081007682304, 38.983434759906203],
            [67.567793410437503, 38.98596690544835],
            [67.640398797166711, 38.988473212568664],
            [67.658433871625903, 38.994157620377848],
            [67.673254198948143, 39.002978021812226],
            [67.690524129000039, 38.989610901000049],
            [67.818371623000075, 38.973410340000044],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "UZB-363",
        OBJECTID_1: 408,
        diss_me: 363,
        adm1_cod_1: "UZB-363",
        iso_3166_2: "UZ-",
        wikipedia: null,
        iso_a2: "UZ",
        adm0_sr: 1,
        name: "Andijon",
        name_alt: "Andijan|Andizhan",
        name_local: null,
        type: "Wiloyat",
        type_en: "Region",
        code_local: null,
        code_hasc: "UZ.AN",
        note: null,
        hasc_maybe: null,
        region: null,
        region_cod: null,
        provnum_ne: 5,
        gadm_level: 1,
        check_me: 0,
        scalerank: 4,
        datarank: 6,
        abbrev: null,
        postal: "AN",
        area_sqkm: 0,
        sameascity: 7,
        labelrank: 7,
        featurecla: "Admin-1 scale rank",
        name_len: 7,
        mapcolor9: 5,
        mapcolor13: 4,
        fips: "UZ01",
        fips_alt: null,
        woe_id: 2347659,
        woe_label: "Andijon, UZ, Uzbekistan",
        woe_name: "Andijon",
        latitude: 40.449100000000001,
        longitude: 72.365099999999998,
        sov_a3: "UZB",
        adm0_a3: "UZB",
        adm0_label: 2,
        admin: "Uzbekistan",
        geonunit: "Uzbekistan",
        gu_a3: "UZB",
        gn_id: 1484846,
        gn_name: "Andijan",
        gns_id: -3431444,
        gns_name: "Andijon Viloyati",
        gn_level: 1,
        gn_region: null,
        gn_a1_code: "UZ.01",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "vie",
        gns_adm1: "UZ01",
        gns_region: null,
        color: "#800080",
        jurisdiction_id: 258,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [72.701226034000058, 40.863243307000118],
            [72.830107056000088, 40.872079977000112],
            [72.868657674000076, 40.864121806000071],
            [72.872998494000058, 40.834821269000088],
            [72.870311320000042, 40.818181458000083],
            [72.883127075000118, 40.819628398000077],
            [72.929429158000062, 40.844174703000135],
            [73.003429810000114, 40.870167948000102],
            [73.019449504000136, 40.861899720000054],
            [73.033298787000092, 40.847223612000079],
            [73.053555949000099, 40.836319886000098],
            [73.112467082000137, 40.839162090000087],
            [73.13504155516182, 40.835273843856527],
            [73.143291994000094, 40.833852782000065],
            [73.143369589000031, 40.833839417000036],
            [73.148640584000134, 40.813685608000043],
            [73.11804813700013, 40.782938131000094],
            [73.070609171000058, 40.762474264000048],
            [72.976764771000035, 40.736067607000052],
            [72.890981893000117, 40.695088196000114],
            [72.818944947000091, 40.681083883000042],
            [72.78390832600013, 40.669663391000114],
            [72.760033814000053, 40.641758118000041],
            [72.74835494000007, 40.575095521000037],
            [72.719209432000071, 40.564863586000058],
            [72.682415812000045, 40.577679342000124],
            [72.664225708000117, 40.577782695000053],
            [72.656474243000105, 40.561142883000031],
            [72.65513065600004, 40.54636342400002],
            [72.650479777000101, 40.532152405000062],
            [72.640867961000083, 40.519853414000124],
            [72.625468384000101, 40.510499980000077],
            [72.58578088400003, 40.508742982000086],
            [72.515294230000052, 40.545639954000109],
            [72.476743612000064, 40.549567363000079],
            [72.447908163000136, 40.56047109000005],
            [72.414835246000052, 40.589874980000047],
            [72.381555624000043, 40.6121475220001],
            [72.351893351000058, 40.601967265000042],
            [72.348509255000124, 40.585508257000043],
            [72.348482706000141, 40.58537913100011],
            [72.369928275000063, 40.557930855000109],
            [72.370083455000099, 40.557732239000103],
            [72.369980102000056, 40.539852194000048],
            [72.36326216700013, 40.523574118000028],
            [72.363467007000054, 40.512410315000039],
            [72.363468872000112, 40.512308655000055],
            [72.372253866000108, 40.503265279000118],
            [72.415585052000097, 40.479329958000051],
            [72.415662069000064, 40.479287415000101],
            [72.420027916000095, 40.471026546000132],
            [72.426095774000089, 40.459545207000076],
            [72.426204061000135, 40.45934031200008],
            [72.425170532000095, 40.435982564000113],
            [72.414421834000052, 40.410712789000058],
            [72.394061320000105, 40.389422099000086],
            [72.370496867000099, 40.38564972000006],
            [72.343418416000077, 40.393401184000055],
            [72.284300578000114, 40.419859518000038],
            [72.2696244710001, 40.424045309000093],
            [72.224355917000082, 40.42244333900004],
            [72.211643514000059, 40.425595601000111],
            [72.211768463000112, 40.425836195000016],
            [72.216501099000084, 40.434949036000063],
            [72.228076620000138, 40.437532858000054],
            [72.24512984200004, 40.43872141500006],
            [72.259599244000071, 40.442390442000061],
            [72.263630005000095, 40.452519023000036],
            [72.263415275000057, 40.452651165000134],
            [72.254224894000117, 40.458306783000097],
            [72.235931437000033, 40.459908753000036],
            [72.22832779984418, 40.459606084176784],
            [72.228232456262788, 40.459959622238728],
            [72.228230407607214, 40.459967216114109],
            [72.22699222191352, 40.464558824430156],
            [72.218310580568811, 40.487554837185968],
            [72.215985142400427, 40.501352443760311],
            [72.218775668562216, 40.519258327909483],
            [72.222341342878622, 40.537939358414661],
            [72.223116490133918, 40.549824937071151],
            [72.222806430872083, 40.555354316148765],
            [72.221721226153647, 40.56041860633357],
            [72.207613560317498, 40.569978745922356],
            [72.155782098265263, 40.58186432637757],
            [72.144258253915325, 40.582794501465088],
            [72.13712690618172, 40.587290350868983],
            [72.117593214954866, 40.609020290853692],
            [72.103485549118773, 40.616797593733992],
            [72.093563674324059, 40.610027981206372],
            [72.082659946699152, 40.599718532784038],
            [72.065761752902574, 40.598400784068929],
            [72.005403680035784, 40.608167630132016],
            [71.958688185911001, 40.620079047210311],
            [71.93016279497698, 40.618683784129331],
            [71.910112338913336, 40.615919093691218],
            [71.886392856644989, 40.618838812860957],
            [71.877091098575306, 40.621190090350382],
            [71.869029574855006, 40.624445706304357],
            [71.828825310839363, 40.666277778296859],
            [71.819833612031516, 40.677930812956646],
            [71.811462029948757, 40.685294704686839],
            [71.800403272692847, 40.690178127718383],
            [71.74810672444579, 40.703794868038756],
            [71.731983677005076, 40.704699204704582],
            [71.720976596592607, 40.703484808776835],
            [71.716015659195307, 40.70072012013739],
            [71.70609378440065, 40.701598619280873],
            [71.69291629365199, 40.70516429269793],
            [71.665889520385008, 40.715706285116937],
            [71.655347527966001, 40.716016344378772],
            [71.649301384950945, 40.712993272421556],
            [71.647596062608216, 40.695345770690778],
            [71.643410271566722, 40.697283637030154],
            [71.633488396772066, 40.708058173445949],
            [71.611577589634066, 40.740097560953643],
            [71.58181196525004, 40.774255682853436],
            [71.610647413647257, 40.795778917263192],
            [71.59483442636764, 40.820919501034155],
            [71.582845493124978, 40.832934271799189],
            [71.581915318037545, 40.839781398692594],
            [71.586152785022989, 40.845517483345191],
            [71.618708937367558, 40.868410143313497],
            [71.63126631139113, 40.875283107729217],
            [71.64423709476614, 40.889959215245597],
            [71.747693312396393, 40.910914007975038],
            [71.780869582365312, 40.898279120485],
            [71.822624139092738, 40.913394477573036],
            [71.834251337129501, 40.916029975003283],
            [71.856110467424116, 40.916029975003283],
            [71.880191684898307, 40.914660549444733],
            [71.90763187021463, 40.916650091728371],
            [72.038631625948767, 40.892594713575107],
            [72.056149937369753, 40.891096095908097],
            [72.072583042273607, 40.89352488956203],
            [72.132631056777825, 40.89538523973701],
            [72.147720575444168, 40.897503974129108],
            [72.153766716660641, 40.899545193255989],
            [72.158262566963913, 40.901483058696186],
            [72.165859002690809, 40.906883246564632],
            [72.189733513690726, 40.990108140977895],
            [72.195486496802289, 41.006357791189544],
            [72.252984660000095, 41.019616191000097],
            [72.289778280000064, 41.023543600000067],
            [72.297323039000048, 41.028142802000019],
            [72.30879520700006, 41.054446106000057],
            [72.314066203000095, 41.06168080700013],
            [72.332359660000066, 41.07284291700006],
            [72.345692179000082, 41.065969950000095],
            [72.374320923000141, 41.031966858000061],
            [72.395198201000142, 41.022044983000058],
            [72.423516887000119, 41.015740459000142],
            [72.476743612000064, 41.011813049000096],
            [72.481911255000114, 41.008815817000084],
            [72.484701782000059, 41.004681702000141],
            [72.485218547000102, 40.999565735000061],
            [72.483564901000136, 40.993519592000013],
            [72.483358195000108, 40.970575256000075],
            [72.501444946000106, 40.96349558600005],
            [72.526249634000123, 40.962203674000108],
            [72.545576620000077, 40.956519267000061],
            [72.588468059000036, 40.905824687000091],
            [72.619473918000097, 40.880089824000095],
            [72.658231242000113, 40.867170716000089],
            [72.701226034000058, 40.863243307000118],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "UZB-364",
        OBJECTID_1: 381,
        diss_me: 364,
        adm1_cod_1: "UZB-364",
        iso_3166_2: "UZ-",
        wikipedia: null,
        iso_a2: "UZ",
        adm0_sr: 4,
        name: "Ferghana",
        name_alt: "Farghona|Farg`ona|Fergana",
        name_local: null,
        type: "Wiloyat",
        type_en: "Region",
        code_local: null,
        code_hasc: "UZ.FA",
        note: null,
        hasc_maybe: null,
        region: null,
        region_cod: null,
        provnum_ne: 2,
        gadm_level: 1,
        check_me: 0,
        scalerank: 4,
        datarank: 6,
        abbrev: null,
        postal: "FA",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 4,
        featurecla: "Admin-1 scale rank",
        name_len: 8,
        mapcolor9: 5,
        mapcolor13: 4,
        fips: "UZ03",
        fips_alt: null,
        woe_id: 2347662,
        woe_label: "Farghona, UZ, Uzbekistan",
        woe_name: "Ferghana",
        latitude: 39.934699999999999,
        longitude: 71.727500000000006,
        sov_a3: "UZB",
        adm0_a3: "UZB",
        adm0_label: 2,
        admin: "Uzbekistan",
        geonunit: "Uzbekistan",
        gu_a3: "UZB",
        gn_id: 1484845,
        gn_name: "Fergana",
        gns_id: -3431446,
        gns_name: "Farg`ona Viloyati",
        gn_level: 1,
        gn_region: null,
        gn_a1_code: "UZ.03",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "vie",
        gns_adm1: "UZ03",
        gns_region: null,
        color: "#FF0000",
        jurisdiction_id: 260,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [71.757304321000134, 39.903095194000102],
              [71.741801391000109, 39.900769755000155],
              [71.71616988100007, 39.906764221000103],
              [71.696842896000106, 39.913843892000116],
              [71.675345500000077, 39.925781149000059],
              [71.665526978000088, 39.940198873000085],
              [71.681753378000195, 39.955081686000128],
              [71.706868123000191, 39.956115214000093],
              [71.724128052000111, 39.962678121000081],
              [71.760611613000123, 39.983710429],
              [71.785519654000183, 39.989704895000116],
              [71.789757121000122, 39.979317932000114],
              [71.76712284300018, 39.915445862000141],
              [71.757304321000134, 39.903095194000102],
            ],
          ],
          [
            [
              [71.169381551000157, 40.042828267000161],
              [71.176926310000141, 40.042001445000139],
              [71.181887248000095, 40.043396708000117],
              [71.192946005000096, 40.050269674000091],
              [71.198217, 40.05259511300001],
              [71.223745158000071, 40.057866109000045],
              [71.236354208000051, 40.056057434000152],
              [71.244002319000032, 40.046755677000036],
              [71.237284383000116, 40.031097717000151],
              [71.190982300000059, 40.006241354000039],
              [71.174704224000124, 39.993994039000185],
              [71.17702966300007, 39.968155823000174],
              [71.221057984000112, 39.931723938000076],
              [71.219094279000188, 39.9009247850001],
              [71.208448934000074, 39.888780823],
              [71.194599650000129, 39.884801738000121],
              [71.161009969000105, 39.88423329700008],
              [71.113674357000065, 39.874466452000107],
              [71.098894898000111, 39.875499980000185],
              [71.087216024000071, 39.883819886000154],
              [71.084787231000092, 39.894826966000053],
              [71.079981323000055, 39.903405254000077],
              [71.060964396, 39.904128723000085],
              [71.049802287000176, 39.897979228000011],
              [71.036056356000159, 39.887540589000153],
              [71.021897014000189, 39.880822652000134],
              [71.009287964000094, 39.885731913000157],
              [71.007634318000129, 39.911156718000186],
              [71.050370728000217, 39.962884827000138],
              [71.045823201000104, 39.992030335000052],
              [71.034299357000094, 39.99911000600018],
              [71.007634318000129, 40.003502503000064],
              [70.994508504000152, 40.008876852000029],
              [70.983139690000172, 40.021382548000034],
              [70.958438354000151, 40.063085429000168],
              [70.952960653000076, 40.079208476000062],
              [70.954045858000171, 40.095796611000068],
              [70.959885294000031, 40.11341827400004],
              [70.977145223000065, 40.144579163000017],
              [71.008564494000069, 40.15775665299999],
              [71.03119877200001, 40.146904603000124],
              [71.047941935000182, 40.122513326000032],
              [71.06173954200014, 40.095176493000096],
              [71.078844442000076, 40.078640036000181],
              [71.105974569000153, 40.064945781000162],
              [71.162043498000088, 40.046910706000162],
              [71.165454142000186, 40.044636943000157],
              [71.169381551000157, 40.042828267000161],
            ],
          ],
          [
            [
              [70.98190026188766, 40.761078193004224],
              [71.013836296607764, 40.757641709896987],
              [71.126956008413032, 40.762292588931771],
              [71.063342319592351, 40.696327623521043],
              [71.068923373714796, 40.692038478792782],
              [71.108042433911265, 40.688731186894827],
              [71.115483840007215, 40.685992337576394],
              [71.143905878153788, 40.663461411914582],
              [71.151192254618906, 40.660205796859984],
              [71.16157921740708, 40.657673652217298],
              [71.179872674284496, 40.654934801100197],
              [71.191034784327968, 40.651782537933698],
              [71.211395297854693, 40.639173488865467],
              [71.256715528898582, 40.620311591206942],
              [71.270978225264884, 40.616900947420888],
              [71.293922561177254, 40.620363268050468],
              [71.359293247385523, 40.667285467750119],
              [71.390764195011542, 40.704079087979451],
              [71.410349562182574, 40.716636461103704],
              [71.49907799725176, 40.753326728545645],
              [71.581811965250012, 40.774255682853479],
              [71.611577589634095, 40.740097560953686],
              [71.633488396772151, 40.708058173445991],
              [71.643410271566694, 40.697283637030225],
              [71.647596062608301, 40.695345770690821],
              [71.649301384950917, 40.712993272421514],
              [71.655347527965972, 40.716016344378815],
              [71.66588952038498, 40.715706285116894],
              [71.692916293652019, 40.705164292697887],
              [71.706093784400622, 40.701598619280915],
              [71.716015659195392, 40.700720120137433],
              [71.720976596592635, 40.703484808776878],
              [71.731983677005104, 40.704699204704625],
              [71.748106724445876, 40.703794868038713],
              [71.800403272692876, 40.690178127718426],
              [71.811462029948785, 40.685294704686882],
              [71.819833612031545, 40.677930812956717],
              [71.828825310839449, 40.666277778296902],
              [71.869029574855034, 40.624445706304314],
              [71.877091098575335, 40.621190090350424],
              [71.88639285664496, 40.618838812861],
              [71.910112338913308, 40.615919093691176],
              [71.930162794977065, 40.618683784129374],
              [71.958688185911029, 40.620079047210268],
              [72.005403680035812, 40.608167630131973],
              [72.06576175290266, 40.598400784068971],
              [72.082659946699181, 40.599718532783996],
              [72.093563674324088, 40.610027981206329],
              [72.103485549118801, 40.616797593734034],
              [72.117593214954894, 40.609020290853735],
              [72.137126906181749, 40.587290350869026],
              [72.14425825391541, 40.582794501465159],
              [72.155782098265291, 40.581864326377527],
              [72.207613560317583, 40.569978745922398],
              [72.221721226153733, 40.560418606333613],
              [72.222806430872112, 40.555354316148808],
              [72.223116490134004, 40.549824937071193],
              [72.22234134287865, 40.537939358414704],
              [72.218775668562188, 40.519258327909441],
              [72.215985142400399, 40.501352443760354],
              [72.21831058056884, 40.487554837186011],
              [72.226992221913491, 40.464558824430227],
              [72.228230407607299, 40.45996721611418],
              [72.228232456262873, 40.459959622238685],
              [72.228327799844266, 40.459606084176826],
              [72.182704712000117, 40.457790019000115],
              [72.165858195000141, 40.454431051000185],
              [72.099298950000019, 40.426370748000167],
              [72.09010054500007, 40.416035462000153],
              [72.084106079000122, 40.39738027],
              [72.06963667800008, 40.369423320000109],
              [72.043178345000086, 40.349321188000133],
              [71.956671998000189, 40.315679831000082],
              [71.951094212000072, 40.301528782000119],
              [71.951090943000082, 40.301520488000151],
              [71.958222290000123, 40.286534322000179],
              [71.977032511000203, 40.276095683000122],
              [72.005971313000174, 40.265760396000033],
              [72.013238928966899, 40.262072352128669],
              [72.019820598000109, 40.258732401000131],
              [72.025401653000159, 40.250877584000179],
              [72.018890422000112, 40.240025533000122],
              [72.004834432000081, 40.237286682],
              [71.989021443000098, 40.239302063000039],
              [71.977032511000203, 40.243074443000168],
              [71.969177694000081, 40.244418030000034],
              [71.963389933000116, 40.243746236000149],
              [71.951814413000164, 40.240904033000092],
              [71.912333619000066, 40.243436178000152],
              [71.872956177000134, 40.250774231000051],
              [71.836472616, 40.249172262000044],
              [71.805466756000072, 40.225297750000138],
              [71.786759888000091, 40.193516744000092],
              [71.775907837000062, 40.179925843000106],
              [71.759784790000111, 40.168091939000092],
              [71.707178182000092, 40.144269104000116],
              [71.693328898000146, 40.141116842000159],
              [71.673071736000139, 40.147886455],
              [71.666870565000153, 40.163131002000128],
              [71.667180623, 40.204162089000093],
              [71.660152629000123, 40.224470927000098],
              [71.645993286000163, 40.247105204000079],
              [71.628319946000175, 40.258887431000161],
              [71.610129842000134, 40.246381735000071],
              [71.604032023000087, 40.227261455000175],
              [71.601654907000096, 40.209949850000058],
              [71.592973267000076, 40.198426005000144],
              [71.568271932000158, 40.196565653000121],
              [71.521246378000086, 40.203748678000082],
              [71.498405396000095, 40.210518291000128],
              [71.477114706000094, 40.220801901000115],
              [71.45892460200011, 40.234134420000046],
              [71.450966431000069, 40.248913879000057],
              [71.441354614000176, 40.260902812000111],
              [71.3965511480001, 40.271548157000026],
              [71.365131876000163, 40.294130758000122],
              [71.344926392000076, 40.295215963000132],
              [71.313558798000059, 40.292683818000128],
              [71.297074016000153, 40.293820699000051],
              [71.283224732000093, 40.298419902000106],
              [71.272837769000176, 40.307721660000041],
              [71.263949423000071, 40.318211975000096],
              [71.253820841000135, 40.324413147000158],
              [71.239558146000064, 40.321105856000159],
              [71.22395186300011, 40.302864075000016],
              [71.215115194000106, 40.280746562],
              [71.201007527000087, 40.263848369000115],
              [71.169381551000157, 40.261419577000069],
              [71.053212932000207, 40.274235331000128],
              [70.99512862200001, 40.266587220000147],
              [70.958955119000194, 40.238371888000088],
              [70.938336222000117, 40.239922181000011],
              [70.849711141000199, 40.233772685000034],
              [70.707187541000195, 40.20018300400001],
              [70.664296102000066, 40.19749582900009],
              [70.622128133000189, 40.199562887000141],
              [70.581820516000136, 40.221732077000169],
              [70.563940470000176, 40.264210104000099],
              [70.55060795100016, 40.310460511000159],
              [70.52404626500001, 40.343740133000111],
              [70.501928752000111, 40.350819804000039],
              [70.482911825000059, 40.349269511000116],
              [70.464101603000159, 40.34482533800012],
              [70.442190796000062, 40.343326721000111],
              [70.42121016500019, 40.347977600000078],
              [70.371394083000069, 40.364927470000069],
              [70.384933309000218, 40.399705709000116],
              [70.382142781000056, 40.403943177000158],
              [70.373564494000135, 40.411797994000111],
              [70.371394083000069, 40.413348288000051],
              [70.371962524000111, 40.412986552000135],
              [70.358785034000135, 40.427714336000136],
              [70.35702803500007, 40.430401510000152],
              [70.355426067000195, 40.435104065000061],
              [70.353824097000171, 40.456136373000064],
              [70.369637085000164, 40.475515036000118],
              [70.399351034000148, 40.490191142000114],
              [70.468184041000228, 40.502490133000052],
              [70.477795858000064, 40.505487366000168],
              [70.487872762000194, 40.515564270000098],
              [70.511953980000072, 40.546776835000074],
              [70.525493205000117, 40.55711212199999],
              [70.539445842000219, 40.561866354000088],
              [70.554225301000059, 40.565018616000046],
              [70.569314820000017, 40.570444642000112],
              [70.582078899000095, 40.578764547000091],
              [70.617167196000224, 40.611940817000104],
              [70.677938681000143, 40.649922994000022],
              [70.692924846000125, 40.660413310000152],
              [70.707239217000193, 40.668991598],
              [70.715662476000176, 40.672143860000133],
              [70.732095581000038, 40.674986064000123],
              [70.73550622500008, 40.678861797000152],
              [70.737159872000149, 40.683564352000147],
              [70.740673869000176, 40.687698467000175],
              [70.756176798000098, 40.695139873000088],
              [70.756292785791999, 40.695578051093108],
              [70.782015821572969, 40.707851466971661],
              [70.792712843622809, 40.717049872253753],
              [70.801911248904986, 40.727307643832731],
              [70.812970005261519, 40.736480210693188],
              [70.82981652221477, 40.742629706495805],
              [70.843304071325832, 40.74340485285164],
              [70.871157667792119, 40.74123444341474],
              [70.884438511328227, 40.742629706495805],
              [70.931464063815469, 40.774746609268632],
              [70.951617872666674, 40.774669094003414],
              [70.967327508058048, 40.769656479762759],
              [70.975389031778462, 40.765367336833137],
              [70.98190026188766, 40.761078193004224],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "UZB-365",
        OBJECTID_1: 409,
        diss_me: 365,
        adm1_cod_1: "UZB-365",
        iso_3166_2: "UZ-",
        wikipedia: null,
        iso_a2: "UZ",
        adm0_sr: 1,
        name: "Namangan",
        name_alt: null,
        name_local: null,
        type: "Wiloyat",
        type_en: "Region",
        code_local: null,
        code_hasc: "UZ.NG",
        note: null,
        hasc_maybe: null,
        region: null,
        region_cod: null,
        provnum_ne: 6,
        gadm_level: 1,
        check_me: 10,
        scalerank: 4,
        datarank: 6,
        abbrev: null,
        postal: "NG",
        area_sqkm: 0,
        sameascity: 7,
        labelrank: 7,
        featurecla: "Admin-1 scale rank",
        name_len: 8,
        mapcolor9: 5,
        mapcolor13: 4,
        fips: "TI04",
        fips_alt: "UZ|UZ06",
        woe_id: 2347666,
        woe_label: "Namangan, UZ, Uzbekistan",
        woe_name: "Namangan",
        latitude: 40.874600000000001,
        longitude: 71.296400000000006,
        sov_a3: "UZB",
        adm0_a3: "UZB",
        adm0_label: 2,
        admin: "Uzbekistan",
        geonunit: "Uzbekistan",
        gu_a3: "UZB",
        gn_id: 1484842,
        gn_name: "Namangan Province",
        gns_id: -3431450,
        gns_name: "Namangan Viloyati",
        gn_level: 1,
        gn_region: null,
        gn_a1_code: "UZ.06",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "vie",
        gns_adm1: "UZ06",
        gns_region: null,
        color: "#0000FF",
        jurisdiction_id: 262,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [71.681443319000095, 41.422847392000079],
            [71.687334432000114, 41.431012268000075],
            [71.691468547000113, 41.441915996000063],
            [71.696119425000063, 41.44744537400004],
            [71.706868123000106, 41.444086405000036],
            [71.71224247300006, 41.428015036000062],
            [71.721647583000106, 41.424759420000072],
            [71.72970910600003, 41.430082092000134],
            [71.730432577000101, 41.451166077000067],
            [71.73684045400006, 41.455403544],
            [71.745212036000112, 41.452819723000118],
            [71.753170207000096, 41.447290344000095],
            [71.847014608000109, 41.341818746000058],
            [71.863034302000131, 41.312208150000089],
            [71.868822063000096, 41.279238587000037],
            [71.866341594000119, 41.236605530000077],
            [71.871612590000097, 41.194489237000113],
            [71.897657512000137, 41.184929098000026],
            [71.977032511000118, 41.195212708000014],
            [72.001217081000107, 41.180019836000099],
            [72.016513306000036, 41.163535055000096],
            [72.033773234000137, 41.156610413000124],
            [72.063642212000047, 41.170201314000025],
            [72.08524296100012, 41.184877421000095],
            [72.108497355000111, 41.196401266000038],
            [72.132888632000061, 41.199295146000026],
            [72.158416789000114, 41.187874654000041],
            [72.169888957000126, 41.168651022000091],
            [72.174643188000118, 41.141417542000084],
            [72.176400188000059, 41.11289215100004],
            [72.185598592000076, 41.060647278000062],
            [72.185598592000076, 41.041061911000043],
            [72.178777303000118, 41.023181865000083],
            [72.16513472500003, 40.999359030000107],
            [72.195486496802289, 41.006357791189544],
            [72.189733513690726, 40.990108140977895],
            [72.165859002690809, 40.906883246564632],
            [72.158262566963913, 40.901483058696186],
            [72.153766716660641, 40.899545193255989],
            [72.147720575444168, 40.897503974129108],
            [72.132631056777825, 40.89538523973701],
            [72.072583042273607, 40.89352488956203],
            [72.056149937369753, 40.891096095908097],
            [72.038631625948767, 40.892594713575107],
            [71.90763187021463, 40.916650091728371],
            [71.880191684898307, 40.914660549444733],
            [71.856110467424116, 40.916029975003283],
            [71.834251337129501, 40.916029975003283],
            [71.822624139092738, 40.913394477573036],
            [71.780869582365312, 40.898279120485],
            [71.747693312396393, 40.910914007975038],
            [71.64423709476614, 40.889959215245597],
            [71.63126631139113, 40.875283107729217],
            [71.618708937367558, 40.868410143313497],
            [71.586152785022989, 40.845517483345191],
            [71.581915318037545, 40.839781398692594],
            [71.582845493124978, 40.832934271799189],
            [71.59483442636764, 40.820919501034155],
            [71.610647413647257, 40.795778917263192],
            [71.58181196525004, 40.774255682853436],
            [71.499077997251732, 40.753326728545687],
            [71.410349562182546, 40.716636461103747],
            [71.39076419501157, 40.704079087979494],
            [71.359293247385438, 40.667285467750077],
            [71.293922561177283, 40.620363268050426],
            [71.270978225264912, 40.616900947420845],
            [71.256715528898553, 40.620311591206899],
            [71.211395297854665, 40.639173488865424],
            [71.191034784327883, 40.651782537933741],
            [71.179872674284468, 40.654934801100239],
            [71.161579217406995, 40.657673652217255],
            [71.15119225461882, 40.660205796859998],
            [71.143905878153703, 40.663461411914625],
            [71.115483840007187, 40.685992337576323],
            [71.10804243391118, 40.688731186894785],
            [71.068923373714767, 40.692038478792824],
            [71.06334231959238, 40.696327623521],
            [71.126956008413003, 40.762292588931814],
            [71.013836296607792, 40.75764170989703],
            [70.981900261887574, 40.761078193004181],
            [70.975389031778434, 40.765367336833066],
            [70.96732750805802, 40.769656479762688],
            [70.951617872666645, 40.774669094003457],
            [70.93146406381544, 40.77474660926859],
            [70.884438511328199, 40.742629706495762],
            [70.87115766779209, 40.741234443414783],
            [70.843304071325861, 40.743404852851654],
            [70.829816522214685, 40.742629706495762],
            [70.812970005261491, 40.736480210693145],
            [70.801911248904958, 40.727307643832688],
            [70.79271284362278, 40.717049872253767],
            [70.782015821572884, 40.707851466971618],
            [70.756292785791914, 40.69557805109315],
            [70.758502238000119, 40.703924866000136],
            [70.767132203000074, 40.737617900000089],
            [70.728891643000111, 40.754464417000065],
            [70.637424357000043, 40.77058746300014],
            [70.62176639900008, 40.785987040000123],
            [70.619337606000101, 40.803195293000101],
            [70.62874271700008, 40.815856018000034],
            [70.648689819000083, 40.817509664000085],
            [70.68346805900012, 40.794513652000035],
            [70.703156779000096, 40.788932597000041],
            [70.70512048300003, 40.807432760000069],
            [70.696852254000078, 40.816217754000036],
            [70.514434448000031, 40.972590637000025],
            [70.482911825000031, 40.993519592000013],
            [70.479759563000073, 40.996465149000102],
            [70.477227417000108, 40.999824117000031],
            [70.458006358003502, 41.015736633019628],
            [70.45805626768265, 41.015894679895581],
            [70.465342645046974, 41.038968207017149],
            [70.463327264341785, 41.113149725779152],
            [70.458779738094393, 41.135163885704713],
            [70.438470899612298, 41.161157131996077],
            [70.418472121291359, 41.181569322366329],
            [70.411960891182218, 41.189630846086629],
            [70.409790479946707, 41.196348781770809],
            [70.409480421584135, 41.202963365566887],
            [70.411340772658491, 41.209009508581943],
            [70.43040937679126, 41.239524440900254],
            [70.433354934382692, 41.245751451068628],
            [70.438005811618893, 41.25970408817318],
            [70.434440139101127, 41.26771393505004],
            [70.406844924153916, 41.270659491742208],
            [70.403382602624959, 41.274251004480249],
            [70.399971957939499, 41.279522000240121],
            [70.399661899576984, 41.294947414791324],
            [70.397181430878277, 41.306445420719569],
            [70.398576694858548, 41.313757636505699],
            [70.401418897863834, 41.319364529049807],
            [70.450253127280007, 41.368534654350782],
            [70.45939985481948, 41.381298733050102],
            [70.470781708904497, 41.404879075904432],
            [70.477227417000108, 41.404657288000124],
            [70.511333862000072, 41.414475810000084],
            [70.63380700700003, 41.467495829000086],
            [70.667810099000064, 41.471371562000058],
            [70.68672367400012, 41.462483216000038],
            [70.703570191000097, 41.445481669000117],
            [70.759690796000029, 41.372514547000094],
            [70.769199259000118, 41.352102356000046],
            [70.771059611000055, 41.331018372000102],
            [70.756280152000045, 41.269626771000048],
            [70.758037150000121, 41.2521601360001],
            [70.770439494000073, 41.238517558000069],
            [70.786562541000137, 41.24042958600009],
            [70.805992880000133, 41.247457581000106],
            [70.828523803000053, 41.249111227000071],
            [70.847747436000077, 41.243065084000108],
            [70.865730835000079, 41.233194886000035],
            [70.882163941000044, 41.220482483000069],
            [70.895961548000059, 41.206116435000069],
            [70.914203329000031, 41.193042297000034],
            [70.934150431000035, 41.191440328000084],
            [70.977145223000036, 41.196349589000107],
            [71.024687541000048, 41.189786682000033],
            [71.047011760000032, 41.182035218000038],
            [71.067578980000121, 41.169736226000083],
            [71.085769084000049, 41.162036439000104],
            [71.123079468000071, 41.158212382000073],
            [71.139099162000036, 41.148393860000084],
            [71.164420614000107, 41.116199443000141],
            [71.180130249000115, 41.108137919000058],
            [71.189948771000047, 41.127464905000096],
            [71.187881714000071, 41.148445537000015],
            [71.183024129000103, 41.166377259000086],
            [71.185711304000108, 41.18022654300006],
            [71.206381877000126, 41.188753154000068],
            [71.230153036000104, 41.187254537000058],
            [71.241521851000073, 41.175162252000064],
            [71.253820841000106, 41.137490133000114],
            [71.263380982000115, 41.123485820000042],
            [71.276145061000079, 41.113150533000123],
            [71.289477580000096, 41.11387400400011],
            [71.300226278000139, 41.133045960000118],
            [71.325237671000139, 41.157333883000035],
            [71.393657267000094, 41.112737122000112],
            [71.416084839000121, 41.127361552000082],
            [71.421459188000142, 41.162088115000103],
            [71.412570842000036, 41.334687398000113],
            [71.418772014000126, 41.34739980100008],
            [71.432466268000042, 41.344815980000078],
            [71.480628703000036, 41.310761211000113],
            [71.523830201000067, 41.296653545000083],
            [71.55793664600003, 41.301717835000062],
            [71.58553186100005, 41.323525289000088],
            [71.618811483000059, 41.37778554300013],
            [71.633074178000072, 41.411323547000052],
            [71.637414999000043, 41.431270651000062],
            [71.633694296000044, 41.449615784000031],
            [71.605582316000095, 41.476849264000037],
            [71.595557088000078, 41.49348907500007],
            [71.595557088000078, 41.493695781000014],
            [71.595453736000138, 41.49374745700004],
            [71.595453736000138, 41.493799134000142],
            [71.615297485000042, 41.532143046000073],
            [71.627389770000093, 41.543201803000073],
            [71.649507284000038, 41.549919739000103],
            [71.671418091000078, 41.547387594000099],
            [71.684130494000044, 41.534055075000083],
            [71.68940148900009, 41.514624735000098],
            [71.689504842000076, 41.493799134000142],
            [71.67110803300011, 41.437626852000079],
            [71.671934855000131, 41.425482890000069],
            [71.681443319000095, 41.422847392000079],
          ],
          [
            [70.645072469000127, 40.935176900000073],
            [70.643418823000047, 40.9522818],
            [70.630499715000042, 40.976414694000056],
            [70.616650432000085, 40.993519592000013],
            [70.593447713000103, 41.009384257000107],
            [70.565904175000071, 41.018375956000057],
            [70.54978112800012, 41.015223694000014],
            [70.561460002000103, 40.994604798000097],
            [70.562493531000086, 40.993777975000086],
            [70.579133342000091, 40.980858866000062],
            [70.60838220200003, 40.95093821200004],
            [70.62522871900012, 40.938690898000118],
            [70.645072469000127, 40.935176900000073],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "UZB-370",
        OBJECTID_1: 410,
        diss_me: 370,
        adm1_cod_1: "UZB-370",
        iso_3166_2: "UZ-",
        wikipedia: null,
        iso_a2: "UZ",
        adm0_sr: 1,
        name: "Jizzakh",
        name_alt: "Dzhizak|Jizzax",
        name_local: null,
        type: "Wiloyat",
        type_en: "Region",
        code_local: null,
        code_hasc: "UZ.JI",
        note: null,
        hasc_maybe: null,
        region: null,
        region_cod: null,
        provnum_ne: 3,
        gadm_level: 1,
        check_me: 10,
        scalerank: 4,
        datarank: 6,
        abbrev: null,
        postal: "JI",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 4,
        featurecla: "Admin-1 scale rank",
        name_len: 7,
        mapcolor9: 5,
        mapcolor13: 4,
        fips: "UZ04",
        fips_alt: "UZ|UZ15",
        woe_id: 2347661,
        woe_label: "Jizzakh, UZ, Uzbekistan",
        woe_name: "Jizzakh",
        latitude: 40.390500000000003,
        longitude: 67.655600000000007,
        sov_a3: "UZB",
        adm0_a3: "UZB",
        adm0_label: 2,
        admin: "Uzbekistan",
        geonunit: "Uzbekistan",
        gu_a3: "UZB",
        gn_id: 1484844,
        gn_name: "Jizzakh Province",
        gns_id: -3431447,
        gns_name: "Jizzax Viloyati",
        gn_level: 1,
        gn_region: null,
        gn_a1_code: "UZ.15",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "vie",
        gns_adm1: "UZ15",
        gns_region: null,
        color: "#ce380a",
        jurisdiction_id: 261,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [68.034379110000089, 41.042715556000076],
            [68.040321900000095, 41.046436259000103],
            [68.04636804200004, 41.050208639000118],
            [68.052775920000045, 41.052534078000065],
            [68.059080445000063, 41.054756165000043],
            [68.065178264000053, 41.054084371000073],
            [68.07137943500004, 41.05330922400006],
            [68.076857137000104, 41.047986552000111],
            [68.08212813300014, 41.042715556000076],
            [68.089052776000131, 41.053929342000032],
            [68.095977417000086, 41.065143128000074],
            [68.106622762000086, 41.065918274000097],
            [68.117371460000129, 41.066745097000108],
            [68.119335165000052, 41.056099752000108],
            [68.121195516000057, 41.045506083000134],
            [68.1212988690001, 41.044369202000041],
            [68.121453898000141, 41.043283997000032],
            [68.120161987000074, 41.03868479500008],
            [68.118921753000052, 41.034033916000027],
            [68.123675985000034, 41.032845357000099],
            [68.128481893000128, 41.031656800000064],
            [68.129980510000053, 41.030623271000081],
            [68.131324097000117, 41.029641419000114],
            [68.119851929000106, 41.023233541000081],
            [68.10822473200011, 41.016825663000049],
            [68.104038941000113, 41.015223694000014],
            [68.099698120000085, 41.013621724000075],
            [68.103780558000039, 41.011916403000015],
            [68.107862996000051, 41.010262757000078],
            [68.110343465000085, 41.007885641000115],
            [68.112668905000135, 41.005508525000067],
            [68.116751343000033, 40.999669088000076],
            [68.120885457000099, 40.993829652000102],
            [68.120782105000046, 40.979515279000111],
            [68.120575399000074, 40.965304261000142],
            [68.115717815000096, 40.948509420000079],
            [68.110756877000142, 40.931662903000102],
            [68.103315470000098, 40.915333151000084],
            [68.095977417000086, 40.898951722000021],
            [68.088225952000073, 40.885929261000086],
            [68.080577841000036, 40.872958476000065],
            [68.07515181500014, 40.866395569000076],
            [68.069519083000102, 40.859832662000088],
            [68.062129354000092, 40.853528138000087],
            [68.054739624000092, 40.847223612000079],
            [68.04652307200007, 40.842262675000114],
            [68.038203165000084, 40.837198385000136],
            [68.020633179000129, 40.831152243000105],
            [68.00306319200007, 40.825157777000072],
            [67.998619019000046, 40.822057190000123],
            [67.994071493000035, 40.818956605000096],
            [67.992521200000112, 40.815029195000108],
            [67.990919231000134, 40.811101787000041],
            [67.994226522000133, 40.809344788000061],
            [67.997482137000134, 40.807536113000083],
            [68.01732588700014, 40.804280497000093],
            [68.037066284000105, 40.801076559000109],
            [68.052259155000115, 40.794513652000035],
            [68.067503703000057, 40.788002421000073],
            [68.113237346000119, 40.753534241000096],
            [68.15891931200008, 40.719117737000062],
            [68.192405640000118, 40.699015605000085],
            [68.226098673000138, 40.67886179700011],
            [68.266496577827525, 40.66270263500931],
            [68.264185012428356, 40.622378647856294],
            [68.250852492048921, 40.611681626705661],
            [68.195920444572835, 40.604524441449826],
            [68.182897984354497, 40.602173163061025],
            [68.1771618997019, 40.600261135143256],
            [68.172511020667116, 40.597315579350465],
            [68.170185580700036, 40.592225449844591],
            [68.168945347249974, 40.568428453210544],
            [68.162382440297392, 40.531247260252826],
            [68.164604526577591, 40.526079617280516],
            [68.170030551968409, 40.522358914232456],
            [68.186773716134098, 40.523883368522633],
            [68.204602085018223, 40.528146673929825],
            [68.214058871819475, 40.528792629975925],
            [68.216746046992455, 40.516157742485774],
            [68.098613722745085, 40.309271144747527],
            [68.094582961334595, 40.295576890960746],
            [68.09179243427343, 40.279763901882518],
            [68.095823194784657, 40.252427070253134],
            [68.100060662669421, 40.24436554653272],
            [68.105951776053644, 40.23666575801829],
            [68.109672479101619, 40.232893378126903],
            [68.137112665317204, 40.229482734340763],
            [68.364437290048897, 40.225658678505198],
            [68.664057244945866, 40.282451077055555],
            [68.66844974066305, 40.282296048323929],
            [68.669534947180068, 40.280125637088474],
            [68.664263949621557, 40.273200994929965],
            [68.659561394642594, 40.268188382487864],
            [68.655375603601271, 40.262839871462859],
            [68.651344842190667, 40.255734361251797],
            [68.637547234717147, 40.217597153885549],
            [68.633361443675653, 40.210465807051321],
            [68.63010582862114, 40.201990872181],
            [68.642043085020305, 40.18426585518506],
            [68.64235314338282, 40.184007472766581],
            [68.642732312923044, 40.183691497882279],
            [68.601993042000117, 40.175429993000137],
            [68.581115764000117, 40.164216208000084],
            [68.606953980000128, 40.142305400000026],
            [68.651705770000092, 40.120756328000084],
            [68.687052450000124, 40.110317688000038],
            [68.696544979783653, 40.111739352244598],
            [68.653825310889374, 40.068252265329733],
            [68.638322381073067, 40.04667735407665],
            [68.63413659003163, 40.035696112985164],
            [68.633206414944084, 40.028151354101652],
            [68.633516473306543, 40.021536770305659],
            [68.634911737286814, 40.01595571528398],
            [68.638787469066415, 40.006679796535423],
            [68.641888055389472, 40.0011762558795],
            [68.642508172114447, 40.000194403948626],
            [68.644678583349901, 39.996628730531484],
            [68.652895134902565, 39.978645331117065],
            [68.656150750856455, 39.969033515584329],
            [68.657546013937349, 39.963607490193539],
            [68.658321161192646, 39.9543057321238],
            [68.655220574869645, 39.921258653364163],
            [68.65165490055324, 39.915910143238534],
            [68.648089227136154, 39.915496731189222],
            [68.644833612081527, 39.919475815756215],
            [68.64080284977166, 39.923403225278605],
            [68.635531854011901, 39.925806178712179],
            [68.62933068226522, 39.926400457914838],
            [68.621269159444182, 39.922653917344434],
            [68.620494012188942, 39.918597317512194],
            [68.622509392894187, 39.914669907989804],
            [68.627160271928972, 39.911259264203665],
            [68.644988640813096, 39.902448432549079],
            [68.649949579109773, 39.899270330960945],
            [68.652895134902565, 39.895446275125494],
            [68.65149987182167, 39.889710191372274],
            [68.648399286397989, 39.882578844538045],
            [68.64312828973874, 39.873793850405832],
            [68.642818231376225, 39.858394273376973],
            [68.640275980099602, 39.848136459364014],
            [68.637753134000093, 39.848938294000021],
            [68.626177612000049, 39.849610088000105],
            [68.615842326000063, 39.828112692000076],
            [68.605817098000045, 39.666882223000073],
            [68.600339397000141, 39.642852682000125],
            [68.587006877000135, 39.623163961000074],
            [68.562925660000076, 39.603578593000066],
            [68.520240926000099, 39.582546285000035],
            [68.506701701000111, 39.567560120000081],
            [68.510158306000108, 39.561411351000061],
            [68.517267708000077, 39.548764820000144],
            [68.51745039900004, 39.548439840000071],
            [68.497710001000087, 39.541411845000056],
            [68.436266724000063, 39.528802796000036],
            [68.386605672000087, 39.525185446000052],
            [68.33756473800014, 39.527924296],
            [68.255864299000052, 39.550558574000064],
            [68.106209351000075, 39.554124248000022],
            [67.764524780000045, 39.622595520000118],
            [67.717602580000118, 39.623370667000131],
            [67.68044722500008, 39.614120586000126],
            [67.587377971000137, 39.562650859000144],
            [67.507434530000069, 39.552935690000083],
            [67.487642456000117, 39.538001200000053],
            [67.494567098000118, 39.493714498000116],
            [67.494567098000118, 39.4936628210001],
            [67.491156454000077, 39.490097148000132],
            [67.487022339000134, 39.487151591000043],
            [67.482371460000081, 39.48529123900002],
            [67.477410522000127, 39.484412740000067],
            [67.451675659000045, 39.487151591000043],
            [67.436482787925229, 39.485704651174302],
            [67.360932652020324, 39.537586981753861],
            [67.319229771236905, 39.566164049531238],
            [67.305070427658052, 39.579031480118772],
            [67.294838495400199, 39.595619615552835],
            [67.297008904837128, 39.664866034439967],
            [67.300264519891641, 39.672720851685909],
            [67.306000603644975, 39.682177639386481],
            [67.319643182386926, 39.684554755297711],
            [67.349925571607855, 39.68527822481019],
            [67.358297153690671, 39.686208400797085],
            [67.367288853397895, 39.689050604701578],
            [67.371629673170844, 39.69359813094897],
            [67.373800082607772, 39.699721788329896],
            [67.374626905807077, 39.711633206307454],
            [67.374316848343824, 39.717265937273197],
            [67.371939732432679, 39.727575384796154],
            [67.37023440919063, 39.732613837458615],
            [67.368839146109735, 39.739512641195432],
            [67.368374058116274, 39.748375148794054],
            [67.36945926283471, 39.763025417888713],
            [67.371526320383396, 39.769975898468971],
            [67.373490025144577, 39.774213365454443],
            [67.381603224808941, 39.780414537201125],
            [67.397106153725986, 39.789251207277317],
            [67.428163690202098, 39.80191193318916],
            [67.449712762134311, 39.808009752148365],
            [67.476687860356435, 39.812686468705579],
            [67.484491000759078, 39.81315155669904],
            [67.499838901843873, 39.81638133423121],
            [67.503714633623474, 39.824236152376528],
            [67.476687860356435, 39.917460435950318],
            [67.459944696190746, 39.938182685582461],
            [67.286776970780579, 39.963116562879094],
            [67.263212518143177, 39.965416165323745],
            [67.253393996136026, 39.962573961419167],
            [67.24424726769729, 39.963607490193539],
            [67.237270948695311, 39.966242988523078],
            [67.220992873422347, 39.974330348866545],
            [67.214171584051314, 39.97399445208228],
            [67.207195265948769, 39.972469997792189],
            [67.191175572194936, 39.971694851436183],
            [67.179703403789006, 39.97285757141978],
            [67.100896844413796, 39.989549058742028],
            [67.089062940802023, 40.004948634871624],
            [67.084412061767239, 40.003605047734766],
            [67.079916213262663, 40.003036606953827],
            [67.066428664151488, 39.99910919923019],
            [67.031650424627344, 40.02877147082674],
            [67.030100131915503, 40.034068305008219],
            [67.029790073553045, 40.042259019937802],
            [67.032890658976783, 40.048821925991092],
            [67.032115512620862, 40.062464504733072],
            [67.027154575223449, 40.068665676479753],
            [67.020178257120847, 40.072515570737011],
            [67.012426791763005, 40.073445745824472],
            [67.006070591284811, 40.073264879570473],
            [67.001109653887397, 40.072283026740251],
            [66.981989373810563, 40.064841619744925],
            [66.975788202063882, 40.063291327033141],
            [66.967881707974414, 40.062309475102182],
            [66.959355096260651, 40.062722887151466],
            [66.947882927854835, 40.064893297487743],
            [66.94323204882005, 40.069259954783121],
            [66.939976433765423, 40.074350084288938],
            [66.935480585260848, 40.119308580126841],
            [66.936255730717448, 40.124657091151761],
            [66.938891229046988, 40.129230454921498],
            [66.94416222480686, 40.131633409254448],
            [66.962920769677794, 40.136413479498515],
            [66.96803673670604, 40.139617417709701],
            [66.970982294297471, 40.14284719524187],
            [66.97532311407042, 40.15189057089313],
            [66.975943230795508, 40.175041612380454],
            [66.948658075110075, 40.20075063693227],
            [66.945402460055504, 40.208760483809243],
            [66.945867547149589, 40.216046861173737],
            [66.94819298711667, 40.221886297714434],
            [66.949123163103536, 40.228914293559825],
            [66.94571251841802, 40.233255113332774],
            [66.940751581020606, 40.236769110805881],
            [66.920236036963502, 40.24436554653272],
            [66.912329542874147, 40.248654690361633],
            [66.908918898188631, 40.253770657389964],
            [66.906593459120813, 40.258912461940525],
            [66.89248579328472, 40.310769762414537],
            [66.89016035331764, 40.327538764102684],
            [66.888455030974853, 40.374564317489188],
            [66.888765090236689, 40.381308092494464],
            [66.889850294955124, 40.387612617028708],
            [66.89186567566037, 40.39355540635691],
            [66.900547316105758, 40.408799953754851],
            [66.906283399858978, 40.42337270848364],
            [66.907368604577414, 40.437118639113834],
            [66.905198195140542, 40.446627101859264],
            [66.901322463360941, 40.456187242347312],
            [66.893570998003099, 40.468822129837463],
            [66.888455030974853, 40.475204168737378],
            [66.883029005584092, 40.478149726328809],
            [66.877137893099246, 40.477736314279525],
            [66.850731235658031, 40.473395494506462],
            [66.82794192937655, 40.47256867130713],
            [66.811767205991714, 40.474429023280749],
            [66.795644158551113, 40.477839667067002],
            [66.754096307398697, 40.487063909871523],
            [66.62805748906186, 40.49065542350894],
            [66.620150994972391, 40.49509959606948],
            [66.77316491063209, 40.721907456863619],
            [66.801225212673387, 40.775211697262051],
            [66.779366083278148, 40.804925645702014],
            [66.770219353940035, 40.822366440958461],
            [66.768514031597306, 40.82696564404921],
            [66.768514031597306, 40.843760484159048],
            [66.770994501195219, 40.861718045151633],
            [66.771304558658471, 40.871562405580534],
            [66.77083947066501, 40.878874620467371],
            [66.768824089959764, 40.88344798423708],
            [66.765258417441999, 40.887246202550216],
            [66.759780715207796, 40.890579331970571],
            [66.669346957795881, 40.909286200897469],
            [66.655549351221623, 40.915099799016559],
            [66.649503208206454, 40.91838125339217],
            [66.641079950179574, 40.926132716951372],
            [66.635498895157866, 40.934659329564397],
            [66.633018425559897, 40.939258530856478],
            [66.631313104116487, 40.94473623309068],
            [66.614259880688962, 41.084159246851755],
            [66.626817254712478, 41.096561591244466],
            [66.635188836795351, 41.112167873848279],
            [66.757506952084213, 41.149736640433616],
            [66.757890443844587, 41.153140136204144],
            [66.774094279000053, 41.149944153000035],
            [66.815280396000048, 41.141882630000026],
            [66.931397339000057, 41.147153626000062],
            [67.028962443000069, 41.151494446000129],
            [67.174793335000118, 41.15810902900003],
            [67.281711874000052, 41.162966614000055],
            [67.409300985000129, 41.168702698000118],
            [67.490536336000105, 41.172423402000021],
            [67.620244182000135, 41.178366191000038],
            [67.748143351000067, 41.1841022750001],
            [67.75956384300008, 41.181673483000054],
            [67.770932658000106, 41.179296367000092],
            [67.792016642000135, 41.170976461000123],
            [67.812997274000111, 41.162708232000085],
            [67.823952678000069, 41.159762676000071],
            [67.834908081000094, 41.156868795000094],
            [67.844519898000101, 41.157902324000077],
            [67.854131714000118, 41.158987528000068],
            [67.883070516000032, 41.17345692900011],
            [67.912009318000116, 41.18797800700014],
            [67.924670044000038, 41.194179179000031],
            [67.937434123000116, 41.200380351000035],
            [67.943531942000106, 41.186117655000146],
            [67.949474731000123, 41.171906637000092],
            [67.950766642000133, 41.171234843],
            [67.951903524000045, 41.170563050000112],
            [67.955417522000118, 41.16498199500009],
            [67.958931519000089, 41.159452616000095],
            [67.980945679000058, 41.137490133000114],
            [68.00306319200007, 41.1156310020001],
            [68.008127482000134, 41.10674265600008],
            [68.013191773000131, 41.09785430900007],
            [68.017119182000101, 41.078527323000117],
            [68.021149943000069, 41.059148662000041],
            [68.027661174000059, 41.050932109000115],
            [68.034379110000089, 41.042715556000076],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "UZB-371",
        OBJECTID_1: 411,
        diss_me: 371,
        adm1_cod_1: "UZB-371",
        iso_3166_2: "UZ-",
        wikipedia: null,
        iso_a2: "UZ",
        adm0_sr: 1,
        name: "Sirdaryo",
        name_alt: "Syr-Dar'ya",
        name_local: null,
        type: "Wiloyat",
        type_en: "Region",
        code_local: null,
        code_hasc: "UZ.SI",
        note: null,
        hasc_maybe: null,
        region: null,
        region_cod: null,
        provnum_ne: 1,
        gadm_level: 1,
        check_me: 10,
        scalerank: 4,
        datarank: 6,
        abbrev: null,
        postal: "SI",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 4,
        featurecla: "Admin-1 scale rank",
        name_len: 8,
        mapcolor9: 5,
        mapcolor13: 4,
        fips: "UZ11",
        fips_alt: "UZ|UZ16",
        woe_id: 2347669,
        woe_label: "Sirdaryo, UZ, Uzbekistan",
        woe_name: "Sirdaryo",
        latitude: 39.951900000000002,
        longitude: 68.803700000000006,
        sov_a3: "UZB",
        adm0_a3: "UZB",
        adm0_label: 2,
        admin: "Uzbekistan",
        geonunit: "Uzbekistan",
        gu_a3: "UZB",
        gn_id: 1484840,
        gn_name: "Sirdaryo Viloyati",
        gns_id: -3431457,
        gns_name: "Sirdaryo Viloyati",
        gn_level: 1,
        gn_region: null,
        gn_a1_code: "UZ.16",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "vie",
        gns_adm1: "UZ16",
        gns_region: null,
        color: "#008000",
        jurisdiction_id: 267,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [68.9869824620001, 40.091714173000142],
              [68.985432170000053, 40.077141419000085],
              [68.97726729300004, 40.064894104000146],
              [68.954529663000102, 40.062051900000185],
              [68.881769247000108, 40.070836894000152],
              [68.861512085000214, 40.065824280000115],
              [68.826062053000072, 40.042053121000052],
              [68.813142944000077, 40.038900859000094],
              [68.790405314000196, 40.070165101],
              [68.774695679000132, 40.079880270000118],
              [68.761724894000196, 40.061483459000115],
              [68.76384362800016, 40.052491761000098],
              [68.781620321000077, 40.041536357000112],
              [68.785030966000107, 40.03393992100014],
              [68.782912232000029, 40.013424377000078],
              [68.784410848000078, 40.004019267000118],
              [68.789371785000213, 39.993994039000185],
              [68.877428426000137, 39.900563050000116],
              [68.880787395000226, 39.878342183000157],
              [68.838257691000109, 39.864647930000146],
              [68.815365031000084, 39.891881409000135],
              [68.800120483000143, 39.935134583000021],
              [68.779759969000082, 39.969447733000109],
              [68.758882690000206, 39.980558167000041],
              [68.752009725000079, 39.972755025000126],
              [68.755575399000094, 39.954048157000145],
              [68.765238892000156, 39.932447409000147],
              [68.787614787000138, 39.895757141],
              [68.787356405000168, 39.881701152000076],
              [68.775212443000072, 39.862529195000079],
              [68.771440064000132, 39.860513814000043],
              [68.760949748000172, 39.859118550000133],
              [68.757539103000141, 39.857516582000116],
              [68.750407756000214, 39.842478740000118],
              [68.750614461000083, 39.841083476000151],
              [68.750511108000154, 39.836070862000085],
              [68.748340698000078, 39.826148987000082],
              [68.741622762000105, 39.819637757000024],
              [68.728186890000103, 39.824960429000058],
              [68.701521851000024, 39.858446757000152],
              [68.685088745000172, 39.868006897000058],
              [68.651085653000138, 39.844700826000022],
              [68.640275980099688, 39.848136459363971],
              [68.64281823137631, 39.858394273376931],
              [68.643128289738769, 39.873793850405875],
              [68.648399286397961, 39.882578844538031],
              [68.651499871821699, 39.889710191372231],
              [68.652895134902593, 39.895446275125451],
              [68.649949579109801, 39.899270330960988],
              [68.644988640813182, 39.902448432549122],
              [68.627160271929057, 39.911259264203707],
              [68.622509392894273, 39.914669907989847],
              [68.620494012188914, 39.918597317512152],
              [68.621269159444211, 39.922653917344476],
              [68.629330682265305, 39.92640045791488],
              [68.635531854011873, 39.925806178712222],
              [68.640802849771688, 39.923403225278562],
              [68.644833612081555, 39.919475815756257],
              [68.648089227136182, 39.915496731189265],
              [68.651654900553211, 39.915910143238577],
              [68.655220574869674, 39.921258653364205],
              [68.658321161192731, 39.954305732123842],
              [68.657546013937377, 39.96360749019361],
              [68.656150750856483, 39.969033515584286],
              [68.652895134902593, 39.978645331117107],
              [68.644678583349929, 39.996628730531526],
              [68.642508172114475, 40.000194403948583],
              [68.641888055389501, 40.001176255879457],
              [68.638787469066443, 40.006679796535465],
              [68.634911737286899, 40.015955715284022],
              [68.633516473306571, 40.021536770305644],
              [68.633206414944112, 40.028151354101695],
              [68.634136590031659, 40.035696112985207],
              [68.638322381073039, 40.046677354076692],
              [68.653825310889403, 40.068252265329775],
              [68.696544979783681, 40.111739352244641],
              [68.930655152000071, 40.146801250000109],
              [68.97726729300004, 40.121014710000011],
              [68.983778524000144, 40.10701039700011],
              [68.9869824620001, 40.091714173000142],
            ],
          ],
          [
            [
              [68.708137240741195, 40.907632555398052],
              [68.711392856695085, 40.897529812550871],
              [68.712736443831858, 40.873836167804868],
              [68.715268589373892, 40.866498115395771],
              [68.720591261977262, 40.863914293010211],
              [68.729427932053483, 40.868926907251037],
              [68.733768751826432, 40.869030260038429],
              [68.736714309417977, 40.862338161876679],
              [68.739969923573113, 40.845052395351686],
              [68.756247999745341, 40.85006500869325],
              [68.778262159670902, 40.847093614478652],
              [68.800017938077445, 40.839729722748487],
              [68.815055779900405, 40.831383979087363],
              [68.823995802764074, 40.822547309011057],
              [68.835622999901574, 40.805519924005253],
              [68.843012730053459, 40.797871812334265],
              [68.849834019424378, 40.793918565289616],
              [68.876499058384837, 40.783609116867282],
              [68.88750613789793, 40.77704620991473],
              [68.892880487344627, 40.771826890998184],
              [68.903215773289304, 40.755652166714171],
              [68.904145949276113, 40.751259670996959],
              [68.902285598201871, 40.739580797016274],
              [68.903215773289304, 40.735188300399685],
              [68.909882033029447, 40.73022736300247],
              [68.916858352031312, 40.729968980584076],
              [68.923938022921419, 40.730847479727359],
              [68.931121046599088, 40.728987127753825],
              [68.944660271654215, 40.71942698816504],
              [68.955719028910124, 40.707179674302509],
              [68.95799279203365, 40.693692125191362],
              [68.944763625341153, 40.680566311286228],
              [68.960214878313963, 40.673538316340327],
              [68.974270868206105, 40.677000636969908],
              [68.988223505310515, 40.684131984703399],
              [69.003468051809278, 40.688007717382177],
              [69.01292483861036, 40.683718574452556],
              [69.009204135562442, 40.673589993183569],
              [68.993184441808552, 40.653255317179188],
              [69.035352410585261, 40.659456488925869],
              [69.053800897093737, 40.658293768942301],
              [69.065583123862126, 40.633334052324656],
              [69.083876580739599, 40.621706855187213],
              [69.088785842192948, 40.612301744329969],
              [69.085065139144973, 40.603749295093891],
              [69.068683710185184, 40.591760361851257],
              [69.068321974979227, 40.584344794176857],
              [69.076073439437693, 40.58070160639393],
              [69.085995314232349, 40.586256822094711],
              [69.096537306651356, 40.59442169950178],
              [69.106149123083554, 40.59863332806556],
              [69.115192498734785, 40.595455227376888],
              [69.121445347325022, 40.588375556486753],
              [69.121858757575666, 40.581295884697298],
              [69.096640659438918, 40.572614244251938],
              [69.087080518950785, 40.558790798356668],
              [69.07576338107512, 40.522901515692368],
              [69.086977167062656, 40.523831692578639],
              [69.097725865056617, 40.523263250898324],
              [69.107647739851387, 40.520627753467991],
              [69.116742792346002, 40.51546011049571],
              [69.097880893788187, 40.507450262719445],
              [69.099947951336873, 40.49936290147663],
              [69.110438266912553, 40.491740628227333],
              [69.116742792346002, 40.485358588428042],
              [69.118758172151985, 40.477271227185398],
              [69.122375523311831, 40.468512071474962],
              [69.12402916881112, 40.459546210189458],
              [69.119843377769683, 40.450916245688134],
              [69.105632359146028, 40.436705227064678],
              [69.100102980068499, 40.429263820968558],
              [69.094160190740212, 40.419135239699571],
              [69.091576369254113, 40.412313951227887],
              [69.070647414046931, 40.320639960365909],
              [69.084910108614594, 40.293018906997034],
              [69.09142133962311, 40.284673163335825],
              [69.093281690697467, 40.270048733562149],
              [69.079018996129747, 40.230309557540053],
              [69.073748000369989, 40.217209581157263],
              [69.07363981716199, 40.216940714221195],
              [69.042017863000154, 40.229173483000082],
              [69.019228556000172, 40.23206736300007],
              [68.856551148000079, 40.212740377000117],
              [68.836655721000085, 40.206435852000098],
              [68.801877482000094, 40.191139628000073],
              [68.778468059000062, 40.18876251200011],
              [68.720538778000076, 40.196513978000112],
              [68.698317912000078, 40.194963685000104],
              [68.642732312923073, 40.183691497882322],
              [68.642353143382849, 40.184007472766623],
              [68.642043085020333, 40.184265855185018],
              [68.630105828621112, 40.201990872180957],
              [68.633361443675625, 40.210465807051278],
              [68.637547234717232, 40.217597153885592],
              [68.651344842190696, 40.255734361251839],
              [68.6553756036013, 40.262839871462901],
              [68.659561394642623, 40.268188382487907],
              [68.664263949621642, 40.273200994930008],
              [68.66953494718004, 40.280125637088432],
              [68.668449740663078, 40.282296048323886],
              [68.664057244945838, 40.282451077055512],
              [68.364437290048983, 40.225658678505269],
              [68.137112665317233, 40.229482734340806],
              [68.109672479101647, 40.23289337812686],
              [68.105951776053672, 40.236665758018361],
              [68.100060662669449, 40.244365546532677],
              [68.095823194784685, 40.252427070253177],
              [68.091792434273458, 40.279763901882561],
              [68.094582961334567, 40.295576890960817],
              [68.098613722745114, 40.309271144747484],
              [68.21674604699254, 40.516157742485845],
              [68.214058871819503, 40.528792629975968],
              [68.204602085018195, 40.528146673929811],
              [68.18677371613407, 40.523883368522704],
              [68.170030551968495, 40.522358914232527],
              [68.164604526577619, 40.526079617280473],
              [68.162382440297421, 40.531247260252869],
              [68.168945347249945, 40.568428453210586],
              [68.170185580700007, 40.592225449844634],
              [68.172511020667088, 40.597315579350536],
              [68.177161899701872, 40.600261135143299],
              [68.182897984354582, 40.602173163060982],
              [68.195920444572806, 40.604524441449783],
              [68.250852492048892, 40.611681626705618],
              [68.264185012428385, 40.622378647856252],
              [68.266496577827553, 40.662702635009353],
              [68.343920939000071, 40.631732890000151],
              [68.46174320500009, 40.584655660000081],
              [68.463396851000226, 40.587187806000159],
              [68.465050496000089, 40.589719950000145],
              [68.471148315000022, 40.594474182000127],
              [68.477349488000215, 40.599228414000137],
              [68.484894247000085, 40.598918356000141],
              [68.492439006000012, 40.598608297000069],
              [68.496314738000166, 40.602122295000029],
              [68.499880412000067, 40.605636292000085],
              [68.502670940000115, 40.609977112000152],
              [68.505461467000174, 40.614317933000123],
              [68.510112346000113, 40.616746724],
              [68.514814901000108, 40.619123840000142],
              [68.520550984000153, 40.618348694000034],
              [68.526338745000061, 40.617573548000124],
              [68.546337524000165, 40.610028789000168],
              [68.566232951000103, 40.602484029000109],
              [68.579668823000105, 40.600933736000101],
              [68.593001343000225, 40.599486796000107],
              [68.603543335000126, 40.604292704000116],
              [68.614240357000227, 40.609098613000029],
              [68.617392619000185, 40.615661520000103],
              [68.620389852000102, 40.622224427000091],
              [68.610674683000155, 40.626100159000046],
              [68.601114543000079, 40.630027568000017],
              [68.613258504000072, 40.645168763000029],
              [68.625454142000052, 40.660413310000152],
              [68.62638431800022, 40.663772278000167],
              [68.627417847000203, 40.667131247],
              [68.623128703000106, 40.672712301],
              [68.618839559000065, 40.67824167900001],
              [68.615428914000148, 40.684442851000185],
              [68.612121623000149, 40.690540671000136],
              [68.610364624000084, 40.696948548000179],
              [68.608504272000147, 40.703356425000138],
              [68.60902103700019, 40.70960927400013],
              [68.609434448000087, 40.715810445000116],
              [68.596928752000196, 40.718084209000139],
              [68.584216349000172, 40.720357972000144],
              [68.579358765000023, 40.722425028000103],
              [68.574604533000155, 40.724492086000069],
              [68.570470419000145, 40.728471171000152],
              [68.566336303000213, 40.732501933000052],
              [68.563029012000101, 40.741803691000158],
              [68.559773397000214, 40.751312155000065],
              [68.554657430000105, 40.793170065000041],
              [68.549696493000084, 40.835027974000113],
              [68.551453491000046, 40.842727763000099],
              [68.553417196000197, 40.850427551000095],
              [68.556156046000154, 40.852908020000157],
              [68.558998250000201, 40.855491842000063],
              [68.56209883600016, 40.857352194000086],
              [68.565406128000035, 40.859160869000064],
              [68.568713419000147, 40.8648452770001],
              [68.571865682000208, 40.870478008000035],
              [68.572537476000065, 40.875283915000139],
              [68.573157593000161, 40.880141500000079],
              [68.571814006000096, 40.89135528600012],
              [68.570573771000142, 40.902517395000061],
              [68.570677124000071, 40.90799509700004],
              [68.570883830000156, 40.913524475000131],
              [68.573002563000131, 40.912542624000153],
              [68.575328003000067, 40.911612448000099],
              [68.57997888200012, 40.910217184000132],
              [68.584733114000102, 40.908821920000051],
              [68.586903523000188, 40.907685039000128],
              [68.589177287000126, 40.906599833000044],
              [68.594654989000077, 40.918382060000155],
              [68.600132690000095, 40.93006093400011],
              [68.613981974000154, 40.937295635000098],
              [68.627934611000143, 40.944530335000152],
              [68.649524450846542, 40.951690602169734],
              [68.700179070707549, 40.916340034265247],
              [68.708137240741195, 40.907632555398052],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "UZB-372",
        OBJECTID_1: 412,
        diss_me: 372,
        adm1_cod_1: "UZB-372",
        iso_3166_2: "UZ-TO",
        wikipedia: null,
        iso_a2: "UZ",
        adm0_sr: 1,
        name: "Tashkent",
        name_alt: "Taschkent|Toshkent",
        name_local: null,
        type: "Wiloyat",
        type_en: "Region",
        code_local: null,
        code_hasc: "UZ.TA",
        note: null,
        hasc_maybe: null,
        region: null,
        region_cod: null,
        provnum_ne: 7,
        gadm_level: 1,
        check_me: 10,
        scalerank: 4,
        datarank: 6,
        abbrev: null,
        postal: "TA",
        area_sqkm: 0,
        sameascity: 7,
        labelrank: 7,
        featurecla: "Admin-1 scale rank",
        name_len: 8,
        mapcolor9: 5,
        mapcolor13: 4,
        fips: "UZ14",
        fips_alt: null,
        woe_id: 2347670,
        woe_label: "Toshkent, UZ, Uzbekistan",
        woe_name: "Tashkent",
        latitude: 41.247,
        longitude: 69.951099999999997,
        sov_a3: "UZB",
        adm0_a3: "UZB",
        adm0_label: 2,
        admin: "Uzbekistan",
        geonunit: "Uzbekistan",
        gu_a3: "UZB",
        gn_id: 1484838,
        gn_name: "Toshkent Viloyati",
        gns_id: -3431460,
        gns_name: "Toshkent Viloyati",
        gn_level: 1,
        gn_region: null,
        gn_a1_code: "UZ.14",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "vie",
        gns_adm1: "UZ14",
        gns_region: null,
        color: "#FF00FF",
        jurisdiction_id: 269,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [71.249868228218077, 42.198385315115075],
            [71.253200725000056, 42.197554627000073],
            [71.238214559000085, 42.160244243000108],
            [71.201007527000058, 42.140788066000141],
            [71.118428589000132, 42.122908020000068],
            [70.977145223000036, 42.044230652000095],
            [70.935907431000032, 42.036866761000084],
            [70.886711467000055, 42.038494568000033],
            [70.845473674000061, 42.030355530000122],
            [70.828058716000044, 41.993742778000069],
            [70.825113160000114, 41.93633026200007],
            [70.814002726000126, 41.919535421000035],
            [70.779327841000111, 41.909665223000047],
            [70.679695679000133, 41.901112773000108],
            [70.648948202000042, 41.88739268000009],
            [70.550091187000078, 41.824063212000084],
            [70.506476277000047, 41.785590108000122],
            [70.477227417000108, 41.738435365000015],
            [70.453662964000102, 41.712080384000046],
            [70.42348392800011, 41.696913351000035],
            [70.390824422000037, 41.685053610000097],
            [70.331189819000031, 41.649629415000078],
            [70.169287557000132, 41.57834177700002],
            [70.148255249000101, 41.552451884000078],
            [70.166548706000071, 41.520205790000034],
            [70.20344567900014, 41.505633037000052],
            [70.344418986000107, 41.493799134000142],
            [70.344522339000036, 41.493695781000014],
            [70.344729045000122, 41.493644104000111],
            [70.344935750000047, 41.49348907500007],
            [70.38224613400007, 41.476435852000122],
            [70.39898929800006, 41.464963685000015],
            [70.413613729000133, 41.45070098900004],
            [70.438056682000138, 41.416077779000034],
            [70.453197876000104, 41.40548411100005],
            [70.470781708904497, 41.404879075904432],
            [70.45939985481948, 41.381298733050102],
            [70.450253127280007, 41.368534654350782],
            [70.401418897863834, 41.319364529049807],
            [70.398576694858548, 41.313757636505699],
            [70.397181430878277, 41.306445420719569],
            [70.399661899576984, 41.294947414791324],
            [70.399971957939499, 41.279522000240121],
            [70.403382602624959, 41.274251004480249],
            [70.406844924153916, 41.270659491742208],
            [70.434440139101127, 41.26771393505004],
            [70.438005811618893, 41.25970408817318],
            [70.433354934382692, 41.245751451068628],
            [70.43040937679126, 41.239524440900254],
            [70.411340772658491, 41.209009508581943],
            [70.409480421584135, 41.202963365566887],
            [70.409790479946707, 41.196348781770809],
            [70.411960891182218, 41.189630846086629],
            [70.418472121291359, 41.181569322366329],
            [70.438470899612298, 41.161157131996077],
            [70.458779738094393, 41.135163885704713],
            [70.463327264341785, 41.113149725779152],
            [70.465342645046974, 41.038968207017149],
            [70.45805626768265, 41.015894679895581],
            [70.458006358003502, 41.015736633019628],
            [70.445704793000118, 41.025920716000115],
            [70.401159709000126, 41.039976705000129],
            [70.363539266000032, 41.032431946000088],
            [70.335892374000082, 40.926960348000136],
            [70.280391886000132, 40.877712708000047],
            [70.207993205000037, 40.84458811500005],
            [70.104226929000049, 40.813323873000058],
            [70.009659057000079, 40.758030090000034],
            [69.825329224000086, 40.714001770000067],
            [69.803315064000117, 40.7056301880001],
            [69.783522990000108, 40.692969462000065],
            [69.741561727000033, 40.65245513900004],
            [69.717687215000126, 40.636280417000052],
            [69.690918823000061, 40.63002756800006],
            [69.660119669000096, 40.638709208000108],
            [69.647510620000048, 40.647442526000077],
            [69.637175333000073, 40.657932841000047],
            [69.618881876000103, 40.681652324000083],
            [69.607823120000091, 40.691780905000115],
            [69.58260502100012, 40.70438995400005],
            [69.570822794000037, 40.712451478000048],
            [69.531858765000038, 40.758598531000075],
            [69.509224487000097, 40.774669902000142],
            [69.477288452000096, 40.777047018000104],
            [69.461682170000074, 40.78433339500009],
            [69.449176474000069, 40.794461975000047],
            [69.435843953000131, 40.802678529000076],
            [69.417757203000122, 40.804228821000095],
            [69.38525272600009, 40.796115622000087],
            [69.369284709000055, 40.789397685000068],
            [69.355073690000097, 40.780354309000103],
            [69.329597208000109, 40.755342916000103],
            [69.314197632000116, 40.72929799500011],
            [69.307169637000072, 40.699325664000071],
            [69.306601197000134, 40.662532044000073],
            [69.313474162000091, 40.609356995000041],
            [69.309340048000081, 40.595817769000064],
            [69.294973999000092, 40.589151510000136],
            [69.234254191000105, 40.585275778000096],
            [69.198442424000064, 40.566517233000098],
            [69.19802901200012, 40.54584666000008],
            [69.238233277000063, 40.493963521000069],
            [69.238336630000106, 40.493963521000069],
            [69.259730672000103, 40.437636211000068],
            [69.269445842000039, 40.419807841000136],
            [69.295490763000032, 40.383944398000096],
            [69.306342814000061, 40.364979147000042],
            [69.312699016000067, 40.342809958000117],
            [69.305309285000078, 40.293820699],
            [69.27311486800005, 40.289583231000051],
            [69.234615927000107, 40.300848694000109],
            [69.208364299000038, 40.29831654900002],
            [69.212498413000048, 40.268757630000096],
            [69.285258830000032, 40.218631490000064],
            [69.285258830000032, 40.194963685000033],
            [69.247690064000039, 40.181734518000042],
            [69.197925659000134, 40.182974752000092],
            [69.108008667000092, 40.20364532500011],
            [69.073639817161904, 40.216940714221209],
            [69.073748000369903, 40.217209581157221],
            [69.079018996129776, 40.230309557539982],
            [69.093281690697438, 40.270048733562192],
            [69.091421339623082, 40.284673163335867],
            [69.084910108614622, 40.293018906996991],
            [69.070647414046903, 40.320639960365867],
            [69.091576369254028, 40.412313951227844],
            [69.094160190740183, 40.419135239699528],
            [69.100102980068471, 40.429263820968515],
            [69.105632359146057, 40.436705227064635],
            [69.119843377769655, 40.450916245688177],
            [69.124029168811091, 40.459546210189416],
            [69.122375523311803, 40.468512071474919],
            [69.118758172151956, 40.47727122718544],
            [69.116742792345974, 40.485358588428085],
            [69.110438266912467, 40.491740628227376],
            [69.099947951336844, 40.499362901476673],
            [69.097880893788158, 40.507450262719402],
            [69.116742792345974, 40.515460110495667],
            [69.107647739851302, 40.520627753468034],
            [69.097725865056645, 40.523263250898282],
            [69.086977167062571, 40.523831692578597],
            [69.075763381075149, 40.522901515692411],
            [69.087080518950756, 40.558790798356625],
            [69.096640659438833, 40.572614244251952],
            [69.121858757575581, 40.581295884697255],
            [69.121445347324936, 40.588375556486682],
            [69.115192498734814, 40.595455227376846],
            [69.106149123083526, 40.598633328065603],
            [69.096537306651328, 40.594421699501822],
            [69.085995314232321, 40.586256822094668],
            [69.076073439437664, 40.580701606393973],
            [69.068321974979199, 40.5843447941769],
            [69.068683710185098, 40.591760361851215],
            [69.085065139144888, 40.60374929509382],
            [69.088785842192863, 40.612301744330011],
            [69.08387658073957, 40.621706855187256],
            [69.065583123862098, 40.633334052324614],
            [69.053800897093708, 40.658293768942229],
            [69.035352410585233, 40.659456488925827],
            [68.99318444180858, 40.653255317179145],
            [69.009204135562413, 40.673589993183612],
            [69.012924838610388, 40.683718574452598],
            [69.003468051809193, 40.68800771738222],
            [68.988223505310543, 40.684131984703328],
            [68.97427086820602, 40.677000636969836],
            [68.960214878313991, 40.673538316340256],
            [68.944763625341068, 40.680566311286185],
            [68.957992792033679, 40.693692125191376],
            [68.955719028910096, 40.707179674302466],
            [68.944660271654243, 40.719426988164997],
            [68.931121046599003, 40.728987127753783],
            [68.923938022921448, 40.730847479727402],
            [68.916858352031284, 40.729968980584005],
            [68.909882033029419, 40.730227363002399],
            [68.903215773289276, 40.735188300399727],
            [68.902285598201786, 40.739580797016202],
            [68.904145949276085, 40.751259670997001],
            [68.903215773289276, 40.7556521667141],
            [68.892880487344598, 40.771826890998142],
            [68.887506137897901, 40.777046209914687],
            [68.876499058384809, 40.78360911686724],
            [68.849834019424407, 40.793918565289573],
            [68.843012730053431, 40.797871812334222],
            [68.835622999901545, 40.80551992400521],
            [68.823995802764102, 40.8225473090111],
            [68.81505577990032, 40.831383979087292],
            [68.80001793807736, 40.83972972274853],
            [68.77826215967093, 40.847093614478695],
            [68.756247999745369, 40.850065008693207],
            [68.739969923573085, 40.845052395351729],
            [68.736714309417891, 40.862338161876636],
            [68.73376875182646, 40.869030260038471],
            [68.729427932053397, 40.868926907250994],
            [68.720591261977177, 40.863914293010168],
            [68.715268589373864, 40.866498115395729],
            [68.71273644383183, 40.873836167804882],
            [68.711392856694999, 40.897529812550829],
            [68.70813724074111, 40.907632555398095],
            [68.700179070707577, 40.916340034265204],
            [68.649524450846513, 40.951690602169776],
            [68.658630412000093, 40.954710592000083],
            [68.689377889000127, 40.96494252500014],
            [68.699558146000072, 40.974399312000116],
            [68.709531698000092, 40.983752747000068],
            [68.727876831000117, 41.023801982000037],
            [68.746273641000073, 41.063799541],
            [68.759864542000059, 41.075530091000104],
            [68.77376550300005, 41.087260641000086],
            [68.808182007000084, 41.098681132000081],
            [68.842443482000135, 41.110101624000094],
            [68.854897502000085, 41.126379700000115],
            [68.867351522000092, 41.142554423],
            [68.873656047000111, 41.149065654000083],
            [68.8802189540001, 41.155525208000114],
            [68.890502564000087, 41.15810902900003],
            [68.900837850000073, 41.160641175000109],
            [68.922231893000117, 41.162759908000083],
            [68.943780965000087, 41.164878642000076],
            [68.961712687000045, 41.171596578000106],
            [68.979747762000045, 41.178211161000092],
            [68.997007691000135, 41.187099508000102],
            [69.014060913000037, 41.196039531000054],
            [69.022587524000073, 41.202395731000081],
            [69.031217489000085, 41.208751933000087],
            [69.035144897000066, 41.214488018000054],
            [69.039175660000069, 41.22027577700004],
            [69.046927124000092, 41.267869771000051],
            [69.054781941000101, 41.315412090000081],
            [69.05436853000009, 41.323628642000102],
            [69.053955119000079, 41.331896871000055],
            [69.052508179000085, 41.337787985000062],
            [69.051164591000031, 41.343627421000036],
            [69.049717651000037, 41.345436096000128],
            [69.048322388000088, 41.347244772000124],
            [69.045996948000038, 41.348536683000077],
            [69.043516480000051, 41.349880270000142],
            [69.038865600000065, 41.354324442000063],
            [69.034266398000057, 41.358716940000136],
            [69.032974487000104, 41.36285105400006],
            [69.031579224000097, 41.366985168000099],
            [69.033904663000044, 41.370499166000059],
            [69.036178426000049, 41.374013163000086],
            [69.040312540000059, 41.376648662],
            [69.044446655000115, 41.379180807000097],
            [69.067546021000112, 41.387759095000035],
            [69.090645386000062, 41.396285706000043],
            [69.100050497000097, 41.39850779300005],
            [69.109455607000086, 41.400678202000051],
            [69.118809041000134, 41.397939352000094],
            [69.128059123000128, 41.395148824000046],
            [69.133123413000078, 41.405535787000076],
            [69.138187704000075, 41.415922751000096],
            [69.150383342000111, 41.424966126000044],
            [69.162682333000134, 41.434061178],
            [69.18986413600004, 41.447083639000141],
            [69.217149292000045, 41.460054423000088],
            [69.225314169000058, 41.461398010000039],
            [69.233324015000107, 41.462638245000079],
            [69.248155152000038, 41.458555806000078],
            [69.262934611000048, 41.454576722000098],
            [69.270272664000061, 41.455351868000093],
            [69.277559042000064, 41.456178690000115],
            [69.296834351000086, 41.46548044900004],
            [69.31616133600005, 41.474833883000088],
            [69.32355106600005, 41.476384176000096],
            [69.331147502000078, 41.477934469000047],
            [69.360809774000074, 41.473800355],
            [69.390678752000099, 41.469717916],
            [69.396466512000131, 41.472353414000111],
            [69.402357626000139, 41.474833883000088],
            [69.40173750800011, 41.480001526000081],
            [69.401117391000128, 41.485169169000102],
            [69.392745809000132, 41.49674469100006],
            [69.384477580000123, 41.508216858000054],
            [69.382720581000115, 41.51348785400009],
            [69.380808553000122, 41.518810526000038],
            [69.387371460000111, 41.531884664000103],
            [69.393779338000115, 41.544958802000053],
            [69.407111858000064, 41.552245179000124],
            [69.420341024000038, 41.559531555],
            [69.448814738000067, 41.566352845000068],
            [69.477288452000096, 41.573122457000096],
            [69.528189738000037, 41.603198141000064],
            [69.57898767100005, 41.633377177000057],
            [69.593198689000133, 41.645831198000053],
            [69.607564738000121, 41.658285218000032],
            [69.615987997000104, 41.66327199300008],
            [69.624566284000139, 41.668310446000049],
            [69.692004028000042, 41.68239227300009],
            [69.759441773000106, 41.696448263],
            [69.815148967000084, 41.698050232000043],
            [69.87085616000013, 41.699574687000052],
            [69.89173344000011, 41.706215109000055],
            [69.912404012000138, 41.71285553000007],
            [69.92764856000008, 41.726317241000118],
            [69.942893107000032, 41.739727275000064],
            [69.949714397000093, 41.747452902000077],
            [69.956535685000119, 41.755178528000073],
            [69.964287150000132, 41.759906922000141],
            [69.972038615000031, 41.764583639000108],
            [69.99999556500012, 41.772335104000035],
            [70.027952515000038, 41.77998321600009],
            [70.069448690000115, 41.802927552000142],
            [70.11109989400012, 41.825975240000105],
            [70.123657267000056, 41.837059835000105],
            [70.136369669000089, 41.848092753000117],
            [70.170372762000113, 41.891991883000031],
            [70.204169149000109, 41.93581349700014],
            [70.211403850000067, 41.941394552000048],
            [70.218586873000049, 41.946923930000054],
            [70.227113485000132, 41.950644633000081],
            [70.235795126000085, 41.954287822000055],
            [70.244890177000059, 41.956716614000129],
            [70.25388187700014, 41.959119568000062],
            [70.262150106000092, 41.962323507000036],
            [70.270314983000105, 41.965553284000123],
            [70.276671183000133, 41.970875956000071],
            [70.282975709000141, 41.976250306000026],
            [70.286748088000138, 41.98498362300009],
            [70.290262085000109, 41.993768616000096],
            [70.30266442900006, 42.014129131000118],
            [70.315066773000126, 42.034592998000079],
            [70.336357462000137, 42.048416443000036],
            [70.357906535000097, 42.062239889000097],
            [70.406740764000062, 42.078001201000063],
            [70.455419963000054, 42.093736674000041],
            [70.462758016000066, 42.092651469000145],
            [70.470199422000121, 42.091617940000077],
            [70.479397827000071, 42.086708680000044],
            [70.488492879000091, 42.081876933000046],
            [70.49650272700012, 42.075133159000103],
            [70.504460897000115, 42.068466899000072],
            [70.508233277000045, 42.061981506000024],
            [70.511850627000115, 42.055418599000035],
            [70.515364624000142, 42.046039327000102],
            [70.518878622000102, 42.03668589300014],
            [70.526630086000125, 42.030820618000064],
            [70.534226522000097, 42.024981181000072],
            [70.544820190000053, 42.021854757000114],
            [70.555258830000128, 42.018702494000053],
            [70.567092733000038, 42.017513937000047],
            [70.579029989000048, 42.016247864000093],
            [70.594532918000084, 42.021854757000114],
            [70.610242553000035, 42.027409973000033],
            [70.62083622300014, 42.042163595000034],
            [70.63148156800014, 42.056865540000047],
            [70.640731649000031, 42.075133159000103],
            [70.649826701000109, 42.093400777000056],
            [70.661453898000048, 42.109678853000077],
            [70.673184449000132, 42.125982768000114],
            [70.68724043800006, 42.138462627000109],
            [70.701141398000061, 42.150942485000087],
            [70.706412395000086, 42.157634583000117],
            [70.711838420000049, 42.164326681000034],
            [70.71586918100013, 42.173654276000065],
            [70.71989994300003, 42.182904359000077],
            [70.727134643000113, 42.190139059000046],
            [70.734265991000029, 42.197296244000086],
            [70.746254923000095, 42.197657980000088],
            [70.75814050300005, 42.198019715000072],
            [70.781394897000041, 42.193213806000074],
            [70.804545940000139, 42.188433737000082],
            [70.823459513000103, 42.190500793000041],
            [70.842373088000102, 42.192542013000093],
            [70.861183309000126, 42.199699199000037],
            [70.879993530000036, 42.206933899],
            [70.913893270000131, 42.227552796000111],
            [70.947793009000065, 42.248145854000114],
            [70.969600464000109, 42.263467916000081],
            [71.014042195000059, 42.287704163000058],
            [71.045771526000067, 42.29095977800003],
            [71.077604207000036, 42.281167094000068],
            [71.217854044000035, 42.20636545800005],
            [71.249868228218077, 42.198385315115075],
          ],
          [
            [69.138971741852401, 41.280692009228829],
            [69.155887235007924, 41.288259467000813],
            [69.157667813518799, 41.275795419223186],
            [69.164790126663036, 41.264666804429424],
            [69.189718222218175, 41.243299865896091],
            [69.205743426118204, 41.25576391367369],
            [69.206633715373698, 41.251757612923541],
            [69.200846836112589, 41.241964432012878],
            [69.213310883890188, 41.225048938857441],
            [69.243447576644314, 41.219172345927035],
            [69.275185977250999, 41.249531889784834],
            [69.32593245761683, 41.284253165351288],
            [69.363769745577258, 41.298497791639761],
            [69.353531420938339, 41.306510394039464],
            [69.360653733183199, 41.315858429423002],
            [69.350415408544222, 41.32743218794505],
            [69.336615926883496, 41.334109356461653],
            [69.351906307330182, 41.353972213946577],
            [69.348189685405657, 41.370166065911206],
            [69.311242687599929, 41.38797184832211],
            [69.280527712783737, 41.391087860716169],
            [69.256935050212348, 41.403551908493796],
            [69.223172345827095, 41.403275884974107],
            [69.170131861296397, 41.36304375276697],
            [69.162119258896723, 41.331438488695227],
            [69.136068807629385, 41.282827653573321],
            [69.138971741852401, 41.280692009228829],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        adm1_code: "UZB-4828",
        OBJECTID_1: 6357,
        diss_me: 4828,
        adm1_cod_1: "UZB-4828",
        iso_3166_2: "UZ-TK",
        wikipedia: null,
        iso_a2: "UZ",
        adm0_sr: 1,
        name: "Tashkent",
        name_alt: "Taschkent|Toshkent",
        name_local: null,
        type: "Wiloyat",
        type_en: "Region",
        code_local: null,
        code_hasc: "UZ.TK",
        note: null,
        hasc_maybe: null,
        region: null,
        region_cod: null,
        provnum_ne: 7,
        gadm_level: 1,
        check_me: 10,
        scalerank: 4,
        datarank: 6,
        abbrev: null,
        postal: "TK",
        area_sqkm: 0,
        sameascity: 7,
        labelrank: 7,
        featurecla: "Admin-1 scale rank",
        name_len: 8,
        mapcolor9: 5,
        mapcolor13: 4,
        fips: "UZ13",
        fips_alt: null,
        woe_id: 20070172,
        woe_label: "Toshkent, UZ, Uzbekistan",
        woe_name: "Tashkent",
        latitude: 41.323,
        longitude: 69.246399999999994,
        sov_a3: "UZB",
        adm0_a3: "UZB",
        adm0_label: 2,
        admin: "Uzbekistan",
        geonunit: "Uzbekistan",
        gu_a3: "UZB",
        gn_id: 1484839,
        gn_name: "Toshkent Shahri",
        gns_id: -3431459,
        gns_name: "Toshkent Shahri",
        gn_level: 1,
        gn_region: null,
        gn_a1_code: "UZ.13",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "vie",
        gns_adm1: "UZ13",
        gns_region: null,
        color: "#FFA500",
        jurisdiction_id: 270,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [69.243447576644314, 41.219172345927035],
            [69.213310883890188, 41.225048938857441],
            [69.200846836112589, 41.241964432012878],
            [69.206633715373698, 41.251757612923541],
            [69.205743426118204, 41.25576391367369],
            [69.189718222218175, 41.243299865896091],
            [69.164790126663036, 41.264666804429424],
            [69.157667813518799, 41.275795419223186],
            [69.155887235007924, 41.288259467000813],
            [69.138971741852401, 41.280692009228829],
            [69.136068807629385, 41.282827653573321],
            [69.162119258896723, 41.331438488695227],
            [69.170131861296397, 41.36304375276697],
            [69.223172345827095, 41.403275884974107],
            [69.256935050212348, 41.403551908493796],
            [69.280527712783737, 41.391087860716169],
            [69.311242687599929, 41.38797184832211],
            [69.348189685405657, 41.370166065911206],
            [69.351906307330182, 41.353972213946577],
            [69.336615926883496, 41.334109356461653],
            [69.350415408544222, 41.32743218794505],
            [69.360653733183199, 41.315858429423002],
            [69.353531420938339, 41.306510394039464],
            [69.363769745577258, 41.298497791639761],
            [69.32593245761683, 41.284253165351288],
            [69.275185977250999, 41.249531889784834],
            [69.243447576644314, 41.219172345927035],
          ],
        ],
      },
    },
  ],
};
