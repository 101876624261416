import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getMessages } from "../../reducers/LocalizationReducer";
import PropTypes from "prop-types";
import { Map } from "immutable";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import MUITableInternationalReports, {
  RENDER,
} from "../../components/orders-core/MUITableInternationalReports";
import { Edit, DeleteForever, Refresh, FilterList } from "@material-ui/icons";
import { getValue } from "../../helpers/DataUtils";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_CENTER,
  JUSTIFY_END,
} from "../../components/ui-core/FlexBox";
import {
  Card,
  CardContent,
  Divider,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ConfirmDialog from "../../components/deprecated/ConfirmDialog";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import _ from "lodash";
import OrderFlowOperationForm from "../../components/order-create-wizard-new/international-reports/OrderFlowOperationForm";
import {
  createOrderFlowOperation,
  deleteOrderFlowOperation,
  getOrderFlowOperationItem,
  getOrderFlowOperationList,
  updateOrderFlowOperation,
} from "../../api/admin/AdminOrderFlowOperationApi";
import { formatText } from "../../helpers/FormatUtils";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import AdminOrderFlowOperationFilterWrapper from "../../wrappers/admin/AdminOrderFlowOperationFilterWrapper";

export const localisationNotificationType = (option, i18n) => {
  switch (option) {
    case "BOT":
      return i18n.get("bot", "Bot");

    case "PUSH":
      return i18n.get("push", "Push");

    default:
      return i18n.get(option, formatText(option));
  }
};

const useStyles = makeStyles({
  tabs: {
    margin: "0 -1rem",
  },
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #eee",
  },
  title: {
    fontSize: "24px",
    textTransform: "capitalize",
    fontWeight: 500,
  },
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  search: {
    flex: "1 1 auto",
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
});

const enhancer = compose(
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    {
      showSuccessMessage,
      showErrorMessage,
    },
  ),
);

AdminOrderFlowContainer.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
};

function AdminOrderFlowContainer(props) {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({
    page: 0,
    size: 20,
    refresh: true,
  });

  const [id, setId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const [data, setData] = useState({});

  const classes = useStyles();

  const refreshList = () => setFilter({ ...filter, refresh: !filter.refresh });
  const [success, setSuccess] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getOrderFlowOperationList(filter)
      .then(res => {
        if (res && res.data) {
          setList(getValue(res, "data.list", []));
          setTotal(getValue(res, "data.total", 0));
          setIsLoading(false);
        }
      })
      .catch(() => {
        setList([]);
        setIsLoading(false);
      });
  }, [filter]);

  useEffect(() => {
    if (id) {
      getOrderFlowOperationItem(id).then(res => {
        if (res && res.data) {
          setData(res.data);
        }
      });
    }
  }, [id]);

  const handleChangePage = (event, page) => setFilter({ ...filter, page });
  const handleChangeRowsPerPage = event =>
    setFilter({ ...filter, size: parseInt(event.target.value, 10), page: 0 });

  const onDelete = removeId => {
    deleteOrderFlowOperation(removeId)
      .then(() => {
        setDeleteId(0);
        props.showSuccessMessage(
          props.i18n.get("successfully_removed", "Successfully Removed"),
        );
        refreshList();
      })
      .catch(error => {
        props.showErrorMessage(error);
        setDeleteId(0);
      });
  };

  return (
    <AdminAppLayout
      title={props.i18n.get("order_flow_operation", "Order flow operation")}
    >
      <AdminOrderFlowOperationFilterWrapper
        open={openFilter}
        onRequestClose={() => {
          setOpenFilter(false);
        }}
        initialValues={{
          serviceType: {
            id: filter.service_type_id,
          },
          customer: {
            id: filter.customer_id,
          },
        }}
        onSubmit={v => {
          setFilter({
            customer_id: getValue(v, "customer.id"),
            service_type_id: getValue(v, "serviceType.id"),
          });
          setOpenFilter(false);
        }}
      />

      <ConfirmDialog
        open={deleteId > 0}
        onRequestClose={() => {
          setDeleteId(0);
        }}
        onConfirm={() => {
          onDelete(deleteId);
          setDeleteId(0);
        }}
      >
        {props.i18n.get(
          "are_you_sure_you_want_delete",
          "Are you sure you want delete?",
        )}
      </ConfirmDialog>

      <Card>
        <CardContent>
          <FlexBox direction="column" style={{ gap: 10 }}>
            <FlexBox
              style={{
                height: "35px",
                padding: "2px 0 2px 20px",
              }}
              justify="space-between"
              align="center"
            >
              <Typography variant="h5" className={classes.title}>
                {props.i18n.get("order_flow_operation", "Order flow operation")}
              </Typography>

              <FlexBox justify={JUSTIFY_END} align={ALIGN_CENTER}>
                <CustomButton
                  variant={CONTAINED}
                  color={SECONDARY}
                  style={{ backgroundColor: "#3F51B5" }}
                  onClick={() => setOpenFilter(true)}
                >
                  <FilterList />
                </CustomButton>

                <IconButton
                  className={isLoading && classes.refresh}
                  tooltip={props.i18n.get("refresh", "Refresh")}
                  onClick={() => refreshList()}
                >
                  <Refresh />
                </IconButton>
              </FlexBox>
            </FlexBox>

            <OrderFlowOperationForm
              onClose={() => {
                setId(null);
                setData({});
              }}
              success={success}
              setSuccess={setSuccess}
              update={id > 0}
              onSubmit={values => {
                if (id) {
                  updateOrderFlowOperation({
                    ...toSnakeCase(values),
                    id,
                  })
                    .then(res => {
                      if (res && res.status === "success") {
                        props.showSuccessMessage(
                          props.i18n.get("successfully_updated"),
                        );

                        refreshList();
                        setData({});
                        setId(null);
                      }
                    })
                    .catch(error => props.showErrorMessage(error));
                } else {
                  createOrderFlowOperation(toSnakeCase(values))
                    .then(res => {
                      if (res && res.status === "success") {
                        setSuccess(true);

                        props.showSuccessMessage(
                          props.i18n.get("successfully_created"),
                        );

                        refreshList();
                        setData({});
                      }
                    })
                    .catch(error => props.showErrorMessage(error));
                }
              }}
              initialValues={{
                ...toCamelCase(data),
              }}
            />

            <Divider style={{ height: 1, weight: "100%" }} />

            <MUITableInternationalReports
              isLoading={isLoading}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              size="small"
              list={list}
              total={total}
              activeId={id}
              orderBy={filter.order_by}
              orderByDirection={filter.order_by_direction}
              setOrderBy={v =>
                setFilter({
                  ...filter,
                  order_by: v,
                  order_by_direction: filter.order_by_direction
                    ? filter.order_by_direction === "desc"
                      ? "asc"
                      : "desc"
                    : "asc",
                })
              }
              page={filter.page}
              rowsPerPage={filter.size}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              columns={[
                {
                  type: RENDER,
                  name: "service_type",
                  label: props.i18n.get("service_type", "Service type"),
                  render: row =>
                    _.get(
                      row,
                      `service_type.name`,
                      props.i18n.get("na", "N/A"),
                    ),
                },
                {
                  type: RENDER,
                  name: "customer",
                  label: props.i18n.get("customer", "Customer"),
                  render: row =>
                    _.get(row, `customer.name`, props.i18n.get("na", "N/A")),
                },
                {
                  type: RENDER,
                  name: "promise",
                  label: props.i18n.get("promise", "Promise"),
                  render: row =>
                    _.get(row, `promise`, props.i18n.get("na", "N/A")),
                },
                {
                  type: RENDER,
                  name: "notification_type",
                  label: props.i18n.get(
                    "notification_type",
                    "Notification type",
                  ),
                  render: row =>
                    localisationNotificationType(
                      _.get(row, `notification_type`),
                      props.i18n,
                    ),
                },
                {
                  type: RENDER,
                  name: "notification_time",
                  label: props.i18n.get(
                    "notification_time",
                    "Notification time",
                  ),
                  render: row =>
                    _.get(
                      row,
                      `notification_time`,
                      props.i18n.get("na", "N/A"),
                    ),
                },
                {
                  type: RENDER,
                  name: "repetition",
                  label: props.i18n.get("repetition", "Repetition"),
                  render: row =>
                    _.get(row, `repetition`, props.i18n.get("na", "N/A")),
                },
                {
                  type: RENDER,
                  name: "status",
                  label: props.i18n.get("status", "Status"),
                  render: row =>
                    _.get(row, `active`)
                      ? props.i18n.get("active", "Active")
                      : props.i18n.get("inactive"),
                },
                {
                  isSortable: false,
                  type: RENDER,
                  name: "action",
                  label: props.i18n.get("action", "Action"),
                  align: "center",
                  render: row => (
                    <FlexBox
                      flex={true}
                      align={ALIGN_CENTER}
                      justify={JUSTIFY_CENTER}
                      style={{ gap: 16 }}
                    >
                      <IconButton onClick={() => setDeleteId(_.get(row, "id"))}>
                        <DeleteForever />
                      </IconButton>

                      <IconButton onClick={() => setId(_.get(row, "id"))}>
                        <Edit />
                      </IconButton>
                    </FlexBox>
                  ),
                },
              ]}
            />
          </FlexBox>
        </CardContent>
      </Card>
    </AdminAppLayout>
  );
}

export default enhancer(AdminOrderFlowContainer);
