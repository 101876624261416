import { Observable } from "rxjs";
import React from "react";
import { List, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { AutoCompleteComponent } from "./FormAutoComplete";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";
import {
  getCachedServiceTypeById,
  getServiceTypePredictions,
} from "../../api/admin/AdminServiceTypesApi";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });

const parseInput = name => ({ name });
const formatOption = fp.get("name");

const enhancer = compose();

const ServiceTypeAutoComplete = enhancer(
  componentFromStream(propsStream => {
    const valueStream = propsStream.switchMap(({ input: { value } }) =>
      value && value.id && !value.name
        ? getCachedServiceTypeById(value.id)
            .map(item => ({
              id: value.id,
              name: item.get("name"),
            }))
            .catch(() => Observable.of(value))
            .startWith(value)
        : Observable.of(value),
    );

    const optionsStream = propsStream
      .switchMap(({ input: { value } }) =>
        getServiceTypePredictions(baseFilter.setSearch(value && value.name))
          .map(fp.flow(fp.get("payload.data"), fp.toArray, fromJS))
          .catch(() => Observable.of(List())),
      )
      .distinctUntilChanged(isEqualData)
      .map(v => v.toJS());

    return propsStream.combineLatest(
      valueStream,
      optionsStream,
      ({ readOnly, ...props }, value, options) => (
        <AutoCompleteComponent
          {...props}
          options={options}
          filter={fp.stubTrue}
          parseInput={parseInput}
          formatOption={formatOption}
          input={{ ...props.input, value }}
          readOnly={readOnly}
        />
      ),
    );
  }),
);

FormServiceTypeByIdAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxSearchResults: PropTypes.number,

  hintText: PropTypes.node,
  label: PropTypes.node,
};

FormServiceTypeByIdAutocomplete.defaultProps = {
  maxSearchResults: 10,
};

export default function FormServiceTypeByIdAutocomplete(props) {
  return <Field {...props} component={ServiceTypeAutoComplete} />;
}
