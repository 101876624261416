import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Map } from "immutable";
import { renderIf } from "../../helpers/HOCUtils";
import {
  getOrderShiftItem,
  saveOrderShiftAccept,
} from "../../api/v2/admin/AdminOrderSortingApi";
import fp from "lodash/fp";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getMessages } from "../../reducers/LocalizationReducer";
import ShiftAcceptDialog from "./ShiftAcceptDialog";
import { getValue } from "../../helpers/DataUtils";

const enhancer = compose(
  renderIf("open"),
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showSuccessMessage, showErrorMessage },
  ),
);

AdminShiftingAcceptDialogWrapper.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  open: PropTypes.bool,

  onRequestClose: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  setSendToCustoms: PropTypes.func,
  sendToCustoms: PropTypes.array,
};

function AdminShiftingAcceptDialogWrapper(props) {
  const { setSendToCustoms, sendToCustoms } = props;
  const [barcodes, setBarcodes] = useState([]);
  const [items, setItems] = useState({});
  const [scanBatch, setScanBatch] = useState(null);
  const [id, setId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  useEffect(() => {
    if (scanBatch && !barcodes.includes(scanBatch)) {
      setIsLoading(true);
      getOrderShiftItem(scanBatch)
        .then(res => {
          setItems({
            from_operator: getValue(res, "data.from_operator", {}),
            to_operator: getValue(res, "data.to_operator", {}),
            supervisor: getValue(res, "data.supervisor", {}),
          });
          setId(getValue(res, "data.id", null));
          setBarcodes(getValue(res, "data.verified_order_barcodes", []));
          setIsLoading(false);
          setScanBatch(null);
        })
        .catch(() => setIsLoading());
    }
  }, [scanBatch]);

  return (
    <ShiftAcceptDialog
      open={props.open}
      barcodes={barcodes}
      sendToCustoms={sendToCustoms}
      scanBatch={scanBatch}
      setSendToCustoms={setSendToCustoms}
      setScanBatch={setScanBatch}
      isLoading={isLoading}
      isLoadingSubmit={isLoadingSubmit}
      initialValues={{
        ...toCamelCase(items),
        verifiedOrderBarcodes: [],
      }}
      onSubmit={fp.flow(toSnakeCase, values => {
        setIsLoadingSubmit(true);
        const unVerify = barcodes.filter(
          item => !values.verified_order_barcodes.includes(item),
        );
        return saveOrderShiftAccept({
          ...values,
          id,
          unverified_order_barcodes: unVerify,
        }).catch(ResponseError.throw);
      })}
      onSubmitSuccess={response => {
        setTimeout(() => {
          setIsLoadingSubmit(false);
          props.showSuccessMessage(
            props.i18n.get("successfully_updated", "Successfully Updated"),
          );
          props.onSubmitSuccess(response);
          setBarcodes([]);
          setItems([]);
          setScanBatch(null);
        }, 1000);
      }}
      onSubmitFail={err => {
        props.showErrorMessage(err);
        setIsLoadingSubmit(false);
      }}
      onRequestClose={props.onRequestClose}
    />
  );
}

export default enhancer(AdminShiftingAcceptDialogWrapper);
