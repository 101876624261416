import React from "react";
import PropTypes from "prop-types";
import { Map } from "immutable";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getMessages } from "../../reducers/LocalizationReducer";
import FlexBox from "../ui-core/FlexBox";
import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import CustomButton, { SECONDARY } from "../ui-core/CustomButton";
import "../../../client/theme/fonts/blogger-sans/loading.css";
import { CheckCircle } from "@material-ui/icons";

const useStyles = makeStyles({
  dialogTitle: {
    padding: "7px 13px",
    fontSize: "20px",
    fontWeight: 500,
  },
});

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
);

IpsBatchSyncProgressDialog.propTypes = {
  i18n: PropTypes.instanceOf(Map),
  success: PropTypes.bool,
  isOpenSuccessLoading: PropTypes.bool,
  onClose: PropTypes.bool,
  open: PropTypes.bool,
};

function IpsBatchSyncProgressDialog(props) {
  const classes = useStyles();

  return (
    <Dialog
      open={props.open}
      PaperProps={{
        style: {
          width: "800px",
          height: "300px",
        },
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <DialogTitle className={classes.dialogTitle}>
          {props.i18n.get("success")}
        </DialogTitle>
      </DialogTitle>

      <DialogContent
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!props.isOpenSuccessLoading ? (
          <CheckCircle
            style={{
              color: "#27AE60",
              width: "300px",
              height: "150px",
            }}
          />
        ) : (
          <div className="loading-bar animate">
            <span className="span" />
          </div>
        )}
      </DialogContent>

      <DialogActions>
        <FlexBox style={{ gap: 16 }}>
          <CustomButton color={SECONDARY} onClick={props.onClose}>
            {props.i18n.get("close", "Close")}
          </CustomButton>
        </FlexBox>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(IpsBatchSyncProgressDialog);
