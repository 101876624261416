import React, { useEffect, useRef, useState } from "react";
import { List, Map } from "immutable";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import {
  Button,
  CircularProgress,
  IconButton,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { connect } from "react-redux";
import {
  AddCircleSharp,
  FilterList,
  Print,
  Publish,
  Refresh,
  Search,
} from "@material-ui/icons";
import { isEmpty, toJS } from "../../helpers/DataUtils";
import { formatDateValuesForDownloadCSV } from "../../helpers/OrderListHelper";
import {
  getCurrentLanguage,
  getMessages,
} from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import FlexBox, { ALIGN_CENTER } from "../../components/ui-core/FlexBox";
import DownloadDynamicCSVDialog from "../../components/csv-core/DownloadDynamicCSVDialog";
import OrderListErrorNotification from "../../components/orders-core/OrderListErrorNotification";
import CustomButton, {
  CONTAINED,
  DEFAULT,
} from "../../components/ui-core/CustomButton";
import { COLUMN } from "../../components/orders-core/MUITable";
import CustomerOrderTable from "../../components/orders-core/CustomerOrderTable";
import _ from "lodash";
import customerColumnOptions from "../../constants/marketplace/CustomerCustomizableOrderListContainer";
import { ALL } from "../../constants/OverallStatusTabs";
import {
  COMPLETED,
  IN_TRANSIT,
  PREPARED_FOR_TRANSIT,
} from "../../constants/OrderStatusCodes";
import fp from "lodash/fp";
import { parseString, stringifyArray } from "../../helpers/SerializeUtils";
import ChipTextField from "../../components/deprecated/ChipTextField";
import {
  getOrderListWithOutStream,
  getDynamicFields,
} from "../../api/customer/CustomerOrderApi";
import CustomerOrderFilterWrapper from "../../wrappers/customer/CustomerOrderFilterWrapper";
import CustomerOrderDetailsDialogWrapperV2 from "../../wrappers/customer/CustomerOrderDetailsDialogWrapperV2";
import {
  ORDER_CREATE_DEFAULT_URL,
  ORDER_UPLOAD_V2_URL,
} from "../../constants/CustomerPathConstants";
import CustomerAppLayout from "../../components/customer/CustomerAppLayout";
import {
  IS_DOWNLOADING,
  IS_PRINTING,
} from "../../components/orders-core/AdminPostForm";
import { getPDF } from "../../helpers/FormUtils";
import { getMarketplaceSettings } from "../../reducers/MarketplaceReducer";
import { API_V2_ROOT_URL } from "../../../shared/constants/ApiConstants";
import CustomerSimpleTabs from "../../components/ui-core/CustomerSimpleTabs";
import DownloadIcon from "../../components/icons/DownloadIcon";
import { getCustomerOrderListColumns } from "../../reducers/CustomerReducer";

export const ZEBRA_URL = `${API_V2_ROOT_URL}/customer/cn22_shipping_label`;
export const BARCODES_PDF = `${API_V2_ROOT_URL}/customer/barcode_printable`;

const useStyles = makeStyles({
  pageFab: { bottom: "64px" },
  appBarRightAction: { height: "100%" },
  appBarRightActionToggle: {
    whiteSpace: "nowrap",
    marginLeft: "12px",
  },
  mr10: {
    marginRight: 10,
  },
  pLR1rem: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    color: "#183E98",
  },

  filterToggle: { marginTop: "12px" },
  btn: {
    flex: "1 1 auto",
    margin: "0 .5rem",
  },
  btnWrapper: {
    margin: "0 -.5rem",
  },
  titleWithBorder2: {
    textAlign: "center",
    borderBottom: "1px solid #eeeeee",
  },
  content: {
    minWidth: "100%",
    "@media (max-width: 1200px)": {
      width: "100%",
    },
  },
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #eee",
  },
  tabs: {
    flexGrow: 1,
    padding: "15px 20px",
    background: "#fff",
    width: "100%",
    borderBottom: "1.5px solid var(--slate-200, #E2E8F0)",
    "& .MuiTabs-root": {
      padding: "5px 8px",
      backgroundColor: "#F9F9F9",
      borderRadius: 12,
      display: "flex",
      flexDirection: "row",
      gap: 4,
      "& .MuiTabs-indicator": {
        display: "none",
      },
    },
  },
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  search: {
    flex: "1 1 auto",
    position: "relative",
    borderRadius: 8,
    background: "var(--zinc-100, #F4F4F5)",
  },
  createSimpleButton: {
    "& .MuiButton-contained:hover": {
      backgroundColor: "#183E98",
      color: "#fff",
    },
  },
});
const enhancer = compose(
  getContext({
    setLocation: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
  }),
  connect(
    state => ({
      i18n: getMessages(state),
      currentLanguage: getCurrentLanguage(state),
      marketplaceSettings: getMarketplaceSettings(state),
      orderListColumns: toJS(getCustomerOrderListColumns(state)),
    }),
    { showSuccessMessage, showErrorMessage },
  ),
);

const OTHERS = "others";

const CustomerOrderList = ({
  i18n,
  setLocation,
  showErrorMessage: showErrorMessage1,
  userWarehouse,
  hasBatchUpdatePermission,
  createSimpleOrder,
  location,
  marketplaceSettings,
  currentLanguage,
  orderListColumns,
  ...props
}) => {
  const classes = useStyles();
  const [divHeight, setDivHeight] = useState(0);
  const ref = useRef(false);
  const refHeight = _.get(ref, "current.clientHeight", false);
  useEffect(() => {
    if (refHeight && refHeight !== divHeight) setDivHeight(refHeight);
  }, [refHeight]);
  const [isOpenDynamicDownloadCSV, setIsOpenDynamicDownloadCSV] = useState(
    false,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isOpenBrief, setIsOpenBrief] = useState(false);
  const [orderId, setOrderId] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [orderList, setOrderList] = useState([]);

  const [zebra, setZebra] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: ZEBRA_URL,
  });
  const [barcodesPDF, setBarcodesPDF] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: BARCODES_PDF,
  });

  const selectedOrderIds = selectedItems ? selectedItems.map(v => v.id) : [];

  const [tabs, setTabs] = useState([
    {
      title: i18n.get("all"),
      value: ALL,
      count: 0,
    },
    {
      title: i18n.get("not_payed"),
      value: "not_paid",
      count: 0,
    },
    {
      title: i18n.get("taken"),
      value: "in_sorting",
      count: 0,
    },
    {
      title: i18n.get("prepared_for_transit"),
      value: PREPARED_FOR_TRANSIT,
      count: 0,
    },
    {
      title: i18n.get("in_transit"),
      value: IN_TRANSIT,
      count: 0,
    },
    {
      title: i18n.get(COMPLETED),
      value: COMPLETED,
      count: 0,
    },
    {
      title: i18n.get(OTHERS),
      value: "others",
      count: 0,
    },
  ]);
  const [error, setError] = useState();
  const [total, setTotal] = useState(0);
  // eslint-disable-next-line camelcase
  const [filter, setFilter] = useState({
    page: 0,
    size: 20,
    state: "all",
    use_solr: true,
    refresh: false,
    with_totals: true,
    search_type: "order_number",
  });
  const refreshOrderList = () =>
    setFilter({ ...filter, refresh: !filter.refresh });

  useEffect(() => {
    setIsLoading(true);

    getOrderListWithOutStream(filter)
      .then(res => {
        const tempTotal = _.get(res, "data.total");
        const tempTotals = _.get(res, "data.totals");
        setIsLoading(false);
        setOrderList(_.get(res, "data.list"));
        setError(_.get(res, "data.error"));
        setTotal(tempTotal);
        if (!isEmpty(tempTotals)) {
          setTabs(prev => {
            const tempPrev = prev.map(item => ({
              ...item,
              count: tempTotals[item.value],
            }));

            return tempPrev;
          });
        }
      })
      .catch(err => {
        setIsLoading(false);
        showErrorMessage1(err);
      });
  }, [filter, currentLanguage]);

  const handleChangePage = (event, page) =>
    setFilter({
      ...filter,
      page,
      with_totals: false,
    });
  const handleChangeRowsPerPage = event =>
    setFilter({
      ...filter,
      size: parseInt(event.target.value, 10),
      page: 0,
      with_totals: false,
    });

  const columns = toJS(customerColumnOptions).filter(v =>
    orderListColumns.some(i => i === v.slug),
  );

  return (
    <CustomerAppLayout
      title={i18n.get("orders", "Orders")}
      appTitleRightAction={
        <FlexBox flex={true} justify="flex-end" align={ALIGN_CENTER}>
          {selectedOrderIds.length ? (
            <FlexBox justify="flex-end" flex={true}>
              <FlexBox>
                <Button
                  className={classes.pLR1rem}
                  onClick={() =>
                    getPDF(
                      zebra,
                      setZebra,
                      IS_PRINTING,
                      selectedOrderIds,
                      showErrorMessage1,
                    )
                  }
                  endIcon={
                    zebra[IS_PRINTING] ? (
                      <CircularProgress size={20} color="secondary" />
                    ) : (
                      <Print />
                    )
                  }
                >
                  {i18n.get("address_label")}
                </Button>
              </FlexBox>
              <FlexBox>
                <Button
                  className={classes.pLR1rem}
                  onClick={() =>
                    getPDF(
                      barcodesPDF,
                      setBarcodesPDF,
                      IS_PRINTING,
                      selectedOrderIds,
                      showErrorMessage1,
                    )
                  }
                  endIcon={
                    barcodesPDF[IS_PRINTING] ? (
                      <CircularProgress size={20} color="white" />
                    ) : (
                      <Print />
                    )
                  }
                >
                  {i18n.get("orders_barcodes")}
                </Button>
              </FlexBox>
            </FlexBox>
          ) : (
            <div className={classes.search} ref={ref}>
              <ChipTextField
                style={{
                  padding: "6px 12px",
                  maxHeight: divHeight > 86 && "5.8rem",
                  overflow: divHeight > 86 && "auto",
                }}
                fullWidth={true}
                addOnBlur={false}
                clearOnBlur={false}
                value={parseString(filter.search)}
                disableUnderline={true}
                hintText={i18n.get("search_order", "Search order")}
                onChange={v =>
                  setFilter({ ...filter, search: stringifyArray(v) })
                }
              />
              <Search
                fontSize="large"
                style={{
                  color: "rgba(0, 0, 0, 0.23)",
                  top: ".7rem",
                  right: ".5rem",
                  position: "absolute",
                }}
              />
            </div>
          )}
          <FlexBox style={{ minWidth: 350, gap: 13 }} align={ALIGN_CENTER}>
            <FlexBox>
              <IconButton
                className={isLoading && classes.refresh}
                tooltip={i18n.get("refresh", "Refresh")}
                onClick={() => refreshOrderList()}
              >
                <Refresh />
              </IconButton>
              <Tooltip title={i18n.get("filter_orders", "Filter Orders")}>
                <IconButton onClick={() => setIsOpenFilter(true)}>
                  <FilterList />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={i18n.get("dynamic_download_csv", "Download Dynamic CSV")}
              >
                <IconButton onClick={() => setIsOpenDynamicDownloadCSV(true)}>
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            </FlexBox>

            <Button
              onClick={() => setLocation(ORDER_CREATE_DEFAULT_URL)}
              endIcon={<AddCircleSharp />}
              variant={CONTAINED}
              style={{
                borderRadius: "20px",
                color: "#fff",
                background: "#183E98",
              }}
            >
              {i18n.get("create_new_order", "Create New Order")}
            </Button>

            <CustomButton
              onClick={() => setLocation(`${ORDER_UPLOAD_V2_URL}?upload=true`)}
              variant={CONTAINED}
              endIcon={<Publish />}
              color={DEFAULT}
              style={{ borderRadius: "20px" }}
            >
              {i18n.get("upload_orders", "Upload Orders")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
      }
    >
      <OrderListErrorNotification
        error={error}
        onFixSizeError={() => setFilter({ ...filter, size: 0 })}
      />
      <DownloadDynamicCSVDialog
        open={isOpenDynamicDownloadCSV}
        getDynamicFields={getDynamicFields}
        createDownloadCSVHref={v =>
          formatDateValuesForDownloadCSV(filter, v).toJS()
        }
        onRequestClose={() => setIsOpenDynamicDownloadCSV(false)}
      />

      <CustomerOrderFilterWrapper
        showLogistic={true}
        setSelectedItems={setSelectedItems}
        filter={filter}
        setFilter={setFilter}
        withCustomFilters={true}
        open={isOpenFilter}
        onRequestClose={() => setIsOpenFilter(false)}
        closeDialog={() => setIsOpenFilter(false)}
      />

      <CustomerOrderDetailsDialogWrapperV2
        location={location}
        tab={location.query.view_tab}
        isOpen={isOpenBrief}
        setIsOpenBrief={setIsOpenBrief}
        orderId={orderId}
        refreshOrderList={refreshOrderList}
        onTabChange={tab => props.setLocationQuery(fp.set("view_tab", tab))}
        onRequestClose={() => {
          setIsOpenBrief(false);
          setOrderId(null);
          props.setLocationQuery(fp.unset("view_tab"));
        }}
      />
      <div className={classes.root}>
        <div className={classes.tabs}>
          <CustomerSimpleTabs
            variant="fullWidth"
            items={tabs}
            isLoading={isLoading}
            value={filter.state}
            onChange={(e, state) => {
              setFilter({
                ...filter,
                state,
                with_totals: false,
              });
            }}
          />
        </div>
      </div>
      <CustomerOrderTable
        filter={filter}
        setFilter={setFilter}
        orderBy={filter.order_by}
        orderByDirection={filter.order_by_direction}
        isLoading={isLoading}
        isCheckboxEnabled={true}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        size="small"
        list={orderList}
        total={total}
        page={filter.page}
        rowsPerPage={filter.size}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        setOrderId={setOrderId}
        setIsOpenBrief={setIsOpenBrief}
        columns={columns.map(v => ({ ...v, type: v.type || COLUMN }))}
        setOrderBy={v =>
          setFilter({
            ...filter,
            order_by: v,
            with_totals: false,
            order_by_direction: filter.order_by_direction
              ? filter.order_by_direction === "desc"
                ? "asc"
                : "desc"
              : "asc",
          })
        }
      />
    </CustomerAppLayout>
  );
};
CustomerOrderList.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocationQuery: PropTypes.func,
  setLocation: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
  marketplaceSettings: PropTypes.instanceOf(Map),
  isOtpSettingsServiceType: PropTypes.instanceOf(List),
  isOtpSettingsTransportationType: PropTypes.instanceOf(List),
  userWarehouse: PropTypes.object,
  location: PropTypes.object,
  hasBatchUpdatePermission: PropTypes.bool,
  createSimpleOrder: PropTypes.bool,
  userIdAVVAL: PropTypes.number,
  currentLanguage: PropTypes.string,
  orderListColumns: PropTypes.array,
};
export default enhancer(CustomerOrderList);
