import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, FormControl, MenuItem, Select } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import { getOrderTotalCountByMonth } from "../../api/admin/AdminDashboardNewApi";
import { getValue } from "../../helpers/DataUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import FlexBox from "../ui-core/FlexBox";
import FilledLineChart from "../charts/FilledLineChart";
import fp from "lodash/fp";

const years = [2021, 2022, 2023, 2024, 2025];

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

AdminOrderCountByMonthChartWrapper.propTypes = {
  getLocalisationMessage: PropTypes.func,
};

function AdminOrderCountByMonthChartWrapper(props) {
  const [chartData, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [note, setNote] = useState(null);
  const monthlyOrderRef = React.useRef(null);
  const [year, setYear] = useState(2025);
  const [year2, setYear2] = useState(2024);

  const [filter, setFilter] = useState({
    year: 2025,
    year2: 2024,
  });

  useEffect(() => {
    setIsLoading(true);
    getOrderTotalCountByMonth(filter)
      .then(r => {
        if (r && r.data) {
          const barData = getValue(r, "data.result");
          const barData2 = getValue(r, "data.result2");
          setData({
            labels:
              barData.length > barData2.length
                ? barData.map(item => item.label)
                : barData2.map(item => item.label),
            datasets: [
              {
                label: filter.year,
                data: barData.map(item => item.value),
                borderColor: "#3F51B5",
                backgroundColor: "#3F51B5",
                fill: false,
                tension: 0.4,
              },
              {
                label: filter.year2,
                data: barData2.map(item => item.value),
                borderColor: "#F50057",
                backgroundColor: "#F50057",
                fill: false,
                tension: 0.4,
              },
            ],
          });
        }
        setIsLoading(false);
      })
      .catch(() => {
        setNote(props.getLocalisationMessage("no_data", "No Data"));
        setIsLoading(false);
        setData({});
      });
  }, [filter]);

  return (
    <Card
      style={{
        width: "100%",
        padding: "15px",
        boxShadow: "rgba(145, 158, 171, 0.12)",
        borderRadius: "16px",
      }}
      ref={monthlyOrderRef}
    >
      <FlexBox direction="row">
        <FlexBox
          flex={true}
          align="center"
          style={{
            marginTop: 10,
            marginLeft: 15,
            marginRight: 15,
            fontSize: 14,
          }}
          justify="space-between"
        >
          <FlexBox flex={true} style={{ fontWeight: 700, fontSize: 16 }}>
            {props.getLocalisationMessage(
              "monthly_order_creation_progress",
              "Monthly Order Creation Progress",
            )}
          </FlexBox>
          <FlexBox flex={true} style={{ gap: 13 }}>
            <FormControl size="small" fullWidth={true}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={year}
                variant="outlined"
                onChange={event => setYear(event.target.value)}
              >
                {years.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl size="small" fullWidth={true}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={year2}
                variant="outlined"
                onChange={event => setYear2(event.target.value)}
              >
                {years.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <CustomButton
              variant={CONTAINED}
              color={SECONDARY}
              fullWidth={true}
              style={{ backgroundColor: "#3F51B5" }}
              onClick={() =>
                setFilter({
                  year,
                  year2,
                })
              }
            >
              {props.getLocalisationMessage("apply", "Apply")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      {isLoading && monthlyOrderRef.current && (
        <FlexBox
          justify="center"
          align="flex-end"
          style={{ height: 400, position: "relative", gap: "10px" }}
        >
          {Array.from(
            { length: Math.ceil(monthlyOrderRef.current.offsetWidth / 30) },
            () => Math.floor(Math.random() * 400),
          ).map(item => (
            <Skeleton style={{ transform: "none" }} width={20} height={item} />
          ))}

          <FlexBox
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
            align="center"
            justify="center"
          >
            <h4>
              <strong>
                {note ||
                  `${props.getLocalisationMessage("loading", "Loading")}...`}
              </strong>
            </h4>
          </FlexBox>
        </FlexBox>
      )}
      {!isLoading && (
        <FilledLineChart
          data={chartData}
          withoutLegend={true}
          options={{
            scales: {
              x: [
                {
                  grid: {
                    display: false,
                  },
                },
              ],
              y: [
                {
                  grid: {
                    drawBorder: false, // Remove the default border
                    borderDash: [5, 5], // Set a dashed pattern for the row lines
                  },
                },
              ],
            },
            plugins: {
              legend: {
                display: false,
              },
              annotation: {
                annotations: (() => {
                  const currentMonth = new Date().toLocaleString("default", {
                    month: "long",
                  });
                  const currentIndex = chartData.labels.findIndex(
                    label => label === fp.toUpper(currentMonth),
                  );

                  if (currentIndex === -1) return {};

                  return {
                    line: {
                      type: "line",
                      xMin: fp.toUpper(currentMonth),
                      xMax: fp.toUpper(currentMonth),
                      borderColor: "#3F51B5",
                      borderWidth: 3,
                    },
                  };
                })(),
              },
            },
          }}
          tooltipFormat={{
            callbacks: {
              labelColor(tooltipItem) {
                return {
                  borderColor:
                    tooltipItem.datasetIndex === 0 ? "#3F51B5" : "#F50057",
                  backgroundColor:
                    tooltipItem.datasetIndex === 0 ? "#3F51B5" : "#F50057",
                };
              },
              label(tooltipItem, data) {
                const datasetLabel =
                  data.datasets[tooltipItem.datasetIndex].label;
                return `${datasetLabel}:  ${new Intl.NumberFormat(
                  "ru-RU",
                ).format(tooltipItem.yLabel)}`;
              },
            },
          }}
        />
      )}
    </Card>
  );
}

export default enhancer(AdminOrderCountByMonthChartWrapper);
