import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";

const enhancer = compose(
  useSheet({
    container: {
      width: "100%",
      padding: "15px",
    },
    title: {
      fontSize: "16px",
    },
    mainContainer: {
      "& > div:nth-child(1)": {
        fontSize: "16px",
      },
      "& > div:nth-child(2)": {
        fontSize: "20px",
        color: "#E91E63",
        "& > span": {
          fontSize: "12px",
          lineHeight: "18px",
        },
      },
    },
    priceValue: {
      fontWeight: "bold",
      "& > span": {
        paddingRight: "5px",
      },
    },
  }),
);

HorizantalStackedBarChart.propTypes = {
  classes: PropTypes.object,
  tooltipFormat: PropTypes.object,
  data: PropTypes.object.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  max: PropTypes.number,
  title: PropTypes.string,
};

function HorizantalStackedBarChart(props) {
  const options = {
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          title: () => null,
          label: tooltipItem => {
            const datasetLabel = tooltipItem.dataset.label || "";
            return `${datasetLabel}: ${tooltipItem.raw}`;
          },
        },
        padding: 12,
        bodySpacing: 4,
        titleFont: {
          size: 18,
        },
        bodyFont: {
          size: 18,
        },
      },
      legend: {
        position: "top",
        labels: {
          boxWidth: 19,
          font: {
            size: 18,
          },
        },
      },
    },
    layout: {
      padding: 0,
    },
    barPercentage: 3,
    categoryPercentage: 2,
    scales: {
      x: {
        stacked: true,
        border: {
          display: false,
        },
        grid: {
          display: false,
          drawOnChartArea: true,
          drawTicks: false,
        },
        ticks: {
          display: false,
          font: {
            size: 0,
          },
        },
      },
      y: {
        min: 0,
        stacked: true,
        border: {
          display: false,
        },
        grid: {
          display: false,
          drawOnChartArea: true,
          drawTicks: false,
        },
        ticks: {
          display: false,
          font: {
            size: 0,
          },
        },
      },
    },
  };

  return (
    <div
      style={{
        margin: 10,
      }}
    >
      <Bar data={props.data} options={options} height={150} />
    </div>
  );
}

export default enhancer(HorizantalStackedBarChart);
