import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import PageLoading from "../../ui-core/PageLoading";
import FlexBox from "../../ui-core/FlexBox";
import FormTextField, { OUTLINED, SECONDARY } from "../../form/FormTextField";
import CustomButton, { CONTAINED } from "../../ui-core/CustomButton";
import { formatText } from "../../../helpers/FormatUtils";
import fp from "lodash/fp";
import { getMessage } from "../../../reducers/LocalizationReducer";
import { showErrorMessage } from "../../../reducers/NotificationsReducer";
import { isValidObjectId } from "../../../helpers/ValidateUtils";
import FormAutoComplete from "../../form/FormAutoComplete";
import FormServiceTypeByIdAutocomplete from "../../form/FormServiceTypeByIdAutocomplete";
import FormCustomerAutoComplete from "../../form/FormCustomerAutoComplete";
import FormCheckbox from "../../form/FormCheckbox";

const ruleTypes = ["SERVICE_TYPE", "CUSTOMER"];
const notificationTypes = ["BOT", "PUSH"];

const getValues = getFormValues("OrderFlowOperationForm");

export const localisationRuleType = (option, getLocalisationMessage) => {
  switch (option) {
    case "SERVICE_TYPE":
      return getLocalisationMessage("service_type", "Service type");

    case "CUSTOMER":
      return getLocalisationMessage("customer", "Customer");

    default:
      return getLocalisationMessage(option, formatText(option));
  }
};

export const localisationNotificationType = (
  option,
  getLocalisationMessage,
) => {
  switch (option) {
    case "BOT":
      return getLocalisationMessage("bot", "Bot");

    case "PUSH":
      return getLocalisationMessage("push", "Push");

    default:
      return getLocalisationMessage(option, formatText(option));
  }
};

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage },
  ),
  reduxForm({
    form: "OrderFlowOperationForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      ruleType:
        !values.ruleType &&
        props.getLocalisationMessage("this_field_is_required"),
      serviceType:
        values.ruleType === "SERVICE_TYPE" &&
        !isValidObjectId(values.serviceType) &&
        props.getLocalisationMessage("this_field_is_required"),
      customer:
        values.ruleType === "CUSTOMER" &&
        !isValidObjectId(values.customer) &&
        props.getLocalisationMessage("this_field_is_required"),
      promise:
        !values.promise &&
        props.getLocalisationMessage("this_field_is_required"),
      notificationType:
        !values.notificationType &&
        props.getLocalisationMessage("this_field_is_required"),
      notificationTime:
        !values.notificationTime &&
        props.getLocalisationMessage("this_field_is_required"),
      repetition:
        !values.repetition &&
        props.getLocalisationMessage("this_field_is_required"),
    }),
  }),
  connect(
    fp.flow(getValues, fp.toPlainObject, values => ({
      values: {
        ...values,
      },
    })),
  ),
);

const OrderFlowOperationForm = ({
  getLocalisationMessage,
  handleSubmit,
  submitting,
  values,
  change,
  onClose,
  update,
  success,
  setSuccess,
  reset,
}) => {
  const { ruleType } = values;
  const [ignore, setIgnore] = useState(false);
  const [clearAdd, setClearAdd] = useState(false);

  useEffect(() => {
    if (success) {
      if (clearAdd) {
        reset();
        fp.keys(values).forEach(key => {
          change(key, null);
          setClearAdd(false);
          setSuccess(false);
          setIgnore(false);
        });
      }
    }
  }, [success]);

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <PageLoading isLoading={submitting} />
      <FlexBox container={8} direction="column" style={{ gap: 13 }}>
        <FlexBox gutter={8} flex={true}>
          <FlexBox style={{ width: 200 }}>
            <FormAutoComplete
              ignoreError={ignore && !success}
              name="ruleType"
              fullWidth={true}
              options={ruleTypes}
              formatOption={option =>
                localisationRuleType(option, getLocalisationMessage)
              }
              label={`${getLocalisationMessage("rule_type", "Rule Type")}*`}
            />
          </FlexBox>
        </FlexBox>

        <FlexBox gutter={8} flex={true}>
          {ruleType === "SERVICE_TYPE" ? (
            <FlexBox flex={true}>
              <FormServiceTypeByIdAutocomplete
                fullWidth={true}
                optional={true}
                name="serviceType"
                label={`${getLocalisationMessage(
                  "service_type",
                  "Service Type",
                )} *`}
              />
            </FlexBox>
          ) : (
            ruleType === "CUSTOMER" && (
              <FlexBox flex={true}>
                <FormCustomerAutoComplete
                  fullWidth={true}
                  name="customer"
                  label={`${getLocalisationMessage("customer", "Customer")} *`}
                />
              </FlexBox>
            )
          )}

          <FlexBox flex={true}>
            <FormTextField
              name="promise"
              type="number"
              fullWidth={true}
              ignoreError={ignore && !success}
              label={`${getLocalisationMessage("promise", "Promise")} *`}
            />
          </FlexBox>

          <FlexBox flex={true}>
            <FormAutoComplete
              ignoreError={ignore && !success}
              name="notificationType"
              fullWidth={true}
              options={notificationTypes}
              formatOption={option =>
                localisationNotificationType(option, getLocalisationMessage)
              }
              label={`${getLocalisationMessage(
                "notification_type",
                "Notification Type",
              )}*`}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormTextField
              name="notificationTime"
              fullWidth={true}
              type="number"
              ignoreError={ignore && !success}
              label={`${getLocalisationMessage(
                "notification_time",
                "Notification time",
              )} *`}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormTextField
              type="number"
              name="repetition"
              fullWidth={true}
              ignoreError={ignore && !success}
              label={`${getLocalisationMessage("repetition", "Repetition")} *`}
            />
          </FlexBox>

          <FlexBox flex={true} align="center" justify="center">
            <FormCheckbox
              name="active"
              label={getLocalisationMessage("active", "Active")}
            />
          </FlexBox>
        </FlexBox>

        <FlexBox justify="flex-end">
          <FlexBox style={{ gap: 16 }} justify="flex-end">
            <CustomButton
              onClick={() => {
                setSuccess(true);
                setIgnore(true);
                setClearAdd(true);
                onClose();
              }}
              style={{
                height: 40,
                minWidth: 220,
              }}
              fullWidth={true}
              variant={OUTLINED}
              color={SECONDARY}
            >
              {getLocalisationMessage("discard", "Discard")}
            </CustomButton>
            <CustomButton
              variant={CONTAINED}
              color={SECONDARY}
              type="submit"
              style={{
                height: 40,
                minWidth: 220,
              }}
              disabled={!ruleType}
              fullWidth={true}
              onClick={() => {
                setIgnore(true);
                setClearAdd(true);
              }}
            >
              {update
                ? getLocalisationMessage("update", "update")
                : getLocalisationMessage("add", "Add")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </form>
  );
};
OrderFlowOperationForm.propTypes = {
  submitting: PropTypes.bool,
  success: PropTypes.bool,
  update: PropTypes.bool,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
  change: PropTypes.func,
  onClose: PropTypes.func,
  setSuccess: PropTypes.func,
  reset: PropTypes.func,
};
export default enhancer(OrderFlowOperationForm);
