import React from "react";
import fp from "lodash/fp";
import { compose, createEventHandler, componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { connect } from "react-redux";
import { mergeSideEffectStreams } from "../../helpers/StreamUtils";
import { getIsRTL } from "../../reducers/LocalizationReducer";
import ChipInput from "material-ui-chip-input";

const enhancer = compose(
  connect(state => ({
    isRTL: getIsRTL(state),
  })),
);

const FormChipInputComponent = componentFromStream(propsStream => {
  const nodeRef = createEventHandler();

  const sideEffectsStream = mergeSideEffectStreams(
    propsStream
      .distinctUntilKeyChanged("focus")
      .filter(props => props.focus)
      .combineLatest(
        nodeRef.stream.map(
          fp.get(["autoComplete", "refs", "searchTextField", "input"]),
        ),
      )
      .delay(100)
      .do(([, input]) => input && input.focus()),
  );

  return propsStream
    .merge(sideEffectsStream)
    .combineLatest(
      ({ meta, input, focus, addOnBlur, maxItems, isRTL, ...custom }) => {
        const compactChips = fp.flow(
          fp.compact,
          fp.uniq,
          fp.takeLast(maxItems),
        );
        const concatChip = fp.flow(
          fp.concat(input.value),
          compactChips,
          fp.filter(fp.isString),
        );

        return (
          <ChipInput
            {...input}
            {...custom}
            fullWidth={true}
            ref={nodeRef.handler}
            value={fp.toArray(input.value)}
            onFocus={() => input.onFocus()}
            label={custom.floatingLabelText || custom.label}
            onBlur={fp.flow(
              event => (addOnBlur ? event.target.value : null),
              fp.trim,
              concatChip,
              input.onBlur,
            )}
            helperText={
              meta.touched && meta.invalid ? meta.error : custom.errorText
            }
            error={meta.touched && meta.invalid && meta.error}
            onAdd={fp.flow(fp.trim, concatChip, input.onChange)}
            onDelete={fp.flow(
              fp.rearg([1, 0], fp.pull)(input.value),
              compactChips,
              input.onChange,
            )}
          />
        );
      },
    );
});

FormChipInput.propTypes = {
  maxItems: PropTypes.number,

  focus: PropTypes.bool,
  addOnBlur: PropTypes.bool,
  clearOnBlur: PropTypes.bool,

  fullWidth: PropTypes.bool,
  underlineShow: PropTypes.bool,
  inputStyle: PropTypes.object,
  errorText: PropTypes.string,

  validate: PropTypes.func,

  disabled: PropTypes.bool,

  hintText: PropTypes.string,
  hintStyle: PropTypes.object,
  label: PropTypes.string,

  name: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
  size: PropTypes.oneOf(["medium", "small"]),
};

FormChipInput.defaultProps = {
  addOnBlur: true,
  clearOnBlur: false,
  maxItems: Infinity,
  variant: "outlined",
  size: "small",
};

function FormChipInput(props) {
  return <Field {...props} component={FormChipInputComponent} />;
}

export default enhancer(FormChipInput);
