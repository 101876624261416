import React, { useEffect, useState } from "react";
import { fromJS, List, Map, Set } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  mapPropsStream,
  withContext,
  withState,
} from "recompose";
import PropTypes from "prop-types";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListSubheader,
} from "@material-ui/core";
import { connect } from "react-redux";
import Audio from "../ui-core/Audio";
import ScannerTextField from "../deprecated/ScannerTextField";
import { getMessage } from "../../reducers/LocalizationReducer";
import { danger1 } from "../../../shared/theme/main-theme";
import ErrorSound from "../../assets/voices/error.mp3";
import SuccessSound from "../../assets/voices/success.wav";
import BeepSound from "../../assets/voices/beep.wav";
import { withTheme } from "@material-ui/core/styles";
import OrderSortingVerifyBinOrdersTableWrapper from "./OrderSortingVerifyBinOrdersTableWrapper";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import Tabs from "../ui-core/Tabs";
import FlexBox, { JUSTIFY_END, JUSTIFY_SPACE_AROUND } from "../ui-core/FlexBox";
import { mergeSideEffectStreams } from "../../helpers/StreamUtils";
import { MIXED } from "../../helpers/OrderOutboundSortingHelper";
import {
  ACCEPTED,
  DISPATCHED,
  IN_TRANSIT,
  ON_HIS_WAY,
  PREPARED_FOR_TRANSIT,
} from "../../constants/OrderStatusCodes";
import FormAutoComplete from "../form/FormAutoComplete";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import FormDriverAutoComplete from "../form/FormDriverAutoComplete";
import FormCourierTypeSelectField from "../form/FormCourierTypeSelectField";
import FormTextField from "../form/FormTextField";
import { formValues, reduxForm } from "redux-form";
import { isBlankString, isValidObjectId } from "../../helpers/ValidateUtils";
import FormSelectField from "../form/FormSelectField";
import TransportationType from "../../constants/TransportationType";
import { formatText } from "../../helpers/FormatUtils";
import { hasUserPermission } from "../../reducers/ProfileReducer";
import { Done } from "@material-ui/icons";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import OfflineOrderSortingVerifyBinConfirmDialog from "./OfflineOrderSortingVerifyBinConfirmDialog";
import { getValue } from "../../helpers/DataUtils";
import _ from "lodash";
import { categoriesConstants } from "./CreateOrderRegistryDialog";
import { OrderStatusCodes } from "../../wrappers/admin/AdminOfflineOutboundOrderSortingBatchUpdateDialogWrapper";

const UNVERIFIED = "Unverified";
const VERIFIED = "Verified";
const UNKNOWN = "Unknown";
const status = [UNKNOWN, UNVERIFIED];

const statusesWithDriver = Set.of(ACCEPTED, ON_HIS_WAY, DISPATCHED);

const enhancer = compose(
  connect(
    state => ({
      hasUserPermission: hasUserPermission(
        state,
        "ADMIN_SORTING_OUTBOUND_WITHOUT_VERIFICATION",
      ),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
    },
  ),
  useSheet({
    chip: { margin: "4px" },
    chipDanger: { backgroundColor: danger1 },
    modal: { width: "800px", minWidth: 800 },
    scannerField: { marginBottom: "10px" },
  }),
  withState(
    "state",
    "setState",
    Map({
      tab: UNVERIFIED,
      inputRef: null,
    }),
  ),
  withContext(
    {
      getCachedDriver: PropTypes.func,
      getDriverPredictions: PropTypes.func,

      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,

      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,
    },
    props => ({
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,

      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,

      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
    }),
  ),
  mapPropsStream(propsStream => {
    const { handler: refInput, stream: refStream } = createEventHandler();
    const {
      handler: onSetFocus,
      stream: onSetFocusStream,
    } = createEventHandler();

    const sideEffectsStream = mergeSideEffectStreams(
      onSetFocusStream
        .withLatestFrom(refStream)
        .delay(1000)
        .do(([, input]) => {
          input.focus();
        }),
    ).startWith(null);

    return propsStream.combineLatest(sideEffectsStream, props => ({
      ...props,

      onSetFocus,
      refInput,
    }));
  }),
  withTheme,
  reduxForm({
    form: "OfflineOrderOutboundSortingVerifyOrdersDialog",
    enableReinitialize: true,
    validate: (values, props) => ({
      orderStatus:
        !values.orderStatus &&
        props.getLocalisationMessage &&
        props.getLocalisationMessage("this_field_is_required"),

      weight:
        !values.weight &&
        props.getLocalisationMessage &&
        props.getLocalisationMessage("this_field_is_required"),
      category:
        isBlankString(values.category) &&
        props.getLocalisationMessage(
          "this_field_is_required",
          "This field is required.",
        ),
      transportationType:
        !values.transportationType &&
        props.getLocalisationMessage(
          "this_field_is_required",
          "This field is required.",
        ),
      warehouse:
        !isValidObjectId(values.warehouse) &&
        props.getLocalisationMessage("this_field_is_required"),
      to_warehouse:
        !isValidObjectId(values.to_warehouse) &&
        props.getLocalisationMessage("this_field_is_required"),
    }),
  }),
  formValues({
    orderBarcodes: "orderBarcodes",
    orderStatus: "orderStatus",
    weight: "weight",
    category: "category",
    next_postcode: "next_postcode",
    next_jurisdiction: "next_jurisdiction",
    to_postcode: "to_postcode",
    to_jurisdiction: "to_jurisdiction",
    supplier: "supplier",
    scannedOrders: "scannedOrders",
    currentOrder: "currentOrder",
    unknown: "unknown",
    unverified: "unverified",
    allOrders: "allOrders",
    verifiedOrders: "verifiedOrders",
    indexedOrders: "indexedOrders",
  }),
);

OfflineOrderOutboundSortingVerifyOrdersDialog.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.object,
  filter: PropTypes.object,

  warehouseId: PropTypes.number,
  weight: PropTypes.number,
  weights: PropTypes.array,

  orderStatus: PropTypes.string,
  barcodes: PropTypes.array,
  orderBarcodes: PropTypes.array,
  indexedOrders: PropTypes.array,

  setState: PropTypes.func,
  setWeights: PropTypes.func,
  state: PropTypes.instanceOf(Map),
  supplier: PropTypes.instanceOf(Map),

  open: PropTypes.bool.isRequired,
  hasUserPermission: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,

  getLocalisationMessage: PropTypes.func,
  isSubmitLoading: PropTypes.bool,

  onSetFocus: PropTypes.func,
  refInput: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};

function OfflineOrderOutboundSortingVerifyOrdersDialog(props) {
  const { classes, getLocalisationMessage, orderStatus, barcodes = [] } = props;
  const tab = props.state.get("tab", UNVERIFIED);
  const unverified = fromJS(
    barcodes.map(barcode => ({ barcode, status: UNVERIFIED })),
  );
  const unverifiedCount = unverified ? unverified.size : 0;
  const showDriverField = statusesWithDriver.has(orderStatus);

  const [scanNumber, setScanNumber] = useState(null);

  const [unknown, setUnknown] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [verifiedOrders, setVerifiedOrders] = useState([]);
  const unknownCount = unknown ? unknown.length : 0;

  const verifiedCount = verifiedOrders.length;

  const all = allOrders.concat(verifiedOrders);

  const totalCount = all ? all.length : 0;

  useEffect(() => {
    if (
      scanNumber &&
      !barcodes.includes(scanNumber) &&
      !props.orderBarcodes.includes(scanNumber)
    ) {
      setAllOrders(prev => {
        const numbers = prev.map(item => item.number);
        if (numbers.includes(scanNumber)) {
          return [...prev];
        }
        return [...prev, { number: scanNumber, status: UNKNOWN }];
      });

      setUnknown(prev => [...prev, { number: scanNumber, status: UNKNOWN }]);
    } else if (
      scanNumber &&
      barcodes.includes(scanNumber) &&
      !props.orderBarcodes.includes(scanNumber)
    ) {
      setVerifiedOrders(prev => [
        ...prev,
        {
          number: scanNumber,
          status: VERIFIED,
        },
      ]);

      props.change("orderBarcodes", [...props.orderBarcodes, scanNumber]);

      setAllOrders(prev => {
        const idx = prev.findIndex(i => i.number === scanNumber);
        return [...prev.slice(0, idx), ...prev.slice(idx + 1)];
      });

      props.setWeights(prev => {
        const idx = prev.findIndex(i => i.number === scanNumber);
        props.change(
          "weight",
          parseFloat(Number(props.weight) + Number(prev[idx].weight)).toFixed(
            3,
          ),
        );

        return [...prev.slice(0, idx), ...prev.slice(idx + 1)];
      });

      setScanNumber(null);
    }
  }, [scanNumber]);

  const unknownFilter = allOrders.filter(item => item.status === UNKNOWN);
  const blockView = unknownFilter.length > 0;

  useEffect(() => {
    if (barcodes && barcodes.length > 0) {
      setAllOrders(barcodes.map(number => ({ number, status: UNVERIFIED })));
    }
  }, [barcodes]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      maxWidth="lg"
      PaperProps={{
        style: {
          width: "1200px",
        },
      }}
    >
      <DialogTitle
        style={{
          color: props.theme.palette.appBarTextColor,
          backgroundColor: props.theme.palette.primary.main,
        }}
      >
        <FlexBox flex={true}>
          <FlexBox flex={true}>
            {`${getLocalisationMessage("verify_orders", "Verify Orders")} - ${
              props.filter.bean ? props.filter.bean.name : ""
            }`}
          </FlexBox>
          <FlexBox flex={true} justify={JUSTIFY_END}>
            {`${getLocalisationMessage(
              "verified",
              "Verified",
            )}: ${verifiedCount}, ${getLocalisationMessage(
              "total",
              "Total",
            )}: ${totalCount}`}
          </FlexBox>
        </FlexBox>
      </DialogTitle>

      <DialogContent>
        <form className={classes.form}>
          <FlexBox flex={true}>
            <FlexBox flex={9} direction="column">
              <FlexBox flex={true}>
                <ScannerTextField
                  className={classes.scannerField}
                  focus={true}
                  autoFocus={true}
                  fullWidth={true}
                  hintText={getLocalisationMessage(
                    "verify_orders",
                    "Verify Orders",
                  )}
                  onChange={v => setScanNumber(v)}
                  inputRef={props.refInput}
                />
                {props.hasUserPermission && (
                  <FlexBox
                    flex="none"
                    style={{ paddingLeft: 10, height: 45, paddingTop: 5 }}
                  >
                    <CustomButton
                      variant={CONTAINED}
                      color={SECONDARY}
                      disabled={!(allOrders && allOrders.length > 0)}
                      onClick={() => {
                        if (allOrders && allOrders.length > 0) {
                          props.change(
                            "orderBarcodes",
                            props.orderBarcodes.concat(barcodes),
                          );
                          setVerifiedOrders(prev => [
                            ...prev,
                            ...allOrders.map(item => ({
                              number: item.number,
                              status: VERIFIED,
                            })),
                          ]);

                          const allWeight = props.weights.reduce(
                            (totalReducer, item) =>
                              Number(_.get(item, "weight", 0)) + totalReducer,
                            0,
                          );

                          props.change(
                            "weight",
                            parseFloat(
                              Number(props.weight) + allWeight,
                            ).toFixed(3),
                          );
                        }

                        setAllOrders([]);
                      }}
                    >
                      {getLocalisationMessage("verify_all", "Verify All")}
                    </CustomButton>
                  </FlexBox>
                )}
              </FlexBox>

              {Boolean(
                !props.isLoading && unknownCount === 0 && unverifiedCount === 0,
              ) && (
                <ListSubheader>
                  {getLocalisationMessage(
                    "all_orders_were_verified",
                    "All Orders Were Verified",
                  )}
                  <Audio key="success" play={true} src={SuccessSound} />
                </ListSubheader>
              )}

              {scanNumber &&
                !props.orderBarcodes.includes(scanNumber) &&
                blockView && (
                  <div>
                    <OfflineOrderSortingVerifyBinConfirmDialog
                      open={blockView}
                      warehouseId={props.warehouseId}
                      binName={getValue(props.filter, "bean.name")}
                      scannedOrder={scanNumber}
                      onRequestClose={orderNumber => {
                        setScanNumber(null);

                        setAllOrders(prev => {
                          const idx = prev.findIndex(
                            i => i.number === orderNumber,
                          );
                          return [
                            ...prev.slice(0, idx),
                            ...prev.slice(idx + 1),
                          ];
                        });
                        props.onSetFocus(scanNumber);
                      }}
                      onConfirm={order => {
                        if (order && getValue(order, "barcode")) {
                          setVerifiedOrders(prev => [
                            ...prev,
                            {
                              number: getValue(order, "barcode"),
                              status: VERIFIED,
                            },
                          ]);

                          props.change(
                            "weight",
                            (
                              Number(props.weight) +
                              Number(getValue(order, "weight", 0))
                            ).toFixed(3),
                          );

                          props.change(
                            "orderBarcodes",
                            props.orderBarcodes.concat([
                              getValue(order, "barcode"),
                            ]),
                          );

                          props.change(
                            "indexedOrders",
                            props.indexedOrders.concat([
                              getValue(order, "barcode"),
                            ]),
                          );

                          setAllOrders(prev => {
                            const idx = prev.findIndex(
                              i => i.number === getValue(order, "barcode"),
                            );
                            return [
                              ...prev.slice(0, idx),
                              ...prev.slice(idx + 1),
                            ];
                          });
                          props.onSetFocus(getValue(order, "barcode"));

                          setScanNumber(null);
                        } else {
                          props.onSetFocus(scanNumber);

                          setScanNumber(null);
                        }
                      }}
                    >
                      <Audio play={true} src={ErrorSound} />
                    </OfflineOrderSortingVerifyBinConfirmDialog>
                  </div>
                )}

              {unknownCount === 0 && scanNumber && (
                <Audio play={true} key={scanNumber} src={BeepSound} />
              )}

              <Tabs
                value={tab}
                width="auto"
                onChange={(e, v) =>
                  props.setState(s => s.update("tab", () => v))
                }
                tabs={[
                  {
                    label: `${getLocalisationMessage(
                      "unverified_orders",
                      "Unverified Orders",
                    )} (${allOrders.length})`,
                    value: UNVERIFIED,
                  },
                  {
                    label: `${getLocalisationMessage(
                      "verified_orders",
                      "Verified Orders",
                    )} (${verifiedOrders ? verifiedOrders.length : 0})`,
                    value: VERIFIED,
                  },
                ]}
              />

              {tab === UNVERIFIED && (
                <OrderSortingVerifyBinOrdersTableWrapper
                  list={
                    fromJS(
                      allOrders.sort(
                        (a, b) =>
                          status.indexOf(a.status) - status.indexOf(b.status),
                      ),
                    ) || List()
                  }
                  isLoading={props.isLoading}
                />
              )}

              {tab === VERIFIED && (
                <OrderSortingVerifyBinOrdersTableWrapper
                  list={fromJS(verifiedOrders) || List()}
                />
              )}
            </FlexBox>
            <FlexBox
              flex={3}
              direction="column"
              justify={JUSTIFY_SPACE_AROUND}
              style={{ paddingLeft: 15 }}
            >
              <FlexBox direction="column">
                <FormAutoComplete
                  immediatelyShowError={!orderStatus}
                  name="orderStatus"
                  fullWidth={true}
                  label={getLocalisationMessage("status", "Status")}
                  options={OrderStatusCodes}
                  margin="dense"
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type To Search...",
                  )}
                  formatOption={x =>
                    formatOrderStatusCodeForLocalisation(
                      x,
                      getLocalisationMessage,
                    )
                  }
                />

                {(orderStatus === IN_TRANSIT ||
                  orderStatus === PREPARED_FOR_TRANSIT) && (
                  <FormWarehouseAutoComplete
                    name="warehouse"
                    label={getLocalisationMessage("next_warehouse")}
                    margin="dense"
                    postcodeIndexes={
                      fp.get("next_postcode.name", props)
                        ? [fp.get("next_postcode.name", props)]
                        : null
                    }
                    jurisdictionIds={
                      !fp.get("next_postcode.name", props) &&
                      fp.get("next_jurisdiction.id", props)
                        ? [fp.get("next_jurisdiction.id", props)]
                        : null
                    }
                    fullWidth={true}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                  />
                )}

                {props.supplier && showDriverField && (
                  <FormDriverAutoComplete
                    name="driver"
                    fullWidth={true}
                    margin="dense"
                    label={getLocalisationMessage("driver", "Driver")}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                  />
                )}

                <div style={{ marginBottom: 8, marginTop: 8 }}>
                  <FormCourierTypeSelectField
                    hintText={getLocalisationMessage(
                      "what_is_included_in",
                      "What is included in?",
                    )}
                    label={getLocalisationMessage(
                      "what_is_included_in",
                      "What is included in?",
                    )}
                    name="innerShipmentType"
                    fullWidth={true}
                    additionalOption={{
                      code: MIXED,
                      name: getLocalisationMessage(fp.toLower(MIXED)),
                    }}
                  />
                </div>

                {(orderStatus === IN_TRANSIT ||
                  orderStatus === PREPARED_FOR_TRANSIT) && (
                  <FormSelectField
                    name="category"
                    fullWidth={true}
                    margin="dense"
                    label={getLocalisationMessage("category", "Category")}
                    options={categoriesConstants}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                    formatOption={x => getLocalisationMessage(x)}
                  />
                )}

                {(orderStatus === IN_TRANSIT ||
                  orderStatus === PREPARED_FOR_TRANSIT) && (
                  <FormWarehouseAutoComplete
                    disableP7={false}
                    name="to_warehouse"
                    label={getLocalisationMessage(
                      "destination_warehouse",
                      "Destination Warehouse",
                    )}
                    margin="dense"
                    postcodeIndexes={
                      fp.get("to_postcode.name", props)
                        ? [fp.get("to_postcode.name", props)]
                        : null
                    }
                    includePostcode={true}
                    jurisdictionIds={
                      !fp.get("to_postcode.name", props) &&
                      fp.get("to_jurisdiction.id", props)
                        ? [fp.get("to_jurisdiction.id", props)]
                        : null
                    }
                    fullWidth={true}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                  />
                )}

                <div style={{ paddingTop: 8 }}>
                  <FormSelectField
                    name="transportationType"
                    fullWidth={true}
                    options={TransportationType}
                    formatOption={x =>
                      getLocalisationMessage(x.toLowerCase(), formatText(x))
                    }
                    label={getLocalisationMessage(
                      "transportation_type",
                      "Transportation Type",
                    )}
                  />
                </div>

                <FormTextField
                  type="number"
                  margin="dense"
                  name="weight"
                  fullWidth={true}
                  label={getLocalisationMessage("weight_kg", "Weight (kg)")}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </form>
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={props.onRequestClose}>
            {getLocalisationMessage("close", "Close")}
          </Button>
          <CustomButton
            disabled={
              !(props.orderBarcodes && props.orderBarcodes.length > 0) ||
              props.isSubmitLoading
            }
            variant={CONTAINED}
            color={SECONDARY}
            onClick={() => props.handleSubmit()}
            endIcon={
              props.isLoading || props.isLoading ? (
                <CircularProgress color="secondary" size={20} />
              ) : (
                <Done size={20} />
              )
            }
          >
            {props.isSubmitLoading || props.isLoading
              ? getLocalisationMessage("loading")
              : getLocalisationMessage("confirm", "Confirm")}
          </CustomButton>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(OfflineOrderOutboundSortingVerifyOrdersDialog);
