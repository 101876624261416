import React, { useState } from "react";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
} from "recompose";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import PropTypes from "prop-types";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { pipeStreams } from "../../helpers/StreamUtils";
import fp from "lodash/fp";
import { isEqualData } from "../../helpers/DataUtils";
import { Observable } from "rxjs";
import { fromJS, List, Map } from "immutable";
import DataListFilter from "../../helpers/DataListFilter";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import { Card, CardContent, IconButton } from "@material-ui/core";
import FlexBox from "../../components/ui-core/FlexBox";

import { makeStyles } from "@material-ui/core/styles";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import OrderCustomsSearchWrapper from "../../components/order-customs-sorting/OrderCustomsSearchWrapper";
import {
  batchBarcodesStatusUpdate,
  getBatchChildrenSimpleStream,
} from "../../api/admin/AdminOrderApi";
import CustomsOrdersList from "../../components/order-customs-sorting/CustomsOrdersList";
import AdminOrderDetailsDialogWrapperV2 from "../../wrappers/admin/AdminOrderDetailsDialogWrapperV2";
import OrderCustomsCompleteDialog from "../../components/order-customs-sorting/OrderCustomsCompleteDialog";
import AdminBatchUpdatesItemDialogWrapper from "../../wrappers/admin/AdminBatchUpdatesItemDialogWrapper";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { ORDER_CUSTOMS_LIST_VIEW_URL } from "../../constants/AdminPathConstants";
import { getUserWarehouseId } from "../../reducers/ProfileReducer";
import { ArrowBack } from "@material-ui/icons";
import {
  HOLD_ON_AT_CUSTOMS,
  RETURNED_FROM_CUSTOMS,
} from "../../constants/OrderStatusCodes";

const useStyles = makeStyles(() => ({
  topContent: {
    display: "flex",
    flex: "1 1 100%",
  },
  marginRight: {
    marginRight: 15,
  },
  tabs: {
    "& .MuiTab-root": {
      backgroundColor: "#fff",
    },
  },
  filter: {
    paddingLeft: 15,
    flex: 1,
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "flex-start",
  },
}));

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      warehouseId: getUserWarehouseId(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(propsStream => {
      const {
        handler: onRequestRefresh,
        stream: onRequestRefreshStream,
      } = createEventHandler();

      const idStream = propsStream
        .map(fp.flow(fp.get("params.id"), fp.toFinite))
        .distinctUntilChanged();

      const filterStream = propsStream
        .map(fp.flow(fp.get("location.query"), DataListFilter.create))
        .distinctUntilChanged(isEqualData);

      const listResponseStream = idStream
        .distinctUntilChanged(isEqualData)
        .switchMap(id =>
          getBatchChildrenSimpleStream(id)
            .repeatWhen(() => onRequestRefreshStream)
            .catch(() => Observable.of({})),
        )
        .startWith({})
        .map(response => fromJS(response))
        .map(response =>
          Map({
            pending: response.get("pending", false),
            total: response.getIn(["payload", "orders_count"], 0),
            list: response.getIn(["payload", "children"], List()),
            parent: response.get("payload", Map()),
          }),
        );

      return propsStream
        .combineLatest(
          listResponseStream,
          filterStream,
          (props, listResponse, filter) => {
            const list = listResponse.get("list");
            // const total = listResponse.get("total");

            const search = filter.getSearch();
            const filteredList = fp.isEmpty(search)
              ? list
              : list.filter(item => item.get("barcode") === search);

            return {
              ...props,
              onRequestRefresh,
              list: filteredList,
              total: filteredList.length,
              filter,
              parent: listResponse.get("parent"),
              isLoading: listResponse.get("pending"),
            };
          },
        )
        .distinctUntilChanged(isEqualData);
    }),
  ),
);

AdminCustomsOrdersListContainer.propTypes = {
  isLoading: PropTypes.bool,

  total: PropTypes.number,

  location: PropTypes.object,

  list: PropTypes.instanceOf(List),
  parent: PropTypes.instanceOf(Map),
  filter: PropTypes.instanceOf(DataListFilter),

  setLocationQueryFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,
  setLocation: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminCustomsOrdersListContainer(props) {
  const {
    getLocalisationMessage,
    location: { query },
  } = props;
  const classes = useStyles();

  const [completeTask, setCompleteTask] = useState(false);
  const [isShowBatchDetails, setIsShowBatchDetails] = useState(false);
  const batchId = props.parent && props.parent.get("barcode", "");
  let title = getLocalisationMessage("customs", "Customs");
  title = props.parent ? `${title} - ${batchId}` : "";
  return (
    <AdminAppLayout slug="customs_list" title={title}>
      {isShowBatchDetails && (
        <AdminBatchUpdatesItemDialogWrapper
          isCustoms={true}
          batchId={batchId}
          // refreshBatchList={props.onRequestRefresh()}
          onRequestClose={() => {
            setIsShowBatchDetails(false);
            props.setLocation(updateQuery(ORDER_CUSTOMS_LIST_VIEW_URL));
          }}
        />
      )}
      <OrderCustomsCompleteDialog
        open={completeTask}
        batchId={batchId}
        list={props.list}
        onRequestClose={() => setCompleteTask(false)}
        onSubmit={values => {
          const newUVArray = values.uvOrders.map(item => ({
            batch_id: batchId,
            status: HOLD_ON_AT_CUSTOMS,
            barcodes: [item.barcode],
            hold_on_reason: item.reason,
          }));

          const uvOrdersBarcode = values.uvOrders.map(item => item.barcode);

          const vOrders = values.orders.filter(
            item => !uvOrdersBarcode.includes(item),
          );

          const grouped = newUVArray.reduce(
            (acc, item) => {
              const key = `${item.status}_${item.hold_on_reason ||
                "no_reason"}`;
              if (item.hold_on_reason || item.status) {
                if (!acc.grouped[key]) {
                  acc.grouped[key] = {
                    status: item.status,
                    batch_id: item.batch_id,
                    hold_on_reason: item.hold_on_reason || null,
                    barcodes: [],
                  };
                }
                acc.grouped[key].barcodes.push(...item.barcodes);
              }
              return acc;
            },
            { grouped: {} },
          );

          batchBarcodesStatusUpdate(
            vOrders && vOrders.length > 0
              ? [
                  ...Object.values(grouped.grouped),
                  {
                    batch_id: batchId,
                    status: RETURNED_FROM_CUSTOMS,
                    barcodes: [...vOrders],
                  },
                ]
              : [...Object.values(grouped.grouped)],
          )
            .catch(error => props.showErrorMessage(error))
            .then(() => {
              setIsShowBatchDetails(true);

              props.showSuccessMessage("Successfully Updated");
            });
        }}
      />

      {fp.toFinite(query.view) > 0 && (
        <AdminOrderDetailsDialogWrapperV2
          tab={query.view_order_tab}
          orderId={fp.toFinite(query.view)}
          onTabChange={x => props.setLocationQuery(fp.set("view_order_tab", x))}
          onRequestClose={() =>
            props.setLocationQuery(
              fp.flow(fp.unset("view"), fp.unset("view_order_tab")),
            )
          }
          location={props.location}
        />
      )}

      {fp.toFinite(query.batch_id) > 0 && (
        <AdminBatchUpdatesItemDialogWrapper
          batchId={fp.toFinite(query.batch_id)}
          onRequestClose={() => {
            props.setLocationQuery(fp.unset("batch_id"));
            props.setLocation(updateQuery(ORDER_CUSTOMS_LIST_VIEW_URL));
          }}
        />
      )}

      <FlexBox direction="column" flex={true}>
        <FlexBox style={{ marginBottom: 10 }} element={<Card />}>
          <CardContent className={classes.topContent}>
            <FlexBox flex={true} style={{ gap: 16 }}>
              <FlexBox>
                <div>
                  <IconButton
                    onClick={event => {
                      event.preventDefault();
                      return props.setLocation(
                        updateQuery(ORDER_CUSTOMS_LIST_VIEW_URL),
                      );
                    }}
                  >
                    <ArrowBack />
                  </IconButton>
                </div>
              </FlexBox>
              <FlexBox flex={true} style={{ gap: 13 }} direction="column">
                <OrderCustomsSearchWrapper
                  filter={props.filter}
                  onChange={search => {
                    props.setLocationQueryFilter(
                      props.filter.setSearch(search[0]),
                    );
                  }}
                />
                <h6>
                  {getLocalisationMessage("total", "Total")}: {props.list.size}{" "}
                </h6>
              </FlexBox>
              <FlexBox flex={true} justify="flex-end">
                <CustomButton
                  style={{ height: "40px", minWidth: "300px", marginTop: 5 }}
                  color={SECONDARY}
                  variant={CONTAINED}
                  className={classes.marginRight}
                  onClick={() => {
                    setCompleteTask(true);
                  }}
                >
                  {getLocalisationMessage("complete_task", "Complete Task")}
                </CustomButton>
              </FlexBox>
            </FlexBox>
          </CardContent>
        </FlexBox>

        <FlexBox element={<Card />} flex={true}>
          <CustomsOrdersList
            isLoading={props.isLoading}
            totalCount={props.total}
            list={props.list}
            filter={props.filter}
            maxSearchItems={1}
            onFilterChange={filter => {
              props.setLocationQueryFilter(filter);
            }}
            onClickOrder={id => props.setLocationQuery(fp.set("view", id))}
            overscanRowCount={1}
            rowCount={props.list.size}
            rowGetter={options => props.list.get(options.index)}
            location={props.location}
          />
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminCustomsOrdersListContainer);
