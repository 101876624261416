import React, { useState } from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { formValues, reduxForm } from "redux-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormChipInput from "../form/FormChipInput";
import { withTheme } from "@material-ui/core/styles";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import FormAutoComplete from "../form/FormAutoComplete";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import {
  DELIVERY_FAILED,
  HOLD_ON_AT_CUSTOMS,
  IN_SORTING_FACILITY,
  ISSUED_TO_RECIPIENT,
  OUT_FOR_DELIVERY,
  READY_FOR_DELIVERY,
  RETURNED_FROM_CUSTOMS,
  RETURNED_TO_ORIGIN,
  RETURNING_TO_ORIGIN,
  SENT_TO_CUSTOMS,
} from "../../constants/OrderStatusCodes";
import { OrderedSet } from "immutable";
import ConfirmDialog from "../deprecated/ConfirmDialog";
import { COMPLETED } from "../../constants/ActStatusTypes";
import FormDateTimeField from "../form/FormDateTimeField";
import { isValidDate } from "../../helpers/DateUtils";

export const options = OrderedSet.of(
  IN_SORTING_FACILITY,
  SENT_TO_CUSTOMS,
  RETURNED_FROM_CUSTOMS,
  HOLD_ON_AT_CUSTOMS,
  OUT_FOR_DELIVERY,
  DELIVERY_FAILED,
  COMPLETED,
  RETURNING_TO_ORIGIN,
  RETURNED_TO_ORIGIN,
  READY_FOR_DELIVERY,
  ISSUED_TO_RECIPIENT,
);

const enhancer = compose(
  withTheme,
  renderIf("open"),
  useSheet({
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
    paper: {
      maxWidth: "1000px",
      minHeight: "auto",
    },
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  withHandlers({
    onSubmit: props => values => {
      if (!props.onSubmit) {
        return null;
      }

      return props.onSubmit(values);
    },
  }),
  reduxForm({
    form: "BatchHistoryUpdateNewForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      barcodes:
        fp.size(values.barcodes) === 0 &&
        props.getLocalisationMessage &&
        props.getLocalisationMessage("this_field_is_required"),
      status:
        !values.status &&
        props.getLocalisationMessage &&
        props.getLocalisationMessage("this_field_is_required"),
      updateDateTime:
        !isValidDate(values.updateDateTime) &&
        props.getLocalisationMessage &&
        props.getLocalisationMessage("this_field_is_required"),
    }),
  }),
  formValues({
    status: "status",
  }),
  withContext(
    {
      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,
    },
    props => ({
      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
    }),
  ),
);

BatchHistoryUpdateNewForm.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
  classes: PropTypes.object,
  theme: PropTypes.object,
  valid: PropTypes.bool,
};

BatchHistoryUpdateNewForm.defaultProps = {
  disableBarcodes: false,
};

function BatchHistoryUpdateNewForm(props) {
  const { getLocalisationMessage, classes } = props;
  const [confirm, setConfirm] = useState(false);

  if (confirm) {
    return (
      <ConfirmDialog
        confirmButtonLabel={getLocalisationMessage("yes", "Yes")}
        dismissButtonLabel={getLocalisationMessage("no", "NO")}
        open={confirm}
        onConfirm={props.handleSubmit}
        onRequestClose={() => setConfirm(false)}
      >
        {getLocalisationMessage("are_you_sure", "Are you sure ?")}
      </ConfirmDialog>
    );
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      PaperProps={{
        style: {
          minWidth: 1000,
          maxWidth: 1000,
        },
      }}
    >
      <form onSubmit={props.handleSubmit}>
        <PageLoading isLoading={props.submitting} />
        <DialogTitle
          style={{ color: props.theme.palette.appBarTextColor }}
          className={classes.dialogTitle}
        >
          {getLocalisationMessage(
            "custom_new_batch_update",
            "IPS batch update",
          )}
        </DialogTitle>
        <DialogContent className={classes.paper}>
          <FlexBox container={8} style={{ gap: 16 }}>
            <FlexBox style={{ width: "70%" }}>
              <FormChipInput
                name="barcodes"
                focus={true}
                margin="normal"
                fullWidth={true}
                label={getLocalisationMessage("orders_barcodes")}
              />
            </FlexBox>

            <FlexBox style={{ width: "30%", gap: 16 }} direction="column">
              <FormAutoComplete
                name="status"
                fullWidth={true}
                margin="normal"
                label={getLocalisationMessage("status", "Status")}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type To Search...",
                )}
                options={options}
                formatOption={option =>
                  formatOrderStatusCodeForLocalisation(
                    option,
                    getLocalisationMessage,
                  )
                }
              />

              <FormDateTimeField
                label={getLocalisationMessage("select_date", "Select date")}
                placeholder={getLocalisationMessage(
                  "select_date",
                  "Select date",
                )}
                name="updateDateTime"
                fullWidth={true}
              />
            </FlexBox>
          </FlexBox>
        </DialogContent>

        <DialogActions>
          <Button onClick={props.onRequestClose} primary={true}>
            {getLocalisationMessage("dismiss")}
          </Button>

          <Button
            type={props.valid ? "button" : "submit"}
            onClick={() => {
              if (props.valid) {
                setConfirm(true);
              }
            }}
            primary={true}
          >
            {getLocalisationMessage("submit")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default enhancer(BatchHistoryUpdateNewForm);
